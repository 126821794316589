import type {PersonalDetails, User} from "./types";
import {UserRecommendationData} from "./types";
import Immutable from "immutable";
export const parseUser: User = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		email: datum.get('email'),
		firstName: datum.get('firstName'),
		mobile: datum.get('mobile'),
		confirm: datum.get('confirm'),
		confirmationNum: datum.get('confirmationNum'),
		lastName: datum.get('lastName'),
		username: datum.get('username'),
		date_birth: datum.get('date_birth'),
		member_from: datum.get('member_from'),
		pontoi: datum.get('pontoi')
	}
}

export const parseUserMessage: User = (datum) => {
	if (!datum) return;
	return {
		text: datum.get('text'),
		variant: datum.get('variant')
	}
}

export const parsePersonalDetailsUser: PersonalDetails = (datum) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		id_user: datum.get('id_user'),
		country: datum.get('country'),
		city: datum.get('city'),
		address: datum.get('address'),
		phone1: datum.get('phone1'),
		phone2: datum.get('phone2'),
		family_state: datum.get('family_state'),
		work_state: datum.get('work_state'),
		facebook:datum.get('facebook'),
		twitter:datum.get('twitter'),
		linkedin:datum.get('linkedin'),
		url:datum.get('url'),
	}
}

export const parseUserRecommendation: UserRecommendationData = (datum) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		id_user: datum.get('id_user'),
		title: datum.get('title'),
		description: datum.get('description'),
	}
}

export const parseUserProfileImg: ProfileImage = (datum) => {
	if (!datum) return;
	return {
		photoprofile:datum.get('photoprofile'),
	}
}
export const parseUserCoverImg: CoverImage = (datum) => {
	if (!datum) return;
	return {
		photobanner:datum.get('photobanner'),
	}
}

export const parsePostId: PostId = (datum) => {
	if (!datum) return;
	return {
		idpost:datum.get('idpost'),
	}
}

export const parseAllPosts: Posts = (datum) => {
	
	if (!datum) return;
	let comments = null
	let user = null
	let contents = null
	let company = null
	if(datum.has('comments')){comments = datum.get('comments')}
	if(datum.has('user')){user = datum.get('user')}
	if(datum.has('company')){company = datum.get('company')}
	if(datum.has('contents')){contents = datum.get('contents')}

	return {
		id: datum.get('id'),
		id_user: datum.get('id_user'),
		public_date: datum.get('public_date'),
		description: datum.get('description'),
		public: datum.get('public'),
		type: datum.get('type'),
		comments:comments ? comments.toJS() : null,
		commentsNumber:datum.get('commentsNumber'),
		likesNumber:datum.get('likesNumber'),
		profilelogo:datum.get('profilelogo'),
		ihadLike:datum.get('ihadLike'),
		user:user ? user.toJS() : null,
		company:company ? company.toJS() : null,
		contents:contents ? contents.toJS() : null,
	}
}
export const parseCredentials  = (datum) => {
	if (!datum) return;
	return {
		username: datum.getIn(['credentials','username']),
		password: datum.getIn(['credentials','password']),
		rememberMe: datum.getIn(['credentials','rememberMe']),
	}
}

export const parseModal  = (datum) => {
	if (!datum) return;
	return datum.toJS()
}
//
// export const parseRecommendationWithIdData: UserRecommendationData = (datum) => {
// 	if (!datum) return;
// 	return {
// 		id: datum.get('id'),
// 		id_user: datum.get('id_user'),
// 		title: datum.get('title'),
// 		description: datum.get('description'),
// 	}
// }