// @flow
import React from 'react';
import Images from "../../assets";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../routers/routes";
import {useHistory} from "react-router-dom";

type Props = {

};

const Logo = (props: Props) => {
	const history = useHistory()
	return (
		<img
			onClick={() => history.push(getRouteUrl(ROUTE_PAGE_HOME))}
			src={Images.logoLayout}
			alt="logo"
			style={{maxWidth: 160, cursor: "pointer"}}
		/>
	);
};

export default Logo