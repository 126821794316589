// @flow
import {closeSnackbar, MaterialDesignContent, SnackbarProvider} from "notistack";
import {IconButton, styled} from "@mui/material";
import {blue, green, grey, orange, red} from "@mui/material/colors";
import {CancelOutlined} from "@mui/icons-material";

type Props = {

};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: green[500]
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: orange[700]
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: red[400]
	},
	'&.notistack-MuiContent-info': {
		backgroundColor: blue[400]
	},
}));

const SnackbarProviderExtended = (props: Props) => {
	return (
		<SnackbarProvider
			maxSnack={8}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			Components={{
				success: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent,
				warning: StyledMaterialDesignContent,
				info: StyledMaterialDesignContent
			}}
			dense={true}
			preventDuplicate={true}
			action={(snackbarId) => (
				<IconButton
					onClick={() => closeSnackbar(snackbarId)}
					sx={{
						color: grey[900]
					}}
				>
					<CancelOutlined/>
				</IconButton>
			)}
		>
			{props.children}
		</SnackbarProvider>
	);
};

export default SnackbarProviderExtended