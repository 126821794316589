// @flow
import React from "react";
import Box from "@mui/material/Box";
import GeneralDetailsTab from "../rightTabs/generalDetailsTab";
import RecommendationTab from "../rightTabs/recommendationTab";
import FileTab from "../rightTabs/fileTab";
import FriendsTab from "../rightTabs/friendsTab";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import Images from "../../../assets";
import { Typography, useMediaQuery } from "@mui/material";
import Footer from "../../footer/footer";
import theme from "../../../theme/theme";
import { useQuery } from "react-query";
import { getFiles, getFriendsFiles } from "../../../api/requests";
import { useParams } from "react-router-dom";
import useUserData from "../../../hooks/user/useUserData";
import ContentLoader from "../../contentLoader/contentLoader";
import { getFileExtension } from "../../../helpers/helpers";
import Link from "@mui/material/Link";
import { PictureAsPdf } from "@mui/icons-material";
import ImageListItem from "@mui/material/ImageListItem";
import Modal from "@mui/material/Modal";
import ImageList from "@mui/material/ImageList";
import CardMedia from "@mui/material/CardMedia";
import { imageExtensions } from "../../../helpers/constants";
import OstriaModal from "../../ostriaModal/ostriaModal";

type Props = {};

const FilesTab = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const [isModalOpen, setOpenModal] = React.useState(null);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams();
  const { accessCode } = useUserData();
  const { data: files, isLoading } = useQuery({ queryKey: ["userFiles", accessCode], queryFn: getFiles, enabled: !id });
  const { data: friendsFiles, isLoading: isFiendsFilesLoading } = useQuery({
    queryKey: ["userFriendsFiles", accessCode, id],
    queryFn: getFriendsFiles,
    enabled: !!id,
  });
  return (
    <>
      <Box className="centerLeftTab"
           sx={{
             width: {
               xs: "100%",
               sm: "100%",
               md: "60%",
               lg: "60%",
             },
           }}
      >

        <Box className="centerLeftTab">
          <Typography sx={{ padding: "5px" }}>
            {t("files")}
          </Typography>
          <Typography sx={{ border: "1px #F3F3F3 solid", marginRight: "3%" }} />
        </Box>
        <Box >
          {id ?
            isFiendsFilesLoading === false
              ?
              friendsFiles?.map(x =>
                getFileExtension(x) === "pdf" ?
                    <Link href={x} target="_blank"
                          underline="hover" style={{ objectFit: "contain", width: "120px" }} sx={{
                      fontSize: "large",
                      color: "#616161",
                      fontFamily: "Open Sans-Regular",
                      border: "1px solid grey",
                      borderRadius: "25px",
                      padding: "5px 5px 5px 10px",
                      margin: "10px",
                    }}>
                      <PictureAsPdf />
                    </Link>
                  : imageExtensions.includes(getFileExtension(x)) ?
                    <img
                      src={`${x}`}
                      alt={x}
                      loading="lazy"
                      style={{ objectFit: "contain", width: "120px", cursor: 'pointer' }}
                      onClick={() => setOpenModal(x)}
                    />
                    : null,
              ) :
              <ContentLoader loading={isFiendsFilesLoading}></ContentLoader>
            : isLoading === false
              ?
              files.map(x =>
                getFileExtension(x) === "pdf" ?
                    <Link href={x} target="_blank" style={{ objectFit: "contain", width: "120px" }}
                          underline="hover" sx={{
                      fontSize: "large",
                      color: "#616161",
                      fontFamily: "Open Sans-Regular",
                      border: "1px solid grey",
                      borderRadius: "25px",
                      padding: "5px 5px 5px 10px",
                      margin: "10px",
                    }}>
                      <PictureAsPdf />
                    </Link>
                  : imageExtensions.includes(getFileExtension(x)) ?
                    <img
                      src={`${x}`}
                      alt={x}
                      loading="lazy"
                      onClick={() => setOpenModal(x)}
                      style={{ objectFit: "contain", width: "120px", cursor: 'pointer' }}
                    />
                    : null,
              )
              :
              <ContentLoader loading={isLoading}></ContentLoader>
          }
        </Box>
        <OstriaModal
          open={Boolean(isModalOpen)}
          onClose={() => {
            setOpenModal(null);
          }}
        >
            <CardMedia
              component="img"
              image={isModalOpen}
              alt={isModalOpen}
            />
        </OstriaModal>
      </Box>
      {!matches && (
        <Box sx={{ width: "40%" }}>
          <GeneralDetailsTab type={props.type} userId={props.id} />
          {/*<RecommendationTab type={props.type} userId={props.id} />*/}
          <FriendsTab type={props.type} userId={props.id} />

          <Footer color={"#aeacac"} />
        </Box>
      )}
    </>
  );
};

export default FilesTab;