
import React, { useState } from "react";
import {Button, IconButton, ListItem, ListItemAvatar, ListItemButton, Typography} from "@mui/material";
import './searchResultsData.css';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_SEARCH} from "../../locales/pages/namespaces";
import Avatar from "@mui/material/Avatar";
import {grey} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import Images from "../../assets";
import Link from "@mui/material/Link";
import {
	ROUTE_PAGE_COMPANIES,
	ROUTE_PAGE_COMPANY_CENTER,
	ROUTE_PAGE_FRIEND,
	ROUTE_PAGE_FRIEND_ABOUT,
	ROUTE_PAGE_GROUP, ROUTE_PAGE_GROUPS, ROUTE_PAGE_JOB_SHOW, ROUTE_PAGE_JOBS, ROUTE_PAGE_SEARCH_RESULTS,
} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import { useParams } from "react-router";


function randomColor() {
	let hex = Math.floor(Math.random() * 0xFFFFFF);
	let color = "#" + hex.toString(16);
	
	return color;
}

const SearchResultsData = (props) => {
	const {search,type} = useParams();
	const {t} = useTranslation(PAGE_SEARCH)
	const history = useHistory()
	const searchResultData = props.data;

	return (
		<Box className="searchResultsContainer">
			{(type === 'all' || type === 'users') &&
			<>
				<Typography
					sx={{
						color: '#050505',
						fontFamily: 'Open Sans-Regular',
						fontSize: 'large',
						fontStyle: 'normal',
						fontWeight: '700',
					}}
				>
					{t('users')}
				</Typography>
				<Box className="users">
					{searchResultData?.users?.map(usersData => (
							<ListItem key={usersData.id} sx={{padding:'5px'}}>
								{/*<ListItemText>*/}
								{/*<ListItem>*/}
								<ListItemButton onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_FRIEND, {username: usersData.firstname + " " + usersData.lastname ,id:usersData.id}))}>
									<ListItemAvatar>
										{
											(usersData.image_profile) ?
												<Avatar sx={{width: 50, height: 50}} src={usersData.image_profile}></Avatar>
												:
												<Avatar  sx={{
													bgcolor: randomColor(),
													width: 50,
													height: 50
												}}>{(usersData?.firstname?.[0]?.toUpperCase()) + (usersData?.lastname?.[0]?.toUpperCase())}</Avatar>

										}
									</ListItemAvatar>
									<ListItemText primary={<span className="userName">{usersData.firstname + " " + usersData.lastname}</span>}
																secondary={<span className="memberDate">{usersData.member_from}</span>}
									/>
								</ListItemButton>
								{/*/!*</ListItem>*!/*/}
								{/*<IconButton*/}
								{/*	className="more"*/}
								{/*	size="small"*/}
								{/*	onClick={()=>console.log('more','& userId = ',usersData.id)}*/}
								{/*	sx={{*/}
								{/*		border: '1px black solid',*/}
								{/*		borderRadius: '5px',*/}
								{/*		"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},*/}
								{/*		height: '28px',*/}
								{/*		paddingLeft: '5px'*/}
								{/*	}}*/}
								{/*>*/}
								{/*	<Avatar sx={{width: '15px', height: '15px'}} src={Images.more}/>*/}

								{/*</IconButton>*/}
							</ListItem>
						)
					)
					}
					<Box sx={{textAlign:'start'}}>
						<Link href={getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS,{type:'users',search:search} )} underline="hover" sx={{
							color: '#27293D',
							fontFamily: 'Open Sans-Regular',
							fontSize: 'smaller',
							fontStyle: 'normal',
							fontWeight: '400',
							lineHeight: 'normal'
						}}>{t('show all results')}</Link>

					</Box>
				</Box>
			</>
			}
			{(type === 'all' || type === 'companies') &&
			<>
				<Typography
					sx={{
						color: '#050505',
						fontFamily: 'Open Sans-Regular',
						fontSize: 'large',
						fontStyle: 'normal',
						fontWeight: '700',
					}}
				>
					{t('companies')}
				</Typography>
				<Box className="companies">
					{searchResultData?.companies?.map(companiesData => (
							<ListItem key={companiesData.id} sx={{padding:'5px'}}>
								{/*<ListItemText>*/}
								<ListItemButton onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_COMPANY_CENTER,{id:companiesData.id} ))}>
									<ListItem>
										<ListItemAvatar>
											{
												(companiesData.logo) ?
													<Avatar sx={{width: 50, height: 50}} src={companiesData.logo}></Avatar>
													:
													<Avatar  sx={{
														bgcolor: randomColor(),
														width: 50,
														height: 50
													}}>{(companiesData?.title?.[0]?.toUpperCase())}</Avatar>

											}
										</ListItemAvatar>
										<ListItemText primary={<span className="userName">{companiesData.title}</span>}
											// secondary={
											//   <>
											//   <Box>
											// 	  <span className="memberDate">
											// 		  {companiesData.likes}
											// 	  </span>
											// 	  <Box className="companiesButtons">
											// 		  <IconButton
											// 			  className="more"
											// 			  size="small"
											// 			  onClick={() => console.log('more', '& userId = ', companiesData.id)}
											// 			  sx={{
											// 				  border: '1px black solid',
											// 				  borderRadius: '4px',
											// 				  "&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
											// 				  height: '28px',
											// 				  paddingLeft: '5px',
											// 				  marginRight:'5px'
											// 			  }}
											// 		  >
											// 			  <Avatar sx={{width: '15px', height: '15px'}}
											// 					  src={Images.companyLikeIcon}/>
											// 				  <Typography sx={{color: '#27293D',
											// 							  textAlign: 'center',
											// 							  fontFamily: 'Roboto',
											// 							  fontSize: 'small',
											// 							  fontStyle: 'normal',
											// 							  fontWeight: '600',
											// 					          paddingLeft: '5px',
											// 						  }}
											// 			  >
											// 				  {t('like it')}
											// 			  </Typography>
											//
											// 		  </IconButton>
											// 		  <IconButton
											// 			  className="more"
											// 			  size="small"
											// 			  onClick={() => console.log('more', '& userId = ', companiesData.id)}
											// 			  sx={{
											// 				  border: '1px black solid',
											// 				  borderRadius: '5px',
											// 				  "&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
											// 				  height: '28px',
											// 				  paddingLeft: '5px'
											// 			  }}
											// 		  >
											// 			  <Avatar sx={{width: '15px', height: '15px'}}
											// 					  src={Images.more}/>
											//
											// 		  </IconButton>
											// 	  </Box>
											//   </Box>
											//   </>
											// }

										/>

									</ListItem>
								</ListItemButton>
							</ListItem>
						)
					)
					}
					<Box sx={{textAlign:'start'}}>
						<Link href={getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS,{type:'companies',search:search} )} underline="hover" sx={{
							color: '#27293D',
							fontFamily: 'Open Sans-Regular',
							fontSize: 'smaller',
							fontStyle: 'normal',
							fontWeight: '400',
							lineHeight: 'normal'
						}}>{t('show all results')}</Link>

					</Box>
				</Box>
			</>
			}
			{(type === 'all' || type === 'groups') &&
			<>
				<Typography
					sx={{
						color: '#050505',
						fontFamily: 'Open Sans-Regular',
						fontSize: 'large',
						fontStyle: 'normal',
						fontWeight: '700',
					}}
				>
					{t('groups')}
				</Typography>
				<Box className="groups">
					{searchResultData?.groups?.map(groupsData => (
							<ListItem key={groupsData.id} sx={{padding:'5px'}}>
								{/*<ListItemText>*/}
								<ListItemButton onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_GROUP,{groupCode:groupsData.id} ))}>
									<ListItem>
										<ListItemAvatar>
											{
												(groupsData.img) ?
													<Avatar sx={{width: 50, height: 50}} src={groupsData.img}></Avatar>
													:
													<Avatar  sx={{
														bgcolor: randomColor(),
														width: 50,
														height: 50
													}}>{(groupsData?.title?.[0]?.toUpperCase())}</Avatar>

											}
										</ListItemAvatar>
										<ListItemText primary={<span className="userName">{groupsData.title}</span>}
											// secondary={
											//   <>
											// 	  <Box>
											// 	  <span className="memberDate">
											// 		  {groupsData.likes}
											// 	  </span>
											// 		  <Box className="companiesButtons">
											// 			  <IconButton
											// 				  className="more"
											// 				  size="small"
											// 				  onClick={() => console.log('more', '& userId = ', groupsData.id)}
											// 				  sx={{
											// 					  border: '1px black solid',
											// 					  borderRadius: '4px',
											// 					  "&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
											// 					  height: '28px',
											// 					  paddingLeft: '5px',
											// 					  marginRight:'5px'
											// 				  }}
											// 			  >
											// 				  <Avatar sx={{width: '15px', height: '15px'}}
											// 						  src={Images.companyLikeIcon}/>
											// 				  <Typography sx={{color: '#27293D',
											// 					  textAlign: 'center',
											// 					  fontFamily: 'Roboto',
											// 					  fontSize: 'small',
											// 					  fontStyle: 'normal',
											// 					  fontWeight: '600',
											// 					  paddingLeft: '5px',
											// 				  }}
											// 				  >
											// 					  {t('like it')}
											// 				  </Typography>
											//
											// 			  </IconButton>
											// 			  <IconButton
											// 				  className="more"
											// 				  size="small"
											// 				  onClick={() => console.log('more', '& userId = ', groupsData.id)}
											// 				  sx={{
											// 					  border: '1px black solid',
											// 					  borderRadius: '5px',
											// 					  "&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
											// 					  height: '28px',
											// 					  paddingLeft: '5px'
											// 				  }}
											// 			  >
											// 				  <Avatar sx={{width: '15px', height: '15px'}}
											// 						  src={Images.more}/>
											//
											// 			  </IconButton>
											// 		  </Box>
											// 	  </Box>
											//   </>
											// }

										/>

									</ListItem>
								</ListItemButton>
							</ListItem>
						)
					)
					}
					<Box sx={{textAlign:'start'}}>
						<Link href={getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS,{type:'groups',search:search} )} underline="hover" sx={{
							color: '#27293D',
							fontFamily: 'Open Sans-Regular',
							fontSize: 'smaller',
							fontStyle: 'normal',
							fontWeight: '400',
							lineHeight: 'normal'
						}}>{t('show all results')}</Link>
					</Box>
				</Box>
			</>
			}
			{(type === 'all' || type === 'ads') &&
				<>
					<Typography
						sx={{
							color: '#050505',
							fontFamily: 'Open Sans-Regular',
							fontSize: 'large',
							fontStyle: 'normal',
							fontWeight: '700',
						}}
					>
						{t('ads')}
					</Typography>
					<Box className="ads">
						{searchResultData?.ads?.map(adsData => (
								<ListItem key={adsData.id} sx={{padding:'5px'}}>
									{/*<ListItemText>*/}
									<ListItemButton onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_JOB_SHOW,{jobId:adsData.id} ))}>
										<ListItem>
											<ListItemAvatar>
												{
													(adsData?.company?.logo) ?
														<Avatar sx={{width: 50, height: 50}} src={adsData?.company?.logo}></Avatar>
														:
														<Avatar  sx={{
															bgcolor: randomColor(),
															width: 50,
															height: 50
														}}>{(adsData?.title?.[0]?.toUpperCase())}</Avatar>

												}
											</ListItemAvatar>
											<ListItemText primary={<span className="userName">{adsData.title}</span>}
																		secondary={
																			<>
																				<Box>
													  <span className="memberDate">
														  {adsData?.company?.name}
													  </span>
																					<Typography
																						sx={{
																							backgroundColor: '#F0F0F0',
																							borderRadius: '10px',
																							display: 'flex',
																							width: 'fit-content',
																							height: '32px',
																							color: '#616161',
																							padding: '5px',
																							fontSize: 'small',
																						}}
																					>
																						{adsData.worktype}
																					</Typography>
																				</Box>
																			</>
																		}

											/>

										</ListItem>
									</ListItemButton>
								</ListItem>
							)
						)
						}
						<Box sx={{textAlign:'start'}}>
							<Link href={getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS,{type:'ads',search:search} )} underline="hover" sx={{
								color: '#27293D',
								fontFamily: 'Open Sans-Regular',
								fontSize: 'smaller',
								fontStyle: 'normal',
								fontWeight: '400',
								lineHeight: 'normal'
							}}>{t('show all results')}</Link>
						</Box>
					</Box>
				</>
			}

		</Box>
	);
};

export default SearchResultsData