import Card from "@mui/material/Card";
import { Button, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_JOB_SHOW } from "../../routers/routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGE_JOB } from "../../locales/pages/namespaces";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const JobCard = ({ job }) => {
  const history = useHistory()
  const {t} = useTranslation(PAGE_JOB)
  const handleJobInfo = (resultId) => {
    history.push(getRouteUrl(ROUTE_PAGE_JOB_SHOW,{jobId:job.id}))

  }

  return(
    <Card
      key={job.id}
      elevation={0}
      sx={{
        minWidth: 275,
        // maxWidth: '370px',
        border: '2px solid',
        borderRadius: '20px',
        // maxWidth: 370,
        width: {
          xs: '100%',
          sm: '98%',
          md: '98%',
          lg: '98%',
        },
        marginBottom: '2%',
      }}

    >
      <CardContent
        // onClick={() => handleJobInfo(job.id)}
      >
        <Box className={"cardTitle"}>
          <Typography gutterBottom variant="h6" component="div">
            {job.isOwner === "true" ? `${job.title} ${job.isActive === "1" ? "(Ενεργή)" : "(Ανενεργή)"}` : job.title}
          </Typography>
          {job.isOwner === "true" ? <Box>
            <IconButton
              style={{ float: "right" }}
              onClick={(e) => job.handleClick({ id: job.id, e })}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
          :
            <Button
            onClick={() => handleJobInfo(job.id)}
          sx={{
            color: "white",
            backgroundColor: "black",
            borderRadius: '10px',
            "&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
            justifyContent: 'center',
            padding:'15px',
            height: '42px',
            fontSize: 'revert'

          }}>
          {t('show position')}
        </Button>
          }

        </Box>

        <Typography variant="body2" color="text.secondary" sx={{fontWeight: 'bold'}}>
          {job.companyName}
        </Typography>
        <Typography sx={{color: '#595959', fontSize: 'small', marginBottom: '5%'}}>
          {job.city}
        </Typography>
        <Box className="tags">
          <Typography
            sx={{
              backgroundColor: '#F0F0F0',
              borderRadius: '10px',
              display: 'flex',
              width: 'fit-content',
              height: '32px',
              color: '#616161',
              padding: '5px',
              fontSize: 'small',

            }}
          >
            {job.employmentType}
          </Typography>
          {(job.salary) ?
            <Typography
              sx={{
                backgroundColor: '#F0F0F0',
                borderRadius: '10px',
                display: 'flex',
                width: 'fit-content',
                height: '32px',
                color: '#616161',
                padding: '5px',
                fontSize: 'small',
                marginLeft: '5px',
              }}
            >
              {job.salary + " €" }
            </Typography>
            :
            ''
          }
          {(job.remote) ?
            <Typography
              sx={{
                backgroundColor: '#F0F0F0',
                borderRadius: '10px',
                display: 'flex',
                width: 'fit-content',
                height: '32px',
                color: '#616161',
                padding: '5px',
                fontSize: 'small',
                marginLeft: '5px',
              }}
            >
              {job.remote}
            </Typography>
            :
            ''
          }

        </Box>


      </CardContent>
    </Card>
  )
}

export default JobCard