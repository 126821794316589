// @flow 
import React, {useEffect, useState} from 'react';
import './videoPreview.css'
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Images from "../../assets";
import Link from "@mui/material/Link";


type Props = {
	
};

	
const VideoPreview = (props) => {
	const url= props.url;
	const [videoInfo, setVideoInfo] = useState(null);
	const [close,setClose] = useState( props.close);
	
	
	const getVideoInfo = async () => {
		const response = await fetch(`http://www.youtube.com/oembed?url=${url}&format=json`);
		const data = await response.json();
		setVideoInfo(data);
	};
	
	useEffect(() => {
		getVideoInfo();
	}, []);
	
	if (!videoInfo) {
		return null;
	}
	
	return (
		<>
		{(close === false) ?
			<Box>
				<Link href={url} underline="hover">
					{url}
				</Link>
				<Box className="videoPreview">
					<img className="videoImage" src={videoInfo.thumbnail_url} alt={videoInfo.title}/>
					<Box className="videoDetails">
						<Box className="videoTitle">
							<Typography sx={{
								color: '#050505',
								fontFamily: 'Open Sans-Regular',
								fontSize: 'large',
								fontStyle: 'normal',
								fontWeight: '700',
								height: '-webkit-fill-available',
							}}>
								{videoInfo.title}
							</Typography>
							
							<IconButton
								value={close}
								onClick={() => setClose(true)}
							>
								<img alt="bell" src={Images.cross}/>
							</IconButton>
						</Box>
						<Typography sx={{
							color: '#616161',
							fontFamily: 'Open Sans-Regular',
							fontSize: '15px',
							fontStyle: 'normal',
							fontWeight: '400',
							height: '-webkit-fill-available',
						}}>
							{(videoInfo.description) ? videoInfo.description : videoInfo.title}
						</Typography>
					</Box>
				</Box>
			</Box>
			:
			''
			}
			</>
	);
};

export default VideoPreview