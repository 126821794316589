import React, { useEffect, useMemo, useState } from "react";
import {
  Button, Checkbox, FormHelperText,
  ListItem,
  ListItemAvatar, ListItemButton, Modal, Stack,
  TextField, FormControl,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { PAGE_HOME } from "../../../locales/pages/namespaces";
import "./post.css";
import Images from "../../../assets";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import useUserData from "../../../hooks/user/useUserData";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VideoPreview from "../videoPreview";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import Poll from "../pollModal/poll";
import CreateDivision from "../../userMoreMenu/components/createDivision";
import { Add, CheckBox, Clear, ExpandLess, ExpandMore, Lock, Public, PublicOff } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";
import axios from "axios";
import { getUserProfileImg } from "../../../actions/currentUser/actions";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_COMPANY_CENTER,
  ROUTE_PAGE_GROUP,
  ROUTE_PAGE_HOME,
  ROUTE_PAGE_NOTIFICATIONS_LIST,
} from "../../../routers/routes";
import PostComponent from "../../postComponent/postComponent";
import { useHistory, useLocation, useParams } from "react-router";
import { handleImageCompression, handlePdfCompression } from "../../../helpers/helpers";


const Form = (props: Props) => {
  // const {formik, loading,onSubmit} = props
  const history = useHistory();
  // Define the regex pattern
  const groupRegex = /group\/(\d+)\/chat/;
  const groupMatch = history?.location?.pathname.match(groupRegex);
  const companyRegex = /company\/(\d+)\/center/;
  const companyMatch = history?.location?.pathname.match(companyRegex);
  const { t } = useTranslation(PAGE_HOME);
  const { data, accessCode, loading, message, userProfileImg } = useUserData();
  const { submitPost, setUserMessage } = useUserAction();
  const [global, setGlobal] = useState(true);
  const userNameFirstLetter = useMemo(() => (data?.username?.[0]?.toUpperCase()), [data?.username]);
  const [showPostModal, setShowPostModal] = useState(true);

  // const [open, setOpen] = useState(false);
  // const [uploadImage, setUploadImage] = useState<File | null>(null);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  //image
  const [imageUrl, setImageUrl] = useState(null);
  const handleImageUpload = async (event) => {

    let compressedFile = event.target.files[0];
    if (event.target.files[0].type.includes("image")) {
      compressedFile = await handleImageCompression(event);
    } else if (event.target.files[0].type.includes("pdf")) {
      compressedFile = await handlePdfCompression(event);
    }

    const reader = new FileReader();
    formik.setFieldValue("image", compressedFile);

    reader.onloadend = () => {
      setImageUrl(reader.result);

    };
    reader.readAsDataURL(compressedFile);
  };

  //pdf
  const [pdf, setPdf] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    formik.setFieldValue("file", event.currentTarget.files[0]);

    reader.onloadend = () => {
      setPdf(reader.result);
    };
    reader.readAsDataURL(file);

  };
  //----------------------------------------------//
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [key, setKey] = useState(0);
  const [link, setLink] = useState(null);


  const handlePaste = (e, link) => {
    if (link.charAt(12) === "y") {
      // 	var number = link.substring(32);    //key # = from 33rd character on
      // 	var embed = "https://www.youtube.com/embed/" + number;
      // 	setSrc(embed);
      setLink(link);
      setKey(key + 1); // key = for restart the VideoPreview component with new link
      const str = e.clipboardData.getData("Text");
      const newStr = str.replace(/[+-.]/g, "");
      if (str !== newStr) {
        e.preventDefault();
      }
    }
  };
  const globalClick = () => {
    setGlobal(true);
    formik.setFieldValue("public", true);
    handleMenuClose();
  };
  const privacyClick = () => {
    setGlobal(false);
    formik.setFieldValue("public", false);
    handleMenuClose();
  };

  let submitted = 0;
  const onSubmit = (values) => {
    // submitPost(values)
    axios({
      url: "https://dev.ostrialinked.gr/api/api.php?q=9a&scode=" + accessCode,
      method: "post",
      data: {
        ...values,
        type: groupMatch ? "group" : companyMatch ? "company" : null,
        idco: groupMatch ? groupMatch[1] : companyMatch ? companyMatch[1] : null,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      const postId = response.data.idpost;
      if (imageUrl && postId) {
        console.log(values.image);
        handleSubmitFile(values, postId, "image").then(r => console.log(r));
      }
      if (pdf && postId) {
        console.log(values.file);
        handleSubmitFile(values, postId, "pdf").then(r => console.log(r));
      }
      if (!imageUrl) {
        submitted = submitted + 1;
        if (submitted === 2) {
          if (response.data.type === "company") {
            window.location.href = getRouteUrl(ROUTE_PAGE_COMPANY_CENTER, { id: companyMatch[1] });
          } else if (response.data.type === "group") {
            window.location.href = getRouteUrl(ROUTE_PAGE_GROUP, { groupCode: groupMatch[1] });
          } else {
            window.location.href = getRouteUrl(ROUTE_PAGE_HOME);
          }
        }
      }
      if (!pdf) {
        submitted = submitted + 1;
        if (submitted === 2) {
          if (response.data.type === "company") {
            window.location.href = getRouteUrl(ROUTE_PAGE_COMPANY_CENTER, { id: companyMatch[1] });
          } else if (response.data.type === "group") {
            window.location.href = getRouteUrl(ROUTE_PAGE_GROUP, { groupCode: groupMatch[1] });
          } else {
            window.location.href = getRouteUrl(ROUTE_PAGE_HOME);
          }
        }
      }
    }).catch((error) => {

    });


  };


  async function handleSubmitFile(values, postId, typeOfFile) {
    // event.preventDefault()
    const formData = new FormData();
    if (typeOfFile === "image") {
      formData.append("file", values.image);
      formData.append("type", values.image.type);
      formData.append("id_content", postId);
    } else if (typeOfFile === "pdf") {
      formData.append("file", values.file);
      formData.append("type", values.file.type);
      formData.append("id_content", postId);
    }


    try {
      const response = await axios({
        method: "post",
        url: "https://dev.ostrialinked.gr/api/api.php?q=9b&scode=" + accessCode,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },

      });

      console.log(response.status);
      if (response.status === 200) {
        //what to do after success save
        submitted = submitted + 1;
        if (submitted === 2) {
          if (companyMatch) {
            window.location.href = getRouteUrl(ROUTE_PAGE_COMPANY_CENTER, { id: companyMatch[1] });
          } else if (groupMatch) {
            window.location.href = getRouteUrl(ROUTE_PAGE_GROUP, { groupCode: groupMatch[1] });
          } else {
            window.location.href = getRouteUrl(ROUTE_PAGE_HOME);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  const validationSchema = yup.object({
    description: yup
      .string()
      .required(t("required")),
    public: yup
      .boolean(),
    // .required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
      public: true,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,

  });

  useEffect(() => {

    if (message) {
      enqueueSnackbar(t(message?.text), {
          variant: message?.variant,
          autoHideDuration: 10000,

        },
      );
      setUserMessage(null);
    }
  }, [message, setUserMessage, t]);
  return (
    (showPostModal) ?
      (
        <FormikProvider value={formik} onSubmit={formik.handleSubmit}>
          <Box className="post"
               component="form"
               noValidate
               onSubmit={formik.handleSubmit}
          >

            <Box className="postContainer" sx={{
              width: "100%",
            }}>
              <Box className="postTitle">
                <Typography sx={{
                  color: "#050505",
                  fontFamily: "Open Sans-Regular",
                  fontSize: "large",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}>
                  {t("create a post")}
                </Typography>

                <Tooltip title={t("close")}>
                  <IconButton
                    onClick={() => setShowPostModal(false)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <img className="cross" alt="cross_png" src={Images.cross} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box className="privacyDetails">
                <ListItem sx={{ padding: "0px", borderBottom: "0px #F3F3F3 solid" }}>
                  <ListItemAvatar>
                    {(userProfileImg.photoprofile) ?
                      <Avatar sx={{ width: 40, height: 40, marginRight: "5px" }}
                              src={userProfileImg.photoprofile}></Avatar>
                      :
                      <Avatar
                        sx={{
                          bgcolor: grey[900],
                          width: 40,
                          height: 40,
                          marginRight: "5px",
                        }}>{userNameFirstLetter}</Avatar>
                    }
                  </ListItemAvatar>
                  {/*<ListItemText primary={<span className="friendName">{data.FirstName + " " + data.LastName}</span>} secondary={*/}
                  <ListItemText primary={<span className="userName">{data.username}</span>}
                                secondary={
                                  <>
                                    <Box sx={{ display: "flex" }}>
                                      <Box sx={{
                                        display: "flex",
                                        borderRadius: "4px",
                                        background: "#F5F5F5",
                                      }}>

                                        <Typography
                                          sx={{ display: "flex", fontSize: "medium" }}>
                                          {global &&
                                            <>
                                              <Public />
                                              {t("global")}
                                            </>
                                          }
                                          {!global &&
                                            <>
                                              <PublicOff />
                                              {t("privacy")}
                                            </>
                                          }
                                        </Typography>

                                        <Tooltip title={t("Privacy settings")}>
                                          <IconButton
                                            onClick={handleMenuClick}
                                            sx={{
                                              padding: "0px",
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                          >
                                            <img className="caret_down"
                                                 alt="carret_down_png"
                                                 src={Images.caret_down} />
                                          </IconButton>
                                        </Tooltip>
                                        <Menu
                                          anchorEl={anchorEl}
                                          id="account-menu"
                                          open={menuOpen}
                                          onClose={handleMenuClose}
                                          PaperProps={{
                                            elevation: 0,
                                            sx: {
                                              overflow: "visible",
                                              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                              mt: 1.5,
                                              "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                              },
                                              "&:before": {
                                                content: "\"\"",
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform: "translateY(-50%) rotate(45deg)",
                                                zIndex: 0,
                                              },
                                            },
                                          }}
                                          transformOrigin={{
                                            horizontal: "right",
                                            vertical: "top",
                                          }}
                                          anchorOrigin={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                          }}
                                        >


                                          <ListItemButton onClick={globalClick}>
                                            <ListItemIcon>
                                              <Public />
                                            </ListItemIcon>
                                            <ListItemText primary={t("global")} />
                                          </ListItemButton>
                                          <ListItemButton onClick={privacyClick}>
                                            <ListItemIcon>
                                              <PublicOff />
                                            </ListItemIcon>
                                            <ListItemText primary={t("privacy")} />
                                          </ListItemButton>
                                        </Menu>
                                      </Box>
                                    </Box>
                                  </>
                                }
                  />
                </ListItem>
              </Box>
              <FormControl
                variant="standard"
                fullWidth
                sx={{
                  marginBottom: 2,
                }}
                error={
                  formik.touched["desription"] &&
                  Boolean(formik.errors["description"])
                }
              >

                <TextField
                  id={"description"}
                  value={formik.values.description}
                  name={"description"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // label={t('description')}
                  placeholder={t("what are you thinking?")}
                  defaultValue=" "
                  size={"small"}
                  inputProps={{ maxLength: 1000, style: { height: "unset", overflow: "auto" } }}
                  variant="outlined"
                  multiline={true}
                  minRows={2}
                  required
                  onPaste={(e) => handlePaste(e, e.clipboardData.getData("Text"))}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                      height: 50,
                      border: "0px solid #ffffff",
                      marginTop: "1%",

                      ":hover": {
                        border: "0.5px solid #ffffff !important",
                        boxShadow: "-1px 1px 4px 4px #ffffff",
                      },
                      ":focus-within": { border: "0.5px solid #ffffff !important" },
                    },
                    "& .MuiOutlinedInput-root.Mui-disabled": {
                      ":hover": {
                        border: "1px solid #ffffff !important",
                        boxShadow: "none",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
                <FormHelperText>
                  <ErrorMessage name="description">
                    {msg => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </FormHelperText>
              </FormControl>
              {imageUrl &&

                <Box
                  sx={{ width: "100%" }}
                >

                  <img src={imageUrl} alt="Uploaded Image" height="100" />
                  <Button sx={{
                    "&:hover": {
                      backgroundColor: "lightgrey",
                    },
                    marginTop: "-185px",
                    marginLeft: "-20px",
                    backgroundColor: "lightgray",
                    borderRadius: "50px",
                    fontSize: "small",
                    minWidth: "min-content",
                  }}
                          onClick={() => setImageUrl(null)}>
                    <Clear sx={{ fontSize: "small" }} />
                  </Button>
                </Box>
              }

              {pdf && (
                <Box
                  sx={{
                    border: "solid 1px",
                    borderRadius: "20px",
                    padding: "10px",
                    borderColor: "lightgrey",
                    // width: 'fit-content',
                    display: "flex",
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                    },
                  }}
                >


                  <Box sx={{
                    display: "grid",

                    width: "100%",
                  }}>
                    <Typography sx={{
                      fontSize: {
                        xs: "x-small",
                        sm: "small",
                        md: "medium",
                        lg: "medium",
                      },
                    }}> File details: </Typography>
                    <Typography sx={{
                      fontSize: {
                        xs: "x-small",
                        sm: "small",
                        md: "medium",
                        lg: "medium",
                      },
                    }}> Name: {pdf.name} </Typography>
                    <Typography sx={{
                      fontSize: {
                        xs: "x-small",
                        sm: "small",
                        md: "medium",
                        lg: "medium",
                      },
                    }}> Type: {pdf.type} </Typography>
                    <Typography sx={{
                      fontSize: {
                        xs: "x-small",
                        sm: "small",
                        md: "medium",
                        lg: "medium",
                      },
                    }}> Size: {pdf.size} bytes</Typography>

                  </Box>
                  <Box>
                    <Button sx={{
                      "&:hover": {
                        backgroundColor: "lightgrey",
                      },
                      backgroundColor: "lightgray",
                      borderRadius: "50px",
                      fontSize: "small",
                      minWidth: "min-content",
                    }}
                            onClick={() => setPdf(null)}>
                      <Clear sx={{ fontSize: "small" }} />
                    </Button>
                  </Box>
                </Box>

              )}


              <div key={key} className="videoPreviewContainer">
                {link && <VideoPreview url={link} close={false} />}
              </div>

              <Box className="postButtons">
                <Box className="iconBtns">
                  {/*<IconButton*/}
                  {/*  variant="contained"*/}
                  {/*  component="label"*/}
                  {/*  // onClick={() => history.push(getRouteUrl())}*/}
                  {/*>*/}
                  {/*  <img alt="clip" src={Images.clipPostComponent} />*/}
                  {/*  <input type="file" name="file" hidden accept="application/pdf"*/}
                  {/*         onChange={handleFileChange} />*/}
                  {/*</IconButton>*/}
                  <IconButton
                    variant="contained"
                    component="label"
                  >
                    <img alt="search" src={Images.addPictures} />
                    <input
                      type="file"
                      name="image"
                      multiple
                      hidden
                      onChange={async (e) => await handleImageUpload(e)}
                    />
                  </IconButton>

                  {/*<IconButton*/}
                  {/*	onClick={handleOpen}*/}
                  {/*	// onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_LIST))}*/}
                  {/*>*/}
                  {/*	<img alt="bell" src={Images.addPoll}/>*/}

                  {/*</IconButton>*/}
                  {/*<Modal*/}
                  {/*	open={open}*/}
                  {/*	onClose={handleClose}*/}
                  {/*	onBackgroundClick={() => {*/}
                  {/*		setOpen(false)*/}
                  {/*	}}*/}
                  {/*>*/}
                  {/*	<Box className="modal">*/}
                  {/*		<Poll onClose={() => setOpen(false)}/>*/}
                  {/*	</Box>*/}
                  {/*</Modal>*/}
                </Box>
                <Box>
                  <Button
                    className="sharing"
                    size="small"
                    loading={loading}
                    type={"submit"}
                    // onClick={console.log()}
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "4px",
                      "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                    }}
                  >
                    <Box sx={{
                      fontSize: "smaller",
                      fontFamily: "Open Sans-Regular, Helvetica",
                      marginRight: "5px",
                      marginLeft: "5px",
                      padding: "3px",
                    }}>
                      {t("sharing")}
                    </Box>
                  </Button>
                </Box>
              </Box>

            </Box>
          </Box>
        </FormikProvider>
      )
      :
      (<PostComponent />)
  );
};

export default Form;