// @flow
import React from "react";
import Box from "@mui/material/Box";
import { IconButton, Tab, Tabs } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import Avatar from "@mui/material/Avatar";
import {
  ROUTE_PAGE_COMPANY_CENTER,
  ROUTE_PAGE_COMPANY_ABOUT,
  ROUTE_PAGE_COMPANY_MEMBERS,
  ROUTE_PAGE_COMPANY_FILES,
  ROUTE_PAGE_COMPANY_ADS,
  ROUTE_PAGE_COMPANY_MORE,
} from "../../routers/routes";
import { TabPanel } from "../tabPanel/tabPanel";
import useUrlLastPart from "../../hooks/useUrlLastPart";
import { useTranslation } from "react-i18next";
import { PAGE_COMPANY } from "../../locales/pages/namespaces";
import Images from "../../assets";
import CenterTab from "./tabPanels/centerTab";
import InformationTab from "./tabPanels/informationTab";
import MemberTab from "./tabPanels/memberTab";
import FilesTab from "./tabPanels/filesTab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "../../pages/profiles/company/company.css";
import { useQuery } from "react-query";
import { getCompany } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import AdsTab from "./tabPanels/adsTab";

type Props = {};

const CompanyProfileTabs = (props: Props) => {
  const { t } = useTranslation(PAGE_COMPANY);
  const { id } = useParams();
  const { accessCode } = useUserData();
  const { data: companyData, isLoading } = useQuery(["getCompany", accessCode, id], getCompany);
  const data = {
    id,
  };
  let lastPartOfPath = useUrlLastPart();
  if (!["center", "about", "files", "ads"].includes(lastPartOfPath)) {
    lastPartOfPath = "";
  }
  if (isLoading) {
    return null;
  }
  return (
    <>
      <Box sx={{
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: "white",
        marginTop: "2px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",

      }}>
        <Tabs variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              value={lastPartOfPath}
              sx={{ display: "inline-flex" }}>
          {/*sx={{display: 'inline-flex'}}*/}
          <Tab
            component={Link}
            value={"center"}
            to={getRouteUrl(ROUTE_PAGE_COMPANY_CENTER, { id: data.id })}
            label={<span className="tabLabel">{t("INITIAL")}</span>}
          />
          <Tab
            component={Link}
            value={"about"}
            to={getRouteUrl(ROUTE_PAGE_COMPANY_ABOUT, { id: data.id })}
            label={<span className="tabLabel">{t("INFORMATION")}</span>}
          />
          <Tab
            component={Link}
            value={"files"}
            to={getRouteUrl(ROUTE_PAGE_COMPANY_FILES, { id: data.id })}
            label={<span className="tabLabel">{t("FILES")}</span>}
          />
          <Tab
            component={Link}
            value={"ads"}
            to={getRouteUrl(ROUTE_PAGE_COMPANY_ADS, { id: data.id })}
            label={<span className="tabLabel">{t("ADS")}</span>}
          />
        </Tabs>
      </Box>
      <Box>
        <TabPanel className="centerTabPanel" isVisible={lastPartOfPath === "center"}>
          <CenterTab />
        </TabPanel>
        <TabPanel className="infoTabPanel" isVisible={lastPartOfPath === "about"}>
          <InformationTab />
        </TabPanel>
        {/*<TabPanel className="memberTabPanel" isVisible={lastPartOfPath === "members"}>*/}
        {/*    <MemberTab/>*/}
        {/*</TabPanel>*/}
        <TabPanel className="fileTabPanel" isVisible={lastPartOfPath === "files"}>
          <FilesTab />
        </TabPanel>
        <TabPanel className="fileTabPanel" isVisible={lastPartOfPath === "ads"}>
          <AdsTab companyData={companyData} />
        </TabPanel>
      </Box>
    </>
  );
};

export default CompanyProfileTabs;