import React, { useMemo, useState } from "react";
import {Box, Collapse, List, ListItem, ListItemButton, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Switch from '@mui/material/Switch';
import FeedLayout from "../../../layouts/feedLayout/feedLayout";

const NotificationSettings = () => {
    const [openRows, setOpenRows] = useState([]);

    const handleClick = (id) => {
        setOpenRows((prevOpenRows) => {
            const newOpenRows = [...prevOpenRows];
            newOpenRows[id] = !newOpenRows[id];
            return newOpenRows;
        });
    };

    const data = useMemo(() => ([
        { id: 1, primary: 'Χρήστες', subOption: 'Ειδοποιήσεις Χρηστών', subOption1: 'Νέες αναρτήσεις', subOption2: 'Ενημέρωση πληροφοριών προφίλ',subOption3: null },
        { id: 2, primary: 'Σελίδες', subOption: null, subOption1: null, subOption2: null,subOption3: null },
        { id: 3, primary: 'Ομάδες', subOption: 'Ειδοποιήσεις Ροής', subOption1: 'Αίτημα μέλους', subOption2: 'Έγκριση αιτήματος μέλους',subOption3:'Αναφορά χρήστη με ετικέτα' },
        { id: 4, primary: 'Μαθήματα', subOption: null, subOption1: null, subOption2: null,subOption3: null },
        { id: 5, primary: 'Αγγελίες Εργασίας', subOption: 'Ειδοποιήσεις Αγγελιών Εργασίας', subOption1: 'Νέες αγγελίες', subOption2: 'Αγγελίες από σελίδες που ακολουθείς',subOption3: null },
    ]), [])

    return (
        <FeedLayout>
            <Box>
            <Box sx={{ marginTop: '20px', marginBottom: '15px' }}>
                <Typography> Ρυθμίσεις Ειδοποιήσεων</Typography>
            </Box>
            <Paper elevation={0} sx={{borderRadius: 2}}>
                <Box>
                    {data.map((x) => (
                        <MenuList  key={x.id}>
                            <MenuItem onClick={() => handleClick(x.id)}>
                                <ListItemText>
                                    <Typography sx={{'&.MuiTypography-root':{fontSize: '18px'}}}>{x.primary}</Typography>
                                </ListItemText>
                                {openRows[x.id] ? <ExpandLess /> : <ExpandMore />}
                            </MenuItem>
                            <Collapse in={openRows[x.id]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {x.subOption && (
                                        <ListItem sx={{ pl: 4}}>
                                            <ListItemText>
                                                <Typography sx={{'&.MuiTypography-root':{fontSize: '15px'},color: '#050505'}}>{x.subOption}</Typography>
                                            </ListItemText>
                                            <Switch color="third"/>
                                        </ListItem>
                                    )}
                                    {x.subOption1 && (
                                        <ListItem sx={{ pl: 4}}>
                                            <ListItemText>
                                                <Typography sx={{'&.MuiTypography-root':{fontSize: '15px'},color: '#616161'}}>{x.subOption1}</Typography>
                                            </ListItemText>
                                            <Switch  color="third"/>
                                        </ListItem>
                                    )}
                                    {x.subOption2 && (
                                        <ListItem sx={{ pl: 4}}>
                                            <ListItemText>
                                                <Typography sx={{'&.MuiTypography-root':{fontSize: '15px'},color: '#616161'}}>{x.subOption2}</Typography>
                                            </ListItemText>
                                            <Switch  color="third"/>
                                        </ListItem>
                                    )}
                                    {x.subOption3 && (
                                        <ListItem sx={{ pl: 4}}>
                                            <ListItemText>
                                                <Typography sx={{'&.MuiTypography-root':{fontSize: '15px'},color: '#616161'}}>{x.subOption3}</Typography>
                                            </ListItemText>
                                            <Switch  color="third"/>
                                        </ListItem>
                                    )}
                                </List>
                            </Collapse>
                        </MenuList>
                    ))}
                </Box>
            </Paper>
            </Box>
        </FeedLayout>
    )
}

export default NotificationSettings;
