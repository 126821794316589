// @flow
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import { IconButton, ListItem, Typography, useMediaQuery } from "@mui/material";
import theme from "../../../theme/theme";
import axios from "axios";
import useUserData from "../../../hooks/user/useUserData";
import Box from "@mui/material/Box";
import { Add, Delete, EditOutlined, PictureAsPdf } from "@mui/icons-material";
import CreateEducation from "../../userTabs/cvTab/createEducation";
import Education from "../../userTabs/cvTab/education";
import CreateExperience from "../../userTabs/cvTab/createExperience";
import Experience from "../../userTabs/cvTab/experience";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import GeneralDetailsTab from "../../userTabs/rightTabs/generalDetailsTab";
import RecommendationTab from "../../userTabs/rightTabs/recommendationTab";
import FileTab from "../../userTabs/rightTabs/fileTab";
import FriendsTab from "../../userTabs/rightTabs/friendsTab";
import Footer from "../../footer/footer";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import "./friendCvTab.css";
import Link from "@mui/material/Link";

type Props = {};

const FriendCvTab = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { accessCode } = useUserData();
  const [cvData, setCvData] = useState();
  const [loading, setLoading] = useState(false);
  const [personalData, setPersonalData] = useState();
  const [cv, setcv] = useState();
  useEffect(() => {

    axios({
      url: "https://dev.ostrialinked.gr/api/api.php?q=20c&scode=" + accessCode,
      method: "post",
      data: { "idu": props.id },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log(response.data);

      setPersonalData(response.data[0]);
      setcv(response.data[0].user);

      setLoading(true);

      // debugger

    }).catch((error) => {

    });

    axios({
      url: "https://dev.ostrialinked.gr/api/api.php?q=20b&scode=" + accessCode,
      method: "post",
      data: { "idu": props.id },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log(response.data.epaggelma);
      console.log(response.data.ekpaidefsi);
      setCvData(response.data);

      setLoading(true);

    }).catch((error) => {

    });
  }, [accessCode]);


  return (
    <>
      {loading &&
        <>
          <Box className="centerLeftTab"
               sx={{
                 width: {
                   xs: "100%",
                   sm: "100%",
                   md: "60%",
                   lg: "60%",
                 },
               }}
          >
            <Box className="centerLeftTab">
              <Typography sx={{ padding: "5px" }}>
                {t("cv")}
              </Typography>
              <Typography sx={{ border: "1px #F3F3F3 solid", marginRight: "3%" }} />
              <Box className="leftTabContainer">
                <Typography sx={{
                  fontSize: "smaller",
                  color: "#616161",
                  fontFamily: "Open Sans-Regular",
                }}>{personalData?.country} {personalData?.city}</Typography>
                <Typography sx={{
                  fontSize: "smaller",
                  color: "#616161",
                  fontFamily: "Open Sans-Regular",
                }}>{personalData?.family_state}</Typography>
                <Typography sx={{
                  fontSize: "smaller",
                  color: "#616161",
                  fontFamily: "Open Sans-Regular",
                }}>{personalData?.work_state}</Typography>
                <Typography sx={{
                  fontSize: "smaller",
                  color: "#616161",
                  fontFamily: "Open Sans-Regular",
                }}>{personalData?.address}</Typography>
                <Typography sx={{
                  fontSize: "smaller",
                  color: "#616161",
                  fontFamily: "Open Sans-Regular",
                }}>{personalData?.phone1}</Typography>
                <Typography sx={{
                  fontSize: "smaller",
                  color: "#616161",
                  fontFamily: "Open Sans-Regular",
                }}>{personalData?.phone2}</Typography>

              </Box>
              {/*</Box>*/}
            </Box>
            <Box className="centerLeftTab">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ padding: "5px" }}>
                  {t("education")}
                </Typography>
              </Box>

              <Typography sx={{ border: "1px #F3F3F3 solid", marginRight: "3%" }} />

              {cvData?.ekpaidefsi?.map(educationData => (
                  <>
                    <ListItem key={educationData.id} className="announcement"
                              secondaryAction={
                                <>
                                </>
                              }>
                      <ListItemText>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{educationData?.sxoli}</Typography>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{educationData?.title}</Typography>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{educationData?.year}</Typography>
                      </ListItemText>
                    </ListItem>
                  </>
                ),
              )
              }

            </Box>
            <Box className="centerLeftTab">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ padding: "5px" }}>
                  {t("work experience")}
                </Typography>
              </Box>

              <Typography sx={{ border: "1px #F3F3F3 solid", marginRight: "3%" }} />

              {cvData?.epaggelma?.map(experienceData => (
                  <>
                    <ListItem key={experienceData.id} className="announcement"
                              secondaryAction={
                                <>
                                </>
                              }>
                      <ListItemText>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{dayjs(experienceData?.date_from).format("YYYY-MM-DD")}</Typography>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{dayjs(experienceData?.date_to).format("YYYY-MM-DD")}</Typography>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{experienceData?.etairia}</Typography>
                        <Typography sx={{
                          fontSize: "smaller",
                          color: "#616161",
                          fontFamily: "Open Sans-Regular",
                        }}>{experienceData?.thesi}</Typography>
                      </ListItemText>
                    </ListItem>
                  </>
                ),
              )
              }

            </Box>
            <Box className="centerLeftTab">
              <Typography sx={{ padding: "5px" }}>
                {"Αλλες Πληροφορίες"}
              </Typography>
              <Typography sx={{ border: "1px #F3F3F3 solid", marginRight: "3%" }} />
              <Box className="leftTabContainer">
                {cv && cv?.cvfile?.length > 0 &&
                  <Box>
                    <Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular',wordWrap:'break-word'}}>
                      {cv.text_user}
                    </Typography>
                  </Box>}
              </Box>
            </Box>
          </Box>
          {!matches && (
            <Box sx={{ width: "40%" }}>
              <GeneralDetailsTab type={props.type} userId={props.id} />
              {/*<RecommendationTab type={props.type} userId={props.id}/>*/}
              <FileTab type={props.type} userId={props.id} />
              <FriendsTab type={props.type} userId={props.id} />
              <Footer color={"#aeacac"} />
            </Box>
          )}
        </>
      }
    </>
  );
};

export default FriendCvTab;