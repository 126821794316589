// @flow
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, FormControl, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PAGE_COMPANY } from "../../../locales/pages/namespaces";
import Link from "@mui/material/Link";
import Images from "../../../assets";
import CompanyMap from "../companyMap";
import { useHistory, useParams } from "react-router-dom";
import useUserData from "../../../hooks/user/useUserData";
import { useMutation, useQuery } from "react-query";
import { addCompany, editCompany, getCompany } from "../../../api/requests";
import ContentLoader from "../../contentLoader/contentLoader";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_COMPANIES } from "../../../routers/routes";
import * as yup from "yup";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";


type Props = {};

const InformationTab = (props: Props) => {
  const { t } = useTranslation(PAGE_COMPANY);
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const { accessCode } = useUserData();
	const history = useHistory()
  const { data: companyData, isLoading,refetch } = useQuery(["getCompany", accessCode, id], getCompany);
	const {mutate: editCompanyAction} = useMutation({
		mutationFn: editCompany,
		onSuccess: (data, variables, context) => {
			refetch()
			setEdit(false)
		},
	})

	const onSubmit = (values) => {
		let formData = new FormData();
		formData.append('title', values.title);
		formData.append('afm', values.afm);
		formData.append('address', values.address);
		formData.append('city', values.city);
		formData.append('job', values.job);
		formData.append('official_title', values.official_title);
		formData.append('country', values.country);
		formData.append('id_company', id);
		editCompanyAction({
			formData,
			accessCode
		});
	}
	const validationSchema = yup.object({
		title: yup.string().required(),
		afm: yup.string().required(),
		address: yup.string().required(),
		city: yup.string().required(),
		job: yup.string().required(),
		official_title: yup.string().required(),
		country: yup.string().required(),
	})
	const formik = useFormik({
		initialValues: {
			title: companyData?.title,
			afm: companyData?.afm,
			address: companyData?.address,
			city: companyData?.city,
			job: companyData?.job,
			official_title: companyData?.official_title,
			country: companyData?.country,
		},
		validationSchema: validationSchema,
		enableReinitialize:true,
		onSubmit: onSubmit
	})

  return (
    <Box sx={{ display: "grid" }}>
      <Box className="infoLeftTab">
        <Box className="centerLeftTab">
          {companyData?.isOwner === "true" ?
						!edit ?
            <Button
              style={{ float: "right" }}
              sx={{
                fontSize: "smaller",
              }}
              onClick={() => setEdit(true)}>
              {t("edit")}
            </Button>
							:
							<Button
								style={{ float: "right" }}
								sx={{
									fontSize: "smaller",
								}}
								onClick={() => setEdit(false)}>
								{t("cancel")}
							</Button>
						: null
          }
					<Typography>
						{t("information")}
					</Typography>
          {isLoading ?
            <ContentLoader loading={isLoading}></ContentLoader>
            :
            edit ? <Box className="rightTabDetails">
								<FormikProvider value={formik}>
									<Box
										component="form"
										sx={{
											marginTop: '20px'
										}}
										//onSubmit={formik.handleSubmit}
									>
										<Paper elevation={0}>
											<Box sx={{margin: '10px'}}>
												<FormControl
													variant="standard"
													required
													fullWidth
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['title'] &&
														Boolean(formik.errors['title'])
													}
												>
													<TextField
														id={'title'}
														required
														value={formik.values.title}
														name={'title'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('title')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'title'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													required
													fullWidth
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['afm'] &&
														Boolean(formik.errors['afm'])
													}
												>
													<TextField
														id={'afm'}
														required
														value={formik.values.afm}
														name={'afm'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('afm')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'afm'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													required
													fullWidth
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['address'] &&
														Boolean(formik.errors['address'])
													}
												>
													<TextField
														id={'address'}
														required
														value={formik.values.address}
														name={'address'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('address')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'address'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													fullWidth
													required
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['city'] &&
														Boolean(formik.errors['city'])
													}
												>
													<TextField
														id={'city'}
														required
														value={formik.values.city}
														name={'city'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('city')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'city'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													required
													fullWidth
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['job'] &&
														Boolean(formik.errors['job'])
													}
												>
													<TextField
														id={'job'}
														required
														value={formik.values.job}
														name={'job'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('job')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'job'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													required
													fullWidth
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['official_title'] &&
														Boolean(formik.errors['official_title'])
													}
												>
													<TextField
														id={'official_title'}
														required
														value={formik.values.official_title}
														name={'official_title'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('official_title')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'official_title'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													required
													fullWidth
													sx={{
														marginBottom: 2
													}}
													error={
														formik.touched['country'] &&
														Boolean(formik.errors['country'])
													}
												>
													<TextField
														id={'country'}
														required
														value={formik.values.country}
														name={'country'}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														label={t('country')}
														size={'small'}
														inputProps={{maxLength: 64}}
														variant="outlined"
														sx={{
															'& .MuiOutlinedInput-root': {
																':focus-within': {border: '0.0px solid #ffffff !important'}
															},
															'& .MuiFormLabel-root': {
																color: '#616161',
																fontSize: '15px',
																fontWeight: 400,
															},
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'country'}/>
													</FormHelperText>
												</FormControl>
												<Box
													sx={{
														paddingBottom: '15px',
														display: 'flex',
														justifyContent: 'space-between',
														marginTop: '10px',
														'@media (max-width: 600px)': {
															justifyContent: 'center',
														},
														width: '100%'
													}}
												>
													<Button
														onClick={formik.handleSubmit}
														variant="outlined"
														sx={{
															marginRight: '20px',
															backgroundColor: '#27293d',
															borderRadius: '4px',
															color: '#ffffff',
															'&:hover': {
																backgroundColor: '#27293d',
															},
															width: '35%',
														}}
													>
														{t('edit')}
													</Button>
													<Button
														onClick={() => setEdit(false)}
														sx={{
															borderRadius: '4px',
															width: '35%',
														}}
														variant="outlined"
													>
														{t('cancel')}
													</Button>
												</Box>
											</Box>
										</Paper>
									</Box>
								</FormikProvider>

							</Box> :
              <>
                <Box className="rightTabDetails">
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("title") + ": "}</strong>{" " + companyData.title}
                  </Typography>
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("official_title") + ": "}</strong>{" " + companyData.official_title}
                  </Typography>
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("job") + ": "}</strong>{" " + companyData.job}
                  </Typography>
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("country") + ": "}</strong>{" " + companyData.country}
                  </Typography>
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("city") + ": "}</strong>{" " + companyData.city}
                  </Typography>
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("afm") + ": "}</strong>{" " + companyData.afm}
                  </Typography>
                  <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "medium" }}>
                    <strong>{t("main address") + ": "}</strong>{" " + companyData.address}
                  </Typography>

                </Box>
              </>

          }
        </Box>
      </Box>
      <Box className="centerLeftTab" sx={{ marginTop: "0px" }}>


        <Box display="flex">
          {/*<Typography sx={{paddingLeft:'5px',fontFamily:' Open Sans',fontSize:'small',color:'#616161', width:'15%',marginTop:'10px',background: 'rgba(140, 140, 140, 0.20)'}}>*/}
          {/*	{t('main address') + " "  }*/}
          {/*</Typography><Typography sx={{paddingLeft:'5px',fontFamily:' Open Sans',fontSize:'small',color:'#616161', width:'15%',marginTop:'10px',background: 'rgba(140, 140, 140, 0.20)'}}>*/}
          {/*	{companyData.address}*/}
          {/*</Typography>*/}
        </Box>
        {/*<Typography sx={{paddingLeft:'5px',fontFamily:' Open Sans',fontSize:'small',color:'#616161'}}>*/}
        {/*	{t('center address')}*/}
        {/*</Typography>*/}
        {/*<Typography sx={{paddingLeft:'5px',fontFamily:' Open Sans',fontSize:'smaller',color:'#27293D',display:'inline-flex'}}>*/}
        {/*	{t('access instructions')} <img src={Images.externalLinkSmall}/>*/}
        {/*</Typography>*/}
        <CompanyMap />
      </Box>
    </Box>
  );
};

export default InformationTab;