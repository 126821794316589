//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {
	parsePersonalDetailsUser,
	parseUser,
	parseUserMessage,
	parseUserRecommendation,
	parseRecommendationWithIdData,
	parseUserProfileImg,
	parseUserCoverImg,
	parseAllPosts, parseCredentials, parseModal,
} from "../../types/user/parse";
import {parseMultiple} from "../../helpers/parse";

const selectLoading = createSelector(
	(state) => state.currentUser,
	(item) => item.get('loading')
)

const selectRegisterSuccess = createSelector(
	(state) => state.currentUser,
	(item) => item.get('registerSuccess')
)

const selectResetPasswordSuccess = createSelector(
	(state) => state.currentUser,
	(item) => item.get('resetPasswordSuccess')
)

const selectIsLoggedIn = createSelector(
	(state) => state.currentUser,
	(item) => item.get('isLoggedIn')
)

const selectToken = createSelector(
	(state) => state.currentUser,
	(item) => item.get('token')
)

const selectAccessCode = createSelector(
	(state) => state.currentUser,
	(item) => item.get('accessCode')
)

const selectUserId = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'id'])
)

const selectLoginName = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'loginName'])
)

const selectUserName = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'username'])
)

const selectMessage = createSelector(
	(state) => state.currentUser.get('message'),
	(item) => parseUserMessage(item)
)

const selectLanguage = createSelector(
	(state) => state.currentUser,
	(item) => item.get('language')
)

const selectData = createSelector(
	[
		(state) => state.currentUser.get('data'),
		(state) => state.currentUser.get('language'),
		
	],
	(item, language) => parseUser(item, language)
)


const selectPersonalData = createSelector(
	(state) => state.currentUser.get('personalData'),
	(item) => parsePersonalDetailsUser(item)
)
const selectUserRecommendationData = createSelector(
	[
		(state) => state.currentUser.get('userRecommendationData')
	],
	(item) => parseMultiple(item, (i) => parseUserRecommendation(i))
)

//
// const selectRecommendationWithIdData = createSelector(
//
// 	(state) => state.currentUser.get('recommendationWithIdData'),
// 	(item) => parseRecommendationWithIdData(item)
//
// )

const selectUserProfileImg = createSelector(
	(state) => state.currentUser.get('userProfileImg'),
	(item) => parseUserProfileImg(item)
)

const selectUserCoverImg = createSelector(
	(state) => state.currentUser.get('userCoverImg'),
	(item) => parseUserCoverImg(item)
)

const selectPostId = createSelector(
	(state) => state.currentUser,
	(item) => item.get('postId')
)

const selectAllPosts = createSelector(
	[
		(state) => state.currentUser.get('posts')
	],
	(item) => parseMultiple(item, (i) => parseAllPosts(i))
)

const selectFullPostData = createSelector(
	(state) => state.currentUser.get('fullPostData'),
	(item) => parseAllPosts(item)
)
const selectCredentials = createSelector(
	(state) => state.currentUser,
	(item) => parseCredentials(item)
)

const selectModal = createSelector(
	(state) => state.currentUser,
	(item) => {
		return parseModal(item.get('modal'))
	}
)
const useUserData = () => {
	const loading = useSelector(selectLoading)
	const registerSuccess = useSelector(selectRegisterSuccess)
	const resetPasswordSuccess = useSelector(selectResetPasswordSuccess)
	const isLoggedIn = useSelector(selectIsLoggedIn)
	const token = useSelector(selectToken)
	const accessCode = useSelector(selectAccessCode)
	const userId = useSelector(selectUserId)
	const loginName = useSelector(selectLoginName)
	const username = useSelector(selectUserName)
	const message = useSelector(selectMessage)
	const language = useSelector(selectLanguage)
	const data = useSelector(selectData)
	const personalData = useSelector(selectPersonalData)
	const userRecommendationData = useSelector(selectUserRecommendationData)
	// const recommendationWithIdData = useSelector(selectRecommendationWithIdData)
	const userProfileImg = useSelector(selectUserProfileImg)
	const userCoverImg = useSelector(selectUserCoverImg)
	const postId = useSelector(selectPostId)
	const allPosts = useSelector(selectAllPosts)
	const fullPostData = useSelector(selectFullPostData)
	const credentials = useSelector(selectCredentials)
	const isModalOpen = useSelector(selectModal)

	return {
		loading,
		isLoggedIn,
		token,
		userId,
		loginName,
		username,
		message,
		language,
		accessCode,
		data,
		registerSuccess,
		resetPasswordSuccess,
		personalData,
		userRecommendationData,
		// recommendationWithIdData,
		userProfileImg,
		userCoverImg,
		postId,
		allPosts,
		fullPostData,
		credentials,
		isModalOpen
	}
}

export default useUserData