import Box from "@mui/material/Box";
import Images from "../../assets";
import { FormControl, Input, InputLabel, MenuItem, OutlinedInput, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGE_HOME } from "../../locales/pages/namespaces";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from "react-query";
import { getBiosBySearch } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../contentLoader/contentLoader";
import Link from "@mui/material/Link";
import { PictureAsPdf } from "@mui/icons-material";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_FRIEND, ROUTE_PAGE_PERSONAL } from "../../routers/routes";
import Avatar from "@mui/material/Avatar";
import Select from "@mui/material/Select";

const CvCompanySearch = () => {
  const {t} = useTranslation(PAGE_HOME);
  const [searchValue,setSearchValue] = useState("")
  const onInput = (e) => setSearchValue(e.target.value);
  const matches = useMediaQuery('(max-width:600px)');
  const {accessCode, userId, username} = useUserData();
  const {
    data: cvs,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getBiosBySearch", accessCode, searchValue],
    queryFn: getBiosBySearch,
    enabled: false,
  });
  const handleKeyDown = (e,text) => {
    if (e.keyCode === 13 || text==='click' ){
      refetch()
    }
  };

  return(
    <Box minHeight={'60vh'}>
    <Box alignItems="center" display="flex" backgroundColor="#0207000A" borderRadius={15} width={250} margin="10px auto">
        <img className="search-png" alt="search" src={Images.search} />
        <Input
          id={'search'}
          className={'search'}
          name={'search'}
          inputtype={'text'}
          placeholder={'search'}
          disableUnderline={true}
          onInput={onInput}
          value={searchValue}
          sx={{
            fontSize: '15px'
          }}
           onKeyDown={(e) => handleKeyDown(e, null)}


        />
        {matches &&
          <IconButton
            id={"icon-search"}
            color="primary"
            onClick={(e) => handleKeyDown(e, "click") }
          >

            <SendIcon />
          </IconButton>
        }
    </Box>
      <Typography gutterBottom variant="h7" component="div">
        {"Παρακάτω βρίσκετε όλα τα αποτελέσματα που ταιριάζουν στον όρο αναζήτησή σας με βάση τον αλγόριθμο της πλατφόρμας"}
      </Typography>
      <Typography gutterBottom variant="h7" component="div">
        {"Μπορείτε να πατήσετε στο όνομα του υποψηφίου για να μεταβείτε στο προφίλ του ή να δείτε το αρχείο βιογραφικού που έχει ανεβάσει στο σύστημα"}
      </Typography>
      {isLoading  ?
        <ContentLoader loading={isLoading}></ContentLoader>
        :
        <Box display={'flex'} justifyContent={'flex-start'} flexDirection={'column'} marginLeft="20px">
          {cvs?.map((item, index) => (
            <Box marginTop="20px" key={index} display={'flex'} alignItems={'center'}>
              <Link style={{ textDecoration: "none" }} href={ item.userid === userId ?
                getRouteUrl(ROUTE_PAGE_PERSONAL, {username: username})
                :
                getRouteUrl(ROUTE_PAGE_FRIEND, {
                  username: item.username,
                  id: item.userid,
                })
              }>
                <Typography fontSize={18} fontWeight="bold">
                  {item.firstname + " " + item.lastname}
                </Typography>
              </Link>
              <Link href={item.cvfile} target="_blank"
                    underline="hover" sx={{
                fontSize: "large",
                color: "#616161",
                fontFamily: "Open Sans-Regular",
                border: "1px solid grey",
                borderRadius: "25px",
                padding: "5px 5px 5px 10px",
                margin: "10px"
              }}>
                <PictureAsPdf />
                {'Βιογραφικό υποψηφίου'}
              </Link>
            </Box>
          ))}
        </Box>}
    </Box>
  )
}

export default CvCompanySearch;