import JobsShow from "./jobsShow";
import Box from "@mui/material/Box";
import {
    Button,
    Collapse, FormControl, FormHelperText,
    Grid,
    IconButton,
    List,
    ListItem,
    MenuItem,
    MenuList, TextField,
    Typography,
    useTheme
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PAGE_JOB} from "../../locales/pages/namespaces";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Switch} from "react-router";
import Images from "../../assets";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import * as yup from "yup";
import {enqueueSnackbar} from "notistack";
import {ErrorMessage, FormikProvider, useFormik} from "formik";


const EditJobByAdmin = () => {
	const theme = useTheme();
	const {t} = useTranslation(PAGE_JOB);
	const [expandedField, setExpandedField] = useState(null);
	const {postUserRecommendation, setUserMessage} = useUserAction()
	const {message} = useUserData();
	
	
	const handleExpand = (field) => {
		setExpandedField((prev) => (prev === field ? null : field));
	};
	
	const [resultData, setResultData] = useState({
		id: 1,
		title: "Junior Web Developer",
		companyName: "Company Name",
		companyLogo: '',
		city: "Αθήνα",
		positionCateg: "IT",
		experienceLevel: "Entry Level",
		employmentType: "Πλήρης Απασχόληση",
		salary: "900,00€",
		announcementDay: "2023-04-11",
		fullPositionDes:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ante ex, porta vel mauris quis, tempus volutpat orci. Ut facilisis ultrices nulla a posuere. Praesent rutrum dolor nec turpis facilisis varius quis id nibh. Ut volutpat velit mi, nec consequat felis laoreet in. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tristique dui a commodo sollicitudin. Quisque vestibulum pretium ex, posuere rutrum lectus hendrerit sit amet. Fusce nulla ex, tincidunt ac vulputate eget, congue non nunc. Sed fringilla lorem leo, eget cursus sapien vestibulum lacinia. Phasellus ultricies ex eget quam suscipit euismod. Integer quis tincidunt ipsum, in scelerisque odio. Nulla urna magna, dapibus in purus non, interdum posuere mauris. Nulla sit amet interdum nisi. Pellentesque cursus turpis et justo scelerisque, a rhoncus eros sodales. Nam nulla nunc, venenatis id vulputate vel, hendrerit vitae quam. Donec risus urna, feugiat finibus viverra a, volutpat ac tellus. Donec a felis eu dui consectetur pellentesque. Integer sit amet ipsum sit amet massa faucibus pharetra sit amet pellentesque mauris. Quisque nisl enim, elementum ut fermentum in, gravida et neque. Nullam eget commodo diam. Nullam ut nunc ac arcu ullamcorper hendrerit. Pellentesque porttitor molestie mauris, vel vehicula erat ultricies ut. Proin ut risus vitae dolor mattis pharetra sit amet eget odio. Integer convallis rutrum nunc, non euismod arcu scelerisque ut. Praesent eget massa mi.",
	});
	
	const [selectedOptions, setSelectedOptions] = useState({});
	const [openDropdown, setOpenDropdown] = useState(null);
	
	const handleDropdownChange = (field, option) => {
		setSelectedOptions((prev) => ({...prev, [field]: option}));
		setOpenDropdown(null); // Collapse the dropdown after selecting an option
	};
	const data = useMemo(
		() => [
			{
				id: 1,
				primary: t('employmentType'),
				subOptions: ['Πλήρης Απασχόληση', 'Μερική Απασχόληση', 'Συμβόλαιο/Εξωτερική'],
			},
			{
				id: 4,
				primary: t('Salary'),
			},
			{
				id: 2,
				primary: t('Position Category'),
				subOptions: ['Marketing', 'Financial', 'IT'],
			},
			{
				id: 3,
				primary: t('Experience Level'),
				subOptions: ['Entry Level', 'Mid Level', 'Senior'],
			},
		],
		[]
	);
	
	const validationSchema = yup.object({
		title: yup
		.string()
		.required(t('required')),
	})
	const onSubmit = (values) => {
		// postUserRecommendation(values);
		console.log('submit')
	};
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			setUserMessage(null)
			if (message === "data_saved") {
				// window.location.href = getRouteUrl(ROUTE_PAGE_HOME)
			}
		}
	}, [message, setUserMessage, t])
	const formik = useFormik({
		initialValues: {
			title: resultData.title,
			description: resultData.fullPositionDes,
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	});
	return (
		<FormikProvider value={formik} onSubmit={formik.handleSubmit}
						noValidate>
			<Box component="form"
				 onSubmit={formik.handleSubmit}
				 noValidate>
				<JobsShow>
					<Box
						className="jobInfo">
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Grid container>
									
									{!resultData.companyLogo &&
										<Box
										className="company-logo"
										>
											<Button sx={{
												backgroundColor: "#f0f0f0",
												border: "1px solid",
												borderColor: "#27293d80",
												borderRadius: "9px",
												height: "112px",
												width: "132px",
												flex: "0 0 auto", // Fixed size
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
												// variant="contained"
												component="label"
											>
												<Typography
													sx={{
														color: '#000000',
														fontFamily: '"Open Sans-Italic", Helvetica',
														fontSize: '15px',
														fontStyle: 'italic',
														fontWeight: '400',
														letterSpacing: '0',
														textAlign: 'center',
													}}
												>
													{t("Company")}
													<br/>
													{t("Logo")}
												</Typography>
												{/*{t('choose an image')}*/}
												<input
													type="file"
													accept="image/png, image/jpeg"
													multiple
													hidden
													// onChange={handleFileChange}
												/>
											</Button>
										
									</Box>
									}
									{resultData.companyLogo &&
										<Button
											// variant="contained"
											component="label"
										>
											<img alt="google-logo" style={{ height: "112px",width: "132px",}}
												 src={resultData.companyLogo}/>
											{/*{t('choose an image')}*/}
											<input
												type="file"
												accept="image/png, image/jpeg"
												multiple
												hidden
												// onChange={handleFileChange}
											/>
										</Button>
										
									}
									<Grid item xs={12} md={6} sx={{paddingLeft: theme.spacing(2),}}>
										<Box
											sx={{
												color: "#27293D",
												fontFamily: "'Open Sans-Bold', Helvetica",
												fontSize: {xs: "20px", md: "28px"},
												fontWeight: "700",
												whiteSpace: "nowrap", // Prevent title from wrapping
											}}
										>
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                sx={{
                                                    marginBottom: 2
                                                }}
                                                error={
                                                    formik.touched['title'] &&
                                                    Boolean(formik.errors['title'])
                                                }
                                            >
                                                <TextField
                                                    id={"title"}
                                                    value={formik.values.title}
                                                    required
                                                    name={"title"}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    // label={t('title')}
                                                    size={"small"}
                                                    variant="outlined"
                                                    sx={{
                                                        width:'max-content',
                                                        display: 'flex',
                                                        "& .MuiOutlinedInput-root": {
                                                            ":focus-within": {
                                                                border: "0.0px solid #ffffff !important",
                                                            },
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                            color: "#616161",
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                        },
                                                        "& fieldset": { border: 'none' },
                                                        marginBottom: "10px",
                                                       
                                                    }}
                                                    inputProps={{
                                                    sx: {
                                                        fontWeight: "700",
                                                        fontSize: {xs: "20px", md: "28px"},
                                                        fontFamily: "'Open Sans-Bold', Helvetica",
                                                        color: "#27293D",
                                                        whiteSpace: "nowrap",
														maxLength: 24,
                                                    },
                                                }}
                                                />
                                                <FormHelperText>
                                                    <ErrorMessage name={'title'}/>
                                                </FormHelperText>
                                            </FormControl>
											{/*{resultData.title}*/}
										</Box>
										<Box
											sx={{
												color: '#27293d',
												fontFamily: '"Open Sans-Bold", Helvetica',
												fontSize: '15px',
												fontWeight: '700',
												height: '16px'
											}}
										>
											{resultData.companyName}
										</Box>
										<Box
											sx={{
												color: '#616161',
												fontFamily: '"Open Sans-Regular", Helvetica',
												fontSize: '15px',
												fontWeight: '400',
												marginTop: '7px'
											}}
										>
											<img src={Images.redPin}/>
											{resultData.city}
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item>
										<Button
											type={'submit'}
											sx={{
												color: "white",
												backgroundColor: "black",
												borderRadius: '10px',
												"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
												justifyContent: 'center',
												padding: '15px',
												height: '42px',
												fontSize: 'revert',
												margin: '5px'
												
											}}
										>
											{t("save")}
										</Button>
										<Button
											sx={{
												color: "white",
												backgroundColor: "black",
												borderRadius: '10px',
												"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
												justifyContent: 'center',
												padding: '15px',
												height: '42px',
												fontSize: 'revert'
												
											}}
										>
											{t("delete")}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Box sx={{
							borderBottom: 1,
							borderColor: 'divider',
							backgroundColor: 'white',
							marginTop: '10px'
						}}>
						</Box>
						<Box>
							<Box className="fullDescriptionTitle"
								 sx={{padding: '0', paddingLeft: '1%', paddingTop: '1%', paddingRight: '1%'}}>
								<Typography
								    sx={{
								        color: '#27293d',
								        fontFamily: '"Open Sans-Regular", Helvetica',
								        fontSize: '24px',
								        fontWeight: '400'
								    }}
								>
								    {t('full desc position')}
								</Typography>
							</Box>
							<Box className="fullDescription"
								 sx={{padding: '0', paddingLeft: '1%', paddingTop: '1%', paddingRight: '1%'}}>
								{/*<Typography*/}
								{/*	sx={{*/}
								{/*		color: '#595959',*/}
								{/*		fontFamily: '"Inter-Regular", Helvetica',*/}
								{/*		fontSize: '13.1px',*/}
								{/*		fontWeight: ' 400'*/}
								{/*	}}*/}
								{/*>*/}
								{/*	{resultData.fullPositionDes}*/}
								{/*</Typography>*/}
								<FormControl
									variant="standard"
									fullWidth
									sx={{
										marginBottom: 2
									}}
									error={
										formik.touched['description'] &&
										Boolean(formik.errors['description'])
									}
								>
									<TextField
										id={"description"}
										value={formik.values.description}
										required
										name={"description"}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										multiline
										variant="outlined"
										sx={{
											width:'-webkit-fill-available',
											display: 'flex',
											"& .MuiOutlinedInput-root": {
												":focus-within": {
													border: "0.0px solid #ffffff !important",
												},
											},
											"& .MuiFormLabel-root": {
												color: "#616161",
												fontSize: "15px",
												fontWeight: 400,
											},
											"& fieldset": { border: 'none' },
											marginBottom: "10px",
											
										}}
										inputProps={{
											sx: {
												color: '#595959',
												fontFamily: '"Inter-Regular", Helvetica',
												fontSize: '13.1px',
												fontWeight: ' 400'
											},
										}}
									/>
									<FormHelperText>
										<ErrorMessage name={'description'}/>
									</FormHelperText>
								</FormControl>
							</Box>
						</Box>
						<Box sx={{marginTop: '30px', marginLeft: '20px'}}>
							<Grid container spacing={3}>
								{data.map((x) => (
									<React.Fragment key={x.id}>
										{x.id === 1 ? (
											<Box sx={{
												margin: '5px',
												paddingTop: '10px'
											}}>
												<Box sx={{
													backgroundColor: '#f5f5f5',
													border: '1px solid #808080',
													borderRadius: '5px',
													width: '170px',
													height: '35px',
												}}>
													<MenuItem
														onClick={() => setOpenDropdown((prev) => (prev === x.id ? null : x.id))}
														sx={{
															padding: '10px',
															width: '170px',
															height: '35px',
															'& .MuiSvgIcon-root': {
																color: '#595959',
															},
														}}>
														<ListItemText>
															<Typography sx={{
																'&.MuiTypography-root': {
																	color: '#595959',
																	fontFamily: '"Open Sans-Regular", Helvetica',
																	fontSize: '12px',
																	fontWeight: '400',
																}
															}}>
																{x.primary}
															</Typography>
															<Typography
																sx={{
																	'&.MuiTypography-root': {
																		color: '#595959',
																		fontFamily: '"Open Sans-Regular", Helvetica',
																		fontSize: '12px',
																		fontWeight: '400',
																	},
																}}
															>{selectedOptions[x.id]}</Typography>
														</ListItemText>
														{openDropdown === x.id ? <ExpandLess/> : <ExpandMore/>}
													</MenuItem>
												</Box>
												<Collapse in={openDropdown === x.id} timeout="auto" unmountOnExit>
													<Box sx={{
														backgroundColor: '#FFFFFF',
														border: '1px solid #808080',
														borderRadius: '8px',
														marginTop: '5px',
														width: '170px',
													}}>
														<List component="div" disablePadding>
															{x.subOptions &&
																x.subOptions.map((subOption, index) => (
																	<MenuItem key={index} sx={{pl: 4}}
																			  onClick={() => handleDropdownChange(x.id, subOption)}>
																		<ListItemText>
																			<Typography
																				sx={{
																					'&.MuiTypography-root': {
																						color: '#27293d !important',
																						fontFamily: '"Open Sans-Regular", Helvetica !important',
																						fontSize: '12px !important',
																					},
																				}}
																			>
																				{subOption}
																			</Typography>
																		</ListItemText>
																		<Switch color="third"/>
																	</MenuItem>
																))}
														</List>
													</Box>
												</Collapse>
											</Box>
										) : x.id === 4 ? (
											<Box>
												<Typography sx={{
													color: '#595959',
													fontFamily: '"Open Sans-Bold", Helvetica',
													fontSize: '10px',
													fontWeight: '700',
												}}>{t('Salary(optionally)')}</Typography>
												<TextField
													sx={{
														'& .MuiOutlinedInput-root': {
															height: '33px',
														},
														backgroundColor: '#f5f5f5',
														border: '1px solid #808080',
														borderColor: '#808080',
														borderRadius: '5px',
														width: '133px',
														height: '33px',
													}}
													variant="outlined"
													value={resultData.salary}
													onChange={(e) => setResultData({
														...resultData,
														salary: e.target.value
													})}
												/>
											</Box>
										) : (
											<Box sx={{
												margin: '5px',
												paddingTop: '10px'
											}}>
												<Box sx={{
													backgroundColor: '#f5f5f5',
													border: '1px solid #808080',
													borderRadius: '5px',
													width: '170px',
													height: '35px',
												}}>
													<MenuItem
														onClick={() => setOpenDropdown((prev) => (prev === x.id ? null : x.id))}
														sx={{
															padding: '10px',
															width: '170px',
															height: '35px',
															'& .MuiSvgIcon-root': {
																color: '#595959',
															},
														}}>
														<ListItemText>
															<Typography sx={{
																'&.MuiTypography-root': {
																	color: '#595959',
																	fontFamily: '"Open Sans-Regular", Helvetica',
																	fontSize: '12px',
																	fontWeight: '400',
																}
															}}>
																{x.primary}
															</Typography>
															<Typography
																sx={{
																	'&.MuiTypography-root': {
																		color: '#595959',
																		fontFamily: '"Open Sans-Regular", Helvetica',
																		fontSize: '12px',
																		fontWeight: '400',
																	},
																}}
															>{selectedOptions[x.id]}</Typography>
														</ListItemText>
														{openDropdown === x.id ? <ExpandLess/> : <ExpandMore/>}
													</MenuItem>
												</Box>
												<Collapse in={openDropdown === x.id} timeout="auto" unmountOnExit>
													<Box sx={{
														backgroundColor: '#FFFFFF',
														border: '1px solid #808080',
														borderRadius: '8px',
														marginTop: '5px',
														width: '170px',
													}}>
														<List component="div" disablePadding>
															{x.subOptions &&
																x.subOptions.map((subOption, index) => (
																	<MenuItem key={index} sx={{pl: 4}}
																			  onClick={() => handleDropdownChange(x.id, subOption)}>
																		<ListItemText>
																			<Typography
																				sx={{
																					'&.MuiTypography-root': {
																						color: '#27293d !important',
																						fontFamily: '"Open Sans-Regular", Helvetica !important',
																						fontSize: '12px !important',
																					},
																				}}
																			>
																				{subOption}
																			</Typography>
																		</ListItemText>
																		<Switch color="third"/>
																	</MenuItem>
																))}
														</List>
													</Box>
												</Collapse>
											</Box>
										)}
									</React.Fragment>
								))}
							
							</Grid>
						</Box>
					</Box>
				</JobsShow>
			</Box>
		</FormikProvider>
	)
	
}
export default EditJobByAdmin