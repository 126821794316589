// @flow
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import RecommendationTab from "../rightTabs/recommendationTab";
import FileTab from "../rightTabs/fileTab";
import FriendsTab from "../rightTabs/friendsTab";
import {Button, TextField, Typography, useMediaQuery} from "@mui/material";
import Link from "@mui/material/Link";
import Footer from "../../footer/footer";
import theme from "../../../theme/theme";
import './personalDetailsTab.css'
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {enqueueSnackbar} from "notistack";
import useUserData from "../../../hooks/user/useUserData";
import useUserAction from "../../../hooks/user/useUserAction";
import {getPersonalData} from "../../../actions/currentUser/actions";

type Props = {};

const PersonalDetailsTab = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [edit, setEdit] = useState(false);
	const {message, personalData} = useUserData()
	const {postPersonalData, setUserMessage, getPersonalData} = useUserAction()

	const validationSchema = yup.object({
		country: yup
		.string(),
		city: yup
		.string(),
		// .required(t('required')),
	})
	const onSubmit = (values) => {
		postPersonalData(values)
	}
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			setUserMessage(null)
			if (message.text === "data_saved") {
				setEdit(false)
				// window.location.reload();
			}
		}
	}, [message, setUserMessage, t])
	
	useEffect(()=>{
		getPersonalData()
	},[getPersonalData])
	
	const formik = useFormik({
		initialValues: {
			country: personalData.country,
			city: personalData.city,
			family_state: personalData.family_state,
			work_state: personalData.work_state,
			address: personalData.address,
			phone1: personalData.phone1,
			phone2: personalData.phone2,
			facebook: personalData.facebook,
			twitter: personalData.twitter,
			linkedin: personalData.linkedin,
			url: personalData.url,
		},
		// validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})
	return (
		<>
			<Box className="centerLeftTab"
				 sx={{
					 width: {
						 xs: '100%',
						 sm: '100%',
						 md: '60%',
						 lg: '60%'
					 }
				 }}
			>
				{!(Boolean(edit)) ?
					<>
						<Box className="lineTitle">
							<Typography sx={{padding: '5px'}}>
								{t('general details')}
							</Typography>
							<Button
								sx={{
									fontSize: 'smaller'
								}}
								onClick={() => setEdit(true)}>
								{t('edit')}
							</Button>
						</Box>
						<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
						<Box className="leftTabContainer">
							<Typography sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular',
								marginBottom:1.5
							}}>{personalData?.country}, {personalData?.city}</Typography>
							<Typography sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular',
								marginBottom:1.5
							}}>{personalData?.family_state}</Typography>
							<Typography sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular',
								marginBottom:1.5
							}}>{personalData?.work_state}</Typography>
							<Typography sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular',
								marginBottom:1.5
							}}>{personalData?.address}</Typography>
							<Typography sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular',
								marginBottom:1.5
							}}>{personalData?.phone1}</Typography>
							<Typography sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular',
								marginBottom:1.5
							}}>{personalData?.phone2}</Typography>
							<Box className="leftTabLinks">
								<Link href={personalData.facebook} underline="hover" sx={{
									fontSize: 'smaller',
									color: '#616161',
									fontFamily: 'Open Sans-Regular',
									marginBottom:1.5
								}}>{personalData?.facebook}</Link>
								{/*<Link href={personalData.instagramLink} underline="hover" sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans'}}>{personalData.instagramLink}</Link>*/}
								<Link href={personalData?.twitter} underline="hover" sx={{
									fontSize: 'smaller',
									color: '#616161',
									fontFamily: 'Open Sans-Regular',
									marginBottom:1.5
								}}>{personalData?.twitter}</Link>
								<Link href={personalData?.linkedin} underline="hover" sx={{
									fontSize: 'smaller',
									color: '#616161',
									fontFamily: 'Open Sans-Regular',
									marginBottom:1.5
								}}>{personalData?.linkedin}</Link>
								<Link href={personalData?.url} underline="hover" sx={{
									fontSize: 'smaller',
									color: '#616161',
									fontFamily: 'Open Sans-Regular',
									marginBottom:1.5
								}}>{personalData?.url}</Link>
							</Box>
						</Box>
					</>
					:
					<FormikProvider value={formik} onSubmit={formik.handleSubmit}
									noValidate>
						<Box component="form"
							 onSubmit={formik.handleSubmit}
							 noValidate>
							<Box className="lineTitle">
								<Typography sx={{padding: '5px'}}>
									{t('general details')}
								</Typography>
								<Button
									type={'submit'}
									sx={{
										fontSize: 'smaller'
									}}
								>
									{t('save')}
								</Button>
							</Box>
							<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
							<Button
								sx={{
									fontSize: 'smaller',
									justifyContent: 'end',
									width: '100%',
									'&:hover': {
										backgroundColor: '#ffffff',
									}
								}}
								onClick={() => setEdit(false)}>
								{t('cancel')}
							</Button>
							
							<Box className={"editForm"}>
								<TextField size={'small'}
										   id={'country'}
										   name={'country'}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   label={t('country')} variant="outlined"
										   value={formik.values.country}/>
								<TextField size={'small'}
										   id={'city'}
										   name={'city'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('city')} variant="outlined"
									// defaultValue={personalData.city}
										   value={formik.values.city}/>
								<TextField size={'small'}
										   id={'family_state'}
										   name={'family_state'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('family_state')} variant="outlined"
										   defaultValue={personalData.family_state}/>
								<TextField size={'small'}
										   id={'work_state'}
										   name={'work_state'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('work_state')} variant="outlined"
										   defaultValue={personalData.work_state}/>
								<TextField size={'small'}
										   id={'address'}
										   name={'address'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('address')} variant="outlined"
										   defaultValue={personalData.address}/>
								<TextField size={'small'}
										   id={'phone1'}
										   name={'phone1'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('phone1')} variant="outlined"
										   defaultValue={personalData.phone1}/>
								<TextField size={'small'}
										   id={'phone2'}
										   name={'phone2'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('phone2')} variant="outlined"
										   defaultValue={personalData.phone2}/>
								<TextField size={'small'}
										   id={'facebook'}
										   name={'facebook'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('facebook')} variant="outlined"
										   defaultValue={personalData.facebook}/>
								<TextField size={'small'}
										   id={'twitter'}
										   name={'twitter'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('twitter')} variant="outlined"
										   defaultValue={personalData.twitter}/>
								<TextField size={'small'}
										   id={'linkedin'}
										   name={'linkedin'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('linkedin')} variant="outlined"
										   defaultValue={personalData.linkedin}/>
								<TextField size={'small'}
										   id={'url'}
										   name={'url'}
										   onChange={formik.handleChange}
										   onBlur={formik.handleBlur}
										   sx={{
											   padding: '2%',
											   marginBottom: '1%',
											   '& .MuiOutlinedInput-root': {
												   // ':focus-within': { border: '0.0px solid #ffffff !important' }
												   fontSize: 'smaller',
											   },
										   }}
										   label={t('url')} variant="outlined"
										   defaultValue={personalData.url}/>
							</Box>
						</Box>
					</FormikProvider>
				}
			</Box>
			
			{!matches && (
				<Box sx={{width: '40%',}}>
					{/*<RecommendationTab type={props.type} userId={props.id}/>*/}
					<FileTab type={props.type} userId={props.id}/>
					<FriendsTab type={props.type} userId={props.id}/>
					<Footer color={'#aeacac'}/>
				</Box>
			)}
		</>
	
	);
};

export default PersonalDetailsTab