// @flow
import * as React from 'react';
import NoAuthLayout from "../../components/layouts/noAuthLayout/noAuthLayout";
import {Grid} from "@mui/material";
import LoginBanner from "../../components/loginBanner/loginBanner";
import Form from "./partials/form";
import {useFormik} from "formik";
import useUserData from "../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {PAGE_RESET_PASSWORD} from "../../locales/pages/namespaces";
import * as yup from "yup";
import './resetPassword.css';
import useUserAction from "../../hooks/user/useUserAction";
import {useEffect} from "react";
import {enqueueSnackbar} from "notistack";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_FORGOT_PASSWORD} from "../../routers/routes";
import {useHistory} from "react-router-dom";

const ResetPassword = () => {
    const history = useHistory()
    const {loading, message, resetPasswordSuccess} = useUserData()
    const {t} = useTranslation(PAGE_RESET_PASSWORD)
    const {resetUserPassword, setUserMessage} = useUserAction()
    
    const onSubmit = (values) => {
        resetUserPassword(values)
    }
    const validationSchema = yup.object({
        email: yup
        .string()
        .email()
        .required(t('required')),
    })
    
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })
    
    useEffect(() => {
        if (message) {
            enqueueSnackbar(t(message?.text), {
                    variant: message?.variant,
                    autoHideDuration: 10000
                    
                }
            )
            setUserMessage(null)
        }
    }, [message, setUserMessage, t])
    
    useEffect(() => {
        if (resetPasswordSuccess) history.push(getRouteUrl(ROUTE_PAGE_FORGOT_PASSWORD))
    }, [formik, resetPasswordSuccess])
    
    return (
        <NoAuthLayout>
            <Grid container maxWidth={'1024px'}>
                <Grid
                    sx={{
                        borderRight: {
                            sm: 'none',
                            md: '1px solid #ededed'
                        },
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                >
                    <LoginBanner/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                >
                    <Form
                        formik={formik}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </NoAuthLayout>
    )
}

export default ResetPassword;
