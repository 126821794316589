// @flow
import React from "react";
import ChatContainer from "../../components/chat/chatContainer";

const Chat = (props) => {
  console.log("chat-props->", props);
  return <ChatContainer />;
};

export default Chat;
