import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.NAVIGATION]: {
		el: {},
		en: {},
	},
	[Namespaces.ALERTS]: {
		el: {},
		en: {},
	},
	[Namespaces.FILES]: {
		el: {},
		en: {},
	},
	[Namespaces.AXIOS_ERROR_MESSAGE]: {
		el: {
			'More': 'Περισσότερα..',
			'Less': 'Λιγότερα',
			'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
			'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
			'Duplicate Entry': 'Υπάρχει ήδη αυτή η εγγραφή',
			'Not Found': 'Δεν βρέθηκε',
			errorMessage: 'Μήνυμα',
			'Message': 'Μήνυμα',
			moreMessage: '',
			'Code': 'Κωδικός',
			'Status': 'Status',
		},
		en: {
			'More': 'More..',
		}
	},
	[Namespaces.COMPONENT_POSTCARD]: {
		el: {
			'show comments' : 'Δείτε σχόλια',
			'add comment' : 'Πρόσθεσε σχόλιο',
		},
		en: {

		}
	},
	[Namespaces.COMPONENT_LISTITEMPERSON]: {
		el: {
			'add' : 'Προσθήκη',
			'delete friend' : 'Διαγραφή φίλου'
		},
		en: {

		}
	},
	[Namespaces.ADSFORM_COMPONENT]: {
		el: {
			'add' : 'Προσθήκη',
			'delete friend' : 'Διαγραφή φίλου'
		},
		en: {

		}
	},
	[Namespaces.GENERIC]: {
		el: {
			'Are you sure?' : 'Είστε σίγουροι;',
			'yes' : 'Nαι',
			'no' : 'Όχι',
		},
		en: {

		}
	},
}

export default locales
