// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {ErrorMessage, FormikProps, FormikProvider} from "formik";
import {LoadingButton} from "@mui/lab";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import {PAGE_REGISTER} from "../../../locales/pages/namespaces";
import PasswordInput from "../../../components/passwordInput/passwordInput";
import dayjs from "dayjs";

type Props = {
	formik: FormikProps,
	loading: Boolean
};

const fieldMargin = 2

const Form = (props: Props) => {
	const {formik, loading} = props
	const {t} = useTranslation(PAGE_REGISTER)
	return (
		<FormikProvider value={formik}>
			<Box
				className={'register-form'}
				sx={{
					padding: {
						xs: '6px 14px',
						sm: '12px 28px',
						md: '46px'
					}
				}}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Typography
					sx={{
						marginBottom: 1,
						color: '#050505',
						fontSize: '20px',
						fontWeight: '300',
						lineHeight: '40px',
						letterSpacing: 0,
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: '"Open Sans-Light", Helvetica'
					}}
				>
					{t('Create an account')}
				</Typography>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['firstname'] &&
						Boolean(formik.errors['firstname'])
					}
				>
					<TextField
						id={'firstname'}
						value={formik.values.firstname}
						name={'firstname'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('Your Name')}
						size={'small'}
						inputProps={{maxLength: 250}}
						variant="outlined"
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'firstname'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['lastname'] &&
						Boolean(formik.errors['lastname'])
					}
				>
					<TextField
						id={'lastname'}
						value={formik.values.lastname}
						name={'lastname'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('Last Name')}
						size={'small'}
						inputProps={{maxLength: 250}}
						variant="outlined"
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'lastname'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['username'] &&
						Boolean(formik.errors['username'])
					}
				>
					<TextField
						id={'username'}
						value={formik.values.username}
						name={'username'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('User Name')}
						size={'small'}
						inputProps={{maxLength: 250}}
						variant="outlined"
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'username'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['datebirth'] &&
						Boolean(formik.errors['datebirth'])
					}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
						<DatePicker
							label={t('Date Birth')}
							value={formik.values.datebirth}
							onChange={(newValue) => formik.setFieldValue('datebirth', newValue)}
							maxDate={dayjs()}
							slotProps={{ textField: { required: true, size: 'small' } }}
						/>
					</LocalizationProvider>
					<FormHelperText>
						<ErrorMessage name={'datebirth'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['mobile'] &&
						Boolean(formik.errors['mobile'])
					}
				>
					<TextField
						id={'mobile'}
						value={formik.values.mobile}
						name={'mobile'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('Mobile Phone')}
						size={'small'}
						inputProps={{maxLength: 250}}
						variant="outlined"
						required
					/>
					
					<FormHelperText>
						<ErrorMessage name={'mobile'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['email'] &&
						Boolean(formik.errors['email'])
					}
				>
					<TextField
						id={'email'}
						value={formik.values.email}
						name={'email'}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('Email Address')}
						size={'small'}
						inputProps={{maxLength: 250}}
						variant="outlined"
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'email'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['password'] &&
						Boolean(formik.errors['password'])
					}
				>
					<PasswordInput
						formik={formik}
						name={'password'}
						label={t('Password')}
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'password'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: fieldMargin
					}}
					error={
						formik.touched['agree'] &&
						Boolean(formik.errors['agree'])
					}
				>
					<FormControlLabel
						control={
							<Checkbox
								checked={formik.values.agree}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								size={'small'}
								name={'agree'}
								required
							/>
						}
						label={
							<Typography
								sx={{
									fontFamily: '"Open Sans-Light", Helvetica',
									fontSize: '15px',
									fontWeight: '300'
								}}
							> {t('I have read and agree to the terms of use')}</Typography>}
						labelPlacement="end"
					/>
					<FormHelperText>
						<ErrorMessage name={'agree'}/>
					</FormHelperText>
				</FormControl>
				<LoadingButton
					loading={loading}
					fullWidth
					variant="contained"
					type={'submit'}
					sx={{
						paddingTop: '10px',
						paddingBottom: '10px',
					}}
				>
					{t('Δημιουργία')}
				</LoadingButton>
			</Box>
		</FormikProvider>
	);
};

export default Form