import {Button, ListItem, ListItemAvatar} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {grey} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import useUserData from "../../hooks/user/useUserData";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_FRIEND, ROUTE_PAGE_PERSONAL} from "../../routers/routes";
import {People, PersonAddAlt1Outlined} from "@mui/icons-material";
import { COMPONENT_LISTITEMPERSON } from "../../locales/components/namespaces";

const ListItemPerson = ({id, image_profile, firstname, lastname, username,handleRequestSend, handleRemoveRequest,isFriend}) => {
    const {t} = useTranslation(COMPONENT_LISTITEMPERSON)
    const {personalData} = useUserData();
    return (
        <>
            <Link href={personalData.id_user !== id ? getRouteUrl(ROUTE_PAGE_FRIEND,{username: username,id}) :getRouteUrl(ROUTE_PAGE_PERSONAL,{username: username}) } color={'#616161'} underline="hover"
                  style={{textDecoration: 'none'}}
                  sx={{fontSize: 'smaller', fontFamily: 'Open Sans-Regular'}}>
                <ListItem sx={{padding: '5px', paddingLeft: '10px', borderBottom: '1px #F3F3F3 solid'}}>
                    <ListItemAvatar>
                        {
                            (image_profile) ?
                                <Avatar sx={{width: 32, height: 32}}
                                        src={image_profile}></Avatar>
                                :
                                <Avatar sx={{
                                    bgcolor: grey[900],
                                    width: 32,
                                    height: 32
                                }}>{(firstname?.[0]?.toUpperCase()) + (lastname?.[0]?.toUpperCase())}</Avatar>
                        }
                    </ListItemAvatar>
                </ListItem>
            </Link>
            <ListItemText primary={<span
                className="friendName">{firstname + " " + lastname}</span>}
                          secondary={
                              <>
                                  {isFriend !== undefined
                                      ? isFriend === 'false' ?
                                      <Button
                                          variant={'outlined'}
                                          size={"small"}
                                          sx={{
                                              fontFamily: 'Open Sans-Regular',
                                              fontSize: 'small',
                                          }}
                                          onClick={() => handleRequestSend(id)}
                                      >

                                          <PersonAddAlt1Outlined/>
                                          {t('add')}
                                      </Button>
                                      :
                                      <Button
                                          variant={'outlined'}
                                          size={"small"}
                                          sx={{
                                              fontFamily: 'Open Sans-Regular',
                                              fontSize: 'small',
                                          }}
                                          onClick={() => handleRemoveRequest(id)}
                                      >
                                          <People/>
                                          {t('delete friend')}
                                      </Button> : null
                                  }
                              </>
                          }
            />
        </>
    )
}
export default ListItemPerson;