import ContentLoader from "../../components/contentLoader/contentLoader";
import Box from "@mui/material/Box";
import { Field, FormikProvider, useFormik } from "formik";
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_HOME } from "../../routers/routes";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { addCompanyAd, addUserCategoryMoodle, getCategoryMoodle, getUserCategoryMoodle } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../locales/pages/namespaces";
import { enqueueSnackbar } from "notistack";

const UserDetailsMoodle = ({ userCategories,refetch} ) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const { accessCode } = useUserData();
  const { data: categories, isLoading } = useQuery(
    ["getCategoryMoodle", accessCode],
    getCategoryMoodle,
  );


  const { mutate: addUserCategoryMoodleAction } = useMutation({
    mutationFn: addUserCategoryMoodle,
    onSuccess: (data, variables, context) => {
      refetch();
      enqueueSnackbar(t('data_saved'), {
        variant: 'success',
        autoHideDuration: 10000
      })
    },
    onError:()=> {
      enqueueSnackbar(t('error'), {
        variant: 'error',
        autoHideDuration: 10000
      })
    }
  });
  const onSubmitCategories = (values) => {
    addUserCategoryMoodleAction({
      form:values,
      accessCode
    })
  };
  const formikForMoodle = useFormik({
    initialValues: {
      categories: userCategories?.categories,
    },
    enableReinitialize: true,
    onSubmit: onSubmitCategories,

  });
  const onChange = (e) => {
    if(formikForMoodle.values.categories.includes(parseInt(e.target.value))) {
      let values = formikForMoodle.values.categories
      formikForMoodle.setFieldValue('categories',values.filter((o)=>o !== parseInt(e.target.value)))
    }else{
      formikForMoodle.setFieldValue('categories',[...formikForMoodle.values.categories,parseInt(e.target.value)])
    }
  };
  return (
    <>
      {isLoading  ?
        <ContentLoader loading={isLoading}></ContentLoader>
        :
        <Box marginTop={"10px"}>
          <FormikProvider value={formikForMoodle} onSubmit={formikForMoodle.handleSubmit}>
            <Box component="form"
                 onSubmit={formikForMoodle.handleSubmit}
                 className="editUserDetails">
              <Box className={"title"}>
                <Typography>
                  {"Θεματικές Κατηγορίες Ενδιαφέροντος"}
                </Typography>
                <p
                  style={{ fontSize: "0.80rem" }}>{"Παρακαλώ επιλέξτε τις κατηγορίες που σας ενδιαφέρουν για εργασία & δια βίου μάθηση"}</p>
                <FormGroup>
                  {categories?.map(x =>
                    <FormControlLabel
                      control={
                        <Field
                          type="checkbox"
                          name="categories"
                          value={x.categoryid}
                          as={Checkbox}
                          checked={formikForMoodle?.values?.categories?.includes(x.categoryid)}
                          onChange={onChange}
                          />
                      }
                      label={x.categoryname}
                    />,
                  )}

                </FormGroup>
              </Box>
              <Box className="formFields">

              </Box>
              <Box className={"formButtons"}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    marginRight: "20px",
                    backgroundColor: "#27293d",
                    borderRadius: "4px",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#27293d",
                    },
                    width: "35%",
                  }}
                >
                  {t("save")}
                </Button>
                <Button
                  onClick={() => window.location.href = getRouteUrl(ROUTE_PAGE_HOME)}
                  sx={{
                    borderRadius: "4px",
                    width: "35%",
                  }}
                  variant="outlined"
                >
                  {t("cancel")}
                </Button>
              </Box>
            </Box>
          </FormikProvider>
        </Box>}
    </>
  );
};
export default UserDetailsMoodle;