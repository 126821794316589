import {createTheme} from "@mui/material";

const theme = createTheme({
	//Changing the theme configuration variables is the most effective way to match Material UI to your needs. The following sections cover the most important theme variables
	palette: {
		mode: 'light',
		primary: {
			main: '#27293d',
		},
		secondary: {
			main: '#f3f3f3',
		},
		third:{
			main:'#2682D5',
		},
		info: {
			main: '#1877f2',
		},
		background: {
			default: '#f3f3f3',
			secondary: '#27293d'
		},
		text: {
			primary: '#050505',
			secondary: '#020700',
			third:'#2682D5',
			hint: '#616161',
		},
	},
	typography: {
		fontFamily: ['Open Sans-Regular, Helvetica'],
		htmlFontSize: 15,
		button: {
			fontSize: 17,
			fontWeight: 400,
			textTransform: 'none',
		},
	},
	// spacing: {},
	// breakpoints: {},
	// zIndex: {},
	// transitions: {},
	components: {
		// Name of the component
		MuiOutlinedInput: {
			// The props to change the default for.
			// defaultProps: {},
			styleOverrides: {
				// // Name of the slot
				root: {
					// Some CSS
					borderRadius: '4px',
					borderColor:  'rgba(0, 0, 0, 0.23)'
				},
				input: {
					'&:-webkit-autofill': {
						WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
					},
					'&:focus-visible': {
						outline: '#27293d'
					},
					'&::placeholder': {
						color: '#616161',
						fontFamily: '"Open Sans-Regular", Helvetica',
						fontSize: '15px',
						fontWeight: 400
					}
				}
			},
		},
		MuiModal: {
			styleOverrides: {
				root: {
					'div': {
						overflow: 'auto', /* Enable scrolling */
						/* Hide the scrollbar for Chrome, Safari, and newer versions of Edge */
						'&::-webkit-scrollbar': {
							display: 'none',
						},
						/* Hide the scrollbar for Firefox */
						scrollbarWidth: 'none', /* Firefox */
						/* Optional: hide the scrollbar for older versions of Edge */
						'-ms-overflow-style': 'none', /* IE and Edge */
					},
				},
				backdrop: {
					backgroundColor: 'rgba(0, 0, 0, 0.9)', // Example: change the backdrop color
				},
			},
		},
		MuiTab:{
			styleOverrides:{
				root: {
					padding: '5px 7px',
					minWidth: '40px'
				}
			}
		},
		MuiTabPanel:{
			styleOverrides:{
				root:{
					padding: '0px'
				}
			}
		},
		MuiSwitch:{
			styleOverrides:{
				checked:{
						backgroundColor:'#2682d5'
				}
			}
		},
		MuiChip:{
			styleOverrides:{
				root:{
					backgroundColor:'#FAC12A',
					borderRadius: '5px'
				}
			}
		}
	}
})

export default theme