import Box from "@mui/material/Box";
import { Button, FormControl, FormHelperText, TextField, Typography, useMediaQuery } from "@mui/material";
import {ErrorMessage} from "formik";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";

const ContactForm = ({formik, textareaStyle}) => {
  const matches = useMediaQuery("(max-width:408px)")
    return(
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{
                bgcolor: '#27293d',
                marginTop:"22px",
            }}
        >
            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <Box>
                    <Typography
                        sx={{
                            color: '#fac12a',
                            fontFamily: '"Open Sans-Regular", Helvetica',
                            fontSize: '1.5em',
                            fontWeight: 400,
                            marginTop:'42px'
                        }}
                    >
                        Επικοινωνήστε μαζί μας
                    </Typography>
                    <Typography
                        sx={{
                            color: '#ffffff',
                            fontFamily: '"Open Sans-Regular", Helvetica',
                            fontSize: '1.5em',
                            fontWeight: 400
                        }}
                    >
                        για οποιαδήποτε απορία</Typography>

                </Box>
                <Box>
                    <Typography
                        sx={{
                            color:'#cecece',
                            fontFamily: '"Open Sans-Regular", Helvetica',
                            fontSize: '16px',
                            fontWeight: 400,
                            margin:'20px'
                        }}
                    >Θα σας απαντήσουμε το συντομότερο δυνατόν</Typography>
                </Box>
            </Box>

            <Box
                component="form"
                sx={{
                    padding:"42px",
                    paddingBottom:"20px"
                }}
            >
                <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                    error={
                        formik.touched['name'] &&
                        Boolean(formik.errors['name'])
                    }
                >
                    <TextField
                        id={'fullname'}
                        value={formik.values.name}
                        name={'fullname'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label={('Ονοματεπώνυμο')}
                        size={'small'}
                        inputProps={{maxLength: 64, style: { color: "white" }}}
                        variant="standard"
                        color="secondary"
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: '#ffffff',
                                fontSize: '15px',
                                fontWeight: 400,
                            },
                            '& .MuiInput-root':{
                                '&:before': { borderBottomColor: '#ffffff' },
                                '&:hover':{
                                    borderBottomColor: '#ffffff'
                                },
                                '&.Mui-focused:after': {
                                    borderBottom: '2px solid #ffffff',
                                },
                            },
                        }}
                    />
                    <FormHelperText>
                        <ErrorMessage name={'fullname'}/>
                    </FormHelperText>
                </FormControl>
                <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                    error={
                        formik.touched['email'] &&
                        Boolean(formik.errors['email'])
                    }
                >
                    <TextField
                        id={'email'}
                        value={formik.values.email}
                        name={'email'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label={('E-mail')}
                        size={'small'}
                        inputProps={{maxLength: 64, style: { color: "white" }}}
                        variant="standard"
                        color="secondary"
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: '#ffffff',
                                fontSize: '15px',
                                fontWeight: 400,
                            },
                            '& .MuiInput-root':{
                                '&:before': { borderBottomColor: '#ffffff' },
                                '&:hover':{
                                    borderBottomColor: '#ffffff'
                                },
                                '&.Mui-focused:after': {
                                    borderBottom: '2px solid #ffffff',
                                },
                            },
                        }}
                    />
                    <FormHelperText>
                        <ErrorMessage name={'email'}/>
                    </FormHelperText>
                </FormControl>
                <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                    error={
                        formik.touched['phone'] &&
                        Boolean(formik.errors['phone'])
                    }
                >
                    <TextField
                        id={'phone'}
                        value={formik.values.phone}
                        name={'phone'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label={('Τηλέφωνο (προαιρετικά)')}
                        size={'small'}
                        color="secondary"
                        inputProps={{maxLength: 64, style: { color: "white" }}}
                        variant="standard"
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: '#ffffff',
                                fontSize: '15px',
                                fontWeight: 400,
                            },
                            '& .MuiInput-root':{
                                '&:before': { borderBottomColor: '#ffffff' },
                                '&:hover':{
                                    borderBottomColor: '#ffffff'
                                },
                                '&.Mui-focused:after': {
                                    borderBottom: '2px solid #ffffff',
                                },
                            },
                        }}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <Typography
                        sx={{
                            color: '#ffffff',
                            fontFamily: '"Open Sans-Regular", Helvetica',
                            fontSize: '17px',
                            fontWeight: 400,
                            marginBottom:'5px'
                        }}
                    >Πείτε μας τι πρόβλημα αντιμετωπίζετε</Typography>
                    <TextareaAutosize
                        style={textareaStyle}
                        value={formik.values.sxolio}
                        onChange={(evt)=> {
                            formik.setFieldValue('sxolio', evt.target.value)
                        }}
                        placeholder="Γράψτε μας για το θέμα που σας απασχολεί."/>
                </FormControl>
            </Box>
            <Box sx={{
                '& .MuiButton-root':{
                    color:'#27293d',
                    fontFamily: '"Open Sans-Bold", Helvetica',
                    fontSize: '20px',
                    fontWeight: 400
                },
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                paddingBottom:'40px'
            }}>
                <Button
                    size={'large'}
                    variant="contained"
                    type={'submit'}
                    sx={{
                        backgroundColor:'#ffffff',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                        },
                    }}
                >Αποστολή<ArrowForwardIcon /></Button>
            </Box>
        </Box>
    )
}
export default ContactForm