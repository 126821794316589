import {Grid, useMediaQuery, useTheme} from "@mui/material";
import FeedLeftColumn from "../../components/feedLeftColumn/feedLeftColumn";
import FeedRightColumn from "../../components/feedRightColumn/feedRightColumn";
import React from "react";
import JobsShowData from "./jobsShowData";

type Props = {};

const JobsShow = (props:Props) =>{
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid container spacing={2} justifyContent="space-between">
            {!matches && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                >
                    <FeedLeftColumn/>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={6}
            >
                {props.children}
            </Grid>
            {!matches && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                >
                    <FeedRightColumn/>
                </Grid>
            )}
        </Grid>
    );

}
export default JobsShow