// @flow
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField,
	Typography
} from "@mui/material";
import './form.css'
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProvider, FormikProps} from "formik";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import Images from "../../../assets";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_RESET_PASSWORD, ROUTE_PAGE_SIGN_UP} from "../../../routers/routes";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../locales/pages/namespaces";
import PasswordInput from "../../../components/passwordInput/passwordInput";
import useUserAction from "../../../hooks/user/useUserAction";

type Props = {
	formik: FormikProps,
	loading: Boolean
};

const Form = (props: Props) => {
	const {formik, loading} = props
	const history = useHistory()
	const {t} = useTranslation(PAGE_LOGIN)
	return (
		<FormikProvider value={formik}>
			<Box
				className={'login-form'}
				sx={{
					padding: {
						xs: '6px 14px',
						sm: '12px 28px',
						md: '46px'
					}
				}}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Typography
					sx={{
						marginBottom: 1,
						color: '#050505',
						fontSize: '20px',
						fontWeight: '300',
						lineHeight: '40px',
						letterSpacing: 0,
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: '"Open Sans-Light", Helvetica'
					}}
				>
					
					{t('Connection')}
				</Typography>
				<Box
					className={'login-inputs-container'}
				>
					<FormControl
						variant="standard"
						fullWidth
						sx={{
							marginBottom: 2
						}}
						error={
							formik.touched['username'] &&
							Boolean(formik.errors['username'])
						}
					>
						<TextField
							autoFocus
							id={'username'}
							value={formik.values.username}
							name={'username'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label={t('Enter your email or username')}
							size={'small'}
							inputProps={{maxLength: 250}}
							variant="outlined"
							required
							sx={{
								'& .MuiOutlinedInput-root': {
									':focus-within': { border: '0.0px solid #ffffff !important' }
								},
							}}
						/>
						
						<FormHelperText>
							<ErrorMessage name={'username'}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						variant="outlined"
						fullWidth
						sx={{
							marginBottom: 2
						}}
						error={
							formik.touched['password'] &&
							Boolean(formik.errors['password'])
						}
					>
						<PasswordInput
							formik={formik}
							allowBrowserAutocomplete
							name={'password'}
							label={t('Password')}
							required
						/>
						<FormHelperText>
							<ErrorMessage name={'password'}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						variant="standard"
						fullWidth
						sx={{
							marginBottom: 2
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={formik.values.rememberMe}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									size={'small'}
									name={'rememberMe'}
								/>
							}
							label={<Typography sx={{fontFamily: '"Open Sans-Light", Helvetica'}}> {t('Remember me')}</Typography>}
							labelPlacement="end"
						/>
					</FormControl>
					<LoadingButton
						loading={loading}
						fullWidth
						variant="contained"
						type={'submit'}
						sx={{
							paddingTop: '10px',
							paddingBottom: '10px',
						}}
					>
						{t('Login')}
					</LoadingButton>
				</Box>
				<Box
					className={'login-buttons-container'}
				>
					<Button
						sx={{
							fontFamily: '"Open Sans-Italic", Helvetica',
							fontSize: '13px'
						}}
						onClick={() => history.push(getRouteUrl(ROUTE_PAGE_RESET_PASSWORD))}
					>
						{t('Forget password')}
					</Button>
					<Button
						href={'https://dev.ostrialinked.gr/api/mygoogle.php'}
						target={'_blank'}
						fullWidth
						variant="outlined"
						disableElevation
						sx={{
							borderRadius: '10px',
							padding: '10px 16px',
							marginTop: 2,
							boxShadow: '0 19.41px 36.05px #00000012',
							border: 'none'
						}}
					>
						<img alt="google-logo" src={Images.googleLogo}/>
						<div className={'login-fb'}> {t('Login with Google')}</div>
					</Button>
					{/*<Button*/}
					{/*	fullWidth*/}
					{/*	disableElevation*/}
					{/*	variant="contained"*/}
					{/*	color={'info'}*/}
					{/*	sx={{*/}
					{/*		borderRadius: '7px',*/}
					{/*		padding: '10px',*/}
					{/*		marginTop: 2*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<img alt="facebook-logo" src={Images.facebookLogo}/>*/}
					{/*	<span className={'login-fb'}>{t('Login with Facebook')}</span>*/}
					{/*</Button>*/}
					<br/>
				</Box>
				<Box
					className={"login-new-account"}
				>
					<Typography
						sx={{
							color: '#050505',
							fontSize: '20px',
							fontWeight: '300',
							lineHeight: '40px',
							letterSpacing: 0,
							textAlign: 'center',
							whiteSpace: 'nowrap',
							fontFamily: '"Open Sans-Light", Helvetica'
						}}
					>
						{t('Want to get started now?')}
					</Typography>
					<Button
						fullWidth
						variant="outlined"
						sx={{
							borderColor: '#2682d580'
						}}
						onClick={() => history.push(getRouteUrl(ROUTE_PAGE_SIGN_UP))}
					>
						{t('Create an account')}
					</Button>
				</Box>
			</Box>
		</FormikProvider>
	);
};

export default Form

