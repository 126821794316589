import Box from "@mui/material/Box";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteAdCompany, deletePost, getCompanyAds } from "../../api/requests";
import { useParams } from "react-router-dom";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../contentLoader/contentLoader";
import React from "react";
import { useTranslation } from "react-i18next";
import { PAGE_COMPANY } from "../../locales/pages/namespaces";
import Menu from "@mui/material/Menu";
import { ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PowerOff } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import PowerIcon from "@mui/icons-material/Power";
import JobCard from "../jobCard/jobCard";

const fields = [
  { name: "isActive", onlyForOwner: true },
  { name: "title" },
  { name: "worktype" },
  { name: "category" },
  { name: "date_ins" },
  { name: "description" },
  { name: "city" },
  { name: "level" },
  { name: "salary" },
];


const AdsList = ({ setInitialValues, deactivateAdCompanyAction, activeAdCompanyAction, isNotOwner }) => {
  const { t } = useTranslation(PAGE_COMPANY);
  const { id } = useParams();
  const { accessCode } = useUserData();
  const queryClient = useQueryClient();
  const { data: companyAdsData, isLoading } = useQuery(["getCompanyAds", accessCode, id], getCompanyAds);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = ({ id, e }) => {
    setAnchorEl({ id, anchorEl: e.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: deleteAdsCompanyAction } = useMutation({
    mutationFn: deleteAdCompany,
    onSuccess: (data, variables, context) => {
      let newCompanyAds = companyAdsData;

      if (data.deleted === "1") {
        newCompanyAds = newCompanyAds.filter(item => item.id !== variables.id);
      }
      queryClient.setQueryData(["getCompanyAds", accessCode, id], newCompanyAds);
      setAnchorEl(null);
    },
  });
  const editForm = () => {
    setInitialValues({
      action: "edit",
      initialValues: companyAdsData.find(x => x.id === anchorEl.id),
      value: 1,
    });
  };

  let isCompanyActive = anchorEl?.id && companyAdsData?.find(x => x.id === anchorEl.id).isActive;

  return (
    <Box backgroundColor={"#f3f3f3"} margin={"10px 4px"}>
      {!isLoading ?
        companyAdsData?.map(item => {
          if(item.company.isOwner === "false" && item.isActive === "0"){
            return null
          }
          return(
            <JobCard job={{
              id: item.id,
              title: item.title,
              companyName: item.company.name,
              city: item.city,
              employmentType: item.worktype,
              salary: item.salary,
              isOwner: item.company.isOwner,
              isActive: item.isActive,
              handleClick:handleClick
            }} />

          )
        })
        :
        <ContentLoader loading={isLoading}></ContentLoader>
      }
      <Menu
        id={`menu`}
        anchorEl={anchorEl?.anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            onClick={editForm}
            primary={t("edit")}
          />
        </ListItemButton>
        {isCompanyActive !== null &&
          <ListItemButton>
            <ListItemIcon>
              {isCompanyActive === "1"
                ?
                <PowerOff fontSize="small" />
                : <PowerIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText
              onClick={() =>{
                if( isCompanyActive === "1") {
                  deactivateAdCompanyAction({ id: anchorEl.id, accessCode })
                }else{
                  activeAdCompanyAction({ id: anchorEl.id, accessCode })
                }
                  setAnchorEl(null);
              }}
              primary={isCompanyActive === "1" ? t("deactivate") : t("activate")}
            />
          </ListItemButton>
        }
        <ListItemButton>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            onClick={() => deleteAdsCompanyAction({ id: anchorEl.id, accessCode })}
            primary={t("delete")}
          />
        </ListItemButton>
      </Menu>
    </Box>
  );
};
export default AdsList;