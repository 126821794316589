// @flow
import React from "react";
import ChatMessagesContainerSendMsgBar from "./chatMessagesContainerSendMsgBar";
import { Box } from "@mui/material";
import "./chatMessagesContainer.style.css";
import ChatMessage from "./chatMessage";

const ChatMessagesContainer = ({ conversationData, onSendMessage }) => {
  //fetch conversation getConversation
  return (
    <Box className="chatBoxWrapper">
      <Box className="chatBoxTop">
        {conversationData?.map((message, index) => {
          return (
            <div
              key={index}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <ChatMessage message={message} />
            </div>
          );
        })}
      </Box>
      <ChatMessagesContainerSendMsgBar onSendMessage={onSendMessage} />
    </Box>
  );
};

export default ChatMessagesContainer;
