// @flow
import React, { useState } from "react";
import Images from "../../assets";
import Box from "@mui/material/Box";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import { AccountCircle, Check, Close, MailOutline, PersonAddAlt1Outlined, PhotoCamera } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import { PAGE_COMPANY } from "../../locales/pages/namespaces";
import "./companyProfileHeader.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  becomeCompanyMember, changeCompanyBanner,
  changeCompanyLogo,
  changeGroupPhoto,
  deleteCompany,
  getCompany,
} from "../../api/requests";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_COMPANIES } from "../../routers/routes";
import { useHistory, useParams } from "react-router-dom";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../contentLoader/contentLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import UploadImage from "../uploadImage/uploadImage";
import OstriaModal from "../ostriaModal/ostriaModal";
import { GENERIC } from "../../locales/components/namespaces";
import useUserAction from "../../hooks/user/useUserAction";

type Props = {};

const CompanyProfileHeader = (props: Props) => {
  const { id } = useParams();
  const { t } = useTranslation(PAGE_COMPANY);
  const { t: g } = useTranslation(GENERIC);
  const { accessCode, isModalOpen: isDeleteModalOpen } = useUserData();
  const { openModal, closeModal } = useUserAction();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: companyData, isLoading } = useQuery(["getCompany", accessCode, id], getCompany);
  const { mutate: deleteCompanyAction } = useMutation({
    mutationFn: deleteCompany,
    onSuccess: (data, variables, context) => {
      history.push(getRouteUrl(ROUTE_PAGE_COMPANIES));
    },
  });

  const {mutate: changeCompanyLogoAction} = useMutation({
    mutationFn: changeCompanyLogo,
    onSuccess: (data, variables, context) => {
      if(data.saved === "true"){
        let newCompany=companyData
        newCompany.logo = variables.imageUrl
        queryClient.setQueryData(["getCompany", accessCode, id], newCompany)
      }
    },
  })

  const {mutate: changeCompanyBannerAction} = useMutation({
    mutationFn: changeCompanyBanner,
    onSuccess: (data, variables, context) => {
      if(data.saved === "true"){
        let newCompany=companyData
        newCompany.banner = variables.imageUrl
        queryClient.setQueryData(["getCompany", accessCode, id], newCompany)
      }
    },
  })

  const { mutate: becomeCompanyMemberAction } = useMutation({
    mutationFn: becomeCompanyMember,
    onSuccess: (data, variables, context) => {
      let company = companyData
      company.isMember = data.action === "insert" ? "true" : "false"
      company.members = data.action === "insert" ? (Number(companyData.members) + 1) : (Number(companyData.members) - 1)
      queryClient.setQueryData(["getCompany", accessCode, id], company);
    },
  });

  const [coverAnchorEl, setCoverAnchorEl] = React.useState(null)

  const handleCoverClick = (event) => {
    setCoverAnchorEl(event.currentTarget)
  }
  const handleCoverClose = () => {
    setCoverAnchorEl(null)
  }


  //cover image full screen
  const [showFullCoverScreen, setShowFullCoverScreen] = useState(false);

  const handleShowCoverClick = () => {
    setShowFullCoverScreen(coverAnchorEl !== null ? coverAnchorEl.id !== 'c-cover' ?
    	(companyData["logo"]) ? companyData["logo"]: Images.companyImage
    	: (companyData["banner"]) ? companyData["banner"] : Images.defaultProfile  :Images.defaultCover);
  };

  const handleCloseCoverClick = () => {
    setShowFullCoverScreen(false);
  };


  //for upload image modal
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [imageType, setImageType] = useState(null);
  const uploadModal = () => {
    setOpenUploadModal(true);
  }
  const deleteCompanyModal = () => {
    deleteCompanyAction(isDeleteModalOpen);
    closeModal();
  };
  return (
    <Box className="profileImages">
      {!!showFullCoverScreen && (
        <div
          className="fullscreen-overlay active"
          onClick={handleCloseCoverClick}
        >
          <div className="fullscreen-image">
            <img
              className="centered-image"
              // src={Images.userCoverPage}
              src={showFullCoverScreen}
              alt="Full Screen"

            />
          </div>
        </div>
      )}
      <Popover
        id={'simple-popover-cover-profile'}
        open={coverAnchorEl}
        anchorEl={coverAnchorEl}
        onClose={handleCoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box sx={{display: 'grid'}}>
          <Tooltip title={t('show cover profile')}>
            <Button sx={{justifyContent: 'start'}} onClick={() => {
              console.log('show image profile')
              setCoverAnchorEl(null) //gia na kleinei to popover, otan anoigei to quickview
              handleShowCoverClick()
            }}>
              <AccountCircle/>
              {coverAnchorEl !== null ? coverAnchorEl.id !== 'c-cover' ? t('show image profile') : t('show cover profile') : null}
            </Button>
          </Tooltip>
          <Tooltip title={t('change image profile')}>
            <Button sx={{justifyContent: 'start'}} onClick={() => {
              console.log('change cover profile')
              setCoverAnchorEl(null) //gia na kleinei to popover, otan anoigei to quickview
              uploadModal()
              setImageType(coverAnchorEl?.id)
            }}>
              <PhotoCamera/>
              {coverAnchorEl !== null ? coverAnchorEl.id !== 'c-cover' ? t('change image profile') : t('change cover profile') : null}
            </Button>
          </Tooltip>
        </Box>
      </Popover>
      <OstriaModal
        open={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
      >
          <UploadImage
            moreInfos={imageType!== 'c-cover' ? {aspect:1,id} : {aspect:3/2,id}}
            mutateFn={imageType !== null ? imageType!== 'c-cover' ? changeCompanyLogoAction : changeCompanyBannerAction : null}
            imageType={imageType}
            onClose={() => setOpenUploadModal(false)}/>
      </OstriaModal>
      {!isLoading ?
        <>
          {(companyData["banner"]) ?
            <img id={'c-cover'} className="userCover_png" alt="usercover_png" src={companyData["banner"]} onClick={companyData?.isOwner === "true" ? handleCoverClick : ()=>null}/> :
            <img id={'c-cover'} className="userCover_png" alt="usercover_png" src={Images.noCover} onClick={companyData?.isOwner === "true" ? handleCoverClick : ()=>null} />}
          <Box>
            {(companyData["logo"]) ?
              <img id={'c-profile'} className="companyImage_png" alt="userimage_png" src={companyData["logo"]} onClick={companyData?.isOwner === "true" ? handleCoverClick : ()=>null}/>
              : <img id={'c-profile'} className="companyImage_png" alt="userimage_png" src={Images.companyImage} onClick={companyData?.isOwner === "true" ? handleCoverClick : ()=>null}/>
            }
            <Box className="companyDetails">
              <Box className="companyDetailsLabels">
                <Box sx={{ display: "flex" }}>
                  <Box display={"flex"} width={"100%"}>
                    <label className="companyDetailsLabel">{companyData["title"]} </label>
                    {/*<Typography sx={{ fontSize: "small" }}>*/}
                    {/*  {companyData["official_title"]} {" "}*/}
                    {/*  {companyData["members"]}*/}
                    {/*</Typography>*/}
                  </Box>
                  {companyData["isOwner"] === "false" && <IconButton
                    onClick={() => becomeCompanyMemberAction({ id: id, accessCode })}
                    className="becomeMember"
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                      paddingLeft: "5px",
                      width: "200px",
                      height: "30px",
                      marginLeft: "5px",

                    }}
                  >
                    {companyData["isMember"] === "true" ?
                      <RemoveIcon />
                      : <Avatar sx={{ width: "15px", height: "15px" }} src={Images.plusIcon} />}

                    <Box sx={{
                      marginLeft: "3%",
                      fontSize: "small",
                      fontFamily: "Open Sans-Regular",
                    }}>
                      {companyData["isMember"] === "true" ? t("Remove member") : t("Become a member")}
                    </Box>
                  </IconButton>}
                </Box>
              </Box>
              <Box>
                <Box className="userDetailsButtons">
                  {
                    companyData["isOwner"] === "true" &&
                    <IconButton
                      onClick={() => openModal({ id: id, accessCode })}
                      className="becomeMember"
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "5px",
                        "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                        paddingLeft: "5px",
                        width: "120px",
                        height: "30px",
                        marginLeft: "5px",

                      }}
                    >
                      <DeleteIcon width={"15px"} height={"15px"} fontSize="small" />
                      <Box sx={{
                        marginLeft: "3%",
                        fontSize: "small",
                        fontFamily: "Open Sans-Regular",
                      }}>
                        {t("Delete")}
                      </Box>
                    </IconButton>}
                </Box>
              </Box>
            </Box>
          </Box>
        </> :
        <ContentLoader loading={isLoading}></ContentLoader>
      }
      <OstriaModal
        open={ Boolean(isDeleteModalOpen) && isDeleteModalOpen.id }
        onClose={closeModal}
      >
        <Box
          sx={{
            height: "auto",
            padding: "16px",
            margin: "auto",
          }}
        >
          <Typography
            sx={{
              color: "#050505",
              fontFamily: "\"Segoe UI-Bold\", Helvetica",
              fontSize: { xs: "15px", md: "21px" },
              fontWeight: "700",
              marginBottom: { xs: "5px", md: "10px" },
              textAlign: "center",
            }}
          >
            {g("Are you sure?")}
          </Typography>
          <Box className="reportButtons">
            <IconButton onClick={deleteCompanyModal}>
              <Check color="success" />
              {g("yes")}
            </IconButton>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: "red" }} />
              {g("no")}
            </IconButton>
          </Box>
        </Box>
      </OstriaModal>
    </Box>
  );
};

export default CompanyProfileHeader;