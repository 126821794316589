import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'
import {request} from "axios";
import { GET_USER_RECOMMENDATIONS, LIKE_POST } from "../actions/types";

const userLogin = requestCreator({
    url: '/api.php?q=2',
    method: 'POST',
    onSuccess: Actions.userLoginSucceeded,
    onFailure: Actions.userLoginFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const userLoginByGoogle = requestCreator({
    url: '/mygoogle.php',
    method: 'POST',
    onSuccess: Actions.userLoginSucceeded,
    onFailure: Actions.userLoginFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const userLogout = requestCreator({
    url: '/api.php?q=5',
    method: 'GET',
    onSuccess: Actions.userLogoutSucceeded,
    onFailure: Actions.userLogoutFailed,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

const registerUser = requestCreator({
    url: '/api.php?q=1',
    method: 'POST',
    onSuccess: Actions.registerUserSucceeded,
    onFailure: Actions.registerUserFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const resetUserPassword = requestCreator({
    url: '/api.php?q=1b',
    method: 'POST',
    onSuccess: Actions.resetUserPasswordSucceeded,
    onFailure: Actions.resetUserPasswordFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const forgotUserPassword = requestCreator({
    url: '/api.php?q=1c',
    method: 'POST',
    onSuccess: Actions.forgotUserPasswordSucceeded,
    onFailure: Actions.forgotUserPasswordFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const getUserDetails = requestCreator({
    url: '/api.php?q=3',
    method: 'GET',
    onSuccess: Actions.getUserDetailsSucceeded,
    onFailure: Actions.getUserDetailsFailed,
})

const submitPost = requestCreator({
    url: '/api.php?q=9a',
    method: 'POST',
    onSuccess: Actions.submitPostSucceeded,
    onFailure: Actions.submitPostFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})
const getPersonalData = requestCreator({
    url: '/api.php?q=8b',
    method: 'GET',
    onSuccess: Actions.getPersonalDataSucceeded,
    onFailure: Actions.getPersonalDataFailed,
    
})

const postPersonalData = requestCreator({
    url: '/api.php?q=8a',
    method: 'POST',
    onSuccess: Actions.postPersonalDataSucceeded,
    onFailure: Actions.postPersonalDataFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const postUserDetails = requestCreator({
    url: '/api.php?q=7',
    method: 'POST',
    onSuccess: Actions.postUserDetailsSucceeded,
    onFailure: Actions.postUserDetailsFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})
const postUserRecommendation = requestCreator({
    url: '/api.php?q=10',
    method: 'POST',
    onSuccess: Actions.postUserRecommendationSucceeded,
    onFailure: Actions.postUserRecommendationFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const getUserRecommendationsData = requestCreator({
    url: '/api.php?q=10b',
    method: 'GET',
    onSuccess: Actions.getUserReccomendationsSucceeded,
    onFailure: Actions.getUserReccomendationsFailed,
    
})

// const recommendationWithId = requestCreator({
//     url: '/api.php?q=10d',
//     method: 'POST',
//     onSuccess: Actions.recommendationWithIdSucceeded,
//     onFailure: Actions.recommendationWithIdFailed,
//     headers: {
//         'Content-Type': 'multipart/form-data',
//     }
// })
const postRecommendationEdit = requestCreator({
    url: '/api.php?q=10a',
    method: 'POST',
    onSuccess: Actions.postRecommendationEditSucceeded,
    onFailure: Actions.postRecommendationEditFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const getUserProfileImg = requestCreator({
    url: '/api.php?q=12b',
    method: 'GET',
    onSuccess: Actions.getUserProfileImgSucceeded,
    onFailure: Actions.getUserProfileImgFailed,
})


const getUserCoverImg = requestCreator({
    url: '/api.php?q=12d',
    method: 'GET',
    onSuccess: Actions.getUserCoverImgSucceeded,
    onFailure: Actions.getUserCoverImgFailed,
})

const getAllPosts = requestCreator({
    url: '/api.php?q=9s2e12',
    method: 'GET',
    onSuccess: Actions.getAllPostsSucceeded,
    onFailure: Actions.getAllPostsFailed
})

const getFullPost = requestCreator({
    url: '/api.php?q=9s2',
    method: 'GET',
    onSuccess: Actions.getFullPostSucceeded,
    onFailure: Actions.getFullPostFailed
})

const getPostComments = requestCreator({
    url: '/api.php?q=9k3',
    method: 'POST',
    onSuccess: Actions.getPostCommentsSucceeded,
    onFailure: Actions.getPostCommentsFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})
const addPostComment = requestCreator({
    url: '/api.php?q=9k1',
    method: 'POST',
    onSuccess: Actions.addPostCommentSucceeded,
    onFailure: Actions.addPostCommentFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const deletePostComment = requestCreator({
    url: '/api.php?q=9k2',
    method: 'POST',
    onSuccess: Actions.deletePostCommentSucceeded,
    onFailure: Actions.deletePostCommentFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const deletePost = requestCreator({
    url: '/api.php?q=9ad',
    method: 'POST',
    onSuccess: Actions.deletePostSucceeded,
    onFailure: Actions.deletePostFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const likePost = requestCreator({
    url: '/api.php?q=401a',
    method: 'POST',
    onSuccess: Actions.likePostSucceeded,
    onFailure: Actions.likePostFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
})

const requestHandler = {
    [ActionTypes.USER_LOGIN]: userLogin,
    [ActionTypes.USER_LOGOUT]: userLogout,
    [ActionTypes.GET_USER_DETAILS]: getUserDetails,
    [ActionTypes.REGISTER_USER]: registerUser,
    [ActionTypes.RESET_USER_PASSWORD]: resetUserPassword,
    [ActionTypes.FORGOT_USER_PASSWORD]: forgotUserPassword,
    [ActionTypes.SUBMIT_POST]: submitPost,
    [ActionTypes.GET_PERSONAL_DATA]: getPersonalData,
    [ActionTypes.POST_PERSONAL_DATA]: postPersonalData,
    [ActionTypes.POST_USER_DETAILS]: postUserDetails,
    [ActionTypes.POST_USER_RECOMMENDATION]: postUserRecommendation,
    [ActionTypes.GET_USER_RECOMMENDATIONS]: getUserRecommendationsData,
    // [ActionTypes.RECOMMENDATION_WITH_ID]: recommendationWithId,
    [ActionTypes.POST_RECOMMENDATION_EDIT]: postRecommendationEdit,
    [ActionTypes.GET_USER_PROFILE_IMG]: getUserProfileImg,
    [ActionTypes.GET_USER_COVER_IMG]: getUserCoverImg,
    [ActionTypes.GET_ALL_POSTS]: getAllPosts,
    [ActionTypes.GET_FULL_POST]: getFullPost,
    [ActionTypes.GET_POST_COMMENTS]: getPostComments,
    [ActionTypes.ADD_POST_COMMENT]: addPostComment,
    [ActionTypes.DELETE_POST_COMMENT]: deletePostComment,
    [ActionTypes.DELETE_POST]: deletePost,
    [ActionTypes.LIKE_POST]: likePost,
    [ActionTypes.LOGIN_GOOGLE]: userLoginByGoogle,
}

export default requestHandler
