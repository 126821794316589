// @flow
import React, {useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import useUserData from "../../hooks/user/useUserData";
import {Button, Input, ListItem, ListItemAvatar, Modal, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {grey} from "@mui/material/colors";
import Images from "../../assets";
import './postComponent.css'
import Post from "../postPopup/postModal/post";

type Props = {};

const PostComponent = (props: Props) => {
	const {t} = useTranslation(PAGE_HOME)
	const {data, userProfileImg} = useUserData();
	const userNameFirstLetter = useMemo(() => (data?.username?.[0]?.toUpperCase()), [data])
	const [showPostComponent, setShowPostComponent] = useState(true);
	
	return (
		
		(showPostComponent) ?
			(
				<Box className="postComponentContainer">
					<Box className={"postComponent"}>
						
						{(userProfileImg.photoprofile) ?
							<Avatar sx={{width: 40, height: 40, marginRight: '5px',cursor:'pointer'}}  onClick={() => setShowPostComponent(false)}
									src={userProfileImg.photoprofile}></Avatar>
							:
							<Avatar
								onClick={() => setShowPostComponent(false)}
								sx={{
									bgcolor: grey[900],
									width: 40,
									height: 40,
									marginRight: '5px'
								}}>{userNameFirstLetter}</Avatar>
						}
						<Box className="thinkingInput"
							 onClick={() => setShowPostComponent(false)}
							 sx={{
								 width: '80%',
								 marginRight: '3%',
							 }}
						>
							<Input
								sx={{padding: '5px'}}
								// className="thinking"
								name="thinking"
								inputtype={'text'}
								placeholder={t('what are you thinking?')}
								disableUnderline={true}
							
							/>
						</Box>
						<Box className="iconBtns">
							<img alt="bank" src={Images.picturesPostComponent} style={{marginRight: '20%',marginLeft: '-5px',width:'30px'}}/>
							{/*<img alt="search" src={Images.clipPostComponent} style={{width:'25px'}}/>*/}
						</Box>
					</Box>
				</Box>
			) :
			(
				<Post/>
			)
	
	
	);
};

export default PostComponent