// @flow
import React from "react";
import {
  InputLabel,
  CircularProgress,
  FormControl,
  Box,
  Avatar,
  Typography,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useUserData from "../../hooks/user/useUserData";
import { getAllUsers } from "../../api/requests";
import { useQuery } from "react-query";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_FRIEND } from "../../routers/routes";
import { useHistory } from "react-router-dom";

const ChatMessagesContainerHeader = ({
  triggerSrc,
  selectedUserInfo,
  onNewReceiversSelected,
}) => {
  const {
    id: selectedReceiverId,
    name: selectedReceiverName,
    imageUrl: selectedReceiverImageUrl,
    username: selectedReceiverUsername,
  } = selectedUserInfo;
  const { accessCode } = useUserData();
  const history = useHistory();

  const {
    data: msgReceivers,
    isLoading,
    error,
  } = useQuery(["getAllUsers", accessCode], getAllUsers, {
    enabled: triggerSrc === "new-chat-button-clicked" || !selectedUserInfo.id, // Initially disabled
  });

  if (error) {
    console.error("Error fetching users:", error);
  }

  const autocompleteOptions = msgReceivers?.map((receiver) => ({
    id: receiver.id,
    label: `${receiver.firstname} ${receiver.lastname}`,
    imageUrl: receiver.image_profile,
    username: receiver.username,
  }));

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Box
      sx={{
        zIndex: 1,
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.15)",
        backgroundColor: "transparent",
      }}
    >
      {selectedReceiverId ? (
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            height: "73px",
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            marginLeft: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginLeft: 2,
            }}
          >
            <Avatar
              src={selectedReceiverImageUrl}
              onClick={() =>
                history.push(
                  getRouteUrl(ROUTE_PAGE_FRIEND, {
                    username: selectedReceiverUsername,
                    id: selectedReceiverId,
                  })
                )
              }
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
            <Typography
              component="div"
              fontWeight="bold"
              onClick={() =>
                history.push(
                  getRouteUrl(ROUTE_PAGE_FRIEND, {
                    username: selectedReceiverUsername,
                    id: selectedReceiverId,
                  })
                )
              }
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              {selectedReceiverName}
            </Typography>
          </Box>
        </Box>
      ) : (
        <FormControl sx={{ m: 1, width: "calc(100% - 32px)" }}>
          <Autocomplete
            id="msg-receivers-dropdown"
            disablePortal
            filterOptions={filterOptions}
            options={autocompleteOptions || []}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              onNewReceiversSelected({
                recieverId: newValue ? newValue.id : "",
                receiverFullName: newValue ? newValue.label : "",
                receiverImageUrl: newValue ? newValue.imageUrl : "",
                receiverUsername: newValue ? newValue.username : "",
              });
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={isLoading}
            noOptionsText="Δεν βρέθηκαν επιλογές"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Προς"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Avatar src={option.imageUrl} sx={{ mr: 1 }} />
                {option.label}
              </li>
            )}
          />
        </FormControl>
      )}
    </Box>
  );
};

export default ChatMessagesContainerHeader;
