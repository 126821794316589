// @flow
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./openPositions.css";
import Link from "@mui/material/Link";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_GROUPS_CREATE,
  ROUTE_PAGE_JOB_APPLY,
  ROUTE_PAGE_JOB_SHOW,
  ROUTE_PAGE_JOBS,
} from "../../routers/routes";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../locales/pages/namespaces";
import { useQuery } from "react-query";
import { getCompany, getRandomJobs } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../contentLoader/contentLoader";
import { useHistory } from "react-router";

type Props = {};

const OpenPositions = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const { accessCode } = useUserData();
  const { data: jobs, isLoading } = useQuery(["getRandomJobs", accessCode], getRandomJobs);
  const history = useHistory();
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: 1,
        padding: 2,
        marginBottom: 2,
        marginTop: 2,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontSize: { xs: 18, sm: 20 },
          color: "#020700",
          fontFamily: "\"Open Sans-Regular\", Helvetica",
          fontWeight: 400,
          lineHeight: "24px",
          height: "24px",
          letterSpacing: 0,
          marginBottom: 3,
        }}
      >
        Ανοιχτές θέσεις εργασίας
      </Typography>
      {isLoading ?
        <ContentLoader loading={isLoading}></ContentLoader>
        :
        <>
          {jobs && jobs?.map(job=>
            <Box>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography
                    style={{cursor:'pointer'}}
                    onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_JOB_SHOW,{jobId:job.id}))}
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 12, sm: 14 },
                    }}
                  >
                    {job.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    gutterBottom
                    sx={{
                      fontWeight: 300,
                      fontSize: { xs: "10px", sm: "12px" },
                    }}
                  >
                    Περιοχή: {job.city}
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: { xs: 12, sm: 14 },
                  color: "#020700",
                  fontFamily: "\"Open Sans-Regular\", Helvetica",
                  fontWeight: 400,
                  lineHeight: "21px",
                  letterSpacing: 0,
                  marginBottom: 2,
                }}
              >
                {job.description}
              </Typography>
            </Box>
          )}
        </>}
      <Box
        sx={{
          height: "26px",
          left: "18px",
          top: "13px",
          width: "100%",
          maxWidth: "506px",
          "@media (min-width: 150px)": {
            maxWidth: "506px",
          },
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          sx={{
            color: "#020700",
            fontFamily: "\"Open Sans-Regular\", Helvetica",
            fontSize: { xs: 14, sm: 16 },
            fontWeight: 400,
            height: "24px",
            left: 0,
            letterSpacing: 0,
            lineHeight: "24px",
            textAlign: "right",
            top: "2px",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <Link href={getRouteUrl(ROUTE_PAGE_JOBS)} underline="hover"
                sx={{ fontSize: "small" }}>{t("search for other jobs...")}</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default OpenPositions;