// @flow
import React, { useState } from "react";
import Box from "@mui/material/Box";
import "./uploadImage.css";
import Images from "../../assets";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../locales/pages/namespaces";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import { ReactCrop } from "react-image-crop";
import Cropper from "react-easy-crop";
import { getCroppedImg, convertUrlToFile, handleImageCompression, handlePdfCompression } from "../../helpers/helpers";

const UploadImage = ({ imageType, onClose, mutateFn, moreInfos }) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const [imageUrl, setImageUrl] = useState(null);
  const [fieldShow, setFieldShow] = useState(true);
  const { accessCode } = useUserData();
  const { getUserProfileImg, getUserCoverImg } = useUserAction();

  //cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
      );
      setImageUrl(null)
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFileChange = async (event) => {
    let compressedFile = event.target.files[0];
    if (event.target.files[0].type.includes("image")) {
      compressedFile = await handleImageCompression(event);
    } else if (event.target.files[0].type.includes("pdf")) {
      compressedFile = await handlePdfCompression(event);
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);

    };
    reader.readAsDataURL(compressedFile);
    setFieldShow(false);
  };


  //upload
  const handleFileUpload = async (event) => {
    let uploadFile = null
    if(croppedImage){
      uploadFile  = await convertUrlToFile(croppedImage);
    }

    if (imageType === "profile") {

      event.preventDefault();
      const formData = new FormData();
      formData.append("photoprofil", uploadFile);


      try {
        const response = await axios({
          method: "post",
          url: "https://dev.ostrialinked.gr/api/api.php?q=12a&scode=" + accessCode,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },

        });

        console.log(response.status);
        if (response.status === 200) {
          onClose();
          getUserProfileImg();
        }
      } catch (error) {
        console.log(error);
      }
    } else if (imageType === "cover") {

      event.preventDefault();
      const formData = new FormData();
      formData.append("photobanner", uploadFile);
      try {
        const response = await axios({
          method: "post",
          url: "https://dev.ostrialinked.gr/api/api.php?q=12c&scode=" + accessCode,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },

        });

        console.log(response.status);
        if (response.status === 200) {
          onClose();
          getUserCoverImg();
        }
      } catch (error) {
        console.log(error);
      }
    } else if (imageType === "group") {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("id_group", moreInfos.groupCode);
      mutateFn({ accessCode: accessCode, form: formData, imageUrl });
      onClose();
    } else if (imageType === "c-cover") {
      const formData = new FormData();
      formData.append("banner", uploadFile);
      formData.append("id_company", moreInfos.id);
      mutateFn({ accessCode: accessCode, form: formData, imageUrl });
      onClose();
    } else if (imageType === "c-profile") {
      const formData = new FormData();
      formData.append("logo", uploadFile);
      formData.append("id_company", moreInfos.id);
      mutateFn({ accessCode: accessCode, form: formData, imageUrl });
      onClose();
    }
  };

  return (
    <>
      {imageUrl ?
        <Box>
          <div className={"cropContainer"}>
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              aspect={moreInfos.aspect || 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <Button onClick={showCroppedImage} variant="contained" style={{ position: "absolute", bottom: 24,right: 24 }}>
            {"Ολοκλήρωση"}
          </Button>
        </Box>
        :
        <form id="formElement" encType="multipart/form-data" method="post" className="modalContainer">
          <Box className="chooseImage">
            {croppedImage && <img src={croppedImage} alt="Uploaded Image" height="100"/>}
            {fieldShow && (<TextField
                defaultValue={t("choose an image")}
                size={"small"}
                // inputProps={{maxLength: 250}}
                variant="outlined"
                disabled
                multiline={true}
                // minRows={5}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: 50,
                    border: "0px solid #ffffff",
                    marginTop: "1%",

                    ":hover": {
                      border: "0.5px solid #ffffff !important",
                      boxShadow: "-1px 1px 4px 4px #ffffff",
                    },
                    ":focus-within": { border: "0.5px solid #ffffff !important" },
                  },
                  "& .MuiOutlinedInput-root.Mui-disabled": {
                    ":hover": {
                      border: "1px solid #ffffff !important",
                      boxShadow: "none",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              />
            )}
          </Box>

          <Box className={"modalButtons"}>
            {fieldShow && (<Button
                // variant="contained"
                component="label"
              >
                <img alt="search" src={Images.addPictures} />
                {/*{t('choose an image')}*/}
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  // multiple
                  hidden
                  onChange={async (e) => await handleFileChange(e)}
                />
              </Button>
            )}
            {!fieldShow && (
              <Button onClick={handleFileUpload}>
                {t("upload")}
              </Button>
            )}
            <Button onClick={onClose}>
              {t("cancel")}
            </Button>
          </Box>
        </form>}
    </>
  );
};

export default UploadImage;

