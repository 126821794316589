import {useTranslation} from "react-i18next";
import {Button, Grid, IconButton, Typography, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Images from "../../assets";
import React, {useState} from "react";
import {PAGE_JOB} from "../../locales/pages/namespaces";
import JobsShow from "./jobsShow";
import {ArrowBack} from "@mui/icons-material";
import {
	ROUTE_PAGE_EDIT_JOB,
	ROUTE_PAGE_JOB_APPLY,
	ROUTE_PAGE_JOBS,
	ROUTE_PAGE_NOTIFICATIONS_LIST
} from "../../routers/routes";
import { useHistory, useParams } from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import useUserData from "../../hooks/user/useUserData";
import { useQuery, useQueryClient } from "react-query";
import { getAdCompany, getCompanyAds } from "../../api/requests";

const JobsShowData = () => {
	const theme = useTheme();
	const {t} = useTranslation(PAGE_JOB);
	const history = useHistory();
	const { jobId } = useParams();
	const { accessCode } = useUserData();
	const queryClient = useQueryClient();
	const { data: companyAdData, isLoading } = useQuery(["getAdCompany", accessCode,jobId ], getAdCompany);

	if(isLoading){
		return null
	}
	return (
		<JobsShow>
			<Box
				className="jobInfo"
				// sx={{width: "100%", margin: "10px auto", padding: theme.spacing(2)}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Grid container>
							{/*{!companyAdData.company.logo && <Box*/}
							{/*	className="company-logo"*/}
							{/*	sx={{*/}
							{/*		backgroundColor: "#f0f0f0",*/}
							{/*		border: "1px solid",*/}
							{/*		borderColor: "#27293d80",*/}
							{/*		borderRadius: "9px",*/}
							{/*		height: "112px",*/}
							{/*		width: "132px",*/}
							{/*		flex: "0 0 auto", // Fixed size*/}
							{/*		display: "flex",*/}
							{/*		alignItems: "center",*/}
							{/*		justifyContent: "center",*/}
							{/*	}}*/}
							{/*>*/}

							{/*	<Typography*/}
							{/*		sx={{*/}
							{/*			color: '#000000',*/}
							{/*			fontFamily: '"Open Sans-Italic", Helvetica',*/}
							{/*			fontSize: '15px',*/}
							{/*			fontStyle: 'italic',*/}
							{/*			fontWeight: '400',*/}
							{/*			letterSpacing: '0',*/}
							{/*			textAlign: 'center',*/}
							{/*		}}*/}
							{/*	>*/}
							{/*		{t("Company")}*/}
							{/*		<br/>*/}
							{/*		{t("Logo")}*/}
							{/*	</Typography>*/}
							{/*</Box>*/}
							{/*}*/}
							{/*{companyAdData.company.logo &&*/}
							{/*	<img alt="google-logo" style={{ height: "112px",width: "132px",}}*/}
							{/*		 src={companyAdData.company.logo}/>*/}
							{/*}*/}
							
							<Grid item xs={12} md={6} sx={{paddingLeft: theme.spacing(2),}}>
								<Box
									sx={{
										color: "#27293D",
										fontFamily: "'Open Sans-Bold', Helvetica",
										fontSize: {xs: "20px", md: "28px"},
										fontWeight: "700",
										whiteSpace: "nowrap", // Prevent title from wrapping
									}}
								>
									{companyAdData.title}
								</Box>
								<Box
									sx={{
										color: '#27293d',
										fontFamily: '"Open Sans-Bold", Helvetica',
										fontSize: '15px',
										fontWeight: '700',
										height: '16px'
									}}
								>
									{companyAdData.company.official_title}
								</Box>
								<Box
									sx={{
										color: '#616161',
										fontFamily: '"Open Sans-Regular", Helvetica',
										fontSize: '15px',
										fontWeight: '400',
										marginTop: '7px'
									}}
								>
									<img src={Images.redPin}/>
									{companyAdData.city}
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Grid item>
								<Button
									onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_JOB_APPLY,{id:jobId}))}
									sx={{
										color: "white",
										backgroundColor: "black",
										borderRadius: '10px',
										"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
										justifyContent: 'center',
										padding: '15px',
										height: '42px',
										fontSize: 'revert'
										
									}}
								>
									{t("send request")}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Box sx={{
					borderBottom: 1,
					borderColor: 'divider',
					backgroundColor: 'white',
					marginTop: '10px'
				}}>
				</Box>
				<Box>
					<Box className="fullDescriptionTitle"
						 sx={{padding: '0', paddingLeft: '1%', paddingTop: '1%', paddingRight: '1%'}}>
						<Typography
							sx={{
								color: '#27293d',
								fontFamily: '"Open Sans-Regular", Helvetica',
								fontSize: '24px',
								fontWeight: '400'
							}}
						>
							{t('full desc position')}
						</Typography>
					</Box>
					<Box className="fullDescription"
						 sx={{padding: '0', paddingLeft: '1%', paddingTop: '1%', paddingRight: '1%'}}>
						<Typography
							sx={{
								color: '#595959',
								fontFamily: '"Open Sans-Regular", Helvetica',
								fontSize: '13.1px',
								fontWeight: ' 400'
							}}
						>
							{companyAdData.description}
						</Typography>
					</Box>
				</Box>
				<Box marginTop={"30px"}>
                    <Box sx={{display:'flex'}}>
                        <Box className="jobTag">
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Bold", Helvetica',
                                fontSize: '12px',
                                fontWeight: '700',
                                textAlign: "center"
                            }}
                            > {t('employmentType')}
                            </Typography>
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '12px',
                                fontWeight: '400',
                                textAlign: "center"
                            }}
                            > {companyAdData.worktype}
                            </Typography>
                        </Box>
                        <Box className="jobTag">
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Bold", Helvetica',
                                fontSize: '12px',
                                fontWeight: '700',
                                textAlign: "center"
                            }}
                            > {t('Salary')}
                            </Typography>
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '12px',
                                fontWeight: '400',
                                textAlign: "center"
                            }}
                            > {companyAdData.salary}
                            </Typography>
                        </Box>
                        <Box className="jobTag" >
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Bold", Helvetica',
                                fontSize: '12px',
                                fontWeight: '700',
                                textAlign: "center"
                            }}
                            > {t('Position Category')}
                            </Typography>
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '12px',
                                fontWeight: '400',
                                textAlign: "center"
                            }}
                            > {companyAdData.category}
                            </Typography>
                        </Box>
                        <Box className="jobTag">
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Bold", Helvetica',
                                fontSize: '12px',
                                fontWeight: '700',
                                textAlign: "center"
                            }}
                            > {t('Experience Level')}
                            </Typography>
                            <Typography sx={{
                                color: '#ffffff',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '12px',
                                fontWeight: '400',
                                textAlign: "center"
                            }}
                            > {companyAdData.level}
                            </Typography>
                        </Box>
                    </Box>
					<Box sx={{justifyContent:'end', display:'flex'}}>
                        <Typography sx={{
                            color: '#595959',
                            fontFamily: '"Open Sans-Bold", Helvetica',
                            fontSize: '10.8px',
                            fontWeight: '400',
                            marginRight:'5px'
                        }}
                        > {t('announced')}
                        </Typography>
                        <Typography sx={{
                            color: '#616161',
                            fontFamily: 'Open Sans-Regular',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                        }}
                        > {companyAdData.date_ins}
                        </Typography>
                    </Box>
				</Box>
				<Box>
					<IconButton
						onClick={()=>history.goBack()}
						sx={{paddingTop:'20px',
							"&:hover, &.Mui-focusVisible": {backgroundColor: "white"},
						}}
					>
						<ArrowBack/>
						<Typography sx={{
							color: '#020700',
							fontFamily: 'Open Sans-Regular',
							fontSize: 'medium',
							fontStyle: 'normal',
							fontWeight: '400',
							lineHeight: '24px' }}
						>
							{t('all ads')}
						</Typography>
					</IconButton>
				</Box>
			</Box>
		</JobsShow>
	);
};

export default JobsShowData;

