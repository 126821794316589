import FeedLayout from "../layouts/feedLayout/feedLayout";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {Button, FormControl, FormHelperText, Grid, Paper, TextField, Typography} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as yup from "yup";
import React, {useState} from "react";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import './createGroup.css'
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_GROUPS, ROUTE_PAGE_GROUPS_CREATE, ROUTE_PAGE_PERSONAL_GROUPS} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PAGE_COMPANY, PAGE_GROUP} from "../../locales/pages/namespaces";
import {useMutation} from "react-query";
import axios from "axios";
import useUserData from "../../hooks/user/useUserData";
import IconButton from "@mui/material/IconButton";
import Images from "../../assets";
import {addGroup} from "../../api/requests";
import { handleImageCompression, handlePdfCompression } from "../../helpers/helpers";


const CreateGroup = () => {
    const history = useHistory()
    const {t} = useTranslation(PAGE_GROUP)
    const {accessCode,username} = useUserData();

    const {mutate: addGroupAction} = useMutation({
        mutationFn: addGroup,
        onSuccess: (data, variables, context) => {
            history.push(getRouteUrl(ROUTE_PAGE_GROUPS))
        },
    })

    const onSubmit = (values) => {
        let formData = new FormData();
        formData.append('file', values.file);
        formData.append('title', values.groupName);
        formData.append('description', values.description);
        addGroupAction({
            formData,
            accessCode
        });
    }
    const validationSchema = yup.object({
        groupName: yup
            .string().required().max(64, 'Μέγιστος αριθμός χαρακτήρων 64'),
        description: yup
            .string().required()
    })
    const formik = useFormik({
        initialValues: {
            groupName: '',
            description: '',
            file: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
    })
    const textareaStyle = {
        fontFamily: 'Arial, sans-serif',
        height: '100px',
        width: '100%',
        overflow: 'auto',
        marginBottom: '10px'
    };
    const handleFileChange =async (event) => {
      let compressedFile = event.target.files[0];
      if (event.target.files[0].type.includes("image")) {
        compressedFile = await handleImageCompression(event);
      } else if (event.target.files[0].type.includes("pdf")) {
        compressedFile = await handlePdfCompression(event);
      }
        formik.setFieldValue('file',compressedFile);
    };

    return (
        <FeedLayout>
            <FormikProvider value={formik}>
                <Box
                    component="form"
                    sx={{
                        marginTop: '20px'
                    }}
                    //onSubmit={formik.handleSubmit}
                >
                    <Paper elevation={0}>
                        <Grid container sx={{display: 'flex', alignItems: 'flex-start', marginBottom: '10px'}}
                              spacing={1}>
                            <Grid item>
                                <Button size={'small'}
                                        onClick={() => history.push(getRouteUrl(ROUTE_PAGE_PERSONAL_GROUPS,{username:username}))}
                                ><ArrowBackIcon/></Button>
                            </Grid>
                            <Grid item> <Typography sx={{fontSize: '17px'}}>{t('Create New Group')}</Typography></Grid>
                        </Grid>
                        <Box sx={{margin: '10px'}}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                sx={{
                                    marginBottom: 2
                                }}
                                error={
                                    formik.touched['groupName'] &&
                                    Boolean(formik.errors['groupName'])
                                }
                            >
                                <TextField
                                    id={'groupName'}
                                    required
                                    value={formik.values.groupName}
                                    name={'groupName'}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label={t('Group Name')}
                                    size={'small'}
                                    inputProps={{maxLength: 64}}
                                    variant="outlined"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            ':focus-within': {border: '0.0px solid #ffffff !important'}
                                        },
                                        '& .MuiFormLabel-root': {
                                            color: '#616161',
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        },
                                    }}
                                />
                                <FormHelperText>
                                    <ErrorMessage name={'groupName'}/>
                                </FormHelperText>
                            </FormControl>
                            <FormControl fullWidth name={'description'} required>
                                <TextareaAutosize
                                    required
                                    maxLength={1024}
                                    name={'description'}
                                    style={textareaStyle}
                                    placeholder={t('Group Description')+ ' *'}
                                    value={formik.values.description}
                                    onChange={(evt) => {
                                        formik.setFieldValue('description', evt.target.value)
                                    }}
                                />
                            </FormControl>
                            <IconButton
                                variant="contained"
                                component="label"
                            >
                                <img alt="clip" src={Images.clipPostComponent}/>
                                <input
                                    id={'file'}
                                    name={'file'}
                                    type="file"
                                    hidden
                                    accept="image/png, image/jpeg"
                                    onChange={async (e)=> await   handleFileChange(e)}/>
                              <Box marginLeft={'4px'} color={'#616161'} fontSize={'15px'} fontWeight={'400'}>{t('cover')}</Box>
                            </IconButton>
                            {formik.values.file && (<Box>{formik.values.file.name}</Box>)}
                            <Box
                                sx={{
                                    paddingBottom: '15px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '10px',
                                    '@media (max-width: 600px)': {
                                        justifyContent: 'center',
                                    },
                                    width: '100%'
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{
                                        marginRight: '20px',
                                        backgroundColor: '#27293d',
                                        borderRadius: '4px',
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#27293d',
                                        },
                                        width: '35%',
                                    }}
                                    onClick={formik.handleSubmit}
                                >
                                    {t('create')}
                                </Button>
                                <Button
                                    sx={{
                                        borderRadius: '4px',
                                        width: '35%',
                                    }}
                                    variant="outlined"
                                >
                                    {t('cancel')}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </FormikProvider>
        </FeedLayout>
    )
}
export default CreateGroup