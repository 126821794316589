// @flow
import React from 'react';
import useUserData from "../../../hooks/user/useUserData";
import * as yup from "yup";
import axios from "axios";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import {CloseRounded, SaveOutlined} from "@mui/icons-material";

type Props = {

};

const CreateEducation = (props: Props) => {
	const {accessCode} = useUserData();
	
	const validationSchema = yup.object({
		sxoli: yup
		.string()
		.required('Απαιτείται'),
		title: yup
		.string()
		.required('Απαιτείται'),
	})
	const onSubmit = (values) => {
		
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=16a&scode=" + accessCode,
			method: 'post',
			data:values,
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log('store',response)
			window.location.reload();
			// props.setAddEducation(false)
			
		}).catch((error) => {
		
		})
	}
	
	const formik = useFormik({
		initialValues: {
			sxoli: '',
			title: '',
			year: '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})
	
	return (
		<FormikProvider value={formik} onSubmit={formik.handleSubmit}
						noValidate>
			<Box component="form"
				 onSubmit={formik.handleSubmit}
				 noValidate>
				<Box sx={{display:'flex',justifyContent:'space-between'}}>
					<Box>
						<TextField size={'small'}
								   id={'sxoli'}
								   name={'sxoli'}
								   label={'Σχολή'}
								   onChange={formik.handleChange}
								   onBlur={formik.handleBlur}
								   sx={{
									   padding: '2%',
									   marginBottom: '1%',
									   '& .MuiOutlinedInput-root': {
										   // ':focus-within': { border: '0.0px solid #ffffff !important' }
										   fontSize: 'smaller',
									   },
								   }}
								   variant="outlined"
								   value={formik.values.sxoli}
						/>
						<TextField size={'small'}
								   id={'title'}
								   name={'title'}
								    label={'Τίτλος'}
								   onChange={formik.handleChange}
								   onBlur={formik.handleBlur}
								   sx={{
									   padding: '2%',
									   marginBottom: '1%',
									   '& .MuiOutlinedInput-root': {
										   // ':focus-within': { border: '0.0px solid #ffffff !important' }
										   fontSize: 'smaller',
									   },
								   }}
								   variant="outlined"
								   value={formik.values.title}
						/>
						<TextField size={'small'}
								   id={'year'}
								   name={'year'}
								    label={'Έτος'}
								   onChange={formik.handleChange}
								   onBlur={formik.handleBlur}
								   sx={{
									   padding: '2%',
									   marginBottom: '1%',
									   '& .MuiOutlinedInput-root': {
										   // ':focus-within': { border: '0.0px solid #ffffff !important' }
										   fontSize: 'smaller',
									   },
								   }}
								   variant="outlined"
								   value={formik.values.year}
						/>
					</Box>
					<Box sx={{display:'inline-flex'}}>
						<Box >
							<Button
								type={'submit'}
								sx={{
									fontSize: 'smaller'
								}}
							>
								<SaveOutlined />
							</Button>
						
						</Box>
						<Box>
							<Button>
								<CloseRounded onClick={()=>props.setAddEducation(false)}/>
							</Button>
						</Box>
					
					</Box>
				
				</Box>
			
			
			</Box>
		</FormikProvider>
	);
};

export default CreateEducation