// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import './rightTabs.css'
import { PAGE_COMPANY, PAGE_GROUP } from "../../../locales/pages/namespaces";
import {Typography} from "@mui/material";
import Images from "../../../assets";
import { useQuery } from "react-query";
import { getGroup } from "../../../api/requests";
import useUserData from "../../../hooks/user/useUserData";
import { useParams } from "react-router-dom";


type Props = {

};
const informationData =
	{
		id: 1,
		subtitle: 'Lorem Ipsum has been the industry\'s  standard dummy text ever since the  1500s.',
		thingsToDo: 'Attractions/Things to Do',
		usersMember:'56 χρήστες είναι μέλη',

	}
const InformationTab = (props: Props) => {
	const {t} = useTranslation(PAGE_GROUP)
	const {accessCode} = useUserData();
	const {groupCode} = useParams()
	const {data: groupData, isLoading} = useQuery(["getGroup",accessCode,groupCode], getGroup);
	if(isLoading){
		return null
	}
	return (
		<Box className="rightTab" sx={{backgroundColor: 'white', borderRadius: '5px', marginBottom: '5px'}}>
			<Typography>
				{t('information')}
			</Typography>
			<Box className="rightTabDetails">
				<Typography sx={{border:'1px #F3F3F3 solid', marginRight: '3%', }}/>
				<Typography sx={{fontFamily:'Open Sans-Regular',fontSize:'small'}}>
					{groupData['description']}
				</Typography>
				{/*<Typography sx={{fontSize:'smaller',paddingTop:'5px',color:'#616161', fontFamily: 'Open Sans', display:'inline-flex'}} >*/}
				{/*	<img src={Images.thingsToDo} style={{paddingRight: '2px'}}/>*/}
				{/*	{informationData['thingsToDo']}*/}
				{/*</Typography>*/}
				<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular',display:'inline-flex'}}>
					<img src={Images.usersMember} style={{paddingRight: '2px'}}/>
					{t('usersMember',{dynamicValue : groupData['members']})}
				</Typography>
			</Box>
		</Box>
	);
};

export default InformationTab