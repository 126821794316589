// @flow
import React from 'react';
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import FeedLeftColumn from "../../feedLeftColumn/feedLeftColumn";
import FeedRightColumn from "../../feedRightColumn/feedRightColumn";


type Props = {};

const FeedLayout = (props: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const menuMatches = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Grid container spacing={2} justifyContent="space-between">
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedLeftColumn/>
				</Grid>
			)}

			<Grid
				item
				xs={12}
				sm={12}
				md={4}
				lg={4}
			>
				{props.children}
			</Grid>
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedRightColumn/>
				</Grid>
			)}
		</Grid>
	);
};

export default FeedLayout