import * as ActionTypes from "../../actions/types";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_HOME, ROUTE_PAGE_LOGIN } from "../../routers/routes";
import { mapUser } from "../../types/user/map";
import { fromJS } from "immutable";

const INITIAL_STATE = {
  loading: false,
  isLoggedIn: false,
  token: null,
  accessCode: null,
  language: "el",
  message: null,
  registerSuccess: false,
  resetPasswordSuccess: false,
  forgotPasswordSuccess: false,
  data: {},
  personalData: {},
  userRecommendationData: [],
  userProfileImg: {},
  userCoverImg: {},
  postId: null,
  posts: [],
  fullPostData: {},
  credentials: {
    password: "",
    rememberMe: false,
    username: ""
  },
  modal:null
  // recommendationWithIdData: {},
  // recommendationId: null,
  // userId:null,
  // recommendationTitle: null,
  // recommendationDescription: null,
};

function userLogin(state) {
  return state
    .set("message", fromJS(null))
    .set("token", null)
    .set("registerSuccess", false)
    .set("accessCode", null)
    .set("accessCode", null)
    .set("loading", true)
    .set("isLoggedIn", false)
    .set("resetPasswordSuccess", false)
    .set("forgotPasswordSuccess", false);
}

function userLoginByGoogle(state) {
  return state
    .set("message", fromJS(null))
    .set("token", null)
    .set("registerSuccess", false)
    .set("accessCode", null)
    .set("accessCode", null)
    .set("loading", true)
    .set("isLoggedIn", false)
    .set("resetPasswordSuccess", false)
    .set("forgotPasswordSuccess", false);
}

function userLoginSucceeded(state, action) {
  const { found, scode } = action.payload;
  const message =
    found === "noconfirm"
      ? "no_confirm"
      : found === "no"
        ? "user_not_found"
        : null;
  return state
    .set("accessCode", scode)
    .set("loading", false)
    .set("isLoggedIn", found === "ok")
    .set("registerSuccess", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: found === "ok" ? "success" : "error",
      }),
    );
}

function userLoginFailed(state, action) {
  const { found } = action.payload;
  const message =
    found === "noconfirm"
      ? "no_confirm"
      : found === "no"
        ? "user_not_found"
        : null;
  return state
    .set(
      "message",
      fromJS({
        text: message,
        variant: "error",
      }),
    )
    .set("token", null)
    .set("accessCode", null)
    .set("loading", false)
    .set("registerSuccess", false)
    .set("isLoggedIn", false);
}

function userLogoutSucceeded(state, action) {
 let credentials = state.get('credentials')
  return fromJS(INITIAL_STATE)
    .set('credentials', credentials)
}

function userLogoutFailed(state, action) {
  let credentials = state.get('credentials')
  return fromJS(INITIAL_STATE)
    .set('credentials', credentials)
}

function setUserLanguage(state, action) {
  return state.set("language", action.payload);
}

function setUserMessage(state, action) {
  const message = action.payload;
  return state
    .set(
      "message",
      fromJS(
        message
          ? {
            text: message?.text,
            variant: message?.variant,
          }
          : null,
      ),
    )
    .set("registerSuccess", false)
    .set("resetPasswordSuccess", false);
}

function getUserDetails(state) {
  return state
    .set("loading", true)
    .set("message", fromJS(null))
    .set("registerSuccess", false)
    .set("resetPasswordSuccess", false);
}

function getUserDetailsSucceeded(state, action) {
  const user = action.payload;
  const mappedUser = mapUser(user);
  return state
    .set("loading", false)
    .set("data", fromJS(mappedUser))
    .set("message", fromJS(null))
    .set("registerSuccess", false);
}

function getUserDetailsFailed(state, action) {
  const message = action.payload;
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: "error",
      }),
    )
    .set("registerSuccess", false);
}

function registerUser(state, action) {
  return state
    .set("loading", true)
    .set("message", fromJS(null))
    .set("registerSuccess", false)
    .set("resetPasswordSuccess", false);
}

function registerUserSucceeded(state, action) {
  const { created } = action.payload;
  // const message = created === '1' ? 'user_register_success' : 'user_register_failed'
  const message =
    created === "1"
      ? "user_register_success"
      : created === "2"
        ? "username_or_email_exist"
        : "user_register_failed";
  const variant = created === "1" ? "success" : "error";
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: variant,
      }),
    )
    .set("registerSuccess", created === "1");
}

function registerUserFailed(state, action) {
  const { message } = action.payload;
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: "error",
      }),
    )
    .set("registerSuccess", false);
}

function resetUserPassword(state, action) {
  return state
    .set("loading", true)
    .set("message", fromJS(null))
    .set("resetPasswordSuccess", false);
}

function resetUserPasswordSucceeded(state, action) {
  const { found } = action.payload;
  const message =
    found === "true"
      ? "Λάβατε email με το PIN που θα χρειαστείτε για την διαδικασία"
      : "Συνέβη κάποιο σφάλμα";
  const variant = found === "true" ? "success" : "error";
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: variant,
      }),
    )
    .set("resetPasswordSuccess", found === "true");
}

function resetUserPasswordFailed(state, action) {
  const { message } = action.payload;
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: "error",
      }),
    )
    .set("resetPasswordSuccess", false);
}

function forgotUserPassword(state, action) {
  return state
    .set("loading", true)
    .set("message", fromJS(null))
    .set("forgotPasswordSuccess", false);
}

function forgotUserPasswordSucceeded(state, action) {
  const { change } = action.payload;
  const message =
    change === "true" ? "Επιτυχής ολοκλήρωση" : "Συνέβη κάποιο σφάλμα";
  const variant = change === "true" ? "success" : "error";
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: variant,
      }),
    )
    .set("forgotPasswordSuccess", change === "true");
}

function forgotUserPasswordFailed(state, action) {
  const { message } = action.payload;
  return state
    .set("loading", false)
    .set(
      "message",
      fromJS({
        text: message,
        variant: "error",
      }),
    )
    .set("forgotPasswordSuccess", false);
}

function submitPost(state, action) {
  return state.set("loading", true).set("message", fromJS(null));
}

function submitPostSucceeded(state, action) {
  const { saved } = action.payload;
  const { idpost } = action.payload;
  const message = saved === "true" ? "post_saved" : "post_not_saved";
  const variant = saved === "true" ? "success" : "error";
  return state
    .set("loading", false)
    .set("postId", idpost)
    .set(
      "message",
      fromJS({
        text: message,
        variant: variant,
      }),
    );
}

function submitPostFailed(state, action) {
  const { message } = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getPersonalData(state) {
  return state.set("loading", true).set("message", fromJS(null));
}

function getPersonalDataSucceeded(state, action) {
  const personalData = action.payload;
  const data = personalData.length > 0 ? personalData[0] : {};
  return state
    .set("loading", false)
    .set("personalData", fromJS(data))
    .set("message", fromJS(null));
}

function getPersonalDataFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function postPersonalData(state, action) {
  return state.set("loading", true).set("message", fromJS(null));
}

function postPersonalDataSucceeded(state, action) {
  const { saved } = action.payload;
  const { parameters } = action;
  const message = saved === "true" ? "data_saved" : "data_not_saved";
  const variant = saved === "true" ? "success" : "error";
  return (
    state
      .set("loading", false)
      // .setIn(['personalData','country'],parameters.country)
      .set("personalData", fromJS(parameters))
      .set(
        "message",
        fromJS({
          text: message,
          variant: variant,
        }),
      )
  );
}

function postPersonalDataFailed(state, action) {
  const { message } = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function postUserDetails(state, action) {
  return state.set("loading", true).set("message", fromJS(null));
}

function postUserDetailsSucceeded(state, action) {
  window.location.href = getRouteUrl(ROUTE_PAGE_HOME);
  const { change } = action.payload;
  const message = change === "true" ? "data_saved" : "data_not_saved";
  const variant = change === "true" ? "success" : "error";
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: variant,
    }),
  );
}

function postUserDetailsFailed(state, action) {
  const { message } = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function postUserRecommendation(state, action) {
  return state.set("loading", true).set("message", fromJS(null));
}

function postUserRecommendationSucceeded(state, action) {
  // window.location.href = getRouteUrl(ROUTE_PAGE_HOME)
  const { saved } = action.payload;
  const message = saved === "true" ? "data_saved" : "data_not_saved";
  const variant = saved === "true" ? "success" : "error";
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: variant,
    }),
  );
}

function postUserRecommendationFailed(state, action) {
  const { message } = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getUserRecommendationsData(state) {
  return state.set("loading", true).set("message", fromJS(null));
}

function getUserRecommendationsDataSucceeded(state, action) {
  const userRecommendationData = action.payload;
  const data = userRecommendationData.length > 0 ? userRecommendationData : [];
  return state
    .set("loading", false)
    .set("userRecommendationData", fromJS(data))
    .set("message", fromJS(null));
}

function getUserRecommendationsDataFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

// function recommendationWithId(state) {
// 	return state
// 	.set('loading', true)
// 	.set('message', fromJS(null))
// }
//
// function recommendationWithIdSucceeded(state, action) {
// 	const data = action.payload;
// 	return state
// 	.set('loading', false)
// 	.set('recommendationWithIdData', fromJS(data))
// 	.set('message', fromJS(null))
//
//
// }
//
// function recommendationWithIdFailed(state, action) {
// 	const {message} = action.payload
// 	return state
// 	.set('loading', false)
// 	.set('message', fromJS({
// 		text: message, variant: 'error'
// 	}))
// }
function postRecommendationEdit(state, action) {
  return state.set("loading", true).set("message", fromJS(null));
}

function postRecommendationEditSucceeded(state, action) {
  // window.location.href = getRouteUrl(ROUTE_PAGE_HOME)
  const { saved } = action.payload;
  const message = saved === "true" ? "data_saved" : "data_not_saved";
  const variant = saved === "true" ? "success" : "error";
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: variant,
    }),
  );
}

function postRecommendationEditFailed(state, action) {
  const { message } = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getUserProfileImg(state) {
  return state.set("loading", true).set("message", fromJS(null));
}

function getUserProfileImgSucceeded(state, action) {
  const userProfileImg = action.payload;

  // const data = userProfileImg.length > 0 ? userProfileImg[0] : {}
  const data = userProfileImg ? userProfileImg : {};
  return state
    .set("loading", false)
    .set("userProfileImg", fromJS(data))
    .set("message", fromJS(null));
}

function getUserProfileImgFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getUserCoverImg(state) {
  return state.set("loading", true).set("message", fromJS(null));
}

function getUserCoverImgSucceeded(state, action) {
  const userCoverImg = action.payload;

  // const data = userCoverImg.length > 0 ? userCoverImg[0] : {}
  const data = userCoverImg ? userCoverImg : {};
  return state
    .set("loading", false)
    .set("userCoverImg", fromJS(data))
    .set("message", fromJS(null));
}

function getUserCoverImgFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getAllPosts(state) {
  return state.set("loading", true).set("message", fromJS(null));
}

function getAllPostsSucceeded(state, action) {
  const postData = action.payload;
  const data = postData.length > 0 ? postData : [];
  return state
    .set("loading", false)
    .set("posts", fromJS(data))
    .set("message", fromJS(null));
}

function getAllPostsFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getFullPost(state) {
  return state.set("loading", true).set("message", fromJS(null));
}

function getFullPostSucceeded(state, action) {
  const fullPostData = action.payload;
  // const data = fullPostData.length > 0 ? fullPostData: []
  const data = fullPostData ? fullPostData : {};
  return state
    .set("loading", false)
    .set("fullPostData", fromJS(data))
    .set("message", fromJS(null));
}

function getFullPostFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false).set(
    "message",
    fromJS({
      text: message,
      variant: "error",
    }),
  );
}

function getPostComments(state) {
  return state.set("loading", true);
}

function getPostCommentsSucceeded(state, action) {
  const data = action.payload;
  let posts = state.get("posts").toJS();
  if (data[0] && data[0].id_post) {
    const obj = posts.find((item) => item.id === data[0].id_post);
    if (obj) {
      obj["comments"] = data;
    }
  }
  return state.set("loading", false).set("posts", fromJS(posts));
}

function getPostCommentsFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false);
}

function addPostComment(state) {
  return state.set("loading", true);
}

function addPostCommentSucceeded(state, action) {
  const data = action.payload;
  const parameters = action.parameters;
  let posts = state.get('posts').toJS()
  if(parameters && parameters.id_post) {
    const obj = posts.find(item => item.id === parameters.id_post);
    obj.commentsNumber = (parseInt(obj.commentsNumber) + 1).toString();
  }
  return state.set("loading", false).set("posts", fromJS(posts));
}

function addPostCommentFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false);
}

function deletePostComment(state) {
  return state.set("loading", true);
}

function deletePostCommentSucceeded(state, action) {
  const parameters = action.parameters;
  let posts = state.get("posts").toJS();
  if (parameters && parameters.id_post && parameters.id_comment) {
    const obj = posts.find((item) => item.id === parameters.id_post);
    if (obj) {
      obj["comments"] = obj["comments"].filter(
        (c) => c.id_comment !== parameters.id_comment,
      );
      obj.commentsNumber = (parseInt(obj.commentsNumber) - 1).toString();
    }
  }
  return state.set("loading", false).set("posts", fromJS(posts));
}

function deletePostCommentFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false);
}

function deletePost(state) {
  return state.set("loading", true);
}

function deletePostSucceeded(state, action) {
  const parameters = action.parameters;
  const deleted = action.payload.deleted;
  let posts = state.get("posts").toJS();

  if (parameters && parameters.id_post && deleted === "1") {
    posts = posts.filter((item) => item.id !== parameters.id_post);
  }
  return state.set("loading", false).set("posts", fromJS(posts));
}

function deletePostFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false);
}

function likePost(state) {
  return state.set("loading", true);
}

function likePostSucceeded(state, action) {
  const parameters = action.parameters;
  let posts = state.get("posts").toJS();
  let post = null;
  if (parameters && parameters.id_post) {
    post = posts.find((item) => item.id === parameters.id_post);
    if (action.payload.like === "deleted") {
      post.ihadLike = "0";
      post.likesNumber = (parseInt(post.likesNumber) - 1).toString();
    } else if (action.payload.like === "added") {
      post.ihadLike = "1";
      post.likesNumber = (parseInt(post.likesNumber) + 1).toString();
    }
  }
  return state.set("loading", false).set("posts", fromJS(posts));
}

function likePostFailed(state, action) {
  const message = action.payload;
  return state.set("loading", false);
}

function setCredentials(state, action) {
  const login = action.payload;
  return state
    .setIn(['credentials','username'],login.username)
    .setIn(['credentials','password'],login.password )
    .setIn(['credentials','rememberMe'],login.rememberMe);
}

function clearCredentials(state) {
  return state
    .setIn(['credentials','username'],'')
    .setIn(['credentials','password'],'' )
    .setIn(['credentials','rememberMe'],false);
}

function openModal(state,action) {
  return state
    .set('modal',fromJS(action.payload));
}

function closeModal(state,action) {
  return state
    .set('modal',null)
}

const currentUser = {
  initialState: INITIAL_STATE,
  handlers: {
    [ActionTypes.USER_LOGIN]: userLogin,
    [ActionTypes.LOGIN_GOOGLE]: userLoginByGoogle,
    [ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
    [ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,
    [ActionTypes.USER_LOGOUT_SUCCEEDED]: userLogoutSucceeded,
    [ActionTypes.USER_LOGOUT_FAILED]: userLogoutFailed,
    [ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
    [ActionTypes.SET_USER_MESSAGE]: setUserMessage,
    [ActionTypes.GET_USER_DETAILS]: getUserDetails,
    [ActionTypes.GET_USER_DETAILS_SUCCEEDED]: getUserDetailsSucceeded,
    [ActionTypes.GET_USER_DETAILS_FAILED]: getUserDetailsFailed,
    [ActionTypes.REGISTER_USER]: registerUser,
    [ActionTypes.REGISTER_USER_SUCCEEDED]: registerUserSucceeded,
    [ActionTypes.REGISTER_USER_FAILED]: registerUserFailed,
    [ActionTypes.RESET_USER_PASSWORD]: resetUserPassword,
    [ActionTypes.RESET_USER_PASSWORD_SUCCEEDED]: resetUserPasswordSucceeded,
    [ActionTypes.RESET_USER_PASSWORD_FAILED]: resetUserPasswordFailed,
    [ActionTypes.FORGOT_USER_PASSWORD]: forgotUserPassword,
    [ActionTypes.FORGOT_USER_PASSWORD_SUCCEEDED]: forgotUserPasswordSucceeded,
    [ActionTypes.FORGOT_USER_PASSWORD_FAILED]: forgotUserPasswordFailed,
    [ActionTypes.SUBMIT_POST]: submitPost,
    [ActionTypes.SUBMIT_POST_SUCCEEDED]: submitPostSucceeded,
    [ActionTypes.SUBMIT_POST_FAILED]: submitPostFailed,
    [ActionTypes.POST_PERSONAL_DATA]: postPersonalData,
    [ActionTypes.POST_PERSONAL_DATA_SUCCEEDED]: postPersonalDataSucceeded,
    [ActionTypes.POST_PERSONAL_DATA_FAILED]: postPersonalDataFailed,
    [ActionTypes.POST_USER_DETAILS]: postUserDetails,
    [ActionTypes.POST_USER_DETAILS_SUCCEEDED]: postUserDetailsSucceeded,
    [ActionTypes.POST_USER_DETAILS_FAILED]: postUserDetailsFailed,
    [ActionTypes.POST_USER_RECOMMENDATION]: postUserRecommendation,
    [ActionTypes.POST_USER_RECOMMENDATION_SUCCEEDED]:
    postUserRecommendationSucceeded,
    [ActionTypes.POST_USER_RECOMMENDATION_FAILED]: postUserRecommendationFailed,
    [ActionTypes.GET_PERSONAL_DATA]: getPersonalData,
    [ActionTypes.GET_PERSONAL_DATA_SUCCEEDED]: getPersonalDataSucceeded,
    [ActionTypes.GET_PERSONAL_DATA_FAILED]: getPersonalDataFailed,
    [ActionTypes.GET_USER_RECOMMENDATIONS]: getUserRecommendationsData,
    [ActionTypes.GET_USER_RECOMMENDATIONS_SUCCEEDED]:
    getUserRecommendationsDataSucceeded,
    [ActionTypes.GET_USER_RECOMMENDATIONS_FAILED]:
    getUserRecommendationsDataFailed,
    // [ActionTypes.RECOMMENDATION_WITH_ID]: recommendationWithId,
    // [ActionTypes.RECOMMENDATION_WITH_ID_SUCCEEDED]: recommendationWithIdSucceeded,
    // [ActionTypes.RECOMMENDATION_WITH_ID_FAILED]: recommendationWithIdFailed,
    [ActionTypes.POST_RECOMMENDATION_EDIT]: postRecommendationEdit,
    [ActionTypes.POST_RECOMMENDATION_EDIT_SUCCEEDED]:
    postRecommendationEditSucceeded,
    [ActionTypes.POST_RECOMMENDATION_EDIT_FAILED]: postRecommendationEditFailed,
    [ActionTypes.GET_USER_PROFILE_IMG]: getUserProfileImg,
    [ActionTypes.GET_USER_PROFILE_IMG_SUCCEEDED]: getUserProfileImgSucceeded,
    [ActionTypes.GET_USER_PROFILE_IMG_FAILED]: getUserProfileImgFailed,
    [ActionTypes.GET_USER_COVER_IMG]: getUserCoverImg,
    [ActionTypes.GET_USER_COVER_IMG_SUCCEEDED]: getUserCoverImgSucceeded,
    [ActionTypes.GET_USER_COVER_IMG_FAILED]: getUserCoverImgFailed,
    [ActionTypes.GET_ALL_POSTS]: getAllPosts,
    [ActionTypes.GET_ALL_POSTS_SUCCEEDED]: getAllPostsSucceeded,
    [ActionTypes.GET_ALL_POSTS_FAILED]: getAllPostsFailed,
    [ActionTypes.GET_FULL_POST]: getFullPost,
    [ActionTypes.GET_FULL_POST_SUCCEEDED]: getFullPostSucceeded,
    [ActionTypes.GET_FULL_POST_FAILED]: getFullPostFailed,
    [ActionTypes.GET_POST_COMMENTS]: getPostComments,
    [ActionTypes.GET_POST_COMMENTS_SUCCEEDED]: getPostCommentsSucceeded,
    [ActionTypes.GET_POST_COMMENTS_FAILED]: getPostCommentsFailed,
    [ActionTypes.ADD_POST_COMMENT]: addPostComment,
    [ActionTypes.ADD_POST_COMMENT_SUCCEEDED]: addPostCommentSucceeded,
    [ActionTypes.ADD_POST_COMMENT_FAILED]: addPostCommentFailed,
    [ActionTypes.DELETE_POST_COMMENT]: deletePostComment,
    [ActionTypes.DELETE_POST_COMMENT_SUCCEEDED]: deletePostCommentSucceeded,
    [ActionTypes.DELETE_POST_COMMENT_FAILED]: deletePostCommentFailed,
    [ActionTypes.DELETE_POST]: deletePost,
    [ActionTypes.DELETE_POST_SUCCEEDED]: deletePostSucceeded,
    [ActionTypes.DELETE_POST_FAILED]: deletePostFailed,
    [ActionTypes.GET_ALL_POSTS_SUCCEEDED]: getAllPostsSucceeded,
    [ActionTypes.LIKE_POST]: likePost,
    [ActionTypes.LIKE_POST_SUCCEEDED]: likePostSucceeded,
    [ActionTypes.LIKE_POST_FAILED]: likePostFailed,
    [ActionTypes.SET_CREDENTIALS]: setCredentials,
    [ActionTypes.CLEAR_CREDENTIALS]: clearCredentials,
    [ActionTypes.OPEN_MODAL]: openModal,
    [ActionTypes.CLOSE_MODAL]: closeModal,
  },
};

export default currentUser;
