import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";


const OstriaModal = ({ onClose, open, children , boxStyle,id}) => {
  return (
    <Modal
      id={id}
      open={open}
      onClose={onClose}
    >
      <Box margin={'70px auto'}  maxHeight={'80vh'} backgroundColor={'white'} width={'50%'} position={'relative'}
            style={boxStyle} minWidth={'35vh'}>
        <IconButton
          onClick={onClose}
          style={{backgroundColor:"black",float:"right", width:30,height:30, position:'absolute',right:0}}

        >
          <CloseIcon style={{width:18,height:18,color:'white'}}/>
        </IconButton>
        <Box padding={3}>
        {children}
        </Box>
      </Box>
    </Modal>

  );
};

export default OstriaModal;