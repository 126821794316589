// @flow
import React, {useEffect, useState} from 'react';
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import SearchResultsData from "./searchResultsData";
import FeedLeftColumn from "../../components/feedLeftColumn/feedLeftColumn";
import FeedRightColumn from "../../components/feedRightColumn/feedRightColumn";
import axios from "axios";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../../components/contentLoader/contentLoader";

type Props = {};

const SearchResults = (props: Props) =>
{
	const searchValue = props.match.params.search;
	const type = props.match.params.type;


	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const {accessCode} = useUserData();
	const [searchData,setSearchData] = useState([]);
	const [loading,setLoading] = useState(false);


	useEffect(() => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=20a1&scode=" + accessCode,
			method: 'post',
			data:{'search': searchValue},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log(response)
			setSearchData(response.data)
			setLoading(true)
		}).catch((error) => {
		
		})
	}, [accessCode,searchValue]);
	return (
		<Grid container spacing={2} justifyContent="space-between">
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedLeftColumn/>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				sm={12}
				md={4}
				lg={4}
			>
				{loading ?
					<SearchResultsData data={searchData}/>
					:
					<ContentLoader loading={loading}/>
				}
				
			</Grid>
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedRightColumn/>
				</Grid>
			)}
		</Grid>
	);
};

export default SearchResults