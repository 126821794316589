import {isImmutable} from "immutable";

export const parseMultipleFromReference = (data, collection, parseFunction: Function, datumIdentifier?: string) => {
    if (!(data && data.size && isImmutable(collection))) return []
    return data
        .valueSeq()
        .toArray()
        .filter((datum) => collection.has(datumIdentifier ? datum.get(datumIdentifier) : datum))
        .map((datum) => parseFunction(
            collection.get(
                datumIdentifier ? datum.get(datumIdentifier) : datum
            ),
            datum
        ))
}

export const parseMultiple = (data, parseFunction: Function) => {
    if (!data || !data.size) return []
    return data
        .valueSeq()
        .toArray()
        .map(parseFunction)
}

export const withLanguage = (language) => (item) => {
    if (!item || typeof item === "string") {
        return item
    }
    return language === 'any' ? ({
        en: item.get('en'),
        el: item.get('el')
    }) : item.get(language);
}