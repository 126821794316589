import FeedLayout from "../../layouts/feedLayout/feedLayout";
import * as yup from "yup";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Paper,
	TextField,
	Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {PAGE_POLL} from "../../../locales/pages/namespaces";

const Poll = ({onClose}) => {
	
	const {t} = useTranslation(PAGE_POLL)
	const onSubmit = (values) => {
		console.log("hiii")
	}
	const validationSchema = yup.object({
		question: yup
		.string().max(64, 'Μέγιστος αριθμός χαρακτήρων 64')
		.required(t('required')),
		answer1: yup
		.string()
		.required(t('required')),
		answer2: yup
		.string()
		.required(t('required')),
	})
	const formik = useFormik({
		initialValues: {
			question: '',
			answer1: '',
			answer2: '',
			isPublicVote: false,
			isMultiple: false,
			hasEndTime: false
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit
	})
	return (
		
		<FormikProvider value={formik} onSubmit={formik.handleSubmit} noValidate>
			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
				sx={{
					bgcolor: "background.paper",
					boxShadow: 24,
					height: "auto",
					padding:'16px',
					width: { xs: "95%", sm: "80%", md: "70%", lg: "60%" },
					maxWidth: "960px",
					margin: "auto",
					marginTop:"-10%",
					display:'flex',
					
					overflowY: "auto",
					maxHeight: {xs:'600px',sm:'600px',md:'100%',lg:'100%'},
					// display: "flex",
					flexGrow: 1,
					flexDirection: "column"

				}}
				onSubmit={formik.handleSubmit}
			>
				<Paper elevation={0}>
					<Box sx={{padding: '8px'}}>
						<Grid container sx={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '10px',
							paddingBottom: '10px'
						}}>
							<Grid item sx={{paddingLeft: '10px'}}>
								<Typography>
									{t('create poll')}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									variant="outlined"
									size={'small'}
									onClick={onClose}
									sx={{
										marginRight: '5px',
										borderColor: '#27293d',
										border: '1px solid',
										boxSizing: 'border-box',
										borderRadius: '4px',
										fontFamily: '"Open Sans-Bold", Helvetica',
										fontSize: '11px',
										fontWeight: 700
									}}
								>{t('cancel')}</Button>
								<Button
									type="submit"
									size={'small'}
									variant="outlined"
									sx={{
										marginRight: '8px',
										backgroundColor: '#27293d',
										borderRadius: '4px',
										border: '1px solid',
										boxSizing: 'border-box',
										fontFamily: '"Open Sans-Bold", Helvetica',
										fontSize: '11px',
										fontWeight: 700,
										color: '#ffffff',
										'&:hover': {
											backgroundColor: '#27293d',
										},
									}}
								>
									{t('create')}
								</Button>
							</Grid>
						</Grid>
						<Box sx={{padding: '5px'}}>
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2
								}}
								error={
									formik.touched['question'] &&
									Boolean(formik.errors['question'])
								}
							>
								
								<TextField
									id={'question'}
									value={formik.values.question}
									name={'question'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									label={t('question')}
									size={'small'}
									required
									inputProps={{maxLength: 250}}
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											':focus-within': {border: '0.0px solid #616161 !important'}
										},
										'& .MuiFormLabel-root': {
											color: '#616161',
											fontSize: '15px',
											fontWeight: 400
										}
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'question'}/>
								</FormHelperText>
							</FormControl>
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2,
								}}
								error={
									formik.touched['answer1'] &&
									Boolean(formik.errors['answer1'])
								}
							>
								<TextField
									id={'answer1'}
									value={formik.values.answer1}
									name={'answer1'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									label={t('answer1')}
									size={'small'}
									required
									inputProps={{maxLength: 250}}
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											':focus-within': {
												border: '0.0px solid #616161 !important',
											},
										},
										'& .MuiFormLabel-root': {
											color: '#616161',
											fontSize: '15px',
											fontWeight: 400,
										},
										width: '100%',
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'answer1'}/>
								</FormHelperText>
							</FormControl>
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2,
								}}
								error={
									formik.touched['answer2'] &&
									Boolean(formik.errors['answer2'])
								}
							>
								<TextField
									id={'answer2'}
									value={formik.values.answer2}
									name={'answer2'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									label={t('answer2')}
									size={'small'}
									required
									inputProps={{maxLength: 250}}
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											':focus-within': {
												border: '0.0px solid #616161 !important',
											},
										},
										'& .MuiFormLabel-root': {
											color: '#616161',
											fontSize: '15px',
											fontWeight: 400,
										},
										width: '100%',
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'answer2'}/>
								</FormHelperText>
							</FormControl>
							
							
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2,
								}}
								error={
									formik.touched['answer3'] &&
									Boolean(formik.errors['answer3'])
								}
							>
								<TextField
									id={'answer3'}
									value={formik.values.answer3}
									name={'answer3'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									label={t('answer3')}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											':focus-within': {
												border: '0.0px solid #616161 !important',
											},
										},
										'& .MuiFormLabel-root': {
											color: '#616161',
											fontSize: '15px',
											fontWeight: 400,
										},
										width: '100%',
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'answer3'}/>
								</FormHelperText>
							</FormControl>
							
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2,
								}}
								error={
									formik.touched['answer4'] &&
									Boolean(formik.errors['answer4'])
								}
							>
								<TextField
									id={'answer4'}
									value={formik.values.answer4}
									name={'answer4'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									label={t('answer4')}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											':focus-within': {
												border: '0.0px solid #616161 !important',
											},
										},
										'& .MuiFormLabel-root': {
											color: '#616161',
											fontSize: '15px',
											fontWeight: 400,
										},
										width: '100%',
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'answer4'}/>
								</FormHelperText>
							</FormControl>
							
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2,
								}}
								error={
									formik.touched['answer1'] &&
									Boolean(formik.errors['answer1'])
								}
							>
								<TextField
									id={'answer5'}
									value={formik.values.answer5}
									name={'answer5'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									label={t('answer5')}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="outlined"
									sx={{
										'& .MuiOutlinedInput-root': {
											':focus-within': {
												border: '0.0px solid #616161 !important',
											},
										},
										'& .MuiFormLabel-root': {
											color: '#616161',
											fontSize: '15px',
											fontWeight: 400,
										},
										width: '100%',
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'answer5'}/>
								</FormHelperText>
							</FormControl>
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2
								}}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values.isPublicVote}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											name={'isPublicVote'}
										/>
									}
									label={<Typography sx={{fontFamily: '"Open Sans-Light", Helvetica'}}> {t('Public Voting')}</Typography>}
									labelPlacement="end"
								/>
							</FormControl>
							<Typography sx={{
								marginBottom: '2px',
								color: '#61616180',
								fontFamily: '"Open Sans-Regular", Helvetica',
								fontSize: '13px',
								fontWeight: 400
							}}>{t('Display all users who voted and the answer they chose.')}</Typography>
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2
								}}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values.isMultiple}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											name={'isMultiple'}
										/>
									}
									label={<Typography sx={{fontFamily: '"Open Sans-Light", Helvetica'}}>{t('Allow Multiple Choices')}</Typography>}
									labelPlacement="end"
								/>
							</FormControl>
							<Typography sx={{
								marginBottom: '2px',
								color: '#61616180',
								fontFamily: '"Open Sans-Regular", Helvetica',
								fontSize: '13px',
								fontWeight: 400
							}}>{t('Allow users to make multiple selections at once.')}</Typography>
							
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2
								}}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values.hasEndTime}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											name={'hasEndTime'}
										/>
									}
									label={<Typography sx={{fontFamily: '"Open Sans-Light", Helvetica'}}>{t('Set Expiration Time')}</Typography>}
									labelPlacement="end"
								/>
							</FormControl>
							<Typography sx={{
								marginBottom: '2px',
								color: '#61616180',
								fontFamily: '"Open Sans-Regular", Helvetica',
								fontSize: '13px',
								fontWeight: 400
							}}>{t('The poll will be closed after this date and no one will be able to vote.')}</Typography>
						</Box>
					</Box>
				</Paper>
			</Box>
		</FormikProvider>
	
	)
}
export default Poll