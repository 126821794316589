import { actionCreator } from "../../helpers/actionCreator";
import * as ActionTypes from "./types";
import { CLOSE_MODAL, OPEN_MODAL } from "./types";

export const userLogin = actionCreator(ActionTypes.USER_LOGIN);
export const userLoginByGoogle = actionCreator(ActionTypes.LOGIN_GOOGLE);
export const userLoginSucceeded = actionCreator(
  ActionTypes.USER_LOGIN_SUCCEEDED
);
export const userLoginFailed = actionCreator(ActionTypes.USER_LOGIN_FAILED);

export const userLogout = actionCreator(ActionTypes.USER_LOGOUT);
export const userLogoutSucceeded = actionCreator(
  ActionTypes.USER_LOGOUT_SUCCEEDED
);
export const userLogoutFailed = actionCreator(ActionTypes.USER_LOGOUT_FAILED);

export const setUserLanguage = actionCreator(ActionTypes.SET_USER_LANGUAGE);
export const setUserMessage = actionCreator(ActionTypes.SET_USER_MESSAGE);

export const getUserDetails = actionCreator(ActionTypes.GET_USER_DETAILS);
export const getUserDetailsSucceeded = actionCreator(
  ActionTypes.GET_USER_DETAILS_SUCCEEDED
);
export const getUserDetailsFailed = actionCreator(
  ActionTypes.GET_USER_DETAILS_FAILED
);

export const registerUser = actionCreator(ActionTypes.REGISTER_USER);
export const registerUserSucceeded = actionCreator(
  ActionTypes.REGISTER_USER_SUCCEEDED
);
export const registerUserFailed = actionCreator(
  ActionTypes.REGISTER_USER_FAILED
);

export const resetUserPassword = actionCreator(ActionTypes.RESET_USER_PASSWORD);
export const resetUserPasswordSucceeded = actionCreator(
  ActionTypes.RESET_USER_PASSWORD_SUCCEEDED
);
export const resetUserPasswordFailed = actionCreator(
  ActionTypes.RESET_USER_PASSWORD_FAILED
);

export const forgotUserPassword = actionCreator(
  ActionTypes.FORGOT_USER_PASSWORD
);
export const forgotUserPasswordSucceeded = actionCreator(
  ActionTypes.FORGOT_USER_PASSWORD_SUCCEEDED
);
export const forgotUserPasswordFailed = actionCreator(
  ActionTypes.FORGOT_USER_PASSWORD_FAILED
);

export const submitPost = actionCreator(ActionTypes.SUBMIT_POST);
export const submitPostSucceeded = actionCreator(
  ActionTypes.SUBMIT_POST_SUCCEEDED
);
export const submitPostFailed = actionCreator(ActionTypes.SUBMIT_POST_FAILED);

export const getPersonalData = actionCreator(ActionTypes.GET_PERSONAL_DATA);
export const getPersonalDataSucceeded = actionCreator(
  ActionTypes.GET_PERSONAL_DATA_SUCCEEDED
);
export const getPersonalDataFailed = actionCreator(
  ActionTypes.GET_PERSONAL_DATA_FAILED
);

export const postPersonalData = actionCreator(ActionTypes.POST_PERSONAL_DATA);
export const postPersonalDataSucceeded = actionCreator(
  ActionTypes.POST_PERSONAL_DATA_SUCCEEDED
);
export const postPersonalDataFailed = actionCreator(
  ActionTypes.POST_PERSONAL_DATA_FAILED
);

export const postUserDetails = actionCreator(ActionTypes.POST_USER_DETAILS);
export const postUserDetailsSucceeded = actionCreator(
  ActionTypes.POST_USER_DETAILS_SUCCEEDED
);
export const postUserDetailsFailed = actionCreator(
  ActionTypes.POST_USER_DETAILS_FAILED
);

export const postUserRecommendation = actionCreator(
  ActionTypes.POST_USER_RECOMMENDATION
);
export const postUserRecommendationSucceeded = actionCreator(
  ActionTypes.POST_USER_RECOMMENDATION_SUCCEEDED
);
export const postUserRecommendationFailed = actionCreator(
  ActionTypes.POST_USER_RECOMMENDATION_FAILED
);

export const getUserReccomendations = actionCreator(
  ActionTypes.GET_USER_RECOMMENDATIONS
);
export const getUserReccomendationsSucceeded = actionCreator(
  ActionTypes.GET_USER_RECOMMENDATIONS_SUCCEEDED
);
export const getUserReccomendationsFailed = actionCreator(
  ActionTypes.GET_USER_RECOMMENDATIONS_FAILED
);

// export const recommendationWithId = actionCreator(ActionTypes.RECOMMENDATION_WITH_ID)
// export const recommendationWithIdSucceeded = actionCreator(ActionTypes.RECOMMENDATION_WITH_ID_SUCCEEDED)
// export const recommendationWithIdFailed = actionCreator(ActionTypes.RECOMMENDATION_WITH_ID_FAILED)

export const postRecommendationEdit = actionCreator(
  ActionTypes.POST_RECOMMENDATION_EDIT
);
export const postRecommendationEditSucceeded = actionCreator(
  ActionTypes.POST_RECOMMENDATION_EDIT_SUCCEEDED
);
export const postRecommendationEditFailed = actionCreator(
  ActionTypes.POST_RECOMMENDATION_EDIT_FAILED
);

export const getUserProfileImg = actionCreator(
  ActionTypes.GET_USER_PROFILE_IMG
);
export const getUserProfileImgSucceeded = actionCreator(
  ActionTypes.GET_USER_PROFILE_IMG_SUCCEEDED
);
export const getUserProfileImgFailed = actionCreator(
  ActionTypes.GET_USER_PROFILE_IMG_FAILED
);

export const getUserCoverImg = actionCreator(ActionTypes.GET_USER_COVER_IMG);
export const getUserCoverImgSucceeded = actionCreator(
  ActionTypes.GET_USER_COVER_IMG_SUCCEEDED
);
export const getUserCoverImgFailed = actionCreator(
  ActionTypes.GET_USER_COVER_IMG_FAILED
);

export const getAllPosts = actionCreator(ActionTypes.GET_ALL_POSTS);
export const getAllPostsSucceeded = actionCreator(
  ActionTypes.GET_ALL_POSTS_SUCCEEDED
);
export const getAllPostsFailed = actionCreator(
  ActionTypes.GET_ALL_POSTS_FAILED
);

export const getFullPost = actionCreator(ActionTypes.GET_FULL_POST);
export const getFullPostSucceeded = actionCreator(
  ActionTypes.GET_FULL_POST_SUCCEEDED
);
export const getFullPostFailed = actionCreator(
  ActionTypes.GET_FULL_POST_FAILED
);

export const getPostComments = actionCreator(ActionTypes.GET_POST_COMMENTS);
export const getPostCommentsSucceeded = actionCreator(
  ActionTypes.GET_POST_COMMENTS_SUCCEEDED
);
export const getPostCommentsFailed = actionCreator(
  ActionTypes.GET_POST_COMMENTS_FAILED
);

export const addPostComment = actionCreator(ActionTypes.ADD_POST_COMMENT);
export const addPostCommentSucceeded = actionCreator(
  ActionTypes.ADD_POST_COMMENT_SUCCEEDED
);
export const addPostCommentFailed = actionCreator(
  ActionTypes.ADD_POST_COMMENT_FAILED
);

export const deletePostComment = actionCreator(ActionTypes.DELETE_POST_COMMENT);
export const deletePostCommentSucceeded = actionCreator(
  ActionTypes.DELETE_POST_COMMENT_SUCCEEDED
);
export const deletePostCommentFailed = actionCreator(
  ActionTypes.DELETE_POST_COMMENT_FAILED
);

export const deletePost = actionCreator(ActionTypes.DELETE_POST);
export const deletePostSucceeded = actionCreator(
  ActionTypes.DELETE_POST_SUCCEEDED
);
export const deletePostFailed = actionCreator(ActionTypes.DELETE_POST_FAILED);

export const likePost = actionCreator(ActionTypes.LIKE_POST);
export const likePostSucceeded = actionCreator(
  ActionTypes.LIKE_POST_SUCCEEDED
);
export const likePostFailed = actionCreator(ActionTypes.LIKE_POST_FAILED);

export const setCredentials = actionCreator(ActionTypes.SET_CREDENTIALS);

export const clearCredentials = actionCreator(ActionTypes.CLEAR_CREDENTIALS);

export const openModal = actionCreator(ActionTypes.OPEN_MODAL);

export const closeModal = actionCreator(ActionTypes.CLOSE_MODAL);
