// @flow
import React from 'react';
import {Box, Typography} from "@mui/material";
import Images from "../../assets";
import './ads.css'
import { useQuery } from "react-query";
import { getMoodleLessons, getPersonalCv } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../contentLoader/contentLoader";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_MOODLE } from "../../routers/routes";
import { useHistory } from "react-router";

type Props = {};

const data = [
	{
		id: 1,
		title: '',
		image: Images.pmp,
		alt: 'pmp_jpeg'
	},
	{
		id: 2,
		title: '',
		image: Images.pmp2,
		alt: 'pmp2_jpeg'
	},
	{
		id: 3,
		title: '',
		image: Images.ecdl,
		alt: 'ecdl_jpeg'
	},
	{
		id: 4,
		title: '',
		image: Images.market,
		alt: 'market_jpeg'
	}
]

const Ads = (props: Props) => {
	const {accessCode} = useUserData()
	const history = useHistory();
	const { data:ads, isLoading } = useQuery(
		["getMoodleLessons", accessCode ],
		getMoodleLessons,
	);

	return (
		<Box
			sx={{
				backgroundColor: "#ffffff",
				borderRadius: 1,
				padding: 2,
				marginBottom: 2,
				marginTop: 2,
			}}
		>
			<Typography
				variant="h5"
				gutterBottom
				sx={{
					fontSize: {xs: 16, sm: 18},
					color: "#020700",
					fontFamily: '"Open Sans-Regular", Helvetica',
					fontWeight: 400,
					lineHeight: "24px",
					letterSpacing: 0,
				}}
			>
				Μαθήματα που ταιριάζουν στο βιογραφικό σας
			</Typography>
			<Box className={'ads'}>
				{isLoading ?
					<ContentLoader loading={isLoading}></ContentLoader>
					:
					ads?.map(ad => (
					<Box className={'ad'} onClick={() => history.push(getRouteUrl(ROUTE_PAGE_MOODLE) +  `${ad.id ? '?courseId=' + ad.id : '' }`)}>
						<img alt={ad.alt} src={ad.image} />
						{ad.coursename}
					</Box>
				))}
			</Box>
			<Box
				sx={{
					height: "26px",
					left: "18px",
					top: "13px",
					width: "100%",
					maxWidth: "506px",
					"@media (min-width: 150px)": {
						maxWidth: "506px",
					},
				}}
			>
				{/*<Typography*/}
				{/*	gutterBottom*/}
				{/*	variant="h5"*/}
				{/*	sx={{*/}
				{/*		color: "#020700",*/}
				{/*		fontFamily: '"Open Sans-Regular", Helvetica',*/}
				{/*		fontSize: {xs: 14, sm: 16},*/}
				{/*		fontWeight: 400,*/}
				{/*		height: "24px",*/}
				{/*		left: 0,*/}
				{/*		letterSpacing: 0,*/}
				{/*		lineHeight: "24px",*/}
				{/*		textAlign: "right",*/}
				{/*		top: "2px",*/}
				{/*		whiteSpace: "nowrap",*/}
				{/*		width: "100%",*/}
				{/*	}}*/}
				{/*>*/}
				{/*	Δείτε περισσότερα..*/}
				{/*</Typography>*/}
			</Box>
		</Box>
	);
};

export default Ads