import Box from "@mui/material/Box";
import { useQuery } from "react-query";
import { getAdsClaimsForCompany } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import { useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import { PictureAsPdf } from "@mui/icons-material";
import * as React from "react";
import dayjs from "dayjs";

const AdsClaims = () => {
  const { accessCode } = useUserData();
  const { id } = useParams();
  const { data: adsClaims, isLoading } = useQuery(["getAdsClaimsForCompany", accessCode, id], getAdsClaimsForCompany);

  if (isLoading) {
    return null;
  }
  return (
    <Box margin={"10px 3px 5px 3px"}  >
      {adsClaims?.map(x =>
        <Box margin={"5px 3px 5px 3px"} border={"1px solid #27293D"} borderRadius={"9px"} display="flex" flexDirection={"column"} key={x.id}>
          <Box margin={"5px 3px 5px 3px"}>
            {`Τίτλος αγγελίας: ${x?.ad?.title}`}
          </Box>
          <Box margin={"5px 3px 5px 3px"}>
            {`Ονομα ενδιαφερόμενου: ${x.user.firstname} ${x.user.lastname}`}
          </Box>
          <Box margin={"5px 3px 5px 3px"}>
            {`Κείμενο: ${x.textsyn}`}
          </Box>
          <Box margin={"5px 3px 5px 3px"}>
            {`Ημερομηνία άιτησης: ${dayjs(x.date_app).format('YYYY-MM-DD')}`}
          </Box>
          <Box margin={"5px 3px 5px 3px"}>
            <Link href={x.cv} target="_blank"
                  underline="hover" sx={{
              fontSize: "large",
              color: "#616161",
              fontFamily: "Open Sans-Regular",
              border: "1px solid grey",
              borderRadius: "25px",
              padding: "5px 5px 5px 10px",
              // margin: "10px",
            }}>
              <PictureAsPdf />
              {x.cv.split('/').pop()}
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AdsClaims;