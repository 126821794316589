// @flow
import React from 'react';
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import FeedLeftColumn from "../../../components/feedLeftColumn/feedLeftColumn";
import FeedRightColumn from "../../../components/feedRightColumn/feedRightColumn";
import GroupProfileHeader from "../../../components/groupProfileHeader/groupProfileHeader"
import GroupProfileTabs from "../../../components/groupProfileTabs/groupProfileTabs";

type Props = {};

const Group = (props: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Grid container spacing={2} justifyContent="space-between">
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedLeftColumn/>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				sm={12}
				md={4}
				lg={6}
			>
				<GroupProfileHeader/>
				<GroupProfileTabs/>
			</Grid>
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedRightColumn/>
				</Grid>
			)}
		</Grid>
	);
};

export default Group