// @flow
import React from 'react';
import {Typography} from "@mui/material";

type Props = {

};

const PrivacyCookies = (props: Props) => {
	return (
		<div>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					color:' #616161',
					fontFamily: '"Open Sans-Regular", Helvetica',
					fontSize: '13px',
					fontWeight: 400,
					paddingRight:'25px'
					
				}}
			>
				
				Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες, όπως pixel, τοπική αποθήκευση και
				αναγνωριστικά διαφημίσεων για κινητά (συλλογικά αναφέρονται στην παρούσα πολιτική ως
				"cookies") για τη συλλογή και χρήση δεδομένων στο πλαίσιο των Υπηρεσιών μας, όπως ορίζεται
				στην Πολιτική απορρήτου μας ("Υπηρεσίες") και η οποία περιλαμβάνει τους ιστότοπους, τις
				επικοινωνίες, τις εφαρμογές για κινητά και τις Υπηρεσίες εκτός ιστότοπου, όπως οι
				διαφημιστικές υπηρεσίες μας. Στο πνεύμα της διαφάνειας, αυτή η πολιτική παρέχει λεπτομερείς
				πληροφορίες σχετικά με το πώς και πότε χρησιμοποιούμε αυτές τις τεχνολογίες. Αυτή η
				Πολιτική Cookie ισχύει για οποιοδήποτε προϊόν ή υπηρεσία Ostrialinked που συνδέεται με
				αυτήν την πολιτική ή την ενσωματώνει μέσω παραπομπής.<br></br>
				Συνεχίζοντας να επισκέπτεστε ή να χρησιμοποιείτε τις Υπηρεσίες μας, συμφωνείτε με τη χρήση
				cookies και παρόμοιων τεχνολογιών για τους σκοπούς που περιγράφονται στην παρούσα
				πολιτική.<br></br>
				Ποιες τεχνολογίες χρησιμοποιούνται;<br></br>
				Τύπος τεχνολογίας Περιγραφή<br></br>
				<b>Cookie</b><br></br>
				Ένα cookie είναι ένα μικρό αρχείο που τοποθετείται στη συσκευή σας και
				επιτρέπει τις δυνατότητες και τη λειτουργικότητα του Ostrialinked. Κάθε
				πρόγραμμα περιήγησης που επισκέπτεται τους ιστότοπούς μας ενδέχεται να
				λαμβάνει cookies από εμάς ή cookies από τρίτους, όπως πελάτες, συνεργάτες
				ή παρόχους υπηρεσιών μας. Εμείς ή τρίτα μέρη ενδέχεται επίσης να
				τοποθετήσουμε cookies στο πρόγραμμα περιήγησής σας όταν επισκέπτεστε
				ιστότοπους εκτός Ostrialinked που προβάλλουν διαφημίσεις ή φιλοξενούν τα
				πρόσθετα ή τις ετικέτες μας.<br></br>
				Χρησιμοποιούμε δύο τύπους cookies: μόνιμα cookies και cookies περιόδου
				λειτουργίας. Ένα μόνιμο cookie διαρκεί πέρα από την τρέχουσα περίοδο
				λειτουργίας και χρησιμοποιείται για πολλούς σκοπούς, όπως η αναγνώρισή
				σας ως υπάρχοντος χρήστη, επομένως είναι ευκολότερο να επιστρέψετε στο
				Ostrialinked και να αλληλεπιδράσετε με τις Υπηρεσίες μας χωρίς να
				συνδεθείτε ξανά. Δεδομένου ότι ένα μόνιμο cookie παραμένει στο πρόγραμμα
				περιήγησής σας, θα διαβαστεί από το Ostrialinked όταν επιστρέψετε σε έναν
				από τους ιστότοπούς μας ή επισκεφθείτε έναν ιστότοπο τρίτου μέρους που
				χρησιμοποιεί τις Υπηρεσίες μας. Τα cookies περιόδου λειτουργίας διαρκούν
				μόνο όσο είναι η περίοδος λειτουργίας (συνήθως για την τρέχουσα επίσκεψη
				σε έναν ιστότοπο ή σε ένα πρόγραμα περιήγησης).<br></br>
				Ισχύει από τη 1 Ιανουαρίου 2023<br></br>
				Τύπος τεχνολογίας:<br></br>
				Pixel <br></br>
				Περιγραφή<br></br>
				Ένα pixel είναι μια μικροσκοπική εικόνα που μπορεί να ενσωματωθεί σε
				ιστοσελίδες και μηνύματα ηλεκτρονικού ταχυδρομείου, απαιτώντας μια
				κλήση (η οποία παρέχει πληροφορίες συσκευής και επίσκεψης) στους
				διακομιστές μας, προκειμένου να αποδοθεί το pixel σε αυτές τις ιστοσελίδες
				και τα μηνύματα ηλεκτρονικού ταχυδρομείου. Χρησιμοποιούμε pixel για να
				μάθουμε περισσότερα σχετικά με τις αλληλεπιδράσεις σας με περιεχόμενο
				ηλεκτρονικού ταχυδρομείου ή περιεχόμενο ιστού, όπως αν αλληλεπιδράσατε
				με διαφημίσεις ή δημοσιεύσεις. Τα pixels μπορούν επίσης να επιτρέψουν σε
				εμάς και σε τρίτους να τοποθετήσουμε cookies στο πρόγραμμα περιήγησής
				σας.<br></br>
				Τύπος τεχνολογίας:<br></br>
				Τοπική αποθήκευση<br></br>
				Περιγραφή<br></br>
				Ο τοπικός χώρος αποθήκευσης επιτρέπει σε έναν ιστότοπο ή μια εφαρμογή να
				αποθηκεύει πληροφορίες τοπικά στις συσκευές σας. Η τοπική αποθήκευση
				μπορεί να χρησιμοποιηθεί για τη βελτίωση της εμπειρίας Ostrialinked, για
				παράδειγμα, ενεργοποιώντας λειτουργίες, απομνημονεύοντας τις προτιμήσεις
				σας και επιταχύνοντας τη λειτουργικότητα του ιστότοπου.<br></br>
				Άλλες παρόμοιες τεχνολογίες<br></br>
				Χρησιμοποιούμε επίσης άλλες τεχνολογίες παρακολούθησης, όπως
				αναγνωριστικά διαφημίσεων για κινητά και ετικέτες για παρόμοιους σκοπούς
				όπως περιγράφονται στην παρούσα Πολιτική cookie. Οι αναφορές σε
				παρόμοιες τεχνολογίες σε αυτήν την πολιτική περιλαμβάνουν pixels, τοπική
				αποθήκευση και άλλες τεχνολογίες παρακολούθησης.<br></br>
				Οι πίνακες cookies μας παραθέτουν cookies και παρόμοιες τεχνολογίες που χρησιμοποιούνται
				ως μέρος των Υπηρεσιών μας. Λάβετε υπόψη ότι τα ονόματα των cookies και παρόμοιων
				τεχνολογιών ενδέχεται να αλλάξουν με την πάροδο του χρόνου.<br></br>
				Σε τι χρησιμεύουν αυτές οι τεχνολογίες;<br></br>
				Παρακάτω περιγράφουμε τους σκοπούς για τους οποίους χρησιμοποιούμε αυτές τις τεχνολογίες.
				<b>Σκοπός</b> <br></br>
				Έλεγχος ταυτότητας<br></br>
				<b>Περιγραφή</b> <br></br>
				Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες για να σας
				αναγνωρίζουμε όταν επισκέπτεστε τις Υπηρεσίες μας.<br></br>
				Εάν είστε συνδεδεμένοι στο Ostrialinked, αυτές οι τεχνολογίες μάς βοηθούν
				να σας δείχνουμε τις σωστές πληροφορίες και να εξατομικεύουμε την
				εμπειρία σας σύμφωνα με τις ρυθμίσεις σας. Για παράδειγμα, τα cookies
				επιτρέπουν στο Ostrialinked να σας αναγνωρίσει και να επαληθεύσει τον
				λογαριασμό σας.
				<b>Σκοπός</b> <br></br>
				Ασφάλεια<br></br>
				<b>Περιγραφή</b> <br></br>
				Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες για να κάνουμε τις
				αλληλεπιδράσεις σας με τις Υπηρεσίες μας ταχύτερες και ασφαλέστερες.
				Για παράδειγμα, χρησιμοποιούμε cookies για να ενεργοποιήσουμε και να
				υποστηρίξουμε τις λειτουργίες ασφαλείας μας, να διατηρήσουμε τον
				λογαριασμό σας ασφαλή και να μας βοηθήσουν να εντοπίσουμε κακόβουλη
				δραστηριότητα και παραβιάσεις του Συμφωνητικού με τους όρους χρήσης της
				Υπηρεσίας μας.<br></br>
				<b>Σκοπός</b> <br></br>
				Προτιμήσεις,
				χαρακτηριστικά και
				υπηρεσίες
				<br></br>
				<b>Περιγραφή</b> <br></br>
				Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες για να ενεργοποιήσουμε
				τη λειτουργικότητα των Υπηρεσιών μας, όπως για να σας βοηθήσουμε να
				συμπληρώσετε φόρμες στις Υπηρεσίες μας πιο εύκολα και να σας παρέχουμε
				λειτουργίες, πληροφορίες και προσαρμοσμένο περιεχόμενο σε συνδυασμό με
				τα plugins μας. Χρησιμοποιούμε επίσης αυτές τις τεχνολογίες για να
				θυμόμαστε πληροφορίες σχετικά με το πρόγραμμα περιήγησής σας και τις
				προτιμήσεις σας.<br></br>
				Για παράδειγμα, τα cookies μπορούν να μας πουν ποια γλώσσα προτιμάτε και
				ποιες είναι οι προτιμήσεις επικοινωνίας σας. Ενδέχεται επίσης να
				χρησιμοποιήσουμε τοπική αποθήκευση για να επιταχύνουμε τη
				λειτουργικότητα του ιστότοπου.<br></br>
				<b>Σκοπός</b> <br></br>
				Προσαρμοσμένο
				περιεχόμενο
				<br></br>
				<b>Περιγραφή</b> <br></br>
				Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες για να προσαρμόσουμε
				την εμπειρία σας στις Υπηρεσίες μας.
				Για παράδειγμα, ενδέχεται να χρησιμοποιήσουμε cookies για να θυμόμαστε
				προηγούμενες αναζητήσεις, έτσι ώστε όταν επιστρέψετε στις υπηρεσίες μας,
				να μπορούμε να προσφέρουμε πρόσθετες πληροφορίες που σχετίζονται με την
				προηγούμενη αναζήτησή σας.
				<br></br>
				<b>Σκοπός</b> <br></br>
				Plugins εντός και
				εκτός Ostrialinked
				<br></br>
				<b>Περιγραφή</b> <br></br>
				Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες για να ενεργοποιήσουμε
				τις προσθήκες Ostrialinked τόσο εντός όσο και εκτός των ιστότοπων
				Ostrialinked.<br></br>
				Για παράδειγμα, τα plugins μας, συμπεριλαμβανομένου του κουμπιού
				"Εφαρμογή με το Ostrialinked " ή του κουμπιού "Κοινή χρήση" μπορούν να
				βρεθούν στο Ostrialinked ή σε ιστότοπους τρίτων, όπως οι ιστότοποι των
				πελατών και των συνεργατών μας. Τα plugins μας χρησιμοποιούν cookies και
				άλλες τεχνολογίες για να παρέχουν αναλυτικά στοιχεία και να σας
				αναγνωρίζουν στο Ostrialinked και σε ιστότοπους τρίτων. Εάν αλληλεπιδράτε
				με μια προσθήκη (για παράδειγμα, κάνοντας κλικ στην επιλογή "Εφαρμογή"),
				η προσθήκη θα χρησιμοποιήσει cookies για να σας αναγνωρίσει και να
				ξεκινήσει το αίτημά σας για υποβολή αίτησης.
				<br></br>
				<b>Σκοπός</b> <br></br>
				Διαφήμιση
				<br></br>
				<b>Περιγραφή</b> <br></br>
				Τα cookies και παρόμοιες τεχνολογίες μας βοηθούν να σας δείχνουμε
				σχετικές διαφημίσεις πιο αποτελεσματικά, τόσο εντός όσο και εκτός των
				Υπηρεσιών μας και να μετράμε την απόδοση τέτοιων διαφημίσεων.
				Χρησιμοποιούμε αυτές τις τεχνολογίες για να μάθουμε αν σας έχει προβληθεί
				περιεχόμενο ή αν κάποιος που παρουσιάστηκε μια διαφήμιση αργότερα
				επέστρεψε και έκανε μια ενέργεια σε έναν άλλο ιστότοπο. Ομοίως, οι
				συνεργάτες μας ή οι πάροχοι υπηρεσιών ενδέχεται να χρησιμοποιήσουν αυτές
				τις τεχνολογίες για να προσδιορίσουν εάν έχουμε προβάλει μια διαφήμιση ή
				μια δημοσίευση και την απόδοσή της ή να μας παράσχουν πληροφορίες
				σχετικά με τον τρόπο με τον οποίο αλληλεπιδράτε με τις διαφημίσεις.
				
				Ενδέχεται επίσης να συνεργαστούμε με τους πελάτες και τους συνεργάτες μας
				για να σας δείξουμε μια διαφήμιση εντός ή εκτός Ostrialinked, όπως αφού
				επισκεφθείτε τον ιστότοπο ή την εφαρμογή ενός πελάτη ή συνεργάτη. Αυτές
				οι τεχνολογίες μας βοηθούν να παρέχουμε συγκεντρωτικές πληροφορίες
				στους πελάτες και τους συνεργάτες μας.
				<br></br>
				<b>Σκοπός</b> <br></br>
				Analytics και έρευνα
				<br></br>
				<b>Περιγραφή</b> <br></br>
				Τα cookies και παρόμοιες τεχνολογίες μάς βοηθούν να μάθουμε περισσότερα
				σχετικά με την απόδοση των Υπηρεσιών και των plugins μας σε διαφορετικές
				τοποθεσίες.<br></br>
				Εμείς ή οι πάροχοι υπηρεσιών μας χρησιμοποιούμε αυτές τις τεχνολογίες για
				να κατανοήσουμε, να βελτιώσουμε και να ερευνήσουμε προϊόντα, λειτουργίες
				και υπηρεσίες, μεταξύ άλλων καθώς περιηγείστε στους ιστότοπούς μας ή όταν
				αποκτάτε πρόσβαση στο Ostrialinked από άλλους ιστότοπους, εφαρμογές ή
				συσκευές. Εμείς ή οι πάροχοι υπηρεσιών μας, χρησιμοποιούμε αυτές τις
				τεχνολογίες για να προσδιορίσουμε και να μετρήσουμε την απόδοση
				διαφημίσεων ή αναρτήσεων εντός και εκτός Ostrialinked και να μάθουμε εάν
				έχετε αλληλεπιδράσει με τους ιστότοπους, το περιεχόμενο ή τα email μας και
				να παρέχουμε αναλυτικά στοιχεία βάσει αυτών των αλληλεπιδράσεων.
				Χρησιμοποιούμε επίσης αυτές τις τεχνολογίες για να παρέχουμε
				συγκεντρωτικές πληροφορίες στους πελάτες και τους συνεργάτες μας ως
				μέρος των Υπηρεσιών μας.<br></br>
				Εάν είστε μέλος του Ostrialinked αλλά αποσυνδεθήκατε από τον λογαριασμό
				σας σε ένα πρόγραμμα περιήγησης, το Ostrialinked μπορεί να συνεχίσει να
				καταγράφει την αλληλεπίδρασή σας με τις Υπηρεσίες μας σε αυτό το
				πρόγραμμα περιήγησης μέχρι τη λήξη του cookie, προκειμένου να
				δημιουργήσει αναλυτικά στοιχεία χρήσης για τις Υπηρεσίες μας. Ενδέχεται να
				μοιραστούμε αυτά τα αναλυτικά στοιχεία σε συγκεντρωτική μορφή με τους
				πελάτες μας.<br></br>
				<b>Ποια τρίτα μέρη χρησιμοποιούν αυτές τις τεχνολογίες σε σχέση με τις Υπηρεσίες μας;</b><br></br>
				Τρίτα μέρη, όπως οι πελάτες, οι συνεργάτες και οι πάροχοι υπηρεσιών μας, ενδέχεται να
				χρησιμοποιούν cookies σε σχέση με τις Υπηρεσίες μας.<br></br>
				Για παράδειγμα, τρίτα μέρη ενδέχεται να χρησιμοποιούν cookies στις σελίδες τους στο
				Ostrialinked, στις αναρτήσεις θέσεων εργασίας και στις διαφημίσεις τους εντός και εκτός
				Ostrialinked για δικούς τους σκοπούς μάρκετινγκ.<br></br>
				Τρίτα μέρη μπορούν επίσης να χρησιμοποιούν cookies σε σχέση με τις Υπηρεσίες μας εκτός
				ιστότοπου, όπως οι διαφημιστικές υπηρεσίες Ostrialinked. Τρίτα μέρη ενδέχεται να
				χρησιμοποιούν cookies για να μας βοηθήσουν να παρέχουμε τις Υπηρεσίες μας. Μπορούμε
				επίσης να συνεργαστούμε με τρίτους για δικούς μας σκοπούς μάρκετινγκ και για να μπορέσουμε
				να αναλύσουμε και να ερευνήσουμε τις Υπηρεσίες μας.<br></br>
				<b>Οι επιλογές σας</b><br></br>
				Έχετε επιλογές σχετικά με τον τρόπο με τον οποίο το Ostrialinked χρησιμοποιεί cookies και
				παρόμοιες τεχνολογίες. Λάβετε υπόψη ότι εάν περιορίσετε τη δυνατότητα του Ostrialinked να
				ορίζει cookies και παρόμοιες τεχνολογίες, ενδέχεται να επιδεινώσετε τη συνολική εμπειρία
				χρήστη, καθώς ενδέχεται να μην είναι πλέον εξατομικευμένη για εσάς. Μπορεί επίσης να σας
				εμποδίσει να αποθηκεύσετε προσαρμοσμένες ρυθμίσεις, όπως πληροφορίες σύνδεσης.
				Εξαίρεση από στοχευμένη διαφήμιση<br></br>
				Ορισμένα λειτουργικά συστήματα κινητών συσκευών, όπως το Android, παρέχουν τη
				δυνατότητα ελέγχου της χρήσης αναγνωριστικών διαφημίσεων για κινητά για εξατομίκευση
				διαφημίσεων. Μπορείτε να μάθετε πώς να χρησιμοποιείτε αυτά τα στοιχεία ελέγχου
				μεταβαίνοντας στην τοποθεσία Web του κατασκευαστή. Δεν χρησιμοποιούμε αναγνωριστικά
				διαφημίσεων iOS για κινητά για στοχευμένες διαφημίσεις.<br></br>
				<b>Στοιχεία ελέγχου προγράμματος περιήγησης</b><br></br>
				Τα περισσότερα προγράμματα περιήγησης σάς επιτρέπουν να ελέγχετε τα cookies μέσω των
				ρυθμίσεών τους, οι οποίες ενδέχεται να προσαρμοστούν ώστε να αντικατοπτρίζουν τη
				συγκατάθεσή σας για τη χρήση cookies. Επιπλέον, τα περισσότερα προγράμματα περιήγησης
				σάς επιτρέπουν επίσης να ελέγχετε και να διαγράφετε τα cookies, συμπεριλαμβανομένων των
				cookies του Ostrialinked. Για να μάθετε περισσότερα σχετικά με τα στοιχεία ελέγχου του
				προγράμματος περιήγησης, συμβουλευτείτε την τεκμηρίωση που παρέχει ο κατασκευαστής του
				προγράμματος περιήγησης.<br></br>
				Ισχύει από τη 1 Ιανουαρίου 2023
			</Typography>
		</div>
	);
};

export default PrivacyCookies