// @flow
import React, {useState} from 'react';
import useUserData from "../../../hooks/user/useUserData";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {Button, IconButton, ListItem, TextField, Typography} from "@mui/material";
import {CloseRounded, Delete, EditOutlined, SaveOutlined} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import * as yup from "yup";
import { useMutation } from "react-query";
import { editProfileDescription } from "../../../api/requests";
import { Textarea } from "@mui/joy";

type Props = {

};

const OtherInformation = ({text_user,refetch}) => {
	const {accessCode} = useUserData();
	
	const [showExperienceEdit,setShowExperienceEdit] = useState(false)
	
	
	
	const validationSchema = yup.object({
		text_user: yup
		.string()
		.required('Απαιτείται'),

	})

	const {mutate: editProfileDescriptionAction} = useMutation({
		mutationFn: editProfileDescription,
		onSuccess: (data, variables, context) => {
			refetch()
			setShowExperienceEdit(false)
		},
	})


	const onSubmit = (values) => {

		editProfileDescriptionAction({
			accessCode,
			formData:{
				text_user:values.text_user
			}
		})
	}
	
	const formik = useFormik({
		initialValues: {
			text_user: text_user
		},
		validationSchema: validationSchema,
		enableReinitialize:true,
		onSubmit: onSubmit,
		
	})
	
	const handleDelete = () => {
		editProfileDescriptionAction({
			accessCode,
			formData:{
				text_user: ""
			}
		})
	}
	return (
		<div>
			{showExperienceEdit ?
				<>
					<FormikProvider value={formik} onSubmit={formik.handleSubmit}
									noValidate>
						<Box component="form"
							 onSubmit={formik.handleSubmit}
							 noValidate>
							<Box sx={{display:'flex',justifyContent:'space-between'}}>
								<Box>
									<TextField
										multiline
										maxRows={4}
										size={'small'}
											   id={'text_user'}
											   name={'text_user'}
											   onChange={formik.handleChange}
											   onBlur={formik.handleBlur}
											   sx={{
												   padding: '2%',
												   marginBottom: '1%',
												   '& .MuiOutlinedInput-root': {
													   // ':focus-within': { border: '0.0px solid #ffffff !important' }
													   fontSize: 'smaller',
												   },
											   }}
											   variant="outlined"
											   value={formik.values.text_user}
									/>
								</Box>
								<Box sx={{display:'inline-flex'}}>
									<Box >
										<Button
											type={'submit'}
											sx={{
												fontSize: 'smaller'
											}}
											onClick={formik.handleSubmit}
										>
											<SaveOutlined />
										</Button>
									
									</Box>
									<Box>
										<Button>
											<CloseRounded onClick={()=>setShowExperienceEdit(false)}/>
										</Button>
									</Box>
								
								</Box>
							
							</Box>
						</Box>
					</FormikProvider>
				</>
				:
				<ListItem className="announcement"
						  secondaryAction={
							  <>
								  <IconButton aria-label="edit" onClick={() => setShowExperienceEdit(true)}>
									  <EditOutlined/>
								  </IconButton>
								  <IconButton aria-label="edit" onClick={() => handleDelete()}>
									  <Delete/>
								  </IconButton>
							  </>
						  }>
					<ListItemText   >
						<Typography style={{maxWidth: "100px"}} sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular',wordWrap:'break-word'}}>{text_user}</Typography>
					</ListItemText>
				</ListItem>
			}
		</div>
	);
};

export default OtherInformation