// @flow
import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import GeneralDetailsTab from "../rightTabs/generalDetailsTab";
import FileTab from "../rightTabs/fileTab";
import FriendsTab from "../rightTabs/friendsTab";
import Footer from "../../footer/footer";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import { CardHeader, IconButton, Modal, Typography, useMediaQuery } from "@mui/material";
import theme from "../../../theme/theme";
import './recommendationTab.css';
import Card from "@mui/material/Card";
import {Edit} from "@mui/icons-material";
import useUserData from "../../../hooks/user/useUserData";
import EditRecommendation from "./editRecommendation";
import useUserAction from "../../../hooks/user/useUserAction";
import OstriaModal from "../../ostriaModal/ostriaModal";
import { useQuery } from "react-query";
import { getFriendRecommendations, getRecommendations } from "../../../api/requests";
import ContentLoader from "../../contentLoader/contentLoader";
import Avatar from "@mui/material/Avatar";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_FRIEND, ROUTE_PAGE_PERSONAL } from "../../../routers/routes";
import Link from "@mui/material/Link";

type Props = {};

const RecommendationTab = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const {userId,accessCode} = useUserData();
	const [openEdit, setOpenEdit] = useState(false);


	const [data,setData] = useState({})
	const {
		isLoading,
		data: userRecommendationData,
	} = useQuery(["getRecommendations", accessCode], getRecommendations);

	
	return (
		<>
			<Box className="recommendationLeftTab"
				 sx={{
					 width: {
						 xs: '100%',
						 sm: '100%',
						 md: '60%',
						 lg: '60%'
					 }
				 }}
			>
				<Box className="recommendationCards">
					{isLoading  ?
						<ContentLoader loading={isLoading}></ContentLoader>
						: userRecommendationData?.map(recommendation => (

							<Card
								key={recommendation.id}
								elevation={0}
								sx={{
									border: "none",
									maxWidth: 300,
									width: {
										xs: "100%",
										sm: "100%",
										md: "100%",
										lg: "100%",
									},
								}}
							>
								<CardHeader
									titleTypographyProps={{
										fontSize: 20,
									}}
									subheaderTypographyProps={{
										fontSize: 15,
									}}
									avatar={
										(recommendation.user_put) &&
										<Link style={{ textDecoration: "none" }} href={ getRouteUrl(ROUTE_PAGE_FRIEND, {
											username: recommendation.user_put.username,
											id: recommendation.user_put.id,
										})
										}>
											<Avatar style={{cursor:'pointer'}}  sx={{ width: 40, height: 40 }} src={recommendation.user_put.image_profile}></Avatar>
											<Typography fontSize={9} fontWeight="bold">
												{recommendation.user_put.firstname + " " + recommendation.user_put.lastname}
											</Typography>
										</Link>

									}
									title={recommendation.title}
									subheader={recommendation.description}
								/>
							</Card>

						))}
				</Box>
			</Box>

			{!matches && (
				<Box sx={{width: '40%',}}>
					<GeneralDetailsTab type={props.type} userId={props.id}/>
					<FileTab  type={props.type} userId={props.id}/>
					<FriendsTab type={props.type} userId={props.id}/>
					<Footer color={'#aeacac'}/>
				</Box>
			)}
		</>
	
	);
};

export default RecommendationTab