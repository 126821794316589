import Home from "../pages/home/home";
import { routeCreator } from "../helpers/routeCreator";
import Login from "../pages/login/login";
import ResetPassword from "../pages/resetPassword/resetPassword";
import Terms from "../pages/terms/terms";
import ForgotPassword from "../pages/forgotPassword/forgotPassword";
import Register from "../pages/register/register";
import Contact from "../pages/contact/contact";
import Personal from "../pages/profiles/personal/personal";
import NotificationSettings from "../components/notifications/components/notificationSettings/notificationSettings";
import NotificationsList from "../components/notifications/components/notificationsList/notificationsList";
import Poll from "../components/postPopup/pollModal/poll";
import Post from "../components/postPopup/postModal/post";
import TermsOfUse from "../components/terms&GDPR/termsOfUse";
import Company from "../pages/profiles/company/company";
import Group from "../pages/profiles/group/group";
import Groups from "../pages/groups/groups";
import Companies from "../pages/companies/companies";
import Jobs from "../pages/jobs/jobs";
import SearchResults from "../pages/searchResults/searchResults";
import JobsShowData from "../pages/jobs/jobsShowData";
import EditJobByAdmin from "../pages/jobs/editJobByAdmin";
import editUserDetails from "../components/editUserDetails/editUserDetails";
import JobApply from "../pages/jobs/jobApply";
import { FullPost } from "../pages/fullPost/fullPost";
import FriendProfile from "../pages/profiles/friendProfile/friendProfile";
import CreateGroup from "../components/groups/createGroup";
import CreateCompany from "../components/companies/createCompany";
import Chat from "../pages/chat/chat";
import Education from "../pages/education/education";
import Faq from "../pages/faq/faq";
export const ROUTE_PAGE_RESET_PASSWORD = routeCreator(
  ResetPassword,
  "/forgot-password",
  "reset",
  "ResetPassword",
  { exact: true }
);
export const ROUTE_PAGE_FORGOT_PASSWORD = routeCreator(
  ForgotPassword,
  "/forgot-2-password",
  "reset",
  "ForgotPassword",
  { exact: true }
);
export const ROUTE_PAGE_SIGN_UP = routeCreator(
  Register,
  "/create-account",
  "signup",
  "SignUp",
  { exact: true }
);
export const ROUTE_PAGE_LOGIN = routeCreator(
  Login,
  "/login",
  "login",
  "Login",
  "page.login"
);
export const ROUTE_PAGE_HOME = routeCreator(Home, "/", "Home", "page.home", {
  exact: true,
});

export const ROUTE_PAGE_EDIT_USER_DETAILS = routeCreator(
  editUserDetails,
  "/edit/userdetails",
  "userdetails",
  "userdetails",
  { exact: true }
);

export const ROUTE_PAGE_TERMS = routeCreator(
  Terms,
  "/terms-of-use",
  "Terms",
  "page.terms",
  { exact: true }
);
export const ROUTE_PAGE_CONTACT = routeCreator(
  Contact,
  "/my/ostriahelp",
  "Contact",
  "page.contact",
  { exact: true }
);
export const ROUTE_PAGE_PERSONAL_ABOUT = routeCreator(
  Personal,
  "/profile/:username/about",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_PERSONAL_CV = routeCreator(
  Personal,
  "/profile/:username/cv",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_PERSONAL_FRIENDS = routeCreator(
  Personal,
  "/profile/:username/friends",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_PERSONAL_GROUPS = routeCreator(
  Personal,
  "/profile/:username/groups",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_PERSONAL_FILES = routeCreator(
  Personal,
  "/profile/:username/files",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_PERSONAL_RECOMMENDATIONS = routeCreator(
  Personal,
  "/profile/:username/recommendations",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_PERSONAL = routeCreator(
  Personal,
  "/profile/:username",
  "personal",
  "Personal"
);
export const ROUTE_PAGE_NOTIFICATIONS_SETTINGS = routeCreator(
  NotificationSettings,
  "/my/notifications",
  "notificationsSettings",
  "page.notificationSettings",
  { exact: true }
);
export const ROUTE_PAGE_NOTIFICATIONS_LIST = routeCreator(
  NotificationsList,
  "/notifications",
  "notifications",
  "page.notifications",
  { exact: true }
);
export const ROUTE_PAGE_GROUPS_CREATE = routeCreator(
  CreateGroup,
  "/group/create",
  "createGroup",
  "page.createGroup",
  { exact: true }
);
export const ROUTE_PAGE_MODAL_POLL = routeCreator(
  Poll,
  "/create/poll",
  "createPoll",
  "page.createPoll",
  { exact: true }
);
export const ROUTE_PAGE_MODAL_POST = routeCreator(
  Post,
  "/create/post",
  "createPost",
  "page.createPost",
  { exact: true }
);
export const ROUTE_PAGE_TERMS_OF_USE = routeCreator(
  TermsOfUse,
  "/my/ostriainfo",
  "terms",
  "page.createPost",
  { exact: true }
);
export const ROUTE_PAGE_FAQ = routeCreator(
  Faq,
  "/my/ostriafaq",
  "faq",
  "page.faq",
  { exact: true }
);

export const ROUTE_PAGE_COMPANY_CREATE = routeCreator(
  CreateCompany,
  "/company/create",
  "createCompany",
  "page.createCompany",
  { exact: true }
);
export const ROUTE_PAGE_COMPANIES = routeCreator(
  Companies,
  "/companies",
  "companies",
  "Companies"
);
export const ROUTE_PAGE_COMPANY_CENTER = routeCreator(
  Company,
  "/company/:id/center",
  "company",
  "Company"
);
export const ROUTE_PAGE_COMPANY_ABOUT = routeCreator(
  Company,
  "/company/:id/about",
  "company",
  "Company"
);
export const ROUTE_PAGE_COMPANY_MEMBERS = routeCreator(
  Company,
  "/company/:id/members",
  "company",
  "Company"
);
export const ROUTE_PAGE_COMPANY_FILES = routeCreator(
  Company,
  "/company/:id/files",
  "company",
  "Company"
);
export const ROUTE_PAGE_COMPANY_ADS = routeCreator(
  Company,
  "/company/:id/ads",
  "company",
  "Company"
);
export const ROUTE_PAGE_COMPANY_MORE = routeCreator(
  Company,
  "/company/:id/more",
  "company",
  "Company"
);

export const ROUTE_PAGE_GROUPS = routeCreator(
  Groups,
  "/groups",
  "groups",
  "Groups"
);
export const ROUTE_PAGE_GROUP = routeCreator(
  Group,
  "/group/:groupCode/chat",
  "group",
  "Group"
);
export const ROUTE_PAGE_GROUP_ABOUT = routeCreator(
  Group,
  "/group/:groupCode/about",
  "group",
  "Group"
);
export const ROUTE_PAGE_GROUP_MEMBERS = routeCreator(
  Group,
  "/group/:groupCode/members",
  "group",
  "Group"
);
export const ROUTE_PAGE_GROUP_FILES = routeCreator(
  Group,
  "/group/:groupCode/files",
  "group",
  "Group"
);

export const ROUTE_PAGE_JOBS = routeCreator(Jobs, "/jobs", "jobs", "Jobs");
export const ROUTE_PAGE_JOB_SHOW = routeCreator(
  JobsShowData,
  "/show/:jobId",
  "jobsShow",
  "page.job"
);
export const ROUTE_PAGE_EDIT_JOB = routeCreator(
  EditJobByAdmin,
  "/company/jobs/edit",
  "editJob",
  "page.editJob"
);
export const ROUTE_PAGE_JOB_APPLY = routeCreator(
  JobApply,
  "/job/apply/:id",
  "jobApply",
  "page.jobApply"
);
export const ROUTE_PAGE_SEARCH_RESULTS = routeCreator(
  SearchResults,
  "/search-results/:type/:search",
  "search",
  "Search"
);

export const ROUTE_PAGE_FULL_POST = routeCreator(
  FullPost,
  "/fullpost/:id",
  "fullPost",
  "page.fullPost"
);

export const ROUTE_PAGE_FRIEND_ABOUT = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id/about",
  "friend",
  "Friend"
);
export const ROUTE_PAGE_FRIEND_CV = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id/cv",
  "friend",
  "Friend"
);
export const ROUTE_PAGE_FRIEND_FRIENDS = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id/friends",
  "friend",
  "Friend"
);
export const ROUTE_PAGE_FRIEND_GROUPS = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id/groups",
  "friend",
  "Friend"
);
export const ROUTE_PAGE_FRIEND_FILES = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id/files",
  "friend",
  "Friend"
);
export const ROUTE_PAGE_FRIEND_RECOMMENDATIONS = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id/recommendations",
  "friend",
  "Friend"
);
export const ROUTE_PAGE_FRIEND = routeCreator(
  FriendProfile,
  "/friend-profile/:username/:id",
  "friend",
  "Friend"
);

export const ROUTE_PAGE_MOODLE = routeCreator(
  Education,
  "/education",
  "education",
  "Education"
);
export const ROUTE_PAGE_CHAT_FRIEND = routeCreator(
  Chat,
  "/chat/:id",
  "chat",
  "Chat"
);
export const ROUTE_PAGE_CHAT = routeCreator(
  Chat,
  "/chat-view/",
  "chat",
  "Chat"
);

const router = {
  ROUTE_PAGE_HOME,
  ROUTE_PAGE_PERSONAL,
  ROUTE_PAGE_PERSONAL_ABOUT,
  ROUTE_PAGE_PERSONAL_CV,
  ROUTE_PAGE_PERSONAL_RECOMMENDATIONS,
  ROUTE_PAGE_PERSONAL_FRIENDS,
  ROUTE_PAGE_PERSONAL_FILES,
  ROUTE_PAGE_PERSONAL_GROUPS,
  ROUTE_PAGE_NOTIFICATIONS_SETTINGS,
  ROUTE_PAGE_NOTIFICATIONS_LIST,
  ROUTE_PAGE_GROUPS_CREATE,
  ROUTE_PAGE_MODAL_POLL,
  ROUTE_PAGE_MODAL_POST,
  ROUTE_PAGE_COMPANIES,
  ROUTE_PAGE_COMPANY_CENTER,
  ROUTE_PAGE_COMPANY_ABOUT,
  ROUTE_PAGE_COMPANY_MEMBERS,
  ROUTE_PAGE_COMPANY_FILES,
  ROUTE_PAGE_COMPANY_ADS,
  ROUTE_PAGE_COMPANY_MORE,
  ROUTE_PAGE_EDIT_USER_DETAILS,
  ROUTE_PAGE_TERMS_OF_USE,
  ROUTE_PAGE_CONTACT,
  ROUTE_PAGE_GROUPS,
  ROUTE_PAGE_GROUP,
  ROUTE_PAGE_GROUP_ABOUT,
  ROUTE_PAGE_GROUP_MEMBERS,
  ROUTE_PAGE_GROUP_FILES,
  ROUTE_PAGE_JOBS,
  ROUTE_PAGE_JOB_APPLY,
  ROUTE_PAGE_SEARCH_RESULTS,
  ROUTE_PAGE_JOB_SHOW,
  ROUTE_PAGE_EDIT_JOB,
  ROUTE_PAGE_FULL_POST,
  ROUTE_PAGE_FRIEND_ABOUT,
  ROUTE_PAGE_FRIEND_CV,
  ROUTE_PAGE_FRIEND_FRIENDS,
  ROUTE_PAGE_FRIEND_FILES,
  ROUTE_PAGE_FRIEND_RECOMMENDATIONS,
  ROUTE_PAGE_FRIEND,
  ROUTE_PAGE_COMPANY_CREATE,
  ROUTE_PAGE_CHAT,
  ROUTE_PAGE_MOODLE,
  ROUTE_PAGE_CHAT_FRIEND,
  ROUTE_PAGE_FAQ
};

export default router;
