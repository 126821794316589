import React from 'react';
import useUserData from "../../hooks/user/useUserData";
import PasswordInput from "../passwordInput/passwordInput";
import {Button, FormControl, FormHelperText, Typography} from "@mui/material";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import axios from "axios";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../routers/routes";
import * as yup from "yup";
import './changePassword.css'
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../locales/pages/namespaces";
import { enqueueSnackbar } from "notistack";



const ChangePassword = (props) => {
	const {accessCode} = useUserData()
	const {t} = useTranslation(PAGE_PERSONAL)
	
	const onSubmit = (values) => {
		const newValues = {
			newpass: values.newPassword,
			oldpass:values.oldPassword
		}
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=6&scode=" + accessCode,
			method: 'post',
			data:newValues,
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			// history.push(getRouteUrl(ROUTE_PAGE_HOME))
			if(response.data.change == 'true'){
				props.setChangePassword(false)
				enqueueSnackbar(t('data_saved'), {
					variant: 'success',
					autoHideDuration: 10000
				})
			}else{
				enqueueSnackbar(t('error'), {
					variant: 'error',
					autoHideDuration: 10000
				})
			}

			console.log('change Password response',response)
		}).catch((error) => {
		
		})
	}
	const validationSchema = yup.object({
		oldPassword: yup
		.string()
		.required(t('required')),
		newPassword: yup
		.string()
		.required(t('required')),
	})
	const formik = useFormik({
		initialValues:{
			oldPassword:'',
			newPassword:'',
		},
		validationSchema: validationSchema,
		onSubmit:onSubmit
	})
	return (
		<FormikProvider value={formik}>
			<Box
				className={'changePasswordForm'}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Box className={"title"}>
					<Typography sx={{paddingBottom:'10px'}}>
						{t('Change password')}
					</Typography>
				</Box>
				<FormControl
					variant="outlined"
					fullWidth
					sx={{
						marginBottom: 2
					}}
					error={
						formik.touched['password'] &&
						Boolean(formik.errors['password'])
					}
				>
					<PasswordInput
						formik={formik}
						allowBrowserAutocomplete
						name={'oldPassword'}
						label={t('Old Password')}
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'oldPassword'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="outlined"
					fullWidth
					sx={{
						marginBottom: 2
					}}
					error={
						formik.touched['password'] &&
						Boolean(formik.errors['password'])
					}
				>
					<PasswordInput
						formik={formik}
						allowBrowserAutocomplete
						name={'newPassword'}
						label={t('New Password')}
						required
					/>
					<FormHelperText>
						<ErrorMessage name={'newPassword'}/>
					</FormHelperText>
				</FormControl>
				<Box className={"formButtons"}>
					<Button
						variant="outlined"
						type="submit"
						sx={{
							marginRight:'20px',
							backgroundColor:'#27293d',
							borderRadius:'4px',
							color: '#ffffff',
							'&:hover': {
								backgroundColor:'#27293d',
							},
							width: '35%',
						}}
					>
						{t('save')}
					</Button>
					<Button
						onClick={()=>props.setChangePassword(false)}
						sx={{
							borderRadius:'4px',
							width: '35%',
						}}
						variant="outlined"
					>
						{t('cancel')}
					</Button>
				</Box>
			</Box>
		</FormikProvider>
	);
};

export default ChangePassword