// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import {Check, Close} from "@mui/icons-material";
import './report.css'

type Props = {

};

const Report = ({ openReportPopUp, onClose }) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	return (
		<Box
			sx={{
				height: "auto",
				padding:'16px',
				margin: "auto",
			}}
		>
			<Typography
				sx={{
					color: "#050505",
					fontFamily: '"Segoe UI-Bold", Helvetica',
					fontSize: { xs: "15px", md: "21px" },
					fontWeight: "700",
					marginBottom: { xs: "5px", md: "10px" },
					textAlign:'center',
				}}
			>
				{t('Would you like to block this user?')}
			</Typography>
			<Box className="reportButtons">
				<IconButton onClick={onClose}>
					<Check color="success"/>
					{t('yes')}
				</IconButton>
				<IconButton onClick={onClose}>
					<Close sx={{color:'red'}}/>
					{t('no')}
				</IconButton>
			</Box>
		</Box>
	);
};

export default Report