export const PAGE_LOGIN = 'pg_login';
export const PAGE_HOME = 'pg_home';
export const PAGE_RESET_PASSWORD = 'pg_reset';
export const PAGE_REGISTER = 'pg_register';
export const PAGE_PERSONAL = 'pg_personal';
export const PAGE_COMPANY = 'pg_company';
export const PAGE_GROUP = 'pg_group';
export const PAGE_JOB = 'pg_job';
export const PAGE_SEARCH = 'pg_search';
export const PAGE_NOTIFICATIONS = 'pg_notifications';
export const PAGE_POLL = 'pg_poll';
export const PAGE_ADS = 'pg_ads';