import { Grid, useMediaQuery, useTheme } from "@mui/material";
import FeedLeftColumn from "../../components/feedLeftColumn/feedLeftColumn";
import GroupsData from "../groups/groupsData";
import FeedRightColumn from "../../components/feedRightColumn/feedRightColumn";
import React from "react";

const Faq = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={2} justifyContent="space-between">
      {!matches && (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
        >
          <FeedLeftColumn />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={6}
      >
        <p class="MuiTypography-root MuiTypography-body1 css-14qwu9e">Ερωτήσεις-Απαντήσεις</p>
        <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6"/>
          <p class="MuiTypography-root MuiTypography-body1 css-88svhr"> Σύστημα Πόντων Συμμετοχής - Gamification</p>
          <p>Καλώς ήρθατε στην πλατφόρμα μας! Στόχος μας είναι να σας προσφέρουμε μια μοναδική εμπειρία κοινωνικής
            δικτύωσης, ενώ ταυτόχρονα σας επιβραβεύουμε για την ενεργή συμμετοχή σας. Μέσω του συστήματος πόντων
            συμμετοχής που έχουμε αναπτύξει, μπορείτε να κερδίσετε πόντους για κάθε σας ενέργεια στην πλατφόρμα, από το
            να κάνετε ένα απλό login, μέχρι το να συμμετάσχετε σε μαθήματα ή να δημοσιεύσετε νέα posts.</p>
          <p>Το σύστημα πόντων λειτουργεί ως εξής: Κάθε φορά που αλληλεπιδράτε με την πλατφόρμα, είτε μέσω ενός like,
            ενός σχολίου, είτε μέσω της δημιουργίας νέου περιεχομένου, κερδίζετε πόντους. Αυτοί οι πόντοι
            συγκεντρώνονται καθημερινά και σας βοηθούν να παρακολουθείτε την πρόοδό σας και τη συμμετοχή σας στην
            κοινότητά μας. Επιπλέον, το σύστημα αυτό ενθαρρύνει τη διαδραστικότητα και τη συνεχή εμπλοκή των μελών,
            δημιουργώντας ένα ζωντανό και δυναμικό περιβάλλον.</p>
          <p>Ακολουθούν οι λεπτομέρειες για τις ενέργειες που μπορείτε να πραγματοποιήσετε και τους πόντους που
            κερδίζετε για κάθε μία από αυτές:</p>
          <ul>
            <li>Login: Κερδίζετε 1 πόντο κάθε φορά που κάνετε είσοδο στην πλατφόρμα.</li>
            <li>Like σε post: Κερδίζετε 1 πόντο για κάθε like που κάνετε σε posts άλλων χρηστών.</li>
            <li>Σχόλιο σε post: Κερδίζετε 2 πόντους για κάθε σχόλιο που αφήνετε.</li>
            <li>Διάκριση σε προφίλ φίλου: Κερδίζετε 2 πόντους όταν διακρίνετε το προφίλ ενός φίλου.</li>
            <li>Νέο post σε προσωπικό προφίλ: Κερδίζετε 3 πόντους για κάθε νέο post που δημιουργείτε στο προφίλ σας.
            </li>
            <li>Νέο post σε προσωπικό προφίλ με φώτο: Κερδίζετε 5 πόντους όταν το post σας περιλαμβάνει φωτογραφία.</li>
            <li>Αποστολή αίτησης σε θέση εργασίας: Κερδίζετε 5 πόντους για κάθε αίτηση που υποβάλλετε για θέση
              εργασίας
            </li>
            <li>Είσοδος σε μάθημα: Κερδίζετε 5 πόντους κάθε φορά που εγγράφεστε και παρακολουθείτε ένα μάθημα.</li>
          </ul>

          <p>Μπορείτε να κερδίζετε έως και <strong>25 πόντους ανά ημέρα</strong>. Αυτό το όριο υπάρχει για να
            διατηρήσουμε το σύστημα δίκαιο και ισορροπημένο για όλους τους χρήστες. Μόλις φτάσετε το μέγιστο όριο πόντων
            για την ημέρα, θα μπορείτε να κερδίσετε περισσότερους πόντους την επόμενη ημέρα.</p>

          <p>Οι πόντοι που συγκεντρώνετε μπορούν να σας προσφέρουν προνόμια και βελτιώσεις στο προφίλ σας και την
            εμπειρία χρήσης της πλατφόρμας:</p>
          <ul>
            <li><strong>Υψηλότερο ranking στην αναζήτηση βιογραφικών από επιχειρήσεις: </strong> Με περισσότερους
              πόντους, το προφίλ σας θα εμφανίζεται ψηλότερα στις αναζητήσεις βιογραφικών από τις επιχειρήσεις.
            </li>
            <li><strong>Υψηλότερο ranking στην αποστολή αιτήσεων εργασίας: </strong> Οι αιτήσεις σας για θέσεις εργασίας
              θα εμφανίζονται πρώτες όταν έχετε περισσότερους πόντους.
            </li>
            <li><strong>700 πόντοι: </strong> Πρόσβαση σε ένα επιπλέον πεδίο πληροφοριών στο προσωπικό σας προφίλ</li>
            <li><strong>1500 πόντοι: </strong> Πρόσβαση σε δεύτερο επιπλέον πεδίο πληροφοριών στο προσωπικό σας προφίλ
            </li>
            <li><strong>2500 πόντοι: </strong> Δωρεάν εγγραφή σε ένα μάθημα της επιλογής σας*</li>
            <li><strong>3000 πόντοι: </strong> Προσαρμογή του φόντου του προφίλ σας για μια πιο εξατομικευμένη εμφάνιση
            </li>
          </ul>
          <p>*Η δωρεάν εγγραφή σε μάθημα ισχύει για μαθήματα που προσφέρονται από την πλατφόρμα μας και υπόκεινται σε
            διαθεσιμότητα.</p>
          <p>Ανακαλύψτε νέους τρόπους να συμμετέχετε και να αλληλεπιδράτε με την κοινότητά μας, και απολαύστε τις
            ανταμοιβές για την αφοσίωσή σας. Σας ευχαριστούμε που είστε μέρος της πλατφόρμας μας και ανυπομονούμε να
            δούμε τη συμμετοχή σας να μεγαλώνει! </p>
      </Grid>
      {!matches && (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
        >
          <FeedRightColumn />
        </Grid>
      )}
    </Grid>
);
}
export default Faq;