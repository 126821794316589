// @flow
import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import * as yup from "yup";
import {useFormik} from "formik";
import NoAuthLayout from "../../components/layouts/noAuthLayout/noAuthLayout";
import {Grid} from "@mui/material";
import LoginBanner from "../../components/loginBanner/loginBanner";
import Form from "./partials/form";
import {enqueueSnackbar} from "notistack";
import './login.css'
import { useLocation } from "react-router";

const Login = () => {
	const {t} = useTranslation(PAGE_LOGIN)
	const {userLogin, setUserMessage,userLoginSucceeded,setCredentials,clearCredentials} = useUserAction()
	const {loading, message,credentials} = useUserData()
	const onSubmit = (values) => {
		if(values.rememberMe){
			setCredentials(values)
		}else{
			clearCredentials()
		}

		userLogin(values)
	}
	const  location = useLocation();
	
	const validationSchema = yup.object({
		username: yup
		.string()
		.required(t('required')),
		password: yup
		.string()
		.required(t('required')),
	})
	
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			rememberMe: false,
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})

	useEffect(() => {
	    if (message) {
	        enqueueSnackbar(t(message?.text), {
	                variant: message?.variant
	            }
	        )
			setUserMessage(null)
	    }
	}, [message, t])

	useEffect(() => {
		if (credentials.rememberMe) {
			formik.setValues(credentials);
		}
	}, [credentials]);

	useEffect(()=>{
		if(location.search && location.search.length > 0){
			const urlParams = new URLSearchParams(location.search);
			const scode = urlParams.get('scode');
			userLoginSucceeded({found:"ok",scode})
		}
	},[location])

	return (
		<NoAuthLayout>
			<Grid container maxWidth={'1024px'}>
				<Grid
					sx={{
						borderRight: {
							sm: 'none',
							md: '1px solid #ededed'
						},
					}}
					item
					xs={12}
					sm={12}
					md={6}
				>
					<LoginBanner/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					className={'login-form-container'}
				>
					<Form
						formik={formik}
						loading={loading}
					/>
				</Grid>
			</Grid>
		</NoAuthLayout>
	)
}

export default Login