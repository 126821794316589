import LOGO from './logo/logo.svg'
import LOGIN_BANNER from './login/login-banner.svg'
import FACEBOOK_LOGO from './login/facebookLogo.svg'
import GOOGLE_LOGO from './login/googleLogo.svg'
import ESPA_LOGO from './espa/espaBanner.png'
import LOGO_PNG from './logo/logo_png.svg'
import SEARCH from './toplayout/search.svg'
import BANK from './toplayout/bank.svg'
import BELL_RING from './toplayout/bell_ring.svg'
import CARET_DOWN from './toplayout/caret_down.svg'
import SEARCH_ALT from './toplayout/search_alt.svg'
import USER from './leftlayout/user.svg'
import HOME from './leftlayout/home.svg'
import ENVELOP from './leftlayout/envelope.svg'
import CHART_NETWORK from './leftlayout/chart_network.svg'
import BUILDING from './leftlayout/building.svg'
import USERCOVERPAGE from './user/3ec66a63-005c-48bf-90b3-03e6e0d6ca41_1024.jpg.png'
import USERIMAGEPROFILE from './user/user_image_profile.jpg'
import ADD_USER from './user/add_user.svg'
import MESSAGE from './user/message.svg'
import MORE from './user/more.svg'
import STAR from './user/star.png'
import ECDL from './rightLayout/ECDL.jpg'
import MARKET from './rightLayout/MARKET.jpg'
import PMP from './rightLayout/PMP.jpg'
import PMP2 from './rightLayout/PMP2.jpg'
import notificationAvatar from './notifications/notificationAvatar.png'
import threeDots from './notifications/threeDots.svg'
import pic1 from './user/pic1.png'
import pic2 from './user/pic2.png'
import pic3 from './user/pic3.png'
import pic4 from './user/pic4.png'
import pic5 from './user/pic5.png'
import Layla from './user/LaylaImage.jpg'
import filesPic1 from './user/filesPic1.png'
import filesPic2 from './user/filesPic2.png'
import filesPic3 from './user/filesPic3.png'
import filesPic4 from './user/filesPic4.png'
import filesPic5 from './user/filesPic5.png'
import groupImage1 from './user/groupImage1.png'
import groupImage2 from './user/groupImage2.png'
import noCover from './company/cover_no_image.png'
import companyImage from './company/companyImage.png'
import likeIcon from './company/likeIcon.svg'
import searchIcon from './company/searchIcon.svg'
import booksIcon from './company/booksIcon.svg'
import createdIcon from './company/createdIcon.svg'
import emailIcon from './company/emailIcon.svg'
import phoneIcon from './company/phoneIcon.svg'
import siteIcon from './company/siteIcon.svg'
import likeInfoIcon from './company/likeInfoIcon.svg'
import externalLinkSmall from './company/externalLinkSmall.svg'
import Abdullah from './company/Abdullah.png'
import Alexander from './company/Alexander.png'
import Dickel from './company/Dickel.png'
import groupCover from './group/groupCover.png'
import clickIcon from './group/clickIcon.svg'
import plusIcon from './group/plusIcon.svg'
import atIcon from './group/atIcon.svg'
import thingsToDo from './group/thingsToDo.svg'
import usersMember from './group/usersMember.svg'
import chatIcon from './job/chatIcon.svg'
import favoriteIcon from './job/favoriteIcon.svg'
import shareIcon from './job/shareIcon.svg'
import companyLikeIcon from './search/likeIcon.svg'
import messages from './toplayout/messages.svg'
import divisionImage from './division/divisionImage.svg'
import cross from './post/cross.svg'
import globalIcon from './post/globalIcon.svg'
import tagFriends from './post/tagFriends.svg'
import addPictures from './post/addPictures.svg'
import addLocation from './post/addLocation.svg'
import addPoll from './post/addPoll.svg'
import picturesPostComponent from'./post/picturesPostComponent.svg'
import clipPostComponent from './post/clipPostComponent.svg'
import statisticsPostComponent from './post/statisticsPostComponent.svg'
import redPin from './job/pin.png'
import defaultProfile from './user/defaultPhotoProfile.webp'
import defaultCover from './user/defaultCover.jpg'
import RemoveIcon from '@mui/icons-material/Remove';

const Images = {
	loginBanner: LOGIN_BANNER,
	logo: LOGO,
	facebookLogo: FACEBOOK_LOGO,
	googleLogo: GOOGLE_LOGO,
	espaLogo: ESPA_LOGO,
	logoLayout: LOGO_PNG,
	search: SEARCH,
	bank: BANK,
	bell_ring: BELL_RING,
	caret_down: CARET_DOWN,
	search_alt: SEARCH_ALT,
	user: USER,
	home: HOME,
	envelop: ENVELOP,
	chart_network: CHART_NETWORK,
	building: BUILDING,
	userCoverPage: USERCOVERPAGE,
	userImageProfile: USERIMAGEPROFILE,
	add_user: ADD_USER,
	message:MESSAGE,
	more:MORE,
	star:STAR,
	ecdl:ECDL,
	market:MARKET,
	pmp:PMP,
	pmp2:PMP2,
	notificationAvatar:notificationAvatar,
	threeDots:threeDots,
	pic1: pic1,
	pic2: pic2,
	pic3: pic3,
	pic4: pic4,
	pic5: pic5,
	Layla: Layla,
	filesPic1: filesPic1,
	filesPic2: filesPic2,
	filesPic3: filesPic3,
	filesPic4: filesPic4,
	filesPic5: filesPic5,
	groupImage1: groupImage1,
	groupImage2: groupImage2,
	noCover: noCover,
	companyImage: companyImage,
	likeIcon: likeIcon,
	searchIcon: searchIcon,
	booksIcon: booksIcon,
	createdIcon: createdIcon,
	emailIcon: emailIcon,
	phoneIcon: phoneIcon,
	siteIcon: siteIcon,
	likeInfoIcon: likeInfoIcon,
	externalLinkSmall: externalLinkSmall,
	Abdullah: Abdullah,
	Alexander: Alexander,
	Dickel: Dickel,
	groupCover: groupCover,
	clickIcon: clickIcon,
	plusIcon: plusIcon,
	removeIcon: RemoveIcon,
	atIcon: atIcon,
	thingsToDo: thingsToDo,
	usersMember: usersMember,
	chatIcon: chatIcon,
	favoriteIcon:favoriteIcon,
	shareIcon:shareIcon,
	companyLikeIcon:companyLikeIcon,
	messages:messages,
	divisionImage:divisionImage,
	cross: cross,
	globalIcon: globalIcon,
	tagFriends: tagFriends,
	addPictures: addPictures,
	addLocation: addLocation,
	addPoll: addPoll,
	picturesPostComponent: picturesPostComponent,
	clipPostComponent: clipPostComponent,
	statisticsPostComponent: statisticsPostComponent,
	redPin:redPin,
	defaultProfile:defaultProfile,
	defaultCover:defaultCover
	

	
}

export default Images