// @flow
import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {Button, Divider, FormControl, FormHelperText, TextField, Typography} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import useUserAction from "../../../hooks/user/useUserAction";
import {enqueueSnackbar} from "notistack";
import { useMutation, useQuery } from "react-query";
import { addRecommendation, editRecommendation, getFriendRecommendations } from "../../../api/requests";
type Props = {};

const EditRecommendation = ({id, userId, data, onClose}) => {

	const {t} = useTranslation(PAGE_PERSONAL)
	const {setUserMessage,getUserRecommendationsData} = useUserAction()
	const {accessCode, message} = useUserData()
	
	// useEffect(() => {
	// 		recommendationWithId(id)
	//
	// 	console.log('recData',recommendationWithIdData.title)
	// }, [recommendationWithId])

	const validationSchema = yup.object({
		title: yup
		.string()
		.required(t('required')),
	})

	const {
		refetch
	} = useQuery(["getFriendRecommendations", accessCode, userId], getFriendRecommendations);
	const { mutate: editRecommendationAction } = useMutation({
		mutationFn: editRecommendation,
		onSuccess: (data, variables, context) => {
			refetch()
			onClose()
		},
	});
	const onSubmit = (values) => {
		editRecommendationAction({form:{...values,"idd":id},accessCode});
	};
	const formik = useFormik({
		initialValues: {
			id:id,
			title: data.title,
			description: data.description
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	});
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			
			setUserMessage(null)
		
			if (message.text === "data_saved") {
				onClose()
				// window.location.reload();
				getUserRecommendationsData()
			}
		}
	}, [message, setUserMessage, t])
	
	return (
		
		<FormikProvider value={formik} onSubmit={formik.handleSubmit}
						noValidate>
			<Box component="form"
				 onSubmit={formik.handleSubmit}
				 noValidate
				 sx={{
					 bgcolor: "background.paper",
					 boxShadow: 24,
					 height: "auto",
					 padding: '16px',
					 width: {xs: "95%", sm: "80%", md: "70%", lg: "60%"},
					 maxWidth: "960px",
					 margin: "auto",
				 }}
			>
				<Box className={"divisionTitle"}>
					<Typography
						sx={{
							color: "#050505",
							fontFamily: '"Segoe UI-Bold", Helvetica',
							fontSize: {xs: "15px", md: "21px"},
							fontWeight: "700",
							marginBottom: {xs: "5px", md: "10px"},
						}}
					>
						{t('add recomendation')}
					</Typography>
					
					<Button
						onClick={onClose}
						size="small"
						sx={{
							borderRadius: "16px",
							height: "32px",
							width: "32px",
							marginTop: {xs: "5px", sm: "0"},
							marginBottom: {xs: "10px", sm: "0"},
							marginLeft: {xs: "10px", sm: "0"},
						}}
					>
						<ClearIcon sx={{color: '#616161'}}/>
					</Button>
				</Box>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: 2
					}}
					error={
						formik.touched['title'] &&
						Boolean(formik.errors['title'])
					}
				>
					<TextField
						id={"title"}
						value={formik.values.title}
						required
						name={"title"}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('title')}
						size={"small"}
						inputProps={{maxLength: 64}}
						variant="outlined"
						sx={{
							width: '100%',
							display: 'flex',
							"& .MuiOutlinedInput-root": {
								":focus-within": {
									border: "0.0px solid #ffffff !important",
								},
							},
							"& .MuiFormLabel-root": {
								color: "#616161",
								fontSize: "15px",
								fontWeight: 400,
							},
							marginBottom: "10px",
						}}
					/>
					<FormHelperText>
						<ErrorMessage name={'title'}/>
					</FormHelperText>
				</FormControl>
				
				<TextField
					id={"description"}
					value={formik.values.description}
					name={"description"}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					// label={t('description')}
					placeholder={t('Provide more information about your cooperation with the user, such as the object of cooperation, the period, etc.')}
					size={"large"}
					variant="outlined"
					multiline
					rows={4}
					sx={{
						width: '100%',
						display: 'flex',
						"& .MuiOutlinedInput-root": {
							":focus-within": {
								border: "0.0px solid #ffffff !important",
							},
						},
						"& .MuiFormLabel-root": {
							color: "#616161",
							fontSize: "15px",
							fontWeight: 400,
						},
						marginBottom: "10px",
					}}
				/>
				
				
				<Divider sx={{marginY: 2}}/>
				<Box sx={{justifyContent: 'end', display: 'flex'}}>
					<Button
						type={'submit'}
						variant="outlined"
						sx={{
							backgroundColor: "#27293d",
							borderRadius: "4px",
							fontFamily: '"Open Sans-Bold", Helvetica',
							fontSize: "15px",
							fontWeight: 700,
							color: "#ffffff",
							"&:hover": {
								backgroundColor: "#27293d",
							},
							width: {xs: "100%", md: "35%"},
							minWidth: "160px",
						}}
					>
						{t('share')}
					</Button>
				</Box>
			
			
			</Box>
		</FormikProvider>
	);
	
};

export default EditRecommendation