import useUserData from "../../hooks/user/useUserData";
import { useLocation } from "react-router";
import useQueryParams from "../../hooks/useQueryParams";


const Education = () => {
  const { accessCode } = useUserData();
  let params= useQueryParams();
  return(
      <iframe style={{flex:'1 1 auto',border:'none',width:'100%',height:`100%`}} src={`https://dev.ostrialinked.gr/api/mdl.php?scode=${accessCode}${params.get('courseId')? '&courseId='+ params.get('courseId') : ''}`} title="Moodle"></iframe>
  )
}

export default Education