import {
	Button,
	Divider, FormControl, FormHelperText,
	TextField,
	Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import React, {useEffect} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import useUserAction from "../../../hooks/user/useUserAction";
import useUserData from "../../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import './createDivision.css'
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { addRecommendation, deleteCompany, getFriendRecommendations, getMoodleLessons } from "../../../api/requests";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_COMPANIES } from "../../../routers/routes";

const CreateDivision = ({openPopUp, onClose,id}) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const { setUserMessage} = useUserAction()
	const {message,accessCode} = useUserData();
	
	
	const validationSchema = yup.object({
		title: yup
		.string()
		.required(t('required')),
	})

	const {
		refetch
	} = useQuery(["getFriendRecommendations", accessCode, id], getFriendRecommendations);
	const { mutate: addRecommendationAction } = useMutation({
		mutationFn: addRecommendation,
		onSuccess: (data, variables, context) => {
			refetch()
			onClose()
		},
	});
	const onSubmit = (values) => {
		addRecommendationAction({form:{...values,"id_user_put":id},accessCode});
	};
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			setUserMessage(null)
			if (message === "data_saved") {
				// window.location.href = getRouteUrl(ROUTE_PAGE_HOME)
			}
		}
	}, [message, setUserMessage, t])
	const formik = useFormik({
		initialValues: {
			title: "",
			description: "",
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	});

	return (
		<FormikProvider value={formik} onSubmit={formik.handleSubmit}
						noValidate>
			<Box component="form"
				 onSubmit={formik.handleSubmit}
				 noValidate
				 sx={{
					 bgcolor: "background.paper",
					 height: "auto",
					 padding: '16px',

					 margin: "auto",
				 }}
			>
				<Box className={"divisionTitle"}>
					<Typography
						sx={{
							color: "#050505",
							fontFamily: '"Segoe UI-Bold", Helvetica',
							fontSize: {xs: "15px", md: "21px"},
							fontWeight: "700",
							marginBottom: {xs: "5px", md: "10px"},
						}}
					>
						{t('add recomendation')}
					</Typography>

				</Box>
				<FormControl
					variant="standard"
					fullWidth
					sx={{
						marginBottom: 2
					}}
					error={
						formik.touched['title'] &&
						Boolean(formik.errors['title'])
					}
				>
					<TextField
						id={"title"}
						value={formik.values.title}
						required
						name={"title"}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						label={t('title')}
						size={"small"}
						inputProps={{maxLength: 64}}
						variant="outlined"
						sx={{
							width: '100%',
							display: 'flex',
							"& .MuiOutlinedInput-root": {
								":focus-within": {
									border: "0.0px solid #ffffff !important",
								},
							},
							"& .MuiFormLabel-root": {
								color: "#616161",
								fontSize: "15px",
								fontWeight: 400,
							},
							marginBottom: "10px",
						}}
					/>
					<FormHelperText>
						<ErrorMessage name={'title'}/>
					</FormHelperText>
				</FormControl>
				
				<TextField
					id={"description"}
					value={formik.values.description}
					name={"description"}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					// label={t('description')}
					placeholder={t('Provide more information about your cooperation with the user, such as the object of cooperation, the period, etc.')}
					size={"large"}
					variant="outlined"
					multiline
					rows={4}
					sx={{
						width: '100%',
						display: 'flex',
						"& .MuiOutlinedInput-root": {
							":focus-within": {
								border: "0.0px solid #ffffff !important",
							},
						},
						"& .MuiFormLabel-root": {
							color: "#616161",
							fontSize: "15px",
							fontWeight: 400,
						},
						marginBottom: "10px",
					}}
				/>
				
				
				<Divider sx={{marginY: 2}}/>
				<Box sx={{justifyContent: 'end', display: 'flex'}}>
					<Button
						type={'submit'}
						variant="outlined"
						sx={{
							backgroundColor: "#27293d",
							borderRadius: "4px",
							fontFamily: '"Open Sans-Bold", Helvetica',
							fontSize: "15px",
							fontWeight: 700,
							color: "#ffffff",
							"&:hover": {
								backgroundColor: "#27293d",
							},
							width: {xs: "100%", md: "35%"},
							minWidth: "160px",
						}}
					>
						{t('share')}
					</Button>
				</Box>
			
			
			</Box>
		</FormikProvider>
	);
};

export default CreateDivision;
