// @flow
import React, { useCallback, useState, useEffect } from "react";
import useUserData from "../../hooks/user/useUserData";

import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Stack,
} from "@mui/material";

const ChatSidebarConversationCardsList = ({
  conversationHistoryData,
  onCardClick,
  resetSelectedCardIndex,
}) => {
  const { userId } = useUserData();
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (
      (!selectedCard && !!conversationHistoryData) ||
      !!resetSelectedCardIndex
    ) {
      setSelectedCard(0);
    }
  }, [conversationHistoryData, selectedCard, resetSelectedCardIndex]);

  const getReceiverInfo = useCallback(
    (conversation) => {
      const userInfo = {
        name: "",
        lastname: "",
        id: null,
        imageUrl: "",
      };
      if (userId === conversation.id_receiver) {
        userInfo.name = conversation.sender_firstname;
        userInfo.lastname = conversation.sender_lastname;
        userInfo.id = conversation.id_sender;
        userInfo.imageUrl = conversation.sender_image;
      } else {
        userInfo.name = conversation.receiver_firstname;
        userInfo.lastname = conversation.receiver_lastname;
        userInfo.id = conversation.id_receiver;
        userInfo.imageUrl = conversation.receiver_image;
      }

      return userInfo;
    },
    [userId]
  );

  console.log(
    "[ChatSidebarConversationCardsList]conversationsData->",
    conversationHistoryData
  );

  console.log("[ChatSidebarConversationCardsList]userId->", userId);

  return (
    <List
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        "&.MuiList-padding": {
          "*::-webkit-scrollbar": {
            width: "8px",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "*::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        },
      }}
    >
      {conversationHistoryData?.map((conversation, index) => (
        <span key={index}>
          <ListItemButton
            disabled={conversation.isActive}
            sx={{
              pl: 0,
              pr: 1,
              width: "100%",
              borderRadius: 2,
              backgroundColor:
                selectedCard === index ? "lightgray" : "transparent",
            }}
            onClick={() => {
              const { id, name, lastname, imageUrl } =
                getReceiverInfo(conversation);
              onCardClick({
                receiverId: id,
                receiverFullName: `${name} ${lastname}`,
                receiverImageUrl: imageUrl,
              });

              setSelectedCard(index);
            }}
          >
            <ListItem sx={{ py: 0, pr: 0 }}>
              <ListItemAvatar>
                <Badge
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  variant="dot"
                  invisible={
                    !parseInt(conversation.is_last_message_unread) &&
                    parseInt(userId) === parseInt(conversation.id_sender)
                  }
                >
                  <Avatar src={conversation.receiver_image} />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "calc(100% - 100px)",
                        fontWeight: conversation.is_last_message_unread
                          ? "bold"
                          : "normal",
                      }}
                    >
                      {getReceiverInfo(conversation).name}{" "}
                      {getReceiverInfo(conversation).lastname}
                    </Typography>
                    <Typography
                      component="div"
                      style={{
                        fontSize: "small",
                        marginLeft: "10px",
                        minWidth: "100px",
                      }}
                    >
                      {conversation.last_datetime}
                    </Typography>
                  </Stack>
                }
                secondary={
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {conversation.id_sender === userId
                      ? `You: ${conversation.last_message}`
                      : conversation.last_message}
                  </div>
                }
              />
            </ListItem>
          </ListItemButton>
        </span>
      ))}
    </List>
  );
};

export default ChatSidebarConversationCardsList;
