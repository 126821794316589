import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import useUserAction from "../../hooks/user/useUserAction";
import {useTranslation} from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import {Button, Collapse, List, ListItem, ListItemButton, Popover, Typography} from "@mui/material";
import useUserData from "../../hooks/user/useUserData";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import {useMemo} from "react";
import {grey} from "@mui/material/colors";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_COMPANY_CREATE,
	ROUTE_PAGE_CONTACT, ROUTE_PAGE_EDIT_USER_DETAILS, ROUTE_PAGE_FAQ, ROUTE_PAGE_GROUPS_CREATE,
	ROUTE_PAGE_PERSONAL,
	ROUTE_PAGE_TERMS_OF_USE,
} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import Images from "../../assets";
import './accountMenu.css'
import {
	Add,
	Edit,
	ExpandLess,
	ExpandMore,
	Help,
	Info, PriorityHigh, PriorityHighOutlined,
	QuestionMark,
	QuestionMarkOutlined,
	QuestionMarkRounded,
} from "@mui/icons-material";


export default function AccountMenu() {
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	const {data,userProfileImg} = useUserData()
	const {username} = data
	const {userLogout} = useUserAction()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const userNameFirstLetter = useMemo(() => (data?.username?.[0]?.toUpperCase()), [data])
	
	const [itemOpen, setItemOpen] = React.useState(false);
	const handleItemClick = () => {
		setItemOpen(!itemOpen);
	};



	return (
		<React.Fragment>
			<Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
				<IconButton
					onClick={() => history.push(getRouteUrl(ROUTE_PAGE_PERSONAL, {username: data.username}))}
					size="small"
					sx={{ml: 2, marginLeft: '0px'}}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				>
					{(userProfileImg.photoprofile) ?
						<Avatar sx={{width:26 , height: 26}} src={userProfileImg.photoprofile}></Avatar>
						:
						<Avatar
							sx={{bgcolor: grey[900], width: 26, height: 26}}>{userNameFirstLetter}</Avatar>
					}
					
				</IconButton>
				<Tooltip title={t("Account settings")}>
					<IconButton
						onClick={handleClick}
						sx={{
							'&:hover': {
								backgroundColor: 'transparent'
							}
						}}
					>
						{/*<span className="accountName">{username}</span>*/}
						<img className="caret_down" alt="carret_down_png" src={Images.caret_down}/>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu-header"
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
			>
				
				
				<ListItemButton onClick={handleItemClick}>
					<ListItemIcon>
						<Add/>
					</ListItemIcon>
					<ListItemText primary={t('add')}/>
					{itemOpen ? <ExpandLess/> : <ExpandMore/>}
				</ListItemButton>
				<Collapse in={itemOpen} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItemButton sx={{pl: 4}}
										onClick={() => history.push(getRouteUrl(ROUTE_PAGE_GROUPS_CREATE))}>
							<ListItemIcon>
								<img src={Images.chart_network}/>
							</ListItemIcon>
							<ListItemText primary={t('group')}/>
						</ListItemButton>
						<ListItemButton sx={{pl: 4}}
										onClick={() => history.push(getRouteUrl(ROUTE_PAGE_COMPANY_CREATE))}>
							<ListItemIcon>
								<img src={Images.building}/>
							</ListItemIcon>
							<ListItemText primary={t('company')}/>
						</ListItemButton>
					</List>
				</Collapse>
				
				<ListItemButton onClick={() => history.push(getRouteUrl(ROUTE_PAGE_EDIT_USER_DETAILS))}>
					<ListItemIcon>
						<Edit/>
					</ListItemIcon>
					<ListItemText
						primary={t('edit details')}
					/>
				</ListItemButton>
				<ListItemButton onClick={() => history.push(getRouteUrl(ROUTE_PAGE_TERMS_OF_USE))}>
					<ListItemIcon>
						<Info/>
					</ListItemIcon>
					<ListItemText
						primary={t('ostria info')}
					/>
				</ListItemButton>
				<ListItemButton onClick={() => history.push(getRouteUrl(ROUTE_PAGE_FAQ))}>
					<ListItemIcon>
						<Help/>
					</ListItemIcon>
					<ListItemText
						primary={t('faq')}
					/>
				</ListItemButton>
				<ListItemButton onClick={() => history.push(getRouteUrl(ROUTE_PAGE_CONTACT))}>
					<ListItemIcon>
						<PriorityHigh/>
					</ListItemIcon>
					<ListItemText
						primary={t('help')}
					/>
				</ListItemButton>
				
				<Typography sx={{border: '1px #F3F3F3 solid'}}/>
				<ListItemButton onClick={userLogout}>
					<ListItemIcon>
						<Logout fontSize="small"/>
					</ListItemIcon>
					<ListItemText
						primary={t('Logout')}
					/>
				</ListItemButton>
			
			
			</Menu>
		</React.Fragment>
	)
}
