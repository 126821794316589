// @flow
import {Divider, Grid, Modal, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";

import './footer.css'

import React, {useState} from "react";
import Terms from "../terms/terms";
import axios from "axios";
import * as yup from "yup";
import {useFormik, FormikProvider} from "formik";
import ContactForm from "../contactForm/contactForm";
import OstriaModal from "../ostriaModal/ostriaModal";

const Footer = ({color = '#FFFFFF'}) => {
    const [openUploadModal, setOpenUploadModal] = useState(null)

    const onSubmit = (values) => {
        axios({
            url: "https://dev.ostrialinked.gr/api/api.php?q=301",
            method: 'post',
            data: values,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setOpenUploadModal(null)
        }).catch((error) => {

        })
    }
    const validationSchema = yup.object({
        fullname: yup
            .string()
            .required(),
        email: yup
            .string()
            .required(),
    })
    const formik = useFormik({
        initialValues: {
            fullname: '',
            email: '',
            phone: '',
            sxolio: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
    })
    const textareaStyle = {
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontFamily: '"Open Sans-Italic", Helvetica',
        fontSize: '18px',
        fontStyle: 'italic',
        fontWeight: 400,
        height: '200px',
        width: '100%',
        overflow: 'auto',
        marginBottom: '10px',
        borderColor: '#ffffff',
    };

    return (
        <Box className={'footer'}>
            <OstriaModal
                open={Boolean(openUploadModal)}
                onClose={() => {
                    if (openUploadModal === 'contact') {
                        formik.resetForm()
                    }
                    setOpenUploadModal(null)
                }}
                boxStyle={openUploadModal !== 'terms'? {backgroundColor: '#27293d'}: {}}
            >
                <>
                    {openUploadModal === 'terms' ?
                        <Terms/>
                        :
                        <FormikProvider value={formik} onSubmit={formik.handleSubmit}
                                        noValidate>
                            <ContactForm formik={formik} textareaStyle={textareaStyle}/>
                        </FormikProvider>}
                </>
            </OstriaModal>
            <Grid container columnGap={3} width={'auto'}>
                <Grid item>
                    <Typography
                        onClick={() => setOpenUploadModal('terms')}
                        component={"span"}
                        color={color}
                        sx={{
                            fontSize: '13px',
                            cursor: 'pointer'
                        }}
                    >
                        Όροι χρήσης
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        onClick={() => setOpenUploadModal('contact')}
                        component={"span"}
                        color={color}
                        sx={{
                            fontSize: '13px',
                            cursor: 'pointer'
                        }}
                    >
                        Επικοινωνία
                    </Typography>
                </Grid>
            </Grid>
            <Box>
                <Typography
                    component={"span"}
                    color={color}
                    sx={{
                        fontSize: '13px'
                    }}
                >
                    ostrialinked.gr&nbsp;&nbsp;© 2024
                </Typography>
            </Box>
        </Box>
    )
};

export default Footer