// @flow
import React, {useEffect} from 'react';
import FeedLayout from "../../components/layouts/feedLayout/feedLayout";
import UserFeeds from "../../components/userFeeds/userFeeds";
import useUserAction from "../../hooks/user/useUserAction";
import {getUserProfileImg} from "../../actions/currentUser/actions";
import useUserData from "../../hooks/user/useUserData";

const Home = () => {
    const {getAllPosts} = useUserAction();
    const {allPosts} = useUserData();
    useEffect(() => {
            getAllPosts()
    }, [getAllPosts])

    return (
        <FeedLayout>
            <UserFeeds posts={allPosts}/>
        </FeedLayout>
    )
}

export default Home
