import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import './rightTabs.css'
import useUserData from "../../../hooks/user/useUserData";
import axios from "axios";
import {useSelector, useStore} from "react-redux";


const GeneralDetailsTab = (props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const {accessCode} = useUserData();
	const [generalDetailsData, setGeneralDetailsData] = useState({})
	const [loading, setLoading] = useState(false)
	
	useEffect(() => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=20c&scode=" + accessCode,
			method: 'post',
			data:{'idu':props.userId},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log(response)
		
			setGeneralDetailsData(response.data[0])
			setLoading(true)
			
			// debugger
			
		}).catch((error) => {
		
		})
	}, [accessCode,props.userId]);
	
	return (
		<>
			{loading &&
				<Box className="rightTab" sx={{backgroundColor: 'white', borderRadius: '5px', marginBottom: '5px'}}>
					<Typography>
						{t('general details')}
					</Typography>
					<Box className="rightTabDetails">
						<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{generalDetailsData?.city}, {generalDetailsData?.country}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{generalDetailsData?.family_state}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{generalDetailsData?.work_state}</Typography>
					</Box>
				</Box>
			}
		</>
	);
};

export default GeneralDetailsTab