// @flow
import React from 'react';
import {ErrorMessage, FormikProps, FormikProvider} from "formik";
import Box from "@mui/material/Box";
import {Button, FormControl, FormHelperText, OutlinedInput, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_RESET_PASSWORD} from "../../../locales/pages/namespaces";
import './form.css';
import {LoadingButton} from "@mui/lab";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN, ROUTE_PAGE_SIGN_UP} from "../../../routers/routes";
import {useHistory} from "react-router-dom";

type Props = {
	formik: FormikProps,
	loading: Boolean
};

const Form = (props: Props) => {
	const {formik, loading} = props
	const {t} = useTranslation(PAGE_RESET_PASSWORD)
	const history = useHistory()
	
	return (
		<FormikProvider value={formik}>
			<Box
				className={'reset-password-form'}
				sx={{
					padding: {
						xs: '6px 14px',
						sm: '12px 28px',
						md: '46px'
					}
				}}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Typography
					sx={{
						marginBottom: 1,
						color: '#050505',
						fontSize: '20px',
						fontWeight: '300',
						lineHeight: '40px',
						letterSpacing: 0,
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: '"Open Sans-Light", Helvetica',
						paddingBottom: '30px'
					}}
				>
					{t('Account Recovery')}
				</Typography>
				<Typography
					sx={{
						color: '#050505',
						fontSize: '15px',
						fontWeight: '300',
						lineHeight: '20px',
						letterSpacing: 0,
						fontFamily: '"Open Sans-Light", Helvetica',
						paddingBottom: '20px'
				
					}}
				>
					{t('Enter your account email address and a password recovery email will be sent to you')}
				</Typography>
				<Box
					className={'reset-password-inputs-container'}
					sx={{
						paddingTop: '20px'
					}}
				>
					<FormControl
						variant="standard"
						fullWidth
						sx={{
							marginBottom: 2
						}}
						error={
							formik.touched['email'] &&
							Boolean(formik.errors['email'])
						}
					>
						<TextField
							id={'email'}
							value={formik.values.email}
							name={'email'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							label={t('Email Address')}
							size={'small'}
							inputProps={{maxLength: 250}}
							variant="outlined"
							required
							sx={{
								'& .MuiOutlinedInput-root': {
									':focus-within': { border: '0.0px solid #ffffff !important' }
								},
							}}
						/>
						<FormHelperText>
							<ErrorMessage name={'email'}/>
						</FormHelperText>
					</FormControl>
					<LoadingButton
						loading={loading}
						fullWidth
						variant="contained"
						// onClick = {}
						type={'submit'}
						sx={{
							paddingTop: '10px',
							paddingBottom: '10px',
						}}
					>
						{t('I want a new password')}
					</LoadingButton>
					<Typography
						sx={{
							color: '#050505',
							fontSize: '20px',
							fontWeight: '300',
							lineHeight: '40px',
							letterSpacing: 0,
							textAlign: 'center',
							width: '395px',
							height: '40px',
							marginTop: '60px',
							fontFamily: '"Open Sans-Light", Helvetica'
						}}
					>
						{t('Άλλες επιλογές')}
					</Typography>
					<Button
						fullWidth
						variant="outlined"
						sx={{
							borderColor: '#2682d580',
							margin: '10px',
						}}
						onClick={() => history.push(getRouteUrl(ROUTE_PAGE_SIGN_UP))}
					>
						{t('Create an account')}
					</Button>
					<Button
						fullWidth
						variant="outlined"
						sx={{
							borderColor: '#2682d580',
							margin: '10px'
						}}
						onClick={() => history.push(getRouteUrl(ROUTE_PAGE_LOGIN))}
					>
						{t('Login to the system')}
					</Button>
					
				</Box>
			</Box>
		</FormikProvider>
	);
};

export default Form