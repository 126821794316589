// @flow
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import GeneralDetailsTab from "../rightTabs/generalDetailsTab";
import RecommendationTab from "../rightTabs/recommendationTab";
import FileTab from "../rightTabs/fileTab";
import FriendsTab from "../rightTabs/friendsTab";
import Images from "../../../assets";
import { Button, ButtonBase, CardContent, CardMedia, Typography, useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import "./groupsTab.css";
import Link from "@mui/material/Link";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_GROUP, ROUTE_PAGE_GROUPS_CREATE, ROUTE_PAGE_PERSONAL } from "../../../routers/routes";
import Footer from "../../footer/footer";
import { useHistory } from "react-router-dom";
import { Done } from "@mui/icons-material";
import theme from "../../../theme/theme";
import { useQuery } from "react-query";
import { getPersonalGroups } from "../../../api/requests";
import useUserData from "../../../hooks/user/useUserData";
import ContentLoader from "../../contentLoader/contentLoader";

type Props = {};

const GroupsTab = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { accessCode } = useUserData();
  const { data: groupsData, isLoading } = useQuery({
    queryKey: ["getPersonalGroups", accessCode],
    queryFn: getPersonalGroups,
  });


  return (
    <>
      <Box className="centerLeftTab"
           sx={{
             width: {
               xs: "100%",
               sm: "100%",
               md: "60%",
               lg: "60%",
             },
           }}
      >
        <Box className="centerLeftTab">
          <Box className="tabTitle">
            <Typography sx={{ padding: "5px" }}>
              {t("groups")}
            </Typography>
            <Typography sx={{ marginTop: "1%" }}>
              <Link href={getRouteUrl(ROUTE_PAGE_GROUPS_CREATE)} underline="hover" sx={{
                fontSize: "smaller",
                color: "#27293D",
                fontFamily: "Open Sans-Regular",
              }}>{t("create a new group")}</Link>
            </Typography>

          </Box>

          <Typography sx={{ border: "1px #F3F3F3 solid" }} />
        </Box>
        <Box className={"groupsContainer"}>
          {!isLoading ?
            groupsData?.map(group => (
                <Card
                  key={group.id}
                  elevation={0}
                  sx={{
                    border: "none",
                    maxWidth: 300,
                    width: {
                      xs: "100%",
                      sm: "49%",
                      md: "49%",
                      lg: "49%",
                    },
                  }}

                >
                  <CardMedia
                    sx={{ height: 100 }}
                    image={group.img}
                    title={group.title}
                    onClick={() => history.push(getRouteUrl(ROUTE_PAGE_GROUP, { groupCode: group.id }))}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {group.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {group.description}
                    </Typography>
                  </CardContent>
                </Card>
              ),)
            :
            <ContentLoader loading={isLoading}></ContentLoader>
          }
        </Box>
      </Box>
      {!matches && (
        <Box sx={{ width: "40%" }}>
          <GeneralDetailsTab userId={props.id} />
          {/*<RecommendationTab userId={props.id} />*/}
          <FileTab userId={props.id} />
          <FriendsTab userId={props.id} />
          <Footer color={"#aeacac"} />
        </Box>
      )}
    </>

  );
};

export default GroupsTab;