// @flow
import React, {useEffect, useState} from 'react';
import './userProfileHeader.css'
import {useTranslation} from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from "@mui/material/Avatar";
import {Button, IconButton, ListItemButton, Modal, Typography} from "@mui/material";
import Popover from '@mui/material/Popover'
import Box from "@mui/material/Box";
import {
    AccountCircle,
    Edit,
    ImageSearch,
    MailOutline,
    PersonAddAlt1Outlined,
    PhotoCamera,
    StarRate
} from "@mui/icons-material";
import {PAGE_PERSONAL} from "../../locales/pages/namespaces";
import useUserData from "../../hooks/user/useUserData";
import Images from "../../assets";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import UploadImage from "../uploadImage/uploadImage";
import ListItemIcon from "@mui/material/ListItemIcon";
import ReportIcon from "@mui/icons-material/Report";
import ListItemText from "@mui/material/ListItemText";
import Report from "../userMoreMenu/components/report";
import CreateDivision from "../userMoreMenu/components/createDivision";
import '../userMoreMenu/moreMenu.css'
import useUserAction from "../../hooks/user/useUserAction";
import {useQuery} from "react-query";
import {getGroupMembers, getMyGamification} from "../../api/requests";
import OstriaModal from "../ostriaModal/ostriaModal";

type Props = {};

const Personal = (props: Props) => {

    const {data, userProfileImg, userCoverImg,accessCode,userId} = useUserData()
    const {t} = useTranslation(PAGE_PERSONAL)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const {data: getMyGamificationData} = useQuery(["getMyGamification", accessCode, userId], getMyGamification);

    // const htmlText2 = "<b>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</b><i>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</i>";
    //cover - popover
    const [coverAnchorEl, setCoverAnchorEl] = React.useState(null)
    const coverOpen = Boolean(coverAnchorEl)
    const handleCoverClick = (event) => {
        setCoverAnchorEl(event.currentTarget)
    }
    const handleCoverClose = () => {
        setCoverAnchorEl(null)
    }



    //cover image full screen
    const [showFullCoverScreen, setShowFullCoverScreen] = useState(false);

    const handleShowCoverClick = () => {
        setShowFullCoverScreen(coverAnchorEl !== null ? coverAnchorEl.id !== 'cover' ?
          (userProfileImg.photoprofile) ? userProfileImg.photoprofile : Images.defaultProfile
          : (userCoverImg.photobanner) ? userCoverImg.photobanner : Images.defaultCover :Images.defaultCover);
    };

    const handleCloseCoverClick = () => {
        setShowFullCoverScreen(false);
    };


    //for upload image modal
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [imageType, setImageType] = useState(null);
    const uploadModal = () => {
        setOpenUploadModal(true);
    }


    const [openPopUp, setOpenPopUp] = React.useState(false)
    const [openReportPopUp, setOpenReportPopUp] = React.useState(false)

    const matches = useMediaQuery('(max-width:600px)');
    return (
        <Box className="profileImages">
            {/*<Box>*/}
            <OstriaModal open={showFullCoverScreen} onClose={handleCloseCoverClick}>
                <img
                  style={{width:'100%',height:'100%'}}
                  // src={Images.defaultCover}
                  src={showFullCoverScreen}
                  alt="Full Screen"
                />
            </OstriaModal>
            <div>
                <Button id={'cover'} className="userCover_png"  aria-describedby={'simple-popover-cover-profile'} onClick={handleCoverClick}>
                    <img className="userCover_png" alt="usercover_png"
                         src={(userCoverImg.photobanner) ? userCoverImg.photobanner : Images.defaultCover}/>
                </Button>
                <Popover
                    id={'simple-popover-cover-profile'}
                    open={coverAnchorEl}
                    anchorEl={coverAnchorEl}
                    onClose={handleCoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <Box sx={{display: 'grid'}}>
                        <Tooltip title={t('show cover profile')}>
                            <Button sx={{justifyContent: 'start'}} onClick={() => {
                                console.log('show image profile')
                                setCoverAnchorEl(null) //gia na kleinei to popover, otan anoigei to quickview
                                handleShowCoverClick()
                            }}>
                                <AccountCircle/>
                                {coverAnchorEl !== null ? coverAnchorEl.id !== 'cover' ? t('show image profile') : t('show cover profile') : null}
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('change image profile')}>
                            <Button sx={{justifyContent: 'start'}} onClick={() => {
                                console.log('change cover profile')
                                setCoverAnchorEl(null) //gia na kleinei to popover, otan anoigei to quickview
                                uploadModal()
                                setImageType(coverAnchorEl?.id)
                            }}>
                                <PhotoCamera/>
                                {coverAnchorEl !== null ? coverAnchorEl.id !== 'cover' ? t('change image profile') : t('change cover profile') : null}
                            </Button>
                        </Tooltip>
                    </Box>
                </Popover>
            </div>
            <OstriaModal
                open={openUploadModal}
                onClose={() => setOpenUploadModal(false)}
            >
                    <UploadImage
                      imageType={imageType}
                      onClose={() => setOpenUploadModal(false)}
                      moreInfos={imageType === "profile" ? {aspect:1} :  {aspect:3/2}}/>

            </OstriaModal>

            <Box display={'flex'} justifyContent={matches ? 'center' :'space-between'} flexDirection={matches ? 'column' : null} height={matches ? 120 : 70}>
                <Box display={'flex'} height={matches ? 80 : null} >
                    <img id={'profile'} className="userImage_png"  alt="Avatar" onClick={handleCoverClick}
                         src={(userProfileImg.photoprofile) ? userProfileImg.photoprofile : Images.defaultProfile}/>
                    <Box>
                        <Box className="userDetails">
                            <Box className="userDetailsLabels">
                                <label className="userDetailsLabel">{data['firstName']} {data['lastName']} </label>
                            </Box>
                            {/*<Box className="userDetailsButtons">*/}
                            {/*	{(sendRequest === false) ? <IconButton*/}
                            {/*			className="addUser"*/}
                            {/*			size="small"*/}
                            {/*			onClick={() => setSendRequest(true)}*/}
                            {/*			sx={{*/}
                            {/*				color: "white",*/}
                            {/*				backgroundColor: "black",*/}
                            {/*				borderRadius: '5px',*/}
                            {/*				"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},*/}
                            {/*				height: '28px',*/}
                            {/*				paddingLeft: '5px',*/}
                            {/*				marginRight: '5px',*/}
                            {/*				*/}
                            {/*			}}*/}
                            {/*		>*/}
                            {/*			<PersonAddAlt1Outlined/>*/}
                            {/*			/!*<Avatar sx={{width:'15px', height:'15px'}} src={Images.add_user}/>*!/*/}
                            {/*			<Box sx={{*/}
                            {/*				marginLeft: '3%',*/}
                            {/*				fontSize: 'smaller',*/}
                            {/*				fontFamily: 'Open Sans-Regular, Helvetica'*/}
                            {/*			}}>*/}
                            {/*				{t('add')}*/}
                            {/*			</Box>*/}
                            {/*		</IconButton>*/}
                            {/*		:*/}
                            {/*		<IconButton*/}
                            {/*			className="addUser"*/}
                            {/*			size="small"*/}
                            {/*			onClick={() => setSendRequest(false)}*/}
                            {/*			sx={{*/}
                            {/*				color: "white",*/}
                            {/*				backgroundColor: "black",*/}
                            {/*				borderRadius: '5px',*/}
                            {/*				"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},*/}
                            {/*				height: '28px',*/}
                            {/*				paddingLeft: '5px',*/}
                            {/*				marginRight: '5px',*/}
                            {/*				*/}
                            {/*			}}*/}
                            {/*		>*/}
                            {/*			<PersonAddAlt1Outlined/>*/}
                            {/*			/!*<Avatar sx={{width:'15px', height:'15px'}} src={Images.add_user}/>*!/*/}
                            {/*			<Box sx={{*/}
                            {/*				marginLeft: '5px',*/}
                            {/*				fontSize: 'smaller',*/}
                            {/*				fontFamily: 'Open Sans-Regular, Helvetica'*/}
                            {/*			}}>*/}
                            {/*				{t('cancel request')}*/}
                            {/*			</Box>*/}
                            {/*		</IconButton>*/}
                            {/*	}*/}
                            {/*	<IconButton*/}
                            {/*		className="message"*/}
                            {/*		size="small"*/}
                            {/*		sx={{*/}
                            {/*			color: "black",*/}
                            {/*			// backgroundColor: "black",*/}
                            {/*			border: '1px black solid',*/}
                            {/*			borderRadius: '5px',*/}
                            {/*			"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},*/}
                            {/*			height: '28px',*/}
                            {/*			paddingLeft: '5px',*/}
                            {/*			marginRight: '5px',*/}
                            {/*			*/}
                            {/*		}}*/}
                            {/*	>*/}
                            {/*		<MailOutline/>*/}
                            {/*		/!*<Avatar sx={{width:'15px', height:'15px'}} src={Images.message}/>*!/*/}
                            {/*		<Box sx={{*/}
                            {/*			marginLeft: '3%',*/}
                            {/*			fontSize: 'smaller',*/}
                            {/*			fontFamily: 'Open Sans-Regular, Helvetica'*/}
                            {/*		}}>*/}
                            {/*			{t('message')}*/}
                            {/*		</Box>*/}
                            {/*	</IconButton>*/}
                            {/*	<IconButton*/}
                            {/*		className="more"*/}
                            {/*		size="small"*/}
                            {/*		onClick={handleClick}*/}
                            {/*		sx={{*/}
                            {/*			// color: "black",*/}
                            {/*			// backgroundColor: "black",*/}
                            {/*			border: '1px black solid',*/}
                            {/*			borderRadius: '5px',*/}
                            {/*			"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},*/}
                            {/*			height: '28px',*/}
                            {/*			paddingLeft: '5px',*/}
                            {/*			*/}
                            {/*		}}*/}
                            {/*	>*/}
                            {/*		<Avatar sx={{width: '15px', height: '15px'}} src={Images.more}/>*/}
                            {/*	</IconButton>*/}
                            {/*	<Menu*/}
                            {/*		anchorEl={anchorEl}*/}
                            {/*		open={open}*/}
                            {/*		onClose={handleClose}*/}
                            {/*		transformOrigin={{horizontal: 'right', vertical: 'top'}}*/}
                            {/*		anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}*/}
                            {/*	>*/}
                            {/*		<ListItemButton onClick={()=>setOpenReportPopUp(true)} >*/}
                            {/*			<ListItemIcon><ReportIcon/></ListItemIcon>*/}
                            {/*			<ListItemText primary={('Report')}/>*/}
                            {/*		</ListItemButton>*/}
                            {/*		*/}
                            {/*		*/}
                            {/*		*/}
                            {/*		<ListItemButton onClick={()=>setOpenPopUp(true)} >*/}
                            {/*			<ListItemIcon><StarRate/></ListItemIcon>*/}
                            {/*			<ListItemText primary={('Διάκριση')}/>*/}
                            {/*		</ListItemButton>*/}
                            {/*	</Menu>*/}
                            {/*</Box>*/}
                        </Box>
                        <Box className="headerText">
                            <Box className="htmlText">
                                {getMyGamificationData?.description &&
                                    <Typography sx={{fontSize: 'small'}}
                                                component={"div"}
                                                dangerouslySetInnerHTML={{__html: `<div>${getMyGamificationData.description}</div>`}}
                                    />}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="headerPoints" marginRight={3} marginLeft={matches ? 15 : null}>
                    <img className="star_png" alt="star_png" src={Images.star}/>
                    {t('participation points')}: {data['pontoi']}
                </Box>
            </Box>
            {/*<Box className="extraDescriptionEditor">*/}
            {/*    <Typography sx={{fontSize: 'small'}}*/}
            {/*                component={"div"}*/}
            {/*                dangerouslySetInnerHTML={{__html: htmlText2}}*/}
            {/*    />*/}

            {/*</Box>*/}
            {/*</div>*/}
            {/*<img className="userImage_png" alt="userimage_png" src={Images.userImageProfile}/>*/}

            {/*</Box>*/}

            <OstriaModal
                open={openReportPopUp}
                onClose={() => setOpenReportPopUp(false)}
            >
                    <Report onClose={() => setOpenReportPopUp(false)}/>

            </OstriaModal>
            <OstriaModal
                open={openPopUp}
                onClose={() => setOpenPopUp(false)}
            >
                    <CreateDivision onClose={() => setOpenPopUp(false)}/>

            </OstriaModal>
        </Box>
    );
};

export default Personal