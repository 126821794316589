// @flow
import React from 'react';
import './feedLeftColumn.css'
import Box from "@mui/material/Box";
import FeedMenu from "../feedMenu/feedMenu";
import GoogleNews from "../googleNews/googleNews";
import ImportantAnnouncements from "../importantAnnouncements/importantAnnouncements";

type Props = {

};

const FeedLeftColumn = (props: Props) => {
	return (
		<Box sx={{paddingTop:'16px'}}>
			<FeedMenu/>
			<ImportantAnnouncements/>
			{/*<GoogleNews/>*/}
		</Box>
	);
};

export default FeedLeftColumn