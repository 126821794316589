// @flow
import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { PAGE_ADS, PAGE_COMPANY, PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import { Button, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  askOwnerPermissionForAds,
  deleteCompany,
  getAdsCategory,
  getCompany,
  userSpecificTypePosts,
} from "../../../api/requests";
import { useParams } from "react-router-dom";
import useUserData from "../../../hooks/user/useUserData";
import AdsForm from "../../companyAds/adsForm";
import OwnerAds from "../../companyAds/ownerAds";
import AdsList from "../../companyAds/adsList";

type Props = {};

const AdsTab = (props: Props) => {
  const { t } = useTranslation(PAGE_COMPANY);
  const { companyData } = props;
  const { id } = useParams();
  const { accessCode } = useUserData();
  const queryClient = useQueryClient();
  const { mutate: askOwnerPermissionForAdsAction } = useMutation({
    mutationFn: askOwnerPermissionForAds,
    onSuccess: (data, variables, context) => {
      let companyNewData = companyData
      companyNewData.approve = "wait";
      queryClient.setQueryData(["getCompany", accessCode, id], companyNewData);
    },
  });
  const { data: adsCategories, isLoading } = useQuery({
    queryKey: ["adsCategory", accessCode],
    queryFn: getAdsCategory,
    enabled: companyData?.approve === "true",
  });

  return (
    <>
      <Box className="centerLeftTab">
        <Box className="centerLeftTab">
          <Typography sx={{ padding: "5px" }}>
            {t("ads")}
          </Typography>
          <Typography sx={{ border: "1px #F3F3F3 solid", marginRight: "3%" }} />
        </Box>
        <Box sx={{ display: "inline" }}>
          {companyData.isOwner === "true"
            ?
            companyData.approve === "no"
              ? <Button
                onClick={()=>askOwnerPermissionForAdsAction({companyId : companyData.id,accessCode})}
                fullWidth
                variant="contained"
                color={"info"}
                sx={{
                  borderRadius: "7px",
                  padding: "10px",
                  marginTop: 2,
                }}
              >
                <span >{t("ask ads permission")}</span>
              </Button>
              : companyData.approve === "wait"
            ?
                t("wait ads permission")

            : companyData.approve === "false" ?
                  t("no permission")
            : companyData.approve === "true" ?
                    isLoading ? null : <OwnerAds adsCategories={adsCategories}/>
            : null
            : <AdsList isNotOwner/>
          }
        </Box>
      </Box>
    </>
  );
};

export default AdsTab;