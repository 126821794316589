import history from './store/history'
import {loadModules} from "./store/loadModule";
import {ConnectedRouter} from "connected-react-router";
import './App.css';
import Main from "./pages/main/main";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from './theme/theme'
import SnackbarProviderExtended from "./components/snackbarProviderExtended/snackbarProviderExtended";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import { createContext, useState } from "react";
import useUserData from "./hooks/user/useUserData";
export const ENABLED_MODULES = loadModules([])
export const ROUTES = ENABLED_MODULES.loadRoutes()


export const LocationContext = createContext(null);
function App() {
    const [value, setValue] = useState(null);
    const queryClientConfig: any = {
        defaultOptions: {
            queries: {
                retry: 1,
                staleTime: 'Infinity',
                refetchOnMount: true,
                refetchOnWindowFocus: false,
                refetchOnReconnect: 'false',
            },
        },
    };

    const queryClient: QueryClient = new QueryClient(queryClientConfig);
    return (
      <LocationContext.Provider value={{ value,setValue} }>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <SnackbarProviderExtended>
                    <ConnectedRouter history={history}>
                        <CssBaseline/>
                        <Main/>
                    </ConnectedRouter>
                </SnackbarProviderExtended>
            </ThemeProvider>
        </QueryClientProvider>
      </LocationContext.Provider>
    );
}

export default App;
