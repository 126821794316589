// @flow
type Props = {

};

const Terms = (props: Props) => {
	return (
		<div></div>
	);
};

export default Terms