// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_COMPANY, PAGE_GROUP} from "../../locales/pages/namespaces";
import useUrlLastPart from "../../hooks/useUrlLastPart";
import Box from "@mui/material/Box";
import {IconButton, Tab, Tabs} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_GROUP_ABOUT, ROUTE_PAGE_GROUP_MEMBERS,
	ROUTE_PAGE_GROUP_FILES,ROUTE_PAGE_GROUP
} from "../../routers/routes";
import Avatar from "@mui/material/Avatar";
import Images from "../../assets";
import {TabPanel} from "../tabPanel/tabPanel";
import CenterTab from "../groupProfileTabs/tabPanels/centerTab/centerTab"
import InformationTab from "./tabPanels/informationTab/informationTab";
import MemberTab from "./tabPanels/memberTab/memberTab";
import FilesTab from "./tabPanels/filesTab/filesTab";

type Props = {

};

const GroupProfileTabs = (props: Props) => {
	const {t} = useTranslation(PAGE_GROUP)
	const {groupCode} = useParams()
	let lastPartOfPath = useUrlLastPart()
	if(!['chat','about', 'members' ,'files' ].includes(lastPartOfPath)) {
		lastPartOfPath = ""
	}
	return (
		<>
			<Box sx={{
				borderBottom: 1,
				borderColor: 'divider',
				backgroundColor: 'white',
				marginTop: '2px',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignItems: 'center',
				justifyContent: 'space-between',
				
			}}>
				{/*hello*/}
				<Tabs variant="scrollable"
					  scrollButtons
					  allowScrollButtonsMobile
					  value={lastPartOfPath}
					  sx={{display: 'inline-flex'}}>
					{/*sx={{display: 'inline-flex'}}*/}
					<Tab
						component={Link}
						value={"chat"}
						to={getRouteUrl(ROUTE_PAGE_GROUP, {groupCode: groupCode})}
						label={<span className="tabLabel">{t('CHATS')}</span>}
					/>
					<Tab
						component={Link}
						value={"about"}
						to={getRouteUrl(ROUTE_PAGE_GROUP_ABOUT, {groupCode: groupCode})}
						label={<span className="tabLabel">{t('INFORMATION')}</span>}
					/>
					<Tab
						component={Link}
						value={"members"}
						to={getRouteUrl(ROUTE_PAGE_GROUP_MEMBERS, {groupCode: groupCode})}
						label={<span className="tabLabel">{t('MEMBERS')}</span>}
					/>
					{/*<Tab*/}
					{/*	component={Link}*/}
					{/*	value={"files"}*/}
					{/*	to={getRouteUrl(ROUTE_PAGE_GROUP_FILES, {groupCode: groupCode})}*/}
					{/*	label={<span className="tabLabel">{t('FILES')}</span>}*/}
					{/*/>*/}
					
				</Tabs>
			</Box>
			<Box>
				<TabPanel className="centerTabPanel" isVisible={lastPartOfPath === "chat"}>
					<CenterTab/>
				</TabPanel>
				<TabPanel className="infoTabPanel" isVisible={lastPartOfPath === "about"}>
					<InformationTab/>
				</TabPanel>
				<TabPanel className="memberTabPanel" isVisible={lastPartOfPath === "members"}>
					<MemberTab/>
				</TabPanel>

				{/*<TabPanel className="fileTabPanel" isVisible={lastPartOfPath === "files"}>*/}
				{/*	<FilesTab/>*/}
				{/*</TabPanel>*/}
			</Box>
		</>
	);
};

export default GroupProfileTabs