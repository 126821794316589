import router from '../routers/routes'
import componentLocales from '../locales/components/locales'
import pageLocales from '../locales/pages/locales'
import api from "../api/api"
import userReducer from "../reducer/currentUser/currentUser"

export const loadModules = (modules) => {
    return ({
            loadPermissions: () => {
                return modules.reduce((prv, curr) => {
                    const {permissions, modules} = curr
                    if (!permissions) return prv
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).loadPermissions() : {}),
                        ...permissions,
                    })
                }, {})
            },
            loadRoutes: () => {
                const applicationRoutes = router ? router : {}
                const routes = modules.reduce((prv, curr) => {
                    const {routes, modules} = curr
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).loadRoutes() : {}),
                        ...(routes ? routes : {}),
                    })
                }, applicationRoutes)
                return Object.keys(routes).map(key => routes[key])
            },
            getTranslations: () => {
                return modules.reduce((prv, curr) => {
                    const {name, locales, modules} = curr
                    if (!locales) return prv
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).getTranslations() : {}),
                        ...(locales ? {[name]: locales} : {}),
                    })
                }, {
                    component_translations: componentLocales,
                    page_translations: pageLocales,
                })
            },
            getRequestHandlers: () => {
                return modules.reduce((prv, curr) => {
                    const {name, api, modules} = curr
                    if (!api) return prv
                    return ({
                        ...prv,
                        ...(modules ? loadModules(modules).getRequestHandlers() : {}),
                        [name]: api,
                    })
                }, {
                    app_request: api,
                })
            },
            loadReducers: () => {
                return modules.reduce((prv, curr) => {
                    const {name, reducer, modules} = curr
                    if (!reducer) return prv
                    return {
                        ...prv,
                        ...(modules ? loadModules(modules).loadReducers() : {}),
                        [name]: {
                            initialState: reducer.initialState,
                            handlers: reducer.handlers
                        },
                    }
                }, {
                    currentUser: userReducer
                })
            }
        }
    )
}
