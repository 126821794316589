//@flow
import Box from "@mui/material/Box";
import React from "react";

type Props = {
    isVisible: boolean
}

export const TabPanel = (props: Props) => {
    const {children, isVisible, ...other} = props;
    return (
        <Box
            role="tabpanel"
            hidden={!isVisible}
            {...other}
        >
            {isVisible ? children : null}
        </Box>
    )
}
