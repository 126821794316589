// @flow
import React, { useContext } from "react";
import "./header.css";
import Images from "../../assets";
import { AppBar, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import Search from "../search/search";
import Logo from "../logo/logo";
import AccountMenu from "../accountMenu/accountMenu";
import Box from "@mui/material/Box";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_CHAT,
  ROUTE_PAGE_COMPANIES,
  ROUTE_PAGE_GROUPS,
  ROUTE_PAGE_HOME,
  ROUTE_PAGE_JOBS, ROUTE_PAGE_MOODLE,
  ROUTE_PAGE_NOTIFICATIONS_LIST,
  ROUTE_PAGE_NOTIFICATIONS_SETTINGS,
} from "../../routers/routes";
import { useHistory } from "react-router-dom";
import theme from "../../theme/theme";
import useUserData from "../../hooks/user/useUserData";
import {
  BankIcon,
  BuildingIcon,
  ChatNetworkIcon,
  HomeIcon,
  MessagesIcon,
  SearchIcon,
} from "../../assets/toplayout/icons";
import { LocationContext } from "../../App";
import { locationPath, locations } from "../../helpers/constants";

type Props = {};

const Header = (props: Props) => {
  const history = useHistory();
  const { value } = useContext(LocationContext);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      zIndex: 1200,
      width: "100%",
      backgroundColor: "#FFFFFF",
      position: "relative",
    },
  };
  return (
    <AppBar {...appBar}>
      <Toolbar variant={"dense"} sx={{ justifyContent: "space-between" }}>
        <Logo />
        <Search />
        <Box className={"icons"}>
          {!matches && (
            <>
              <IconButton
               onClick={() => history.push(getRouteUrl(ROUTE_PAGE_MOODLE))}
              >
                <img alt="bank" src={Images.bank} />
              </IconButton>
              <IconButton
                onClick={() => history.push(getRouteUrl(ROUTE_PAGE_JOBS))}
              >
                <img alt="search" src={Images.search_alt} />
              </IconButton>

              {/*<IconButton*/}
              {/*	onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_LIST))}*/}
              {/*>*/}
              {/*	<img alt="bell" src={Images.bell_ring}/>*/}
              {/*</IconButton>*/}
            </>
          )}

          <Box
            sx={{
              width: "55px",
              marginTop: "-3px",
            }}
          >
            <AccountMenu />
          </Box>
        </Box>
      </Toolbar>

      {matches && (
        <Toolbar variant={"dense"} sx={{ justifyContent: "space-between" }}>
          <Box className="secondBarIcons">
            <IconButton
              className={value && locations[value] === locationPath.root ? "active" : ""}
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_HOME))}
            >
              <HomeIcon  style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_COMPANIES))}
              className={value && locations[value] === locationPath.companies ? "active" : ""}
            >
              <BuildingIcon style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_GROUPS))}
              className={value && locations[value] === locationPath.groups ? "active" : ""}
            >
              <ChatNetworkIcon  style={{width:'26px',height:"26px"}} />
            </IconButton>

            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_MOODLE))}
              className={value && locations[value] === locationPath.education ? "active" : ""}
            >
              <BankIcon />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_JOBS))}
              className={value && locations[value] === locationPath.jobs ? "active" : ""}
            >
              <SearchIcon  style={{width:'26px',height:"26px"}} />
            </IconButton>
            <IconButton
              onClick={() => history.push(getRouteUrl(ROUTE_PAGE_CHAT))}
              className={value && locations[value] === locationPath.chat ? "active" : ""}
            >
              <MessagesIcon
                style={{width:'26px',height:"26px"}} />
            </IconButton>
            {/*<IconButton*/}
            {/*	onClick={()=>history.push(getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_LIST))}*/}
            {/*>*/}
            {/*	<img alt="bell" src={Images.bell_ring}/>*/}
            {/*</IconButton>*/}
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
