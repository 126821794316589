export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_MESSAGE = 'SET_USER_MESSAGE';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCEEDED = 'GET_USER_DETAILS_SUCCEEDED';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCEEDED = 'REGISTER_USER_SUCCEEDED'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'



export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER_PASSWORD_SUCCEEDED = 'RESET_USER_PASSWORD_SUCCEEDED'
export const RESET_USER_PASSWORD_FAILED = 'RESET_USER_PASSWORD_FAILED'


export const FORGOT_USER_PASSWORD = 'FORGOT_USER_PASSWORD'
export const FORGOT_USER_PASSWORD_SUCCEEDED = 'FORGOT_USER_PASSWORD_SUCCEEDED'
export const FORGOT_USER_PASSWORD_FAILED = 'FORGOT_USER_PASSWORD_FAILED'

export const SUBMIT_POST = 'SUBMIT_POST'
export const SUBMIT_POST_SUCCEEDED = 'SUBMIT_POST_SUCCEEDED'
export const SUBMIT_POST_FAILED = 'SUBMIT_POST_FAILED'

export const GET_PERSONAL_DATA = 'GET_PERSONAL_DATA'
export const GET_PERSONAL_DATA_SUCCEEDED = 'GET_PERSONAL_DATA_SUCCEEDED'
export const GET_PERSONAL_DATA_FAILED = 'GET_PERSONAL_DATA_FAILED'

export const POST_PERSONAL_DATA = 'POST_PERSONAL_DATA'
export const POST_PERSONAL_DATA_SUCCEEDED = 'POST_PERSONAL_DATA_SUCCEEDED'
export const POST_PERSONAL_DATA_FAILED = 'POST_PERSONAL_DATA_FAILED'

export const POST_USER_DETAILS = 'POST_USER_DETAILS'
export const POST_USER_DETAILS_SUCCEEDED = 'POST_USER_DETAILS_SUCCEEDED'
export const POST_USER_DETAILS_FAILED = 'POST_USER_DETAILS_FAILED'

export const POST_USER_RECOMMENDATION = 'POST_USER_RECOMMENDATION'
export const POST_USER_RECOMMENDATION_SUCCEEDED = 'POST_USER_RECOMMENDATION_SUCCEEDED'
export const POST_USER_RECOMMENDATION_FAILED = 'POST_USER_RECOMMENDATION_FAILED'


export const GET_USER_RECOMMENDATIONS = 'GET_USER_RECOMMENDATIONS'
export const GET_USER_RECOMMENDATIONS_SUCCEEDED = 'GET_USER_RECOMMENDATIONS_SUCCEEDED'
export const GET_USER_RECOMMENDATIONS_FAILED = 'GET_USER_RECOMMENDATIONS_FAILED'


// export const RECOMMENDATION_WITH_ID = 'RECOMMENDATION_WITH_ID'
// export const RECOMMENDATION_WITH_ID_SUCCEEDED = 'RECOMMENDATION_WITH_ID_SUCCEEDED'
// export const RECOMMENDATION_WITH_ID_FAILED = 'RECOMMENDATION_WITH_ID_FAILED'


export const POST_RECOMMENDATION_EDIT = 'POST_RECOMMENDATION_EDIT'
export const POST_RECOMMENDATION_EDIT_SUCCEEDED = 'POST_RECOMMENDATION_EDIT_SUCCEEDED'
export const POST_RECOMMENDATION_EDIT_FAILED = 'POST_RECOMMENDATION_EDIT_FAILED'

export const GET_USER_PROFILE_IMG = 'GET_USER_PROFILE_IMG'
export const GET_USER_PROFILE_IMG_SUCCEEDED = 'GET_USER_PROFILE_IMG_SUCCEEDED'
export const GET_USER_PROFILE_IMG_FAILED = 'GET_USER_PROFILE_IMG_FAILED'

export const GET_USER_COVER_IMG = 'GET_USER_COVER_IMG'
export const GET_USER_COVER_IMG_SUCCEEDED = 'GET_USER_COVER_IMG_SUCCEEDED'
export const GET_USER_COVER_IMG_FAILED = 'GET_USER_COVER_IMG_FAILED'

export const GET_ALL_POSTS = 'GET_ALL_POSTS'
export const GET_ALL_POSTS_SUCCEEDED = 'GET_ALL_POSTS_SUCCEEDED'
export const GET_ALL_POSTS_FAILED = 'GET_ALL_POSTS_FAILED'

export const GET_FULL_POST = 'GET_FULL_POST'
export const GET_FULL_POST_SUCCEEDED = 'GET_FULL_POST_SUCCEEDED'
export const GET_FULL_POST_FAILED = 'GET_FULL_POST_FAILED'

export const GET_POST_COMMENTS = 'GET_POST_COMMENTS'
export const GET_POST_COMMENTS_SUCCEEDED = 'GET_POST_COMMENTS_SUCCEEDED'
export const GET_POST_COMMENTS_FAILED = 'GET_POST_COMMENTS_FAILED'

export const ADD_POST_COMMENT = 'ADD_POST_COMMENT'
export const ADD_POST_COMMENT_SUCCEEDED = 'ADD_POST_COMMENT_SUCCEEDED'
export const ADD_POST_COMMENT_FAILED = 'ADD_POST_COMMENT_FAILED'

export const DELETE_POST_COMMENT = 'DELETE_POST_COMMENT'
export const DELETE_POST_COMMENT_SUCCEEDED = 'DELETE_POST_COMMENT_SUCCEEDED'
export const DELETE_POST_COMMENT_FAILED = 'DELETE_POST_COMMENT_FAILED'

export const DELETE_POST = 'DELETE_POST'
export const DELETE_POST_SUCCEEDED = 'DELETE_POST_SUCCEEDED'
export const DELETE_POST_FAILED = 'DELETE_POST_FAILED'

export const LIKE_POST = 'LIKE_POST'
export const LIKE_POST_SUCCEEDED = 'LIKE_POST_SUCCEEDED'
export const LIKE_POST_FAILED = 'LIKE_POST_FAILED'

export const LOGIN_GOOGLE = 'LOGIN_GOOGLE'

export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const CLEAR_CREDENTIALS = 'CLEAR_CREDENTIALS'

export const OPEN_MODAL = 'OPEN_MODAL';

export const CLOSE_MODAL = 'CLOSE_MODAL';