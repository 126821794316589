// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {Button, ListItem, ListItemAvatar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Images from "../../../assets";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import './rightTabs.css'
import './friendsTab.css'
import {grey} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_PERSONAL, ROUTE_PAGE_PERSONAL_FRIENDS} from "../../../routers/routes";
import axios from "axios";
import {People, PersonAddAlt1Outlined} from "@mui/icons-material";
import useUserData from "../../../hooks/user/useUserData";
import ListItemPerson from "../../loginBanner/listItemPerson";

type Props = {};


// const {username} = data
// const userNameFirstLetter = useMemo(() => (data?.username?.[0]?.toUpperCase()), [data])


const FriendsTab = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const {accessCode,data} = useUserData();
	const [loading, setLoading] = useState();
	const [friendsData, setFriendsData] = useState([]);
	const [sendRequest, setSendRequest] = useState();

	useEffect(() => {
		if (props.type === 'personal') {
			axios({
				url: "https://dev.ostrialinked.gr/api/api.php?q=122c&scode=" + accessCode,
				method: 'post',
				// data: {'idu': props.id},
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}).then((response) => {
				setFriendsData(response.data)
				setLoading(true)
				
			}).catch((error) => {
			
			})
		} else {
			axios({
				url: "https://dev.ostrialinked.gr/api/api.php?q=122d&scode=" + accessCode,
				method: 'post',
				data: {'idu': props.userId},
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}).then((response) => {
				console.log(response.data)
				setFriendsData(response.data)
				setLoading(true)
				
			}).catch((error) => {
			
			})
		}
	}, [accessCode]);

	useEffect(() => {
		if(sendRequest){
			if (props.type === 'personal') {
				axios({
					url: "https://dev.ostrialinked.gr/api/api.php?q=122c&scode=" + accessCode,
					method: 'post',
					// data: {'idu': props.id},
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				}).then((response) => {
					setFriendsData(response.data)
					setLoading(true)

				}).catch((error) => {

				})
			} else {
				axios({
					url: "https://dev.ostrialinked.gr/api/api.php?q=122d&scode=" + accessCode,
					method: 'post',
					data: {'idu': props.userId},
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				}).then((response) => {
					console.log(response.data)
					setFriendsData(response.data)
					setLoading(true)

				}).catch((error) => {

				})
			}
		}

		setSendRequest(false)
	}, [accessCode, sendRequest]);

	const handleRequestSend = (friendId) => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=122a&scode=" + accessCode,
			method: 'post',
			data: {'idu': friendId},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			setSendRequest(true);
			
		}).catch((error) => {
		
		})
	}
	const handleRemoveRequest = (friendId) => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=122b&scode=" + accessCode,
			method: 'post',
			data: {'idu': friendId},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			setSendRequest(true);
			
		}).catch((error) => {
		
		})
	}

	return (
		<>
			{loading &&
				
				<Box className="rightTab">
					<Box sx={{width: '100%', display: 'inline-flex'}}>
						<Typography>
							{t('friends')}
						</Typography>
						
						<Typography sx={{textAlign: 'end', width: '75%'}}>
							<Link href={getRouteUrl(ROUTE_PAGE_PERSONAL_FRIENDS, {username: data.username})} underline="hover" sx={{fontSize: 'small'}}>{t('all them')}</Link>
						</Typography>
					</Box>
					
					
					<Box className="rightTabDetails">
						<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%'}}/>
						<Box sx={{display: 'flow'}}>
							{(friendsData && friendsData?.length > 0) ?
								<>
									{friendsData.map(friendData => (
											<ListItem key={friendData.id} className="friendsData">
												<ListItemPerson
													id={friendData.id}
													image_profile={friendData.image_profile}
													firstname={friendData.firstname}
													lastname={friendData.lastname}
													username={friendData.username}
													handleRequestSend={handleRequestSend}
													handleRemoveRequest={handleRemoveRequest}
													isFriend={friendData.friend}
												/>
											</ListItem>
										)
									)
									}
								</>
								:
								<Box sx={{padding: '10px', textAlign: 'center'}}>
									{t('There are not friends')}
								</Box>
							}
						</Box>
					</Box>
				
				</Box>
				
			}
		</>
	);
};

export default FriendsTab