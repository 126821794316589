//@flow
import React, { useContext, useEffect } from "react";
import useUserData from "../../hooks/user/useUserData";
import 'dayjs/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import './main.css';
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";
import dayjs from "dayjs";
import { getUserDetails, getUserProfileImg } from "../../actions/currentUser/actions";
import { useLocation } from "react-router";
import { LocationContext } from "../../App";

const Main = () => {
	const {isLoggedIn, language,data} = useUserData()
	const {getUserDetails} = useUserAction()
	const location = useLocation();
	const { value, setValue } = useContext(LocationContext);
	useEffect(() => {
		dayjs.locale(language);
	}, [language])
	useEffect(()=>{
		setValue(location?.pathname)
	},[location?.pathname])
	useEffect(() => {
		if (isLoggedIn) {
			getUserDetails()
			getUserProfileImg()
		}
	}, [getUserDetails,getUserProfileImg,isLoggedIn])
	
	if (!isLoggedIn) return (
		<UnauthenticatedRouter/>
	)
	return <Content/>
}

export default Main