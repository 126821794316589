// @flow
import Box from "@mui/material/Box";
import Footer from "../../footer/footer";
import EspaBanner from "../../espaBanner/espaBanner";
import './noAuthLayout.css'

type Props = {};

const NoAuthLayout = (props: Props) => {
	return (
		<Box
			className={'noAuth-wrapper'}
			bgcolor={'background.secondary'}
			sx={{
				padding: {
					xs: '37px 7px 7px 7px',
					sm: '57px 27px 27px 27px',
					md: '87px 27px 27px 27px',
					lg: '117px 0 0 0',
				}
			}}
		>
			<Box className={'noAuth-card'}>
				{props.children}
			</Box>
			<Footer/>
			<EspaBanner/>
		</Box>
	);
};

export default NoAuthLayout