// @flow
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import GeneralDetailsTab from "../rightTabs/generalDetailsTab";
import RecommendationTab from "../rightTabs/recommendationTab";
import FileTab from "../rightTabs/fileTab";
import FriendsTab from "../rightTabs/friendsTab";
import { Button, IconButton, ListItem, TextField, Typography, useMediaQuery } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {TextareaAutosize} from '@mui/base/TextareaAutosize';
import Footer from "../../footer/footer";
import theme from "../../../theme/theme";
import useUserData from "../../../hooks/user/useUserData";
import axios from "axios";
import { Add, EditOutlined, PictureAsPdf, SaveOutlined } from "@mui/icons-material";
import {FormikProvider} from "formik";
import Education from "./education";
import CreateEducation from "./createEducation";
import CreateExperience from "./createExperience";
import Experience from "./experience";
import Images from "../../../assets";
import {
	becomeMember,
	editProfileDescription,
	getConversation,
	getPersonalCv,
	uploadPersonalCv,
} from "../../../api/requests";
import { useMutation, useQuery } from "react-query";
import Link from "@mui/material/Link";
import OtherInformation from "./otherInformation";
import CreateOtherInformation from "./createOtherInformation";
import { handleImageCompression, handlePdfCompression } from "../../../helpers/helpers";


type Props = {};


const CvTab = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const {message, personalData, accessCode} = useUserData()
	const [educationData, setEducationData] = useState([])
	const [workExperienceData, setWorkExperienceData] = useState([])
	const [refreshData, setRefreshData] = useState(false)
	const [addEducation, setAddEducation] = useState(false)
	const [addExperience, setAddExperience] = useState(false)
	const [addInfo, setOtherInfo] = useState(false)
	const [file, setFile] = useState(null)
	const [textUser, setTextUser] = useState(null)


	const { data, isLoading,refetch } = useQuery(
		["getPersonalCv", accessCode ],
		getPersonalCv,
	);


	const {mutate: uploadPersonalCvAction} = useMutation({
		mutationFn: uploadPersonalCv,
		onSuccess: (data, variables, context) => {
			setFile(null);
			refetch()
		},
	})

	useEffect(() => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=16b&scode=" + accessCode,
			method: 'post',
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log(response.data)
			setEducationData(response.data)
			// setAllPostData(response.data)
			// setLoading(true)
			
			// debugger
			
		}).catch((error) => {
		
		})
		
		//for experience data
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=17b&scode=" + accessCode,
			method: 'post',
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log('experience', response.data)
			setWorkExperienceData(response.data)
			// setAllPostData(response.data)
			// setLoading(true)
			
			// debugger
			
		}).catch((error) => {
		
		})
	}, [refreshData]);
	
	const textareaStyle = {
		fontFamily: 'Arial, sans-serif',
		height: '100px',
		minWidth: '200px',
		maxWidth: '400px',
		overflow: 'auto',
	};
	const handleFileChange = async (event) => {
		let compressedFile = event.target.files[0];
		if (event.target.files[0].type.includes("image")) {
			compressedFile = await handleImageCompression(event);
		} else if (event.target.files[0].type.includes("pdf")) {
			compressedFile = await handlePdfCompression(event);
		}
		setFile(compressedFile);
	};
	const onSubmit = ()=>{
		const formData = new FormData()
		formData.append('cvfile', file);
		uploadPersonalCvAction({
			accessCode,
			form:formData
		})
	}
	return (
		<>
			<Box className="centerLeftTab"
				 sx={{
					 width: {
						 xs: '100%',
						 sm: '100%',
						 md: '60%',
						 lg: '60%'
					 }
				 }}
			>
				<Box className="centerLeftTab">
					<Typography sx={{padding: '5px'}}>
						{t('cv')}
					</Typography>
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					<Box className="leftTabContainer">
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.family_state}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.work_state}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.email}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.phone1}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.phone2}</Typography>
					
					</Box>
				</Box>
				<Box className="centerLeftTab">
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography sx={{padding: '5px'}}>
							{t('education')}
						</Typography>
						<IconButton onClick={() => setAddEducation(true)}>
							<Add/>
						</IconButton>
					</Box>
					
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					{addEducation ?
						<CreateEducation setAddEducation = {()=>setAddEducation(false)}/>
						:
						<>
							{educationData.map(educationData => (
									<>
										<Education educationData={educationData} refreshData={() => setRefreshData(true)}/>
									</>
								
								)
							)
							}
						</>
					}
				</Box>
				<Box className="centerLeftTab">
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography sx={{padding: '5px'}}>
							{t('work experience')}
						</Typography>
						<IconButton onClick={() => setAddExperience(true)}>
							<Add/>
						</IconButton>
					</Box>
					
					
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					{addExperience ?
						<CreateExperience setAddExperience = {()=>setAddExperience(false)}/>
						:
						<>
							{workExperienceData.map(experienceData => (
									<>
										<Experience experienceData={experienceData} refreshData={() => setRefreshData(true)}/>
									</>
								
								)
							)
							}
						</>
					}
				</Box>
				<Box className="centerLeftTab">
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
					<Typography sx={{padding: '5px'}}>
						{t('other information')}
					</Typography>
					</Box>
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					<Box className="leftTabContainer">
						{!isLoading ? <OtherInformation refetch={refetch}  text_user={data.text_user} />

							: null
						}
					</Box>
				</Box>
				<Box className="centerLeftTab">
					<Typography sx={{padding: '5px'}}>
						{t('File cv')}
					</Typography>
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					<Box className="leftTabContainer">
						{!isLoading && data &&
							<Box>
								<Typography sx={{fontSize:'0.65rem',color:'#616161', fontFamily: 'Open Sans-Regular',wordWrap:'break-word',fontStyle: 'italic'}}>
									Πατώντας Ανέβασμα/τροποποίηση βιογραφικού, επιλέγοντας το αρχείο και πατώντας Υποβολή ανεβάζετε το βιογραφικό σας σε μορφή PDF στο σύστημα. Το βιογραφικό αυτό θα είναι διαθέσιμο σε εταιρείες που συμμετέχουν στην πλατφόρμα και ενδιαφέρονται για πρόσληψη ατόμων.
								</Typography>
								<Box marginTop='1rem'>
									<Link href={data.cvfile} target="_blank"
												underline="hover" sx={{
										fontSize: "large",
										color: "#616161",
										fontFamily: "Open Sans-Regular",
										border: "1px solid grey",
										borderRadius: "25px",
										padding: "5px 5px 5px 10px",
										margin: "10px",
									}}>
										<PictureAsPdf />
									</Link>
								</Box>

							</Box>}
						<IconButton
							variant="contained"
							component="label"
						>
							<img alt="clip" src={Images.clipPostComponent}/>
							<input
								id={'cv'}
								name={'cv'}
								required
								type="file"
								hidden
								accept="application/pdf"
								onChange={async (e)=>await handleFileChange(e)}
							/>
							<Box marginLeft={'4px'} color={'#616161'} fontSize={'15px'} fontWeight={'400'}>{t('uploadCv')}</Box>
						</IconButton>
						{file && (<Box>{file.name}</Box>)}
						{file && (<Button
							sx={{
								color: "white",
								backgroundColor: "black",
								borderRadius: '10px',
								"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
								justifyContent: 'center',
								padding: '15px',
								height: '42px',
								fontSize: 'revert'

							}}
							onClick={onSubmit}>Υποβολή</Button>)}
					</Box>
				</Box>
			</Box>
			{!matches && (
				<Box sx={{width: '40%',}}>
					<GeneralDetailsTab userId={props.id}/>
					{/*<RecommendationTab userId={props.id}/>*/}
					<FileTab userId={props.id}/>
					<FriendsTab type={props.type} userId={props.id}/>
					<Footer color={'#aeacac'}/>
				</Box>
			)}
		</>
	
	);
};

export default CvTab