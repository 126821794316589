//@flow
import React, { useEffect } from "react";
import ContentRouter from "../../routers/contentRouter";
import Header from "../../components/header/header";
import Box from "@mui/material/Box";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import { useLocation } from "react-router";
import useUserAction from "../../hooks/user/useUserAction";

export const TitleContext = React.createContext(null)

const Content = () => {
	const [title, setTitle] = React.useState(null)
	const [toolbarBottom, setToolbarBottom] = React.useState(null)
	const [menuNavigation, setMenuNavigation] = React.useState(null)

	return (
		<TitleContext.Provider
			value={{
				title: title,
				setTitle: setTitle,
				toolbar: toolbarBottom,
				setToolbar: setToolbarBottom,
				menuNavigation,
				setMenuNavigation
			}}
		>
			<ScrollToTop />
			<Header/>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					pt: 6,
					height: "100vh",
					// overflowX: 'auto',
					paddingTop:'10px',
				}}
			>
				<ContentRouter/>
			</Box>
		</TitleContext.Provider>
	)
}

export default Content;
