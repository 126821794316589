const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'
export const configuration = window.GLOBAL_CONFIGURATION[mode]
export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;

export const Languages = {
    EL: 'el',
    EN: 'en',
}

export const Permissions = {
    BeAdmin: "ADMIN_CONTENT",
}

export const Roles = {
    Admin: 1
}

export const imageExtensions = [
    "jpg", "jpeg", "png", "gif", "bmp", "tiff", "tif", "webp", "svg", "ico", "heic", "heif", "jfif", "pjpeg", "pjp"
];
export const locationPath={}
locationPath.root = "root"
locationPath.groups = "groups"
locationPath.companies = "companies"
locationPath.jobs = "jobs"
locationPath.chat = "chat"
locationPath.education = "education"

export const locations= {
    "/": locationPath.root,
    "/groups": locationPath.groups,
    "/companies": locationPath.companies,
    "/jobs": locationPath.jobs,
    "/chat-view/": locationPath.chat ,
    "/education": locationPath.education,

}