// @flow
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GeneralDetailsTab from "../rightTabs/generalDetailsTab";
import FileTab from "../rightTabs/fileTab";
import FriendsTab from "../rightTabs/friendsTab";
import Footer from "../../footer/footer";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import { Button, CardHeader, IconButton, Modal, Typography, useMediaQuery } from "@mui/material";
import theme from "../../../theme/theme";
import "./recommendationTab.css";
import Card from "@mui/material/Card";
import { Edit } from "@mui/icons-material";
import useUserData from "../../../hooks/user/useUserData";
import EditRecommendation from "./editRecommendation";
import useUserAction from "../../../hooks/user/useUserAction";
import { useQuery } from "react-query";
import { getAdsClaimsForCompany, getFriendRecommendations } from "../../../api/requests";
import ContentLoader from "../../contentLoader/contentLoader";
import OstriaModal from "../../ostriaModal/ostriaModal";
import Link from "@mui/material/Link";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_FRIEND, ROUTE_PAGE_GROUPS_CREATE, ROUTE_PAGE_PERSONAL } from "../../../routers/routes";
import CreateDivision from "../../userMoreMenu/components/createDivision";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router";

type Props = {};

const FriendRecommendationTab = (props: Props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { accessCode,userId, username } = useUserData();
  const [openEdit, setOpenEdit] = useState(false);
  const [recommendationId, setRecommendationId] = useState(null);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [data, setData] = useState({});
  const {
    data: userRecommendationData,
    isLoading,
    isRefetching,
  } = useQuery(["getFriendRecommendations", accessCode, props.id], getFriendRecommendations);

  const handleEdit = (id) => {
    setRecommendationId(id);
    setOpenEdit(true);
    setData(userRecommendationData.find(f => f.id === id));
  };

  return (
    <>
      <Box className="recommendationLeftTab"
           sx={{
             width: {
               xs: "100%",
               sm: "100%",
               md: "60%",
               lg: "60%",
             },
           }}
      >
        <Box className="centerLeftTab">
          <Box className="tabTitle">
            <Typography sx={{ padding: "5px", width: "80%" }}>
              {t("recommendations")}
            </Typography>
            <Typography sx={{ marginTop: "1%" }}>
              <Typography   onClick={() => setOpenPopUp(true)}  color={"#616161"}
                    sx={{ fontSize: "smaller", fontFamily: "Open Sans-Regular" ,cursor:'pointer'}}>{t("create new recommendation")}</Typography>

            </Typography>
          </Box>
          <Typography sx={{ border: "1px #F3F3F3 solid" }} />
        </Box>
        <Box className="recommendationCards">

          {isLoading || isRefetching  ?
            <ContentLoader loading={isLoading}></ContentLoader>
            :
            userRecommendationData && userRecommendationData?.map(recommendation => (
              <Card
                key={recommendation.id}
                elevation={0}
                sx={{
                  border: "none",
                  maxWidth: 300,
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                }}
              >
                <CardHeader
                  titleTypographyProps={{
                    fontSize: 20,
                  }}
                  subheaderTypographyProps={{
                    fontSize: 15,
                  }}
                  avatar={
                    (recommendation.user_put) &&
                    <Link style={{ textDecoration: "none" }} href={ recommendation.id_user_put === userId ?
                      getRouteUrl(ROUTE_PAGE_PERSONAL, {username: username})
                          :
                   getRouteUrl(ROUTE_PAGE_FRIEND, {
                          username: recommendation.user_put.username,
                          id: recommendation.user_put.id,
                        })
                    }>
                    <Avatar style={{cursor:'pointer'}}  sx={{ width: 40, height: 40 }} src={recommendation.user_put.image_profile}></Avatar>
                      <Typography fontSize={9} fontWeight="bold">
                      {recommendation.user_put.firstname + " " + recommendation.user_put.lastname}
                      </Typography>
                    </Link>

                  }

                  action={ recommendation.id_user_put === userId &&
                    <IconButton aria-label="settings" onClick={() => handleEdit(recommendation.id)}>
                      <Edit sx={{ fontSize: "large" }} />
                    </IconButton>
                  }
                  title={recommendation.title}
                  subheader={recommendation.description}
                />
              </Card>

            ))}
        </Box>
      </Box>
      <OstriaModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
          <EditRecommendation userId={props.id} id={recommendationId} data={data} onClose={() => setOpenEdit(false)} />

      </OstriaModal>
      <OstriaModal open={openPopUp} onClose={() => setOpenPopUp(false)}>
        <CreateDivision onClose={() => setOpenPopUp(false)} id={props.id} />
      </OstriaModal>
      {!matches && (
        <Box sx={{ width: "40%" }}>
          <GeneralDetailsTab type={props.type} userId={props.id} />
          <FileTab type={props.type} userId={props.id} />
          <FriendsTab type={props.type} userId={props.id} />
          <Footer color={"#aeacac"} />
        </Box>
      )}
    </>

  );
};

export default FriendRecommendationTab;