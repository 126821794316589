import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import {Button, TextField, Typography} from "@mui/material";
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import useUserData from "../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../hooks/user/useUserAction";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../locales/pages/namespaces";


const PersonalDetailsEdit = (props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const {message, personalData} = useUserData()
	const {postPersonalData, setUserMessage, getPersonalData} = useUserAction()
	const validationSchema = yup.object({
		country: yup
		.string(),
		city: yup
		.string(),
		// .required(t('required')),
	})
	const onSubmit = (values) => {
		postPersonalData(values)
	}
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			setUserMessage(null)
			if (message.text === "data_saved") {
				props.setChangeDetails(false)
				// window.location.reload();
			}
		}
	}, [message, setUserMessage, t])
	
	useEffect(() => {
		getPersonalData()
	}, [getPersonalData])
	const formik = useFormik({
		initialValues: {
			country: personalData.country,
			city: personalData.city,
			family_state: personalData.family_state,
			work_state: personalData.work_state,
			address: personalData.address,
			phone1: personalData.phone1,
			phone2: personalData.phone2,
			facebook: personalData.facebook,
			twitter: personalData.twitter,
			linkedin: personalData.linkedin,
			url: personalData.url,
		},
		// validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})
	return (
		<FormikProvider value={formik} onSubmit={formik.handleSubmit}
						noValidate>
			<Box sx={{backgroundColor:'white',padding:'10px'}}
				component="form"
				 onSubmit={formik.handleSubmit}
				 noValidate>
				
				<Box className={"title"}>
					<Typography sx={{paddingBottom:'10px'}}>
						{t('Change Details')}
					</Typography>
				</Box>
				<Box className="formFields">
					<TextField size={'small'}
							   id={'country'}
							   name={'country'}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   label={t('country')} variant="outlined"
							   value={formik.values.country}/>
					<TextField size={'small'}
							   id={'city'}
							   name={'city'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('city')} variant="outlined"
						// defaultValue={personalData.city}
							   value={formik.values.city}/>
					<TextField size={'small'}
							   id={'family_state'}
							   name={'family_state'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('family_state')} variant="outlined"
							   defaultValue={personalData.family_state}/>
					<TextField size={'small'}
							   id={'work_state'}
							   name={'work_state'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('work_state')} variant="outlined"
							   defaultValue={personalData.work_state}/>
					<TextField size={'small'}
							   id={'address'}
							   name={'address'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('address')} variant="outlined"
							   defaultValue={personalData.address}/>
					<TextField size={'small'}
							   id={'phone1'}
							   name={'phone1'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('phone1')} variant="outlined"
							   defaultValue={personalData.phone1}/>
					<TextField size={'small'}
							   id={'phone2'}
							   name={'phone2'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('phone2')} variant="outlined"
							   defaultValue={personalData.phone2}/>
					<TextField size={'small'}
							   id={'facebook'}
							   name={'facebook'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('facebook')} variant="outlined"
							   defaultValue={personalData.facebook}/>
					<TextField size={'small'}
							   id={'twitter'}
							   name={'twitter'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('twitter')} variant="outlined"
							   defaultValue={personalData.twitter}/>
					<TextField size={'small'}
							   id={'linkedin'}
							   name={'linkedin'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('linkedin')} variant="outlined"
							   defaultValue={personalData.linkedin}/>
					<TextField size={'small'}
							   id={'url'}
							   name={'url'}
							   onChange={formik.handleChange}
							   onBlur={formik.handleBlur}
							   sx={{
								   padding: '2%',
								   marginBottom: '1%',
								   '& .MuiOutlinedInput-root': {
									   // ':focus-within': { border: '0.0px solid #ffffff !important' }
									   fontSize: 'smaller',
								   },
							   }}
							   label={t('url')} variant="outlined"
							   defaultValue={personalData.url}/>
				</Box>
				<Box className={"formButtons"}>
					<Button
						variant="outlined"
						type="submit"
						sx={{
							marginRight: '20px',
							backgroundColor: '#27293d',
							borderRadius: '4px',
							color: '#ffffff',
							'&:hover': {
								backgroundColor: '#27293d',
							},
							width: '35%',
						}}
					>
						{t('save')}
					</Button>
					<Button
						onClick={() => props.setChangeDetails(false)}
						sx={{
							borderRadius: '4px',
							width: '35%',
						}}
						variant="outlined"
					>
						{t('cancel')}
					</Button>
				</Box>
			</Box>
		</FormikProvider>
	);
};

export default PersonalDetailsEdit