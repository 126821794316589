import React from "react";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import AdsForm from "./adsForm";
import TabPanel from "@mui/lab/TabPanel";
import AdsList from "./adsList";
import TabContext from "@mui/lab/TabContext";
import { useTranslation } from "react-i18next";
import { PAGE_COMPANY } from "../../locales/pages/namespaces";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  activeAdCompany,
  addCompanyAd,
  deactivateAdCompany,
  editAdCompany,
  getCompany,
  getCompanyAds,
} from "../../api/requests";
import * as yup from "yup";
import { useFormik } from "formik";
import useUserData from "../../hooks/user/useUserData";
import { useParams } from "react-router-dom";
import AdsClaims from "./adsClaims";
import CvCompanySearch from "./CvCompanySearch";

const INITIAL_VALUES = {
  action: "create",
  initialValues: {
    title: "",
    city: "",
    description: "",
    worktype: "",
    id_category: "",
    salary: "",
    level: "",
  },
  value: 0,
};

const validationSchema = yup.object({
  title: yup
    .string().required(),
  city: yup
    .string().required(),
  description: yup
    .string().required(),
  worktype: yup
    .string().required(),
  id_category: yup
    .string().required(),
  salary: yup
    .string(),
  level: yup
    .string().required(),

});

const OwnerAds = ({ adsCategories }) => {
  const { t } = useTranslation(PAGE_COMPANY);
  const { accessCode } = useUserData();
  const { id } = useParams();
  const [initialValues, setInitialValues] = React.useState(INITIAL_VALUES);
  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    if (initialValues.action === "create") {
      addCompanyAdAction({
        accessCode,
        form: {
          ...values,
          id_company: id,
        },
      });
    } else {
      editAdCompanyAction({
        accessCode,
        form: { ...values, id_ad: values.id },
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues.initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  })

  const { data: companyData } = useQuery(["getCompany", accessCode, id], getCompany);

  const { data: companyAds, refetch } = useQuery(["getCompanyAds", accessCode, id], getCompanyAds);
  const { mutate: addCompanyAdAction } = useMutation({
    mutationFn: addCompanyAd,
    onSuccess: (data, variables, context) => {
      refetch();
      setInitialValues(INITIAL_VALUES);
      handleChange(null, 0);
      formik.resetForm()
    },
  });

  const { mutate: editAdCompanyAction } = useMutation({
    mutationFn: editAdCompany,
    onSuccess: (data, variables, context) => {
      refetch();
      handleChange(null, 0);
    },
  });

  const { mutate: deactivateAdCompanyAction } = useMutation({
    mutationFn: deactivateAdCompany,
    onSuccess: (data, variables, context) => {
      let newCompanyAds = companyAds;
      if (data.ad_no_active === "true") {
        let newCompany = newCompanyAds.find(x => x.id === variables.id);
        newCompany.isActive = "0";
      }
      queryClient.setQueryData(["getCompanyAds", accessCode, id], newCompanyAds);
    },
  });

  const { mutate: activeAdCompanyAction } = useMutation({
    mutationFn: activeAdCompany,
    onSuccess: (data, variables, context) => {
      let newCompanyAds = companyAds;
      if (data.ad_active === "true") {
        let newCompany = newCompanyAds.find(x => x.id === variables.id);
        newCompany.isActive = "1";
      }
      queryClient.setQueryData(["getCompanyAds", accessCode, id], newCompanyAds);
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setInitialValues({ ...INITIAL_VALUES, value: newValue });
  };

  return (
    <Box>
      <TabContext value={initialValues.value}>
        <Tabs variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile value={initialValues.value} onChange={handleChange}
              aria-label="basic tabs example">
          <Tab label={<span className="tabLabel">{t("List")}</span>} value={0} />
          <Tab label={<span className="tabLabel">{t("Form")}</span>} value={1} />
          {companyData?.isOwner === "true" &&   <Tab label={<span className="tabLabel">{t("AdsClaims")}</span>} value={2} />}
          {companyData?.isOwner === "true" &&   <Tab label={<span className="tabLabel">{t("CvClaims")}</span>} value={3} />}
        </Tabs>
        <TabPanel value={0}>
          <AdsList
            setInitialValues={setInitialValues}
            handleChange={handleChange}
            deactivateAdCompanyAction={deactivateAdCompanyAction}
            activeAdCompanyAction={activeAdCompanyAction}
          />
        </TabPanel>
        <TabPanel value={1}>
          <AdsForm
            formik={formik}
            setInitialValues={setInitialValues}
            initialValues={initialValues}
            adsCategories={adsCategories}
            handleChange={handleChange}
          />
        </TabPanel>
        {companyData?.isOwner === "true" &&
          <TabPanel value={2}>
            <AdsClaims />
          </TabPanel>
        }
        {companyData?.isOwner === "true" &&
          <TabPanel value={3}>
            <CvCompanySearch />
          </TabPanel>
        }
      </TabContext>
    </Box>
  );
};

export default OwnerAds;