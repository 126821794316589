// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import Images from "../../../assets";
import {Typography} from "@mui/material";
import { useQuery } from "react-query";
import { getCompanyFiles, getFiles } from "../../../api/requests";
import { useParams } from "react-router-dom";
import useUserData from "../../../hooks/user/useUserData";
import { getFileExtension } from "../../../helpers/helpers";
import Link from "@mui/material/Link";
import { PictureAsPdf } from "@mui/icons-material";
import { imageExtensions } from "../../../helpers/constants";
import ContentLoader from "../../contentLoader/contentLoader";
import CardMedia from "@mui/material/CardMedia";
import Modal from "@mui/material/Modal";
import OstriaModal from "../../ostriaModal/ostriaModal";

type Props = {

};

const FilesTab = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const { id } = useParams();
	const { accessCode } = useUserData();
	const [isModalOpen, setOpenModal] = React.useState(null);
	const { data: files, isLoading } = useQuery({ queryKey: ["userFiles", accessCode, id], queryFn: getCompanyFiles });

	return (
		<>
			<Box className="centerLeftTab">
				<Box className="centerLeftTab">
					<Typography sx={{padding: '5px'}}>
						{t('files')}
					</Typography>
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
				</Box>
				<Box sx={{display:'inline'}}>
					{
						isLoading === false
							?
							files.map(x =>
								getFileExtension(x) === "pdf" ?
									<Link href={x} target="_blank" style={{ objectFit: "contain", width: "120px" }}
												underline="hover" sx={{
										fontSize: "large",
										color: "#616161",
										fontFamily: "Open Sans-Regular",
										border: "1px solid grey",
										borderRadius: "25px",
										padding: "5px 5px 5px 10px",
										margin: "10px",
									}}>
										<PictureAsPdf />
									</Link>
									: imageExtensions.includes(getFileExtension(x)) ?
										<img
											src={`${x}`}
											alt={x}
											loading="lazy"
											onClick={() => setOpenModal(x)}
											style={{ objectFit: "contain", width: "120px" }}
										/>
										: null,
							)
							:
							<ContentLoader loading={isLoading}></ContentLoader>
					}
					<OstriaModal
						open={Boolean(isModalOpen)}
						onClose={() => {
							setOpenModal(null);
						}}
					>
							<CardMedia
								component="img"
								image={isModalOpen}
								alt={isModalOpen}
							/>
					</OstriaModal>
				</Box>
			</Box>
		</>
	);
};

export default FilesTab