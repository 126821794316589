// @flow
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import ChatSiteBar from "./chatSidebar";
import ChatMessagesContainer from "./chatMessagesContainer";
import ChatMessagesContainerHeader from "./chatMessagesContainerHeader";
import useUserData from "../../hooks/user/useUserData";
import {
  getConversation,
  sendTextMessage,
  getHistoryConversation,
} from "../../api/requests";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import theme from "../../theme/theme";
import { ArrowBack } from "@mui/icons-material";
import { useParams, useHistory } from "react-router-dom";

const ChatContainer = () => {
  const [selectedUserInfo, setSelectedUserInfo] = useState({
    id: null,
    name: "",
    imageUrl: "",
    username: "",
  });
  const isMobile = useMediaQuery(() => theme.breakpoints.down("md"));
  const [showChatbar, setShowChatbar] = useState(null);
  const [triggerSendMsgSrc, setTriggerSendMsgSrc] = useState(null); //src from which we are trying to open 'chat-card' | 'new-chat-button'
  const { accessCode, userId } = useUserData();
  const [hasNewMessageSent, setHasNewMessageSent] = useState(false);
  const { id: friendUserId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (friendUserId && !selectedUserInfo.id) {
      // Make the axios request to fetch user info
      axios({
        method: "post",
        url: `https://dev.ostrialinked.gr/api/api.php?q=4b&scode=${accessCode}`,
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          userid: friendUserId,
        },
      })
        .then((response) => {
          if (response.data) {
            const { id, firstname, lastname, image_profile } = response.data;
            setSelectedUserInfo({
              id: id,
              name: `${firstname} ${lastname}`,
              imageUrl: image_profile,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, [friendUserId, selectedUserInfo, accessCode]);

  const handleNewChatClicked = useCallback(() => {
    setSelectedUserInfo({
      id: null,
      name: "",
      imageUrl: "",
      username: "",
    });

    if (friendUserId) {
      history.replace("/chat-view");
    }
    setTriggerSendMsgSrc("new-chat-button-clicked");
    setShowChatbar(false);
  }, []);

  const handleMsgCardClicked = useCallback(
    async ({
      receiverId,
      receiverFullName,
      receiverImageUrl,
      receiverUsername,
    }) => {
      setTriggerSendMsgSrc("card-clicked");
      setSelectedUserInfo({
        id: receiverId,
        name: receiverFullName,
        imageUrl: receiverImageUrl,
        username: receiverUsername,
      });
      if (friendUserId) {
        history.replace("/chat-view");
      }
      //Flag all messages with this users as read
      try {
        await axios({
          method: "post",
          url:
            "https://dev.ostrialinked.gr/api/api.php?q=msg06&scode=" +
            accessCode,
          headers: { "Content-Type": "multipart/form-data" },
          data: {
            idr: receiverId,
          },
        });
      } catch (err) {
        console.error(err);
      }
      setShowChatbar(false);
    },
    []
  );

  const handleNewMsgReceiverSelected = ({
    recieverId,
    receiverFullName,
    receiverImageUrl,
  }) => {
    setTriggerSendMsgSrc(null);
    setSelectedUserInfo({
      id: recieverId,
      name: receiverFullName,
      imageUrl: receiverImageUrl,
    });
  };

  const { data: conversationData, isLoading: isConversationLoading } = useQuery(
    ["getConversation", accessCode, selectedUserInfo.id],
    getConversation,
    {
      enabled: !!selectedUserInfo.id, // Enable only if selectedMsgReceiverId is not null
      refetchInterval: selectedUserInfo.id ? 1000 : false, // Refetch every 1 seconds if selectedMsgReceiverId is not null
    }
  );

  const {
    data: conversationHistoryData,
    isLoading: isConversationHistoryDataLoading,
  } = useQuery(
    ["getHistoryConversation", accessCode, selectedUserInfo.id],
    getHistoryConversation,
    {
      enabled: true, // Enable only if selectedMsgReceiverId is not null
      refetchInterval: selectedUserInfo.id ? 1000 : false, // Refetch every 4 seconds if selectedMsgReceiverId is not null
    }
  );

  useEffect(() => {
    if (
      !selectedUserInfo.id &&
      !!conversationHistoryData?.length &&
      !triggerSendMsgSrc &&
      !friendUserId
    ) {
      const isSender = userId === conversationHistoryData[0].id_sender;
      const selectedUserInfo = {
        id: isSender
          ? conversationHistoryData[0].id_receiver
          : conversationHistoryData[0].id_sender,
        name: isSender
          ? `${conversationHistoryData[0].receiver_firstname} ${conversationHistoryData[0].receiver_lastname}`
          : `${conversationHistoryData[0].sender_firstname} ${conversationHistoryData[0].sender_lastname}`,
        imageUrl: isSender
          ? conversationHistoryData[0].receiver_image
          : conversationHistoryData[0].sender_image,
        username: isSender
          ? conversationHistoryData[0].receiver_username
          : conversationHistoryData[0].sender_username,
      };

      setSelectedUserInfo(selectedUserInfo);
    }

    setHasNewMessageSent(false);
  }, [conversationHistoryData, selectedUserInfo]);

  const { mutate: sendTextMessageAction } = useMutation({
    mutationFn: sendTextMessage,
    onSuccess: (data, variables, context) => {},
  });

  const handleSendMessage = useCallback(
    (message) => {
      sendTextMessageAction([
        "sendTextMessage",
        accessCode,
        selectedUserInfo.id,
        message,
      ]);

      setHasNewMessageSent(true);
    },
    [selectedUserInfo]
  );

  return !isMobile ? (
    <Grid container sx={{ height: "calc(100% - 60px)", paddingLeft: 0 }}>
      <Grid
        item
        xs={3}
        md={3}
        lg={3}
        sx={{
          borderRight: "1px solid #ECECEC",
          maxHeight: "100%",
        }}
      >
        {isConversationHistoryDataLoading ? (
          <LinearProgress />
        ) : (
          <ChatSiteBar
            resetSelectedCardIndex={hasNewMessageSent}
            onNewChatClicked={handleNewChatClicked}
            onCardClick={handleMsgCardClicked}
            conversationHistoryData={
              !friendUserId
                ? conversationHistoryData
                : [
                    ...conversationHistoryData.filter(
                      (conversation) =>
                        conversation.id_receiver === friendUserId
                    ),
                    ...conversationHistoryData.filter(
                      (conversation) =>
                        conversation.id_receiver !== friendUserId
                    ),
                  ]
            }
          />
        )}
      </Grid>
      <Grid
        item
        xs={9}
        md={9}
        lg={9}
        sx={{ position: "relative", top: "-10px" }}
      >
        <ChatMessagesContainerHeader
          triggerSrc={triggerSendMsgSrc}
          selectedUserInfo={selectedUserInfo}
          onNewReceiversSelected={handleNewMsgReceiverSelected}
        />
        <ChatMessagesContainer
          conversationData={conversationData}
          onSendMessage={handleSendMessage}
        />
      </Grid>
    </Grid>
  ) : (selectedUserInfo.id && !showChatbar) || !showChatbar ? (
    <>
      <IconButton onClick={() => setShowChatbar(true)}>
        <ArrowBack />
      </IconButton>
      <Box height="70vh">
        <ChatMessagesContainerHeader
          triggerSrc={triggerSendMsgSrc}
          selectedUserInfo={selectedUserInfo}
          onNewReceiversSelected={handleNewMsgReceiverSelected}
        />
        <ChatMessagesContainer
          conversationData={conversationData}
          onSendMessage={handleSendMessage}
        />
      </Box>
    </>
  ) : (
    <ChatSiteBar
      resetSelectedCardIndex={hasNewMessageSent}
      onNewChatClicked={handleNewChatClicked}
      onCardClick={handleMsgCardClicked}
      conversationHistoryData={conversationHistoryData}
    />
  );
};

export default ChatContainer;
