//@flow
import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import {
    ROUTE_PAGE_LOGIN,
    ROUTE_PAGE_RESET_PASSWORD,
    ROUTE_PAGE_SIGN_UP,
    ROUTE_PAGE_TERMS,
    ROUTE_PAGE_CONTACT, ROUTE_PAGE_FORGOT_PASSWORD
} from "./routes";

// Έχει σημασία η σειρά δήλωσης.
const ROUTES = [
    ROUTE_PAGE_RESET_PASSWORD,
    ROUTE_PAGE_FORGOT_PASSWORD,
    ROUTE_PAGE_SIGN_UP,
    ROUTE_PAGE_TERMS,
    ROUTE_PAGE_CONTACT,
    ROUTE_PAGE_LOGIN
]

const UnauthenticatedRouter = () => {
    return (
        <Switch>
            {ROUTES.map(route => {
                const {key, path, exact, component} = route;
                return (
                    <Route
                        key={key}
                        path={path}
                        exact={exact}
                        component={component}
                    />
                );
            })}
            <Redirect from="/" to="/login" />
        </Switch>
    );
};

export default UnauthenticatedRouter;