// @flow
import React from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_COMPANY} from "../../../locales/pages/namespaces";
import './rightTabs.css'
import Images from "../../../assets";
import { useQuery } from "react-query";
import { getCompany } from "../../../api/requests";
import { useParams } from "react-router-dom";
import useUserData from "../../../hooks/user/useUserData";
import ContentLoader from "../../contentLoader/contentLoader";

type Props = {

};

const InformationTab = (props: Props) => {
	const {t} = useTranslation(PAGE_COMPANY)
	const { id } = useParams();
	const { accessCode } = useUserData();
	const { data: companyData, isLoading } = useQuery(["getCompany", accessCode, id], getCompany);

	return (
		<Box className="rightTab" sx={{backgroundColor: 'white', borderRadius: '5px', marginBottom: '5px'}}>
			{isLoading ?
				<ContentLoader loading={isLoading}></ContentLoader>
				:
			<>
				<Typography>
					{t('information')}
				</Typography>
				<Box className="rightTabDetails">
					<Typography sx={{border:'1px #F3F3F3 solid', marginRight: '3%', }}/>
					<Typography sx={{fontFamily:' Open Sans-Regular',fontSize:'small'}}>
						{t('subtitle', {dynamicValue: companyData.title})}
					</Typography>
					<Typography sx={{fontSize:'small',color:'#616161', fontFamily: 'Open Sans-Regular', display:'inline-flex'}}>
						<img src={Images.booksIcon} style={{paddingRight: '2px'}}/>
						{t('typeProfile')}
					</Typography>
					<Typography sx={{fontSize:'small',color:'#616161', fontFamily: 'Open Sans-Regular',display:'inline-flex'}}>
						<img src={Images.likeInfoIcon} style={{paddingRight: '2px'}}/>
						{t('likes',{dynamicValue: companyData.members})}
					</Typography>
				</Box>
			</>
			}

		</Box>
	);
};

export default InformationTab