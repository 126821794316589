// @flow
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { PAGE_GROUP } from "../../../../locales/pages/namespaces";
import Images from "../../../../assets";
import useUserData from "../../../../hooks/user/useUserData";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { changeGroupDesciption, changeGroupPhoto, getGroup } from "../../../../api/requests";
import { FormikProvider, useFormik } from "formik";


type Props = {};

const InformationTab = (props: Props) => {
  const { t } = useTranslation(PAGE_GROUP);
  const { accessCode } = useUserData();
  const { groupCode } = useParams();
  const [edit, setEdit] = useState(false);
  const queryClient = useQueryClient()
  const { data: groupData, isLoading } = useQuery(["getGroup", accessCode, groupCode], getGroup);

  const {mutate: changeGroupDesciptionAction} = useMutation({
    mutationFn: changeGroupDesciption,
    onSuccess: (data, variables, context) => {
      if(data.saved === "true"){
        let newGroup=groupData
        newGroup.description = variables.description
        queryClient.setQueryData(["getGroup",accessCode,groupCode], newGroup)
      }
    },
  })
  const onSubmit = (values) => {
    changeGroupDesciptionAction({
      accessCode,
      form:{
        description:values.description,
        id_group:groupCode
      }
    })
  };
  const formik = useFormik({
    initialValues: {
      description: groupData?.description,
    },
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: onSubmit,

  });

  if (isLoading) {
    return null;
  }
  return (
    <Box sx={{ display: "grid" }}>
      <Box className="infoLeftTab">
        <Box className="centerLeftTab">
      {!(Boolean(edit)) ?
        <>
          <Box className="lineTitle">
            <Typography sx={{ padding: "5px" }}>
              {t("general details")}
            </Typography>
            {groupData?.isOwner === "true" &&
              <Button
                sx={{
                  fontSize: "smaller",
                }}
                onClick={() => setEdit(true)}>
                {t("edit")}
              </Button>
            }
          </Box>
          <Box sx={{ padding: "10px", width: "80%" }}>
            <Typography sx={{ fontFamily: "Open Sans-Regular", fontSize: "smaller" }}>
              {groupData["description"]}
            </Typography>
          </Box>
        </> :
        <FormikProvider value={formik} onSubmit={formik.handleSubmit}
                        noValidate>
          <Box component="form"
               onSubmit={formik.handleSubmit}
               noValidate>
            <Box className="lineTitle">
              <Typography sx={{padding: '5px'}}>
                {t('general details')}
              </Typography>
              <Button
                type={'submit'}
                sx={{
                  fontSize: 'smaller'
                }}
              >
                {t('save')}
              </Button>
            </Box>
            <Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
            <Button
              sx={{
                fontSize: 'smaller',
                float:'right',
                justifyContent: 'end',
                '&:hover': {
                  backgroundColor: '#ffffff',
                }
              }}
              onClick={() => setEdit(false)}>
              {t('cancel')}
            </Button>

            <Box>
              <TextField size={'small'}
                         id={'description'}
                         name={'description'}
                         sx={{
                           padding: '2%',
                           marginBottom: '1%',
                           '& .MuiOutlinedInput-root': {
                             // ':focus-within': { border: '0.0px solid #ffffff !important' }
                             fontSize: 'smaller',
                           },
                         }}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         label={t('description')} variant="outlined"
                         value={formik.values.description}/>
            </Box>
          </Box>
        </FormikProvider>
      }
        </Box>
      </Box>
    </Box>
  );
};

export default InformationTab;