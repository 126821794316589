// @flow
import { Typography, IconButton, Box, Divider } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ChatSidebarConversationCardsList from "./chatSidebarConversationCardsList";

const ChatSiteBar = ({
  onNewChatClicked,
  onCardClick,
  conversationHistoryData,
  resetSelectedCardIndex,
}) => {
  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          padding: "0 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: "bold", paddingY: "18px" }}>
          Μηνύματα
        </Typography>
        <IconButton aria-label="new-chat" onClick={onNewChatClicked}>
          <EditIcon />
        </IconButton>
      </Box>
      <Divider />

      <ChatSidebarConversationCardsList
        resetSelectedCardIndex={resetSelectedCardIndex}
        conversationHistoryData={conversationHistoryData}
        onCardClick={onCardClick}
      />
    </Box>
  );
};

export default ChatSiteBar;
