// @flow
import React, { useEffect, useState } from "react";
import "./friendProfile.css";
import { useTranslation } from "react-i18next";
import { PAGE_PERSONAL } from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import UserProfileHeader from "../../../components/userProfileHeader/userProfileHeader";
import { Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import {
  ROUTE_PAGE_FRIEND,
  ROUTE_PAGE_FRIEND_ABOUT,
  ROUTE_PAGE_FRIEND_CV,
  ROUTE_PAGE_FRIEND_FILES,
  ROUTE_PAGE_FRIEND_FRIENDS,
  ROUTE_PAGE_FRIEND_RECOMMENDATIONS,
} from "../../../routers/routes";
import { TabPanel } from "../../../components/tabPanel/tabPanel";
import useUrlLastPart from "../../../hooks/useUrlLastPart";
import FriendProfileHeader from "../../../components/friendProfileHeader/friendProfileHeader";
import { useParams } from "react-router";
import CenterTab from "../../../components/userTabs/centerTab/centerTab";
import PersonalDetailsTab from "../../../components/userTabs/personalDetailsTab/personalDetailsTab";
import FriendPersonalDetails from "../../../components/friendProfileTabs/friendPersonalDetails/friendPersonalDetails";
import FriendCvTab from "../../../components/friendProfileTabs/friendCvTab/friendCvTab";
import axios from "axios";
import useUserData from "../../../hooks/user/useUserData";
import FriendsTab from "../../../components/userTabs/friendsTab/friendsTab";
import GroupsTab from "../../../components/userTabs/groupsTab/groupsTab";
import RecommendationTab from "../../../components/userTabs/recommendationTab/recommendationTab";
import FilesTab from "../../../components/userTabs/filesTab/filesTab";
import FriendRecommendationTab from "../../../components/userTabs/recommendationTab/friendRecommendationTab";

const FriendProfile = (props) => {
  const { t } = useTranslation(PAGE_PERSONAL);
  const { accessCode, userId } = useUserData();
  const [friendData, setFriendData] = useState();
  const [loading, setLoading] = useState(false);
  let lastPartOfPath = useUrlLastPart();
  if (
    !["cv", "friends", "about", "groups", "files", "recommendations"].includes(
      lastPartOfPath
    )
  ) {
    lastPartOfPath = "";
  }

  console.log("friend-prof-params", props);

  useEffect(() => {
    axios({
      url:
        "https://dev.ostrialinked.gr/api/api.php?q=200a2&scode=" + accessCode,
      method: "post",
      data: { idx: userId, idu: props.match.params.id },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setFriendData(response.data);
        setLoading(true);
      })
      .catch((error) => {});
  }, []);

  return (
    <Box className={"friend"}>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "85%",
            lg: "50%",
          },
        }}
      >
        {loading && <FriendProfileHeader data={friendData} />}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "white",
            marginTop: "2px",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={lastPartOfPath}
          >
            <Tab
              component={Link}
              value={""}
              to={getRouteUrl(ROUTE_PAGE_FRIEND, {
                username: props.match.params.username,
                id: props.match.params.id,
              })}
              label={<span className="tabLabel">{t("CENTER")}</span>}
            />
            <Tab
              component={Link}
              value={"about"}
              to={getRouteUrl(ROUTE_PAGE_FRIEND_ABOUT, {
                username: props.match.params.username,
                id: props.match.params.id,
              })}
              label={<span className="tabLabel">{t("PERSONAL DETAILS")}</span>}
            />
            <Tab
              component={Link}
              value={"cv"}
              to={getRouteUrl(ROUTE_PAGE_FRIEND_CV, {
                username: props.match.params.username,
                id: props.match.params.id,
              })}
              label={<span className="tabLabel">{t("CV")}</span>}
            />
            <Tab
              component={Link}
              value={"friends"}
              to={getRouteUrl(ROUTE_PAGE_FRIEND_FRIENDS, {
                username: props.match.params.username,
                id: props.match.params.id,
              })}
              label={<span className="tabLabel">{t("FRIENDS")}</span>}
            />
            <Tab
              component={Link}
              value={"files"}
              to={getRouteUrl(ROUTE_PAGE_FRIEND_FILES, {
                username: props.match.params.username,
                id: props.match.params.id,
              })}
              label={<span className="tabLabel">{t("FILES")}</span>}
            />
            <Tab
              component={Link}
              value={"recommendations"}
              to={getRouteUrl(ROUTE_PAGE_FRIEND_RECOMMENDATIONS, {
                username: props.match.params.username,
                id: props.match.params.id,
              })}
              label={<span className="tabLabel">{t("RECOMMENDATIONS")}</span>}
            />
          </Tabs>
        </Box>
        <Box>
          <TabPanel
            className="centerTabPanel"
            isVisible={lastPartOfPath === ""}
          >
            <CenterTab type={"friend"} id={props.match.params.id} />
          </TabPanel>
          <TabPanel
            className="peronalTabPanel"
            isVisible={lastPartOfPath === "about"}
          >
            <FriendPersonalDetails id={props.match.params.id} />
          </TabPanel>
          <TabPanel className="cvTabPanel" isVisible={lastPartOfPath === "cv"}>
            <FriendCvTab id={props.match.params.id} />
          </TabPanel>
          <TabPanel
            className="friendsTabPanel"
            isVisible={lastPartOfPath === "friends"}
          >
            <FriendsTab type={"friend"} id={props.match.params.id} />
          </TabPanel>
          <TabPanel
            className="fileTabPanel"
            isVisible={lastPartOfPath === "files"}
          >
            <FilesTab type={"friend"} id={props.match.params.id} />
          </TabPanel>
          <TabPanel
            className="discriminationTabPanel"
            isVisible={lastPartOfPath === "recommendations"}
          >
            <FriendRecommendationTab type={"friend"} id={props.match.params.id} />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default FriendProfile;
