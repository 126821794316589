// @flow
import React, {useEffect, useState} from 'react';

import Form from "./form";



const Post = (props: Props) => {


	return (
			<Form/>
	);
};

export default Post