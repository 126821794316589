// @flow
import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Button, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_COMPANY_CENTER, ROUTE_PAGE_COMPANY_CREATE
} from "../../routers/routes";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import {useTranslation} from "react-i18next";
import {PAGE_COMPANY} from "../../locales/pages/namespaces";
import {useHistory} from "react-router-dom";
import Images from "../../assets";
import {Done} from "@mui/icons-material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import { becomeCompanyMember, becomeMember, getCompanies, getGroups } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";

type Props = {

};

const CompaniesData = (props: Props) => {
	const {t} = useTranslation(PAGE_COMPANY)
	const history = useHistory()
	const {accessCode} = useUserData();
	const queryClient = useQueryClient();

	const {data: companiesData} = useQuery(["getCompanies", accessCode], getCompanies);
	const {mutate: becomeCompanyMemberAction} = useMutation({
		mutationFn: becomeCompanyMember,
		onSuccess: (data, variables, context) => {
			let companies = companiesData
			let company = companies.find(x=>x.id === variables.id)
			company.isMember= data.action === "insert" ? "true" : "false"
			queryClient.setQueryData(["getCompanies", accessCode], companies)
		},
	})
	const handleClick = (group) => {
		becomeCompanyMemberAction({id: group.id, accessCode})
	};

	return (
		<Box className="centerLeftTab" sx={{marginTop: '15px'}}>
			<Box className="centerLeftTab">
				<Box className="tabTitle">
					<Typography sx={{padding: '5px',width:'80%'}}>
						{t('companies sites')}
					</Typography>
					{/*<Typography sx={{marginTop:'1%'}}>*/}
					{/*	<Link href={getRouteUrl(ROUTE_PAGE_GROUPS_CREATE)} underline="hover" sx={{fontSize:'smaller',color:'#27293D', fontFamily: 'Open Sans'}}>{t('create a new group')}</Link>*/}
					{/*</Typography>*/}
					<Typography sx={{marginTop:'1%'}}>
						<Link href={getRouteUrl(ROUTE_PAGE_COMPANY_CREATE)} color={'#616161'} underline="hover" style={{textDecoration: 'none'}}  sx={{fontSize:'smaller', fontFamily: 'Open Sans-Regular'}}>{t('Create New Company')}</Link>
					</Typography>
				</Box>
				
				<Typography sx={{border: '1px #F3F3F3 solid'}}/>
			</Box>
			<Box className={'groupsContainer'}>
				{companiesData?.map(company => (
						<Card
							key={company.id}
							elevation={0}
							sx={{
								border: 'none',
								maxWidth: 300,
								width: {
									xs: '100%',
									sm: '49%',
									md: '49%',
									lg: '49%',
								},
								cursor: 'pointer'
							}}

						>
							<CardMedia
								image={(company['logo']) ?  company.logo : Images.noCover }
								title={company.title}
								style={{height: "150px",backgroundSize:"contain"}}
								onClick={() => history.push(getRouteUrl(ROUTE_PAGE_COMPANY_CENTER,{id:company.id}))}
							/>
							<CardContent>
								<Typography gutterBottom variant="h8" component="div" 	onClick={() => history.push(getRouteUrl(ROUTE_PAGE_COMPANY_CENTER,{id:company.id}))}>
									{`${company.title}`}
								</Typography>
								<Typography variant="body2" color="text.secondary">
									{company.subTitle}
								</Typography>
							</CardContent>
							<CardActions>
								<Button
									sx={{
										display: 'flex',
										width: '318px',
										padding: '7.522px 68.082px 7.483px 63.842px',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '2.501px',
										border: '0.625px solid rgba(38, 130, 213, 0.50)'
									}}
									onClick={company.isOwner === "false" ? () => handleClick(company) : () => null}
									size="small">
									{/*{t(group.member ? 'You are member' : 'Become a member') }*/}
									{company.isOwner === "false" ? company.isMember === "true" ? <> <Done
										color="success"/> {t('You are member')}</> : t('Become a member') : null}
									{company.isOwner === "true" && <> <Done color="success"/> {t('You are the owner')}</>}
								</Button>
							</CardActions>
						</Card>
					)
				)
				}
			</Box>
		</Box>
	);
};

export default CompaniesData