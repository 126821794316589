//@flow
import queryString from 'query-string'

export const getRouteUrl = (route, parameters, queryParameters) => {
  if (!route) return ''
  const { path } = route
  let url = parameters ? Object
    .keys(parameters)
    .reduce((previous, key) => (
      previous
        .replace(`:${key}?`, parameters[key])
        .replace(`:${key}`, parameters[key])
    ), path) : path
  url = url.replace(/\/:.*\?/, '')
  if (!queryParameters) return url
  return url + '?' + queryString.stringify(queryParameters)
}