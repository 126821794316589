import { configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import immutableTransform from 'redux-persist-transform-immutable';
import {createLogger} from 'redux-logger';

import {routerMiddleware} from 'connected-react-router'
import {reducers} from './reducers/reducers';
import {createStateSyncMiddleware} from "redux-state-sync";
import axiosMiddleware from "./middleware/axios";
import history from './history';
import Immutable from "immutable";

const middleWare = [];
middleWare.push(axiosMiddleware)
middleWare.push(routerMiddleware(history));
middleWare.push(createStateSyncMiddleware({
  whitelist: [],
}))
// Define the current app version
const APP_VERSION = '2.0.0';
const config = {
  transforms: [immutableTransform({
    whitelist: ['currentUser']
  })],
  key: 'rootIMS',
  whitelist: ['currentUser'], // state you want to persist
  // blacklist: [''], // state you do not want to persist
  storage,
  version: APP_VERSION, // Store the app version
  migrate: (state) => {
    // Check the current version against the stored version
    const currentVersion = APP_VERSION;
    const storedVersion = state?._persist?.version;

    if (currentVersion !== storedVersion) {
      // Clear persisted state if versions don't match
      return Promise.resolve(undefined); // This clears the persisted state
    }

    return Promise.resolve(state); // Otherwise, keep the state as is
  },
}

if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({
    stateTransformer: (state) => {
      let newState = {};
      for (let i of Object.keys(state)) {
        if (Immutable.Iterable.isIterable(state[i])) {
          newState[i] = state[i].toJS();
        } else {
          newState[i] = state[i];
        }
      }
      return newState;
    }
  });
  middleWare.push(logger);
}

const store = configureStore({
  reducer: persistReducer(config, reducers),
  middleware: middleWare
});

export const persistor = persistStore(store);

export default store

function clearPersistedState() {
  // Remove the persisted state from localStorage
  localStorage.removeItem('persist:rootIMS');

  // Purge the persisted state from the store
  persistor.purge();

  // Optionally, you can reload the page or dispatch an action to reset the store state
  // window.location.reload();
}

// Check and clear state on version change
const storedVersion = JSON.parse(localStorage.getItem('app_version'));

// If the versions differ, clear the persisted state
if (storedVersion !== APP_VERSION) {
  clearPersistedState();

  // Update stored version to the current version
  localStorage.setItem('app_version', JSON.stringify(APP_VERSION));
}