import imageCompression from "browser-image-compression";
import { PDFDocument, PDFImage } from "pdf-lib";

export const textTranslate = (language) => (item = { en: "", el: "" }) => {
  return item[language];
};

export const randomColor = () => {
  let hex = Math.floor(Math.random() * 0xFFFFFF);
  let color = "#" + hex.toString(16);

  return color;
};

export const daysPassed = (fromDate) => {
  // Parse the given date
  const givenDate = new Date(fromDate);

  // Get the current date and time
  const now = new Date();

  // Calculate the difference in time (milliseconds)
  const differenceInTime = now - givenDate;

  // Convert the difference from milliseconds to days
  const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));

  return differenceInDays;
};

export const getFileExtension = (filename) => {
  const parts = filename.split(".");
  return parts.length > 1 ? parts.pop() : "";
};

export const resizeImage = (file, width, height) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const targetAspectRatio = width / height;
      let newWidth, newHeight, offsetX, offsetY;

      // Determine whether to crop by width or height
      if (aspectRatio > targetAspectRatio) {
        // Image is wider than the target aspect ratio
        newHeight = img.height;
        newWidth = img.height * targetAspectRatio;
        offsetX = (img.width - newWidth) / 2;
        offsetY = 0;
      } else {
        // Image is taller than the target aspect ratio
        newWidth = img.width;
        newHeight = img.width / targetAspectRatio;
        offsetX = 0;
        offsetY = (img.height - newHeight) / 2;
      }

      // Create a canvas with the target dimensions
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        img,
        offsetX,
        offsetY,
        newWidth,
        newHeight,
        0,
        0,
        width,
        height,
      );

      // Convert the canvas to a Blob
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg"); // Change format if needed
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

export const linkify = (text) => {
  const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" style="text-decoration: none" rel="noopener noreferrer">${url}</a>`);
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getRadianAngle = (degreeValue) => {
  return (degreeValue * Math.PI) / 180;
};

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export const rotateSize = (width, height, rotation) => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

export const getCroppedImg = async (
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
};

export const convertUrlToFile = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const fileName = imageUrl.split("/").pop(); // Extract filename from URL
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting image URL to file:", error);
  }
};

export const handleImageCompression = async (event) => {
  const file = event.target.files[0];

  const options = {
    maxSizeMB: 1,          // Max file size (MB)
    maxWidthOrHeight: 1024, // Max dimensions (px)
    useWebWorker: true,     // Use web worker for better performance
  };

  try {
    const compressedImage = await imageCompression(file, options);
    return compressedImage;

  } catch (error) {
    console.error("Error compressing the image:", error);
  }
};

export const handlePdfCompression = async (event) => {
  const file = event.target.files[0];
  const arrayBuffer = await file.arrayBuffer();

// Load the PDF document
  const pdfDoc = await PDFDocument.load(arrayBuffer);

  // Get all pages
  const pages = pdfDoc.getPages();

  // Loop through each page and get its images
  for (const page of pages) {
    const images = page.node.Resources.XObject ?? {};
    for (const [key, image] of Object.entries(images)) {
      if (image instanceof PDFImage) {
        // Extract the image as a byte array
        const imageBytes = image.imageBytes;

        // Convert the image bytes to a Blob
        const blob = new Blob([imageBytes], { type: "image/jpeg" });

        // Compress the image using browser-image-compression
        const options = {
          maxSizeMB: 0.1, // Compress image to a max size of 0.1MB
          maxWidthOrHeight: 1000, // Reduce image dimensions
        };
        const compressedBlob = await imageCompression(blob, options);

        // Convert the compressed Blob back to bytes
        const compressedImageBytes = await compressedBlob.arrayBuffer();

        // Embed the compressed image back into the PDF
        const compressedImage = await pdfDoc.embedJpg(compressedImageBytes);
        page.node.Resources.XObject[key] = compressedImage.ref;
      }
    }
  }
  const compressedPdfBytes = await pdfDoc.save();
  // Create a download link for the compressed PDF
  return new Blob([compressedPdfBytes], { type: "application/pdf" });

};
