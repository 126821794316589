// @flow
import React, {useState} from 'react';
import Images from "../../assets";
import {Input} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import './search.css'
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN, ROUTE_PAGE_SEARCH_RESULTS} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import { useLocation, useParams } from "react-router";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import useMediaQuery from "@mui/material/useMediaQuery";

type Props = {

};

const Search = (props: Props) => {
	const {t} = useTranslation(PAGE_HOME)
	const history = useHistory()
	const location = useLocation()
	const [searchValue,setSearchValue] = useState("")
	const onInput = (e) => setSearchValue(e.target.value);

	const handleKeyDown = (e,text) => {
		let type = 'all'
			if(location?.pathname?.includes('/search-results/')){
			 let	typeArray = location?.pathname?.split('/')
				if(typeArray?.length === 4 && ['all','users','groups','companies','ads'].includes(typeArray[2])){
					type = typeArray[2]
				}else{

				}
			}

		if (e.keyCode === 13 || text==='click' ){

			history.push(getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS,{type: type,search:(document.getElementById("search").value != "") ? document.getElementById("search").value: " " }))
			setSearchValue("")
		}
	};

	const matches = useMediaQuery('(max-width:600px)');
	return (
		<div className="globalSearchBox">
			<img className="search-png" alt="search" src={Images.search}/>
			<Input
				id={'search'}
				className={'search'}
				name={'search'}
				inputtype={'text'}
				placeholder={t('search')}
				disableUnderline={true}
				onInput={onInput}
				value={searchValue}
				sx={{
					fontSize: '15px'
				}}
				onKeyDown={(e)=>handleKeyDown(e,null)}
				
			
			/>
			{matches &&
				<IconButton id={"icon-search"} color="primary" onClick={(e)=>handleKeyDown(e,"click")}>
					<SendIcon />
				</IconButton>
			}

		</div>
	);
};

export default Search