// @flow
import * as React from 'react';
import {useEffect} from 'react';
import NoAuthLayout from "../../components/layouts/noAuthLayout/noAuthLayout";
import {Button, Grid, Typography} from "@mui/material";
import LoginBanner from "../../components/loginBanner/loginBanner";
import {useTranslation} from "react-i18next";
import {PAGE_REGISTER} from "../../locales/pages/namespaces";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import Form from "./partials/form";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import Box from "@mui/material/Box";
import {enqueueSnackbar} from "notistack";

type Props = {}

const Register = (props: Props) => {
	const {t} = useTranslation(PAGE_REGISTER)
	const history = useHistory()
	const {loading, message, registerSuccess} = useUserData()
	const {registerUser, setUserMessage} = useUserAction()
	
	const onSubmit = (values) => {
		registerUser(values)
	}
	
	const validationSchema = yup.object({
		firstname: yup.string().required(t('required')),
		lastname: yup.string().required(t('required')),
		username: yup.string().required(t('required')),
		datebirth: yup.date().typeError(t('Enter a valid date')).required(t('required')),
		mobile: yup.string().required(t('required')),
		email: yup.string().email(t('Enter a valid email')).required(t('required')),
		password: yup.string().required(t('required')).min(8,'Ελάχιστος αριθμός χαρακτήρων 8'),
		agree: yup.bool().oneOf([true], t('You need to accept the terms and conditions')),
	})
	
	const formik = useFormik({
		initialValues: {
			firstname: '',
			lastname: '',
			username: '',
			datebirth: null,
			mobile: '',
			email: '',
			password: '',
			agree: false
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: 10000
					
				}
			)
			setUserMessage(null)
		}
	}, [message, setUserMessage, t])
	
	useEffect(() => {
		if (registerSuccess) formik.resetForm()
	}, [formik, registerSuccess])
	
	return (
		<NoAuthLayout>
			<Grid container maxWidth={'1024px'}>
				<Grid
					sx={{
						borderRight: {
							sm: 'none',
							md: '1px solid #ededed'
						},
					}}
					item
					xs={12}
					sm={12}
					md={6}
				>
					<LoginBanner/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
							padding: {
								xs: '6px 14px',
								sm: '12px 28px',
								md: '12px 46px'
							}
						}}
					>
						<Typography
							sx={{
								marginBottom: 1,
								color: '#050505',
								fontSize: '20px',
								fontWeight: '300',
								lineHeight: '40px',
								letterSpacing: 0,
								whiteSpace: 'nowrap',
								fontFamily: '"Open Sans-Light", Helvetica'
							}}
						>
							{t('Έχετε λογαριασμό;')}
						
						</Typography>
						<Button
							fullWidth
							variant="outlined"
							sx={{
								borderColor: '#2682d580',
							}}
							onClick={() => history.push(getRouteUrl(ROUTE_PAGE_LOGIN))}
						>
							{t('Συνδεθείτε')}
						</Button>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
				>
					<Form
						formik={formik}
						loading={loading}
					
					/>
				</Grid>
			</Grid>
		</NoAuthLayout>
	)
}
export default Register
