// @flow 
import React, {useEffect, useState} from 'react';
import GeneralDetailsTab from "../rightTabs/generalDetailsTab";
import RecommendationTab from "../rightTabs/recommendationTab";
import FileTab from "../rightTabs/fileTab";
import Box from "@mui/material/Box";
import {Button, IconButton, ListItem, ListItemAvatar, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import './friendsTab.css'
import Images from "../../../assets";
import Avatar from "@mui/material/Avatar";
import {grey} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import Footer from "../../footer/footer";
import theme from "../../../theme/theme";
import axios from "axios";
import useUserData from "../../../hooks/user/useUserData";
import {People, PersonAddAlt1Outlined} from "@mui/icons-material";
import ListItemPerson from "../../loginBanner/listItemPerson";

type Props = {};

const FriendsTab = (props: Props) => {
	console.log('FriendTabProps = ', props)
	const {t} = useTranslation(PAGE_PERSONAL)
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const {accessCode} = useUserData();
	const [loading, setLoading] = useState();
	const [friendsData, setFriendsData] = useState([]);
	const [sendRequest, setSendRequest] = useState();
	
	useEffect(() => {
		if (props.type === 'personal') {
			axios({
				url: "https://dev.ostrialinked.gr/api/api.php?q=122c&scode=" + accessCode,
				method: 'post',
				// data: {'idu': props.id},
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}).then((response) => {
				setFriendsData(response.data)
				setLoading(true)
				
			}).catch((error) => {
			
			})
		} else {
			axios({
				url: "https://dev.ostrialinked.gr/api/api.php?q=122d&scode=" + accessCode,
				method: 'post',
				data: {'idu': props.id},
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}).then((response) => {
				console.log(response.data)
				setFriendsData(response.data)
				setLoading(true)
				
			}).catch((error) => {
			
			})
		}
	}, [accessCode]);

	useEffect(() => {
		if(sendRequest){
			if (props.type === 'personal') {
				axios({
					url: "https://dev.ostrialinked.gr/api/api.php?q=122c&scode=" + accessCode,
					method: 'post',
					// data: {'idu': props.id},
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				}).then((response) => {
					setFriendsData(response.data)
					setLoading(true)

				}).catch((error) => {

				})
			} else {
				axios({
					url: "https://dev.ostrialinked.gr/api/api.php?q=122d&scode=" + accessCode,
					method: 'post',
					data: {'idu': props.id},
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				}).then((response) => {
					console.log(response.data)
					setFriendsData(response.data)
					setLoading(true)

				}).catch((error) => {

				})
			}
			setSendRequest(false)
		}
	}, [sendRequest]);
	
	const handleRequestSend = (friendId) => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=122a&scode=" + accessCode,
			method: 'post',
			data: {'idu': friendId},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			setSendRequest(true);
			
		}).catch((error) => {
		
		})
	}
	const handleRemoveRequest = (friendId) => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=122b&scode=" + accessCode,
			method: 'post',
			data: {'idu': friendId},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			setSendRequest(true);
			
		}).catch((error) => {
		
		})
	}

	return (
		<>
			{loading &&
				<Box className="centerLeftTab"
					 sx={{
						 width: {
							 xs: '100%',
							 sm: '100%',
							 md: '60%',
							 lg: '60%'
						 }
					 }}
				>
					<Box className="centerLeftTab">
						<Typography sx={{padding: '5px'}}>
							{t('friends')}
						</Typography>
						<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					</Box>
					<Box className="centerLeftTab" sx={{display: 'flow'}}>
						{(friendsData && friendsData?.length > 0) ?
							<>
								{friendsData.map(friendData => (
										<ListItem key={friendData.id} className="friendsData">
											<ListItemPerson
												id={friendData.id}
												image_profile={friendData.image_profile}
												firstname={friendData.firstname}
												lastname={friendData.lastname}
												username={friendData.username}
												handleRequestSend={handleRequestSend}
												handleRemoveRequest={handleRemoveRequest}
												isFriend={friendData.friend}
											/>
										</ListItem>
									)
								)
								}
							</>
							:
							<Box sx={{padding:'10px',textAlign:'center'}}>
								{t('There are not friends')}
							</Box>
						}
					
					</Box>
				</Box>
			}
			{!matches && (
				<Box sx={{width: '40%',}}>
					<GeneralDetailsTab userId={props.id}/>
					{/*<RecommendationTab userId={props.id}/>*/}
					<FileTab userId={props.id}/>
					<Footer color={'#aeacac'}/>
				</Box>
			)}
		</>
	);
};

export default FriendsTab