import Box from "@mui/material/Box";
import {Divider, Paper, Typography} from "@mui/material";
import Symvasi from "../terms&GDPR/symvasi";
import PrivacyPolicy from "../terms&GDPR/privacyPolicy";
import PrivacyCookies from "../terms&GDPR/privacyCookies";
import React from "react";

const Terms = ()=>{
    return(
        <Box
            sx={{
                marginTop: '20px',
                marginBottom: '20px',
                paddingBottom: '20px',

            }}
        >
            <Paper elevation={0} sx={{borderRadius: '12px'}}>
                <Box
                    sx={{
                        padding: '15px',
                        display: 'grid',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                        marginLeft: '10px',
                        marginRight: '20px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#27293d',
                            fontFamily: '"Open Sans-Bold", Helvetica',
                            fontSize: '24px',
                            fontWeight: 700
                        }}
                    >
                        Πληροφορίες Ostria
                    </Typography>
                    <Divider></Divider>
                    <Box>
                        <Typography
                            sx={{
                                color: '#020700',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '20px',
                                fontWeight: 400,
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >
                            Σύμβαση
                        </Typography>
                        <Symvasi/>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: '#020700',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '20px',
                                fontWeight: 400,
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >
                            Πολιτική Απορρήτου Ostrialinked
                        </Typography>
                        <PrivacyPolicy/>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: '#020700',
                                fontFamily: '"Open Sans-Regular", Helvetica',
                                fontSize: '20px',
                                fontWeight: 400,
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >Πολιτική Cookies Ostrialinked
                        </Typography>
                        <PrivacyCookies/>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}
export default Terms