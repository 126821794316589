// @flow
import React from 'react';
import {useMediaQuery, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Images from "../../assets";
import './espaBanner.css'
import { Link } from "react-router-dom";

type Props = {

};

const EspaBanner = (props: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Box
			className={'espa-banner'}
			sx={{
				padding: {
					xs: '12px 0 12px 0',
					sm: '12px 29px 12px 28px',
					md: '12px 49px 12px 48px'
				}
			}}
		>
			<a
				href="https://dev.ostrialinked.gr/doc/webtpe.pdf"
				target="_blank" rel="noreferrer"
			>
				<img
					className={`espa-logo ${matches ? '' : 'espa-logo-sm'}`}
					alt="espa" src={Images.espaLogo}/>
			</a>
		</Box>
	)
};

export default EspaBanner

