import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import Box from "@mui/material/Box";
import {Button, Typography, useMediaQuery} from "@mui/material";
import Link from "@mui/material/Link";
import axios from "axios";
import RecommendationTab from "../../userTabs/rightTabs/recommendationTab";
import FileTab from "../../userTabs/rightTabs/fileTab";
import FriendsTab from "../../userTabs/rightTabs/friendsTab";
import Footer from "../../footer/footer";
import theme from "../../../theme/theme";
import "./friendPersonalDetails.css"
import GeneralDetailsTab from "../../userTabs/rightTabs/generalDetailsTab";


const FriendPersonalDetails = (props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const {accessCode} = useUserData()
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [personalData, setPersonalData] = useState()
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=20c&scode=" + accessCode,
			method: 'post',
			data: {'idu': props.id},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log(response.data)
	
			setPersonalData(response.data[0])
			
			setLoading(true)
			
			// debugger
			
		}).catch((error) => {
		
		})
	}, [accessCode, props.id]);
	
	return (
		<>
			{loading &&
				<Box className="centerLeftTab"
					 sx={{
						 width: {
							 xs: '100%',
							 sm: '100%',
							 md: '60%',
							 lg: '60%'
						 }
					 }}
				>
					
					
					<Box className="lineTitle">
						<Typography sx={{padding: '5px'}}>
							{t('general details')}
						</Typography>
					</Box>
					<Typography sx={{border: '1px #F3F3F3 solid', marginRight: '3%',}}/>
					<Box className="leftTabContainer">
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.country} {personalData?.city}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.family_state}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.work_state}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.address}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.phone1}</Typography>
						<Typography sx={{
							fontSize: 'smaller',
							color: '#616161',
							fontFamily: 'Open Sans-Regular'
						}}>{personalData?.phone2}</Typography>
						<Box className="leftTabLinks">
							<Link href={personalData?.facebook} underline="hover" sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular'
							}}>{personalData?.facebook}</Link>
							{/*<Link href={personalData.instagramLink} underline="hover" sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans'}}>{personalData.instagramLink}</Link>*/}
							<Link href={personalData?.twitter} underline="hover" sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular'
							}}>{personalData?.twitter}</Link>
							<Link href={personalData?.linkedin} underline="hover" sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular'
							}}>{personalData?.linkedin}</Link>
							<Link href={personalData?.url} underline="hover" sx={{
								fontSize: 'smaller',
								color: '#616161',
								fontFamily: 'Open Sans-Regular'
							}}>{personalData?.url}</Link>
						</Box>
					</Box>
				</Box>
			}
			{!matches && (
				<Box sx={{width: '40%',}}>
					{/*<RecommendationTab userId={props.id}/>*/}
					<FileTab userId={props.id}/>
					<FriendsTab userId={props.id}/>
					<Footer color={'#aeacac'}/>
				</Box>
			)}
		</>
	)
		;
};

export default FriendPersonalDetails