import type {UnmappedUser, User} from "./types";

export const mapUser: User = (datum: UnmappedUser) => {
    if (!datum) return
    return {
        id: datum.id,
        email: datum.email,
        firstName: datum.firstname,
        mobile: datum.mobile,
        confirm: datum.confirm === '1',
        confirmationNum: datum.confnum,
        lastName: datum.lastname,
        username: datum.username,
        date_birth: datum.date_birth,
        member_from: datum.member_from,
        pontoi: datum.pontoi
    }
}