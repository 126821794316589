import FeedLayout from "../layouts/feedLayout/feedLayout";
import {Divider, Grid, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import FeedLeftColumn from "../feedLeftColumn/feedLeftColumn";
import FeedRightColumn from "../feedRightColumn/feedRightColumn";
import React from "react";
import Symvasi from "./symvasi";
import PrivacyPolicy from "./privacyPolicy";
import PrivacyCookies from "./privacyCookies";
import Terms from "../terms/terms";

const TermsOfUse = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid container spacing={2} justifyContent="space-between">
            {!matches && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                >
                    <FeedLeftColumn/>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={5}
            >
                <Terms/>
            </Grid>
            {!matches && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                >
                    <FeedRightColumn/>
                </Grid>
            )}
        </Grid>

    )
}
export default TermsOfUse