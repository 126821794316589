import { Avatar, Stack, Box, FormHelperText } from "@mui/material";
import "./chatMessage.style.css";
import useUserData from "../../hooks/user/useUserData";
import { useHistory } from "react-router-dom";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_FRIEND } from "../../routers/routes";
export default function ChatMessage(messageInfo) {
  const { userId } = useUserData();
  const history = useHistory();

  const { id_sender, datet, message, receiver_image, sender_username } =
    messageInfo.message;

  const isSender = id_sender === userId;
  return (
    <>
      <Stack direction="row">
        {!isSender && (
          <Avatar
            src={receiver_image}
            sx={{
              fontSize: "small",
              mr: 1,
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={() =>
              history.push(
                getRouteUrl(ROUTE_PAGE_FRIEND, {
                  username: sender_username,
                  id: id_sender,
                })
              )
            }
          />
        )}
        <Box
          className={isSender ? "own" : "notOwn"}
          sx={{ fontSize: "0.86666rem" }}
        >
          {message}
        </Box>
      </Stack>
      <FormHelperText
        sx={[
          ...(isSender
            ? [
                {
                  ml: "auto",
                  pr: 1,
                },
              ]
            : [
                {
                  mr: "auto",
                  pl: 1,
                },
              ]),
        ]}
      >
        {datet}
      </FormHelperText>
    </>
  );
}
