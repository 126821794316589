// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import { ListItem, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Images from "../../../assets";
import './rightTabs.css'
import ListItemText from "@mui/material/ListItemText";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_FRIEND_FILES, ROUTE_PAGE_PERSONAL_FILES } from "../../../routers/routes";
import useUserData from "../../../hooks/user/useUserData";
import theme from "../../../theme/theme";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getFiles, getFriendsFiles } from "../../../api/requests";
import { getFileExtension } from "../../../helpers/helpers";
import { PictureAsPdf } from "@mui/icons-material";
import { imageExtensions } from "../../../helpers/constants";
import ContentLoader from "../../contentLoader/contentLoader";
import Modal from "@mui/material/Modal";
import CardMedia from "@mui/material/CardMedia";
import OstriaModal from "../../ostriaModal/ostriaModal";

type Props = {

};

const FileTab = (props: Props) => {
	const { t } = useTranslation(PAGE_PERSONAL);
	const [isModalOpen, setOpenModal] = React.useState(null);
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const { id } = useParams();
	const { accessCode,data } = useUserData();
	const { data: files, isLoading } = useQuery({ queryKey: ["userFiles", accessCode], queryFn: getFiles, enabled: !id });
	const { data: friendsFiles, isLoading: isFiendsFilesLoading } = useQuery({
		queryKey: ["userFriendsFiles", accessCode, id],
		queryFn: getFriendsFiles,
		enabled: !!id,
	});

	return (
		<Box className="rightTab">
			<Box sx={{width:'100%',display:'inline-flex'}}>
				<Typography>
					{t('files')}
				</Typography>
			
				<Typography sx={{textAlign:'end',width: '75%'}}>
					<Link href={id ?getRouteUrl(ROUTE_PAGE_FRIEND_FILES, {username: data.username,id:id}) : getRouteUrl(ROUTE_PAGE_PERSONAL_FILES, {username: data.username})} underline="hover" sx={{fontSize:'small'}}>{t('all')}</Link>
				</Typography>
			</Box>
			
			<Box className="rightTabDetails">
				<Typography sx={{border:'1px #F3F3F3 solid', marginRight: '3%'}}/>
				<Box sx={{display:'inline'}}>
					{id ?
						isFiendsFilesLoading === false
							?
							friendsFiles?.map(x =>
								getFileExtension(x) === "pdf" ?
									<Link href={x} target="_blank" style={{ objectFit: "contain", width: "120px" }}
												underline="hover" sx={{
										fontSize: "large",
										color: "#616161",
										fontFamily: "Open Sans-Regular",
										border: "1px solid grey",
										borderRadius: "25px",
										padding: "5px 5px 5px 10px",
										margin: "10px",
									}}>
										<PictureAsPdf />
									</Link>
									: imageExtensions.includes(getFileExtension(x)) ?
										<img
											src={`${x}`}
											alt={x}
											loading="lazy"
											style={{ objectFit: "contain", width: "120px", cursor: 'pointer'  }}
											onClick={() => setOpenModal(x)}
										/>
										: null,
							) :
							<ContentLoader loading={isFiendsFilesLoading}></ContentLoader>
						: isLoading === false
							?
							files.map(x =>
								getFileExtension(x) === "pdf" ?
									<Link href={x} target="_blank" style={{ objectFit: "contain", width: "120px"}}
												underline="hover" sx={{
										fontSize: "large",
										color: "#616161",
										fontFamily: "Open Sans-Regular",
										border: "1px solid grey",
										borderRadius: "25px",
										padding: "5px 5px 5px 10px",
										margin: "10px",
									}}>
										<PictureAsPdf />
									</Link>
									: imageExtensions.includes(getFileExtension(x)) ?
										<img
											src={`${x}`}
											alt={x}
											loading="lazy"
											style={{ objectFit: "contain", width: "120px", cursor: 'pointer' }}
											onClick={() => setOpenModal(x)}
										/>
										: null,
							)
							:
							<ContentLoader loading={isLoading}></ContentLoader>
					}
				</Box>
			</Box>
			<OstriaModal
				open={Boolean(isModalOpen)}
				onClose={() => {
					setOpenModal(null);
				}}
			>
					<CardMedia
						component="img"
						image={isModalOpen}
						alt={isModalOpen}
					/>
			</OstriaModal>
		</Box>
	);
};

export default FileTab