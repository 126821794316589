// @flow
import React, {useEffect, useState} from 'react';
import FeedLayout from "../../components/layouts/feedLayout/feedLayout";
import {useTranslation} from "react-i18next";
import {PAGE_JOB} from "../../locales/pages/namespaces";
import {Button, FormControl, FormHelperText, Grid, TextField, Typography, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Images from "../../assets";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_COMPANIES, ROUTE_PAGE_HOME, ROUTE_PAGE_JOB_APPLY } from "../../routers/routes";
import JobsShow from "./jobsShow";
import * as yup from "yup";
import {enqueueSnackbar} from "notistack";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import { useMutation, useQuery } from "react-query";
import { addCompanyAd, getAdCompany, jobApply } from "../../api/requests";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router";
import { handleImageCompression, handlePdfCompression } from "../../helpers/helpers";


type Props = {};

const JobApply = (props: Props) => {
	const {t} = useTranslation(PAGE_JOB);
	const { id } = useParams();
	const { accessCode } = useUserData();
	const history = useHistory()
	const theme = useTheme();
	const { data: companyAdData, isLoading } = useQuery(["getAdCompany", accessCode,id ], getAdCompany);
	const { mutate: jobApplyAction } = useMutation({
		mutationFn: jobApply,
		onSuccess: (data, variables, context) => {
			if(data.application === "inserted"){
				history.push(getRouteUrl(ROUTE_PAGE_HOME));
			}
		},
	});

	
	const validationSchema = yup.object({
		text: yup
		.string()
		.required(t('required')),
		cv: yup
		.string()
		.required(t('required')),
	})
	const onSubmit = (values) => {

		let formData = new FormData();
		formData.append('textsyn', values.text);
		formData.append('cv', values.cv);
		formData.append('id_agg', id);
		jobApplyAction({
			accessCode,
			form:formData
		})
	};

	const formik = useFormik({
		initialValues: {
			text: "",
			cv: "",
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	});
	const handleFileChange = async (event) => {
		let compressedFile = event.target.files[0];
		if (event.target.files[0].type.includes("image")) {
			compressedFile = await handleImageCompression(event);
		} else if (event.target.files[0].type.includes("pdf")) {
			compressedFile = await handlePdfCompression(event);
		}
		formik.setFieldValue('cv', compressedFile);
	};
	if(isLoading){
		return null
	}
	return (
		<FormikProvider value={formik} onSubmit={formik.handleSubmit}
						noValidate>
			<Box component="form"
				 onSubmit={formik.handleSubmit}
				 noValidate>
				<JobsShow>
					<Box className="jobInfo">
						<Typography sx={{
							color: '#27293D',
							fontFamily: 'Open Sans-Regular',
							fontSize: '24px',
							fontStyle: 'normal',
							fontWeight: '400',
						}}>
							{t('Application of interest for a job position')}
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Grid container>
									{/*<Box*/}
									{/*	className="company-logo"*/}
									{/*	sx={{*/}
									{/*		backgroundColor: "#f0f0f0",*/}
									{/*		border: "1px solid",*/}
									{/*		borderColor: "#27293d80",*/}
									{/*		borderRadius: "9px",*/}
									{/*		height: "112px",*/}
									{/*		width: "132px",*/}
									{/*		flex: "0 0 auto", // Fixed size*/}
									{/*		display: "flex",*/}
									{/*		alignItems: "center",*/}
									{/*		justifyContent: "center",*/}
									{/*	}}*/}
									{/*>*/}
									{/*	<Typography*/}
									{/*		sx={{*/}
									{/*			color: '#000000',*/}
									{/*			fontFamily: '"Open Sans-Italic", Helvetica',*/}
									{/*			fontSize: '15px',*/}
									{/*			fontStyle: 'italic',*/}
									{/*			fontWeight: '400',*/}
									{/*			letterSpacing: '0',*/}
									{/*			textAlign: 'center',*/}
									{/*		}}*/}
									{/*	>*/}
									{/*		{t("Company")}*/}
									{/*		<br/>*/}
									{/*		{t("Logo")}*/}
									{/*	</Typography>*/}
									{/*</Box>*/}
									{/*{!companyAdData.company.logo && <Box*/}
									{/*	className="company-logo"*/}
									{/*	sx={{*/}
									{/*		backgroundColor: "#f0f0f0",*/}
									{/*		border: "1px solid",*/}
									{/*		borderColor: "#27293d80",*/}
									{/*		borderRadius: "9px",*/}
									{/*		height: "112px",*/}
									{/*		width: "132px",*/}
									{/*		flex: "0 0 auto", // Fixed size*/}
									{/*		display: "flex",*/}
									{/*		alignItems: "center",*/}
									{/*		justifyContent: "center",*/}
									{/*	}}*/}
									{/*>*/}
									{/*	*/}
									{/*	<Typography*/}
									{/*		sx={{*/}
									{/*			color: '#000000',*/}
									{/*			fontFamily: '"Open Sans-Italic", Helvetica',*/}
									{/*			fontSize: '15px',*/}
									{/*			fontStyle: 'italic',*/}
									{/*			fontWeight: '400',*/}
									{/*			letterSpacing: '0',*/}
									{/*			textAlign: 'center',*/}
									{/*		}}*/}
									{/*	>*/}
									{/*		{t("Company")}*/}
									{/*		<br/>*/}
									{/*		{t("Logo")}*/}
									{/*	</Typography>*/}
									{/*</Box>*/}
									{/*}*/}
									{/*{companyAdData.company.logo &&*/}
									{/*	<img alt="google-logo" style={{ height: "112px",width: "132px",}}*/}
									{/*		 src={companyAdData.company.logo}/>*/}
									{/*}*/}
									<Grid item xs={12} md={6} sx={{paddingLeft: theme.spacing(2),}}>
										<Box
											sx={{
												color: "#27293D",
												fontFamily: "'Open Sans-Bold', Helvetica",
												fontSize: {xs: "20px", md: "28px"},
												fontWeight: "700",
												whiteSpace: "nowrap", // Prevent title from wrapping
											}}
										>
											{companyAdData.title}
										</Box>
										<Box
											sx={{
												color: '#27293d',
												fontFamily: '"Open Sans-Bold", Helvetica',
												fontSize: '15px',
												fontWeight: '700',
												height: '16px'
											}}
										>
											{companyAdData.company.official_title}
										</Box>
										<Box
											sx={{
												color: '#616161',
												fontFamily: '"Open Sans-Regular", Helvetica',
												fontSize: '15px',
												fontWeight: '400',
												marginTop: '7px'
											}}
										>
											<img src={Images.redPin}/>
											{companyAdData.city}
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Box sx={{padding: '10px'}}>
								<Box sx={{display: 'flex'}}>
									<Box className="jobTag">
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Bold", Helvetica',
											fontSize: '12px',
											fontWeight: '700',
											textAlign: "center"
										}}
										> {t('employmentType')}
										</Typography>
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Regular", Helvetica',
											fontSize: '12px',
											fontWeight: '400',
											textAlign: "center"
										}}
										> {companyAdData.worktype}
										</Typography>
									</Box>
									<Box className="jobTag">
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Bold", Helvetica',
											fontSize: '12px',
											fontWeight: '700',
											textAlign: "center"
										}}
										> {t('Salary')}
										</Typography>
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Regular", Helvetica',
											fontSize: '12px',
											fontWeight: '400',
											textAlign: "center"
										}}
										> {companyAdData.salary + '€'}
										</Typography>
									</Box>
									<Box className="jobTag">
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Bold", Helvetica',
											fontSize: '12px',
											fontWeight: '700',
											textAlign: "center"
										}}
										> {t('Position Category')}
										</Typography>
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Regular", Helvetica',
											fontSize: '12px',
											fontWeight: '400',
											textAlign: "center"
										}}
										> {companyAdData.category}
										</Typography>
									</Box>
									<Box className="jobTag">
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Bold", Helvetica',
											fontSize: '12px',
											fontWeight: '700',
											textAlign: "center"
										}}
										> {t('Experience Level')}
										</Typography>
										<Typography sx={{
											color: '#ffffff',
											fontFamily: '"Open Sans-Regular", Helvetica',
											fontSize: '12px',
											fontWeight: '400',
											textAlign: "center"
										}}
										> {companyAdData.level}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box sx={{padding: '15px'}}>
							<Typography sx={{
								color: '#595959',
								fontFamily: 'Open Sans-Regular", Helvetica',
								fontSize: '13.125px',
								fontStyle: 'normal',
								fontWeight: '400',
								marginBottom:'10px',
							}}>
								Συμπληρώνοντας τα στοιχεία παρακάτω θα κάνετε αποστολή αίτησης ενδιαφέροντος για την
								προαναφερόμενη θέση εργασίας. Σας δίνετε η δυνατότητα, να επισυνάψετε ένα μικρό κείμενο
								που θα συνοδεύει την αίτησή σας καθώς και ένα πρόσφατο βιογραφικό σε μορφή pdf. Πατώντας
								το κουμπί κάνε αίτηση, η εταιρεία να λάβει τα στοιχεία επικοινωνίας καθώς και λοιπά
								στοιχεία που έχετε διαθέσιμα στην πλατφόρμα
							</Typography>
							<FormControl
								variant="standard"
								fullWidth
								sx={{
									marginBottom: 2
								}}
								error={
									formik.touched['text'] &&
									Boolean(formik.errors['text'])
								}
							>
								<TextField
									id={"text"}
									value={formik.values.text}
									required
									name={"text"}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									multiline
									rows={4}
									variant="outlined"
									placeholder={t('Accompanying text')}
									sx={{
										width: '-webkit-fill-available',
										display: 'flex',
										"& .MuiOutlinedInput-root": {
											":focus-within": {
												border: "0.0px solid #ffffff !important",
											},
										},
										"& .MuiFormLabel-root": {
											color: "#616161",
											fontSize: "15px",
											fontWeight: 400,
										},
										// "& fieldset": { border: 'none' },
										marginBottom: "10px",
										
									}}
									inputProps={{
										sx: {
											color: '#595959',
											fontFamily: '"Open Sans-Regular", Helvetica',
											fontSize: '13.1px',
											fontWeight: ' 400'
										},
									}}
								/>
								<FormHelperText>
									<ErrorMessage name={'text'}/>
								</FormHelperText>
							</FormControl>
								<IconButton
									variant="contained"
									component="label"
								>
									<img alt="clip" src={Images.clipPostComponent}/>
									<input
										id={'cv'}
										name={'cv'}
										type="file"
										hidden
										accept="image/png, image/jpeg"
										onChange={async (e)=> await handleFileChange(e)}/>
									<Box marginLeft={'4px'} color={'#616161'} fontSize={'15px'} fontWeight={'400'}>{t('cv')}</Box>
								</IconButton>
								{formik.values.cv && (<Box>{formik.values.cv.name}</Box>)}
								<Box sx={{display:'start-flex'}}>
									<Button
										type={"submit"}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: '4px',
											background: '#27293D',
											"&:hover, &.Mui-focusVisible": {backgroundColor: "grey"},
											padding: '12.5px 85px 12.5px 85px',
											margin:'5px'
											
										}}
									>
										<Typography sx={{
											color: '#FFF',
											textAlign: 'center',
											fontFamily: 'Open Sans-Regular',
											fontSize: '17px',
											fontStyle: 'normal',
											fontWeight: '400',
											
										}}>
											{t('send request')}
										</Typography>
									</Button>
									<Button
										onClick={()=> history.goBack()}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: '4px',
											border: '1px solid rgba(38, 130, 213, 0.50)',
											padding: '12.5px 85px 12.5px 85px',
											margin:'5px'
											
										}}
									>
										<Typography sx={{
											color: '#27293D',
											textAlign: 'center',
											fontFamily: 'Open Sans-Regular',
											fontSize: '17px',
											fontStyle: 'normal',
											fontWeight: '400',
										}}>
											{t('cancel')}
										</Typography>
									</Button>
								</Box>
							</Box>
						</Grid>
					</Box>
				</JobsShow>
			</Box>
		</FormikProvider>
	);
};

export default JobApply