// @flow
import React, {useState} from 'react';
import {Button, IconButton, ListItem, TextField, Typography} from "@mui/material";
import {CloseRounded, Delete, EditOutlined, SaveOutlined} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import useUserData from "../../../hooks/user/useUserData";

type Props = {

};

const Education = (props: Props) => {
	const {accessCode} = useUserData();
	const educationData = props.educationData;
	
	const [showEducationEdit,setShowEducationEdit] = useState(false)
	
	
	const validationSchema = yup.object({
		sxoli: yup
		.string()
		.required('Απαιτείται'),
		title: yup
		.string()
		.required('Απαιτείται'),
	})
	const onSubmit = (values) => {
		
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=16c&scode=" + accessCode,
			method: 'post',
			data:values,
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log('update',response)
			setShowEducationEdit(false)
			props.refreshData(true)
			
			
		}).catch((error) => {
		
		})
	}
	
	const formik = useFormik({
		initialValues: {
			sxoli: educationData.sxoli,
			title: educationData.title,
			year: educationData.year,
			ide: educationData.id
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})

	const handleDelete = (id) => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=16d&scode=" + accessCode,
			method: 'post',
			data:{'ide':id},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			// props.refreshData(true)
			window.location.reload();
			
			
		}).catch((error) => {
		
		})
	}
	return (
		<div>
			{showEducationEdit ?
				<>
					<FormikProvider value={formik} onSubmit={formik.handleSubmit}
									noValidate>
						<Box component="form"
							 onSubmit={formik.handleSubmit}
							 noValidate>
							<Box sx={{display:'flex',justifyContent:'space-between'}}>
								<Box>
									<TextField size={'small'}
											   id={'sxoli'}
											   name={'sxoli'}
											   onChange={formik.handleChange}
											   onBlur={formik.handleBlur}
											   // label={'Σχολή'}
											   sx={{
												   padding: '2%',
												   marginBottom: '1%',
												   '& .MuiOutlinedInput-root': {
													   // ':focus-within': { border: '0.0px solid #ffffff !important' }
													   fontSize: 'smaller',
												   },
											   }}
											   variant="outlined"
											   value={formik.values.sxoli}
									/>
									<TextField size={'small'}
											   id={'title'}
											   name={'title'}
											   onChange={formik.handleChange}
											   onBlur={formik.handleBlur}
											   // label={'Τίτλος'}
											   sx={{
												   padding: '2%',
												   marginBottom: '1%',
												   '& .MuiOutlinedInput-root': {
													   // ':focus-within': { border: '0.0px solid #ffffff !important' }
													   fontSize: 'smaller',
												   },
											   }}
											   variant="outlined"
											   value={formik.values.title}
									/>
									<TextField size={'small'}
											   id={'year'}
											   name={'year'}
											   onChange={formik.handleChange}
											   onBlur={formik.handleBlur}
											   // label={'Έτος'}
											   sx={{
												   padding: '2%',
												   marginBottom: '1%',
												   '& .MuiOutlinedInput-root': {
													   // ':focus-within': { border: '0.0px solid #ffffff !important' }
													   fontSize: 'smaller',
												   },
											   }}
											   variant="outlined"
											   value={formik.values.year}
									/>
								</Box>
								<Box sx={{display:'inline-flex'}}>
									<Box >
										<Button
											type={'submit'}
											sx={{
												fontSize: 'smaller'
											}}
										>
											<SaveOutlined />
										</Button>
									
									</Box>
									<Box>
										<Button>
											<CloseRounded onClick={()=>setShowEducationEdit(false)}/>
										</Button>
									</Box>
									
								</Box>
								
							</Box>
							
							
						</Box>
					</FormikProvider>
				</>
				:
			<ListItem key={educationData.id} className="announcement"
					  secondaryAction={
						  <>
							  <IconButton aria-label="edit" onClick={() => setShowEducationEdit(true)}>
								  <EditOutlined/>
							  </IconButton>
							  <IconButton aria-label="edit" onClick={() => handleDelete(educationData.id)}>
								  <Delete/>
							  </IconButton>
						  </>
					  }>
				<ListItemText>
					<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{educationData.sxoli}</Typography>
					<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{educationData.title}</Typography>
					<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{educationData.year}</Typography>
				</ListItemText>
			</ListItem>
			}
		</div>
	);
};

export default Education