// @flow
import {
    Button,
    Divider, FormControl,
    FormHelperText,
    Grid, Icon,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import FeedLeftColumn from "../../components/feedLeftColumn/feedLeftColumn";
import Box from "@mui/material/Box";
import FeedRightColumn from "../../components/feedRightColumn/feedRightColumn";
import React from "react";
import * as yup from "yup";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import axios from "axios";
import useUserData from "../../hooks/user/useUserData";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_CONTACT, ROUTE_PAGE_HOME} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import ContactForm from "../../components/contactForm/contactForm";

type Props = {};

const Contact = (props: Props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const {accessCode} = useUserData();
    const history = useHistory()

    const onSubmit = (values) => {
        axios({
            url: "https://dev.ostrialinked.gr/api/api.php?q=201&scode=" + accessCode,
            method: 'post',
            data: values,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            history.push(getRouteUrl(ROUTE_PAGE_HOME))
        }).catch((error) => {

        })
    }
    const validationSchema = yup.object({
        fullname: yup
            .string()
            .required(),
        email: yup
            .string()
            .required(),
    })
    const formik = useFormik({
        initialValues: {
            fullname: '',
            email: '',
            phone: '',
            sxolio: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
    })
    const textareaStyle = {
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontFamily: '"Open Sans-Italic", Helvetica',
        fontSize: '18px',
        fontStyle: 'italic',
        fontWeight: 400,
        height: '200px',
        width: '100%',
        overflow: 'auto',
        marginBottom: '10px',
        borderColor: '#ffffff',
    };
    return (
        <Grid container spacing={2} justifyContent="space-between">
            {!matches && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                >
                    <FeedLeftColumn/>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={5}
            >
                <FormikProvider value={formik} onSubmit={formik.handleSubmit}
                                noValidate>
                    <ContactForm
                        formik={formik}
                        textareaStyle={textareaStyle}
                    />
                </FormikProvider>
            </Grid>
            {!matches && (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                >
                    <FeedRightColumn/>
                </Grid>
            )}
        </Grid>
    )
};

export default Contact