// @flow
import React from 'react';
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import FeedLeftColumn from "../../components/feedLeftColumn/feedLeftColumn"
import FeedRightColumn from "../../components/feedRightColumn/feedRightColumn";
import JobsData from "./jobsData";
import Box from "@mui/material/Box";


type Props = {};

const Jobs = (props: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const menuMatches = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Grid container spacing={2} justifyContent="space-between">
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedLeftColumn/>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				sm={12}
				md={4}
				lg={6}
			>
				<JobsData/>
			</Grid>
			{!matches && (
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={3}
				>
					<FeedRightColumn/>
				</Grid>
			)}
		</Grid>
	);
};

export default Jobs