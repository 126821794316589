// @flow
import React, {useEffect} from 'react';
import './personal.css'
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {PAGE_PERSONAL} from "../../../locales/pages/namespaces";
import UserProfileHeader from "../../../components/userProfileHeader/userProfileHeader";
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import CenterTab from "../../../components/userTabs/centerTab/centerTab";
import PersonalDetailsTab from "../../../components/userTabs/personalDetailsTab/personalDetailsTab";
import CvTab from "../../../components/userTabs/cvTab/cvTab";
import GroupsTab from "../../../components/userTabs/groupsTab/groupsTab";
import FriendsTab from "../../../components/userTabs/friendsTab/friendsTab";
import {Link} from "react-router-dom";
import FilesTab from "../../../components/userTabs/filesTab/filesTab";
import {TabPanel} from "../../../components/tabPanel/tabPanel";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_PERSONAL,
	ROUTE_PAGE_PERSONAL_ABOUT,
	ROUTE_PAGE_PERSONAL_CV,
	ROUTE_PAGE_PERSONAL_FILES,
	ROUTE_PAGE_PERSONAL_FRIENDS,
	ROUTE_PAGE_PERSONAL_GROUPS,
	ROUTE_PAGE_PERSONAL_RECOMMENDATIONS
} from "../../../routers/routes";
import useUserData from "../../../hooks/user/useUserData";
import useUrlLastPart from "../../../hooks/useUrlLastPart";

import theme from "../../../theme/theme";
import RecommendationTab from "../../../components/userTabs/recommendationTab/recommendationTab";
import useUserAction from "../../../hooks/user/useUserAction";

type Props = {};

const Personal = (props: Props) => {
	const {t} = useTranslation(PAGE_PERSONAL)
	const {data} = useUserData()
	const {getPersonalData,getUserProfileImg,getUserCoverImg,getAllPosts} = useUserAction()
	let lastPartOfPath = useUrlLastPart()
	if (!['cv', 'friends', 'about', 'groups', 'files', 'recommendations', 'groups'].includes(lastPartOfPath)) {
		lastPartOfPath = ""
	}


	const menuMatches = useMediaQuery(theme.breakpoints.up('md'));
	useEffect(() => {
		getPersonalData()
		getUserProfileImg()
		getUserCoverImg()
		getAllPosts()
	}, [getPersonalData,getUserProfileImg,getUserCoverImg,getAllPosts])
	return (
		<Box
			className={'personal'}
		>
			<Box
				sx={{
					width: {
						xs: '100%',
						sm: '100%',
						md: '85%',
						lg: '50%'
					}
				}}
			>
				
				{/*{!menuMatches && (*/}
				{/*	<MenuHamburger/>*/}
				
				{/*)}*/}
				
				<UserProfileHeader/>
				<Box sx={{
					borderBottom: 1,
					borderColor: 'divider',
					backgroundColor: 'white',
					marginTop: '2px'
				}}>
					<Tabs variant="scrollable"
						  scrollButtons
						  allowScrollButtonsMobile
						  value={lastPartOfPath}>
						<Tab
							component={Link}
							value={""}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL, {username: data.username})}
							label={<span className="tabLabel">{t('CENTER')}</span>}
						/>
						<Tab
							component={Link}
							value={"about"}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL_ABOUT, {username: data.username})}
							label={<span className="tabLabel">{t('PERSONAL DETAILS')}</span>}
						/>
						<Tab
							component={Link}
							value={"cv"}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL_CV, {username: data.username})}
							label={<span className="tabLabel">{t('CV')}</span>}
						/>
						<Tab
							component={Link}
							value={"friends"}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL_FRIENDS, {username: data.username})}
							label={<span className="tabLabel">{t('FRIENDS')}</span>}
						/>
						{/*<Tab*/}
						{/*	component={Link}*/}
						{/*	value={"groups"}*/}
						{/*	to={getRouteUrl(ROUTE_PAGE_PERSONAL_GROUPS, {username: data.username})}*/}
						{/*	label={<span className="tabLabel">{t('GROUPS')}</span>}*/}
						{/*/>*/}
						<Tab
							component={Link}
							value={"files"}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL_FILES, {username: data.username})}
							label={<span className="tabLabel">{t('FILES')}</span>}
						/>
						<Tab
							component={Link}
							value={"recommendations"}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL_RECOMMENDATIONS, {username: data.username})}
							label={<span className="tabLabel">{t('RECOMMENDATIONS')}</span>}
						/>
						<Tab
							component={Link}
							value={"groups"}
							to={getRouteUrl(ROUTE_PAGE_PERSONAL_GROUPS, {username: data.username})}
							label={<span className="tabLabel">{t('GROUPS')}</span>}
						/>
					</Tabs>
				</Box>
				<Box>
					<TabPanel className="centerTabPanel" isVisible={lastPartOfPath === ""}>
						<CenterTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="peronalTabPanel" isVisible={lastPartOfPath === "about"}>
						<PersonalDetailsTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="cvTabPanel" isVisible={lastPartOfPath === "cv"}>
						<CvTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="friendsTabPanel" isVisible={lastPartOfPath === "friends"}>
						<FriendsTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="groupsTabPanel" isVisible={lastPartOfPath === "groups"}>
						<GroupsTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="fileTabPanel" isVisible={lastPartOfPath === "files"}>
						<FilesTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="discriminationTabPanel" isVisible={lastPartOfPath === "recommendations"}>
						<RecommendationTab type={'personal'} id={data.id}/>
					</TabPanel>
					<TabPanel className="discriminationTabPanel" isVisible={lastPartOfPath === "group"}>
						<GroupsTab type={'groupsTab'} id={data.id}/>
					</TabPanel>
				</Box>
			</Box>
		</Box>
	);
};

export default Personal