import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { PAGE_COMPANY } from "../../locales/pages/namespaces";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import "./adsForm.css";
import Menu from "@mui/material/Menu";

const form = [
  {
    name: "title",
    type: "input",
  },
  {
    name: "city",
    type: "input",
  },
  {
    name: "description",
    type: "textArea",
  },
  {
    name: "worktype",
    type: "dropdown",
    options: ["", "Πλήρη Απασχοληση", "Μερική Απασχόληση", "Συμβόλαιο/Εξωτερική"],
  },
  {
    name: "id_category",
    type: "dropdown",
  }, {
    name: "salary",
    type: "input",
  },
  {
    name: "level",
    type: "dropdown",
    options: ["", "Entry Level", "Mid Level", "Senior Level"],
  },

];
const CustomBackdrop = React.forwardRef(function CustomBackdrop(props, ref) {
  return <Backdrop {...props} ref={ref} id="adsForm" />;
});
const AdsForm = ({ adsCategories, initialValues, formik, handleChange }) => {
  const { t } = useTranslation(PAGE_COMPANY);
  const textareaStyle = {
    fontFamily: "Arial, sans-serif",
    height: "100px",
    width: "100%",
    overflow: "auto",
    marginBottom: "10px",
    fontSize: "17px",
  };

  return (
    <FormikProvider value={formik}>
      <Box
        component="form"
        sx={{
          marginTop: "20px",
        }}
        //onSubmit={formik.handleSubmit}
      >
        <Paper elevation={0}>
          <Box sx={{ margin: "10px" }}>
            {form.map(x => (
              x.type === "input" ?
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{
                    marginBottom: 2,
                  }}
                  error={
                    formik.touched[x.name] &&
                    Boolean(formik.errors[x.name])
                  }
                >
                  <TextField
                    id={x.name}
                    value={formik.values[x.name]}
                    name={x.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t(x.name)}
                    size={"small"}
                    inputProps={{ maxLength: 64 }}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ":focus-within": { border: "0.0px solid #ffffff !important" },
                      },
                      "& .MuiFormLabel-root": {
                        color: "#616161",
                        fontSize: "15px",
                        fontWeight: 400,
                      },
                    }}
                  />
                  <FormHelperText>
                    <ErrorMessage name={x.name} />
                  </FormHelperText>
                </FormControl>

                :
                x.type === "dropdown" ?
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    error={
                      formik.touched[x.name] &&
                      Boolean(formik.errors[x.name])
                    }
                  >
                    <InputLabel id={x.name}>{t(x.name)}</InputLabel>
                    <Select
                      MenuProps={{
                        BackdropComponent: CustomBackdrop,
                      }}
                      displayEmpty
                      labelId={x.name}
                      id={x.name}
                      label={x.name}
                      name={x.name}
                      onChange={(e) => {
                        formik.setFieldValue(e.target.name, e.target.value);
                      }}
                      value={formik.values[x.name]}
                    >
                      {x.name === "worktype" || x.name === "level"
                        ?
                        x.options.map(o => <MenuItem value={o}>{o}</MenuItem>)
                        : x.name === "id_category" ?

                          adsCategories?.length > 0 && ["", ...adsCategories].map(x =>
                            <MenuItem className={"adsForm"} value={x.id}>{x.category}</MenuItem>,
                          )
                          : null
                      }
                    </Select>
                    <FormHelperText>
                      <ErrorMessage name={x.name} />
                    </FormHelperText>
                  </FormControl> :
                  x.type === "textArea" ?
                    <FormControl
                      fullWidth
                      error={
                        formik.touched[x.name] &&
                        Boolean(formik.errors[x.name])
                      }>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          marginBottom: "5px",
                        }}
                      >{t(x.name)}</Typography>
                      <TextareaAutosize
                        style={textareaStyle}
                        value={formik.values[x.name]}
                        onChange={(evt) => {
                          formik.setFieldValue(x.name, evt.target.value);
                        }}
                        placeholder={t(x.name)} />
                    </FormControl>
                    : null
            ))}
            <Box
              sx={{
                paddingBottom: "15px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                "@media (max-width: 600px)": {
                  justifyContent: "center",
                },
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  backgroundColor: "#27293d",
                  borderRadius: "4px",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#27293d",
                  },
                  width: "35%",
                }}
                onClick={formik.handleSubmit}
              >
                {initialValues.action === "create" ? t("create") : t("edit")}
              </Button>
              <Button
                onClick={() => handleChange(null, 0)}
                sx={{
                  borderRadius: "4px",
                  width: "35%",
                }}
                variant="outlined"
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </FormikProvider>
  );
};

export default AdsForm;