// @flow
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PostComponent from "../postComponent/postComponent";
import useUserAction from "../../hooks/user/useUserAction";
import MediaCard from "../mediaCard/mediaCard";
import { daysPassed } from "../../helpers/helpers";
import CardMedia from "@mui/material/CardMedia";
import useUserData from "../../hooks/user/useUserData";
import { IconButton, ListItemButton, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import { PAGE_HOME } from "../../locales/pages/namespaces";
import DeleteIcon from "@mui/icons-material/Delete";
import OstriaModal from "../ostriaModal/ostriaModal";
import { GENERIC } from "../../locales/components/namespaces";
import { Check, Close } from "@mui/icons-material";

type Props = {
  posts: any
};

const UserFeeds = (props: Props) => {
  const { posts } = props;
  const { t } = useTranslation(PAGE_HOME);
  const { t: g } = useTranslation(GENERIC);
  const {
    getUserProfileImg,
    getPostComments,
    addPostComment,
    deletePostComment,
    deletePost,
    likePost,
    openModal,
    closeModal,
  } = useUserAction();
  const { userId, isModalOpen: isDeleteModalOpen } = useUserData();
  const [isModalOpen, setOpenModal] = React.useState(null);
  const onSubmit = (values, formikHelpers) => {
    if (values?.keimeno?.value?.length > 0) {
      addPostComment({
        postId: values.keimeno.postId,
        comment: values.keimeno.value,
      });
      formikHelpers.setValues({});
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = ({ postId, e }) => {
    setAnchorEl({ postId, anchorEl: e.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUserProfileImg();
  }, [getUserProfileImg]);
  const deleteAction = () => {
    if (isDeleteModalOpen.commentId) {
      deletePostComment(isDeleteModalOpen);
    } else {
      deletePost(isDeleteModalOpen);
    }
    closeModal();
  };
  return (
    <Box>
      <PostComponent />
      {posts.map((post, index) => (
        <Box margin={"10px 4px"}>
          <MediaCard
            type={post.type}
            commentsNumber={post.commentsNumber}
            likesNumber={post.likesNumber}
            description={post.description}
            date={daysPassed(post.public_date) + "d"}
            setOpenModal={setOpenModal}
            postId={post.id}
            postUserId={post.id_user}
            getPostComments={getPostComments}
            assets={post.contents?.length > 1 ? post.contents.map(x => ({
              url: `https://dev.ostrialinked.gr/api/uploads/${x.filename}`,
              type: x.type,
              filename: x.filename,
            })) : null}
            asset={post.contents?.length === 1 ?
              {
                url: `https://dev.ostrialinked.gr/api/uploads/${post.contents[0].filename}`,
                type: post.contents[0].type,
                filename: post.contents[0].filename,
              } : null}
            onSubmit={onSubmit}
            comments={post.comments}
            userId={userId}
            deletePostComment={openModal}
            handleClick={handleClick}
            likePost={likePost}
            ihadLike={post.ihadLike}
            imageProfile={post.company ? post.company.logo : post?.user?.image_profile}
            user={post.company ? {
              ...post.user,
              firstname: post.company.title,
              lastname: "",
              companyId: post.company.id,
            } : post.user}
          />
        </Box>
      ))}
      <OstriaModal
        open={Boolean(isModalOpen)}
        onClose={() => {
          setOpenModal(null);
        }}
      >
        <CardMedia
          component="img"
          image={isModalOpen}
          alt={isModalOpen}
        />
      </OstriaModal>
      <Menu
        id={`menu`}
        anchorEl={anchorEl?.anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              openModal({ id_post: anchorEl.postId });
              setAnchorEl(null);
            }}
            primary={t("delete")}
          />
        </ListItemButton>
      </Menu>
      <OstriaModal
        open={Boolean(isDeleteModalOpen)}
        onClose={closeModal}
      >
        <Box
          sx={{
            height: "auto",
            padding: "16px",
            margin: "auto",
          }}
        >
          <Typography
            sx={{
              color: "#050505",
              fontFamily: "\"Segoe UI-Bold\", Helvetica",
              fontSize: { xs: "15px", md: "21px" },
              fontWeight: "700",
              marginBottom: { xs: "5px", md: "10px" },
              textAlign: "center",
            }}
          >
            {g("Are you sure?")}
          </Typography>
          <Box className="reportButtons">
            <IconButton onClick={deleteAction}>
              <Check color="success" />
              {g("yes")}
            </IconButton>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: "red" }} />
              {g("no")}
            </IconButton>
          </Box>
        </Box>
      </OstriaModal>
    </Box>
  )
    ;
};

export default UserFeeds;