// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {getCurrentUserPermissions} from "../../modules/permission/actions/actions";
import useUserData from "./useUserData";
import dayjs from "dayjs";
import { openModal } from "../../actions/actions";

const useUserAction = () => {
    const dispatch = useDispatch()

    const userLogin = useCallback((values) => {
        const bodyFormData = new FormData();
        bodyFormData.append('username', values.username);
        bodyFormData.append('password', values.password);
        dispatch(actions.userLogin(bodyFormData))
    }, [dispatch])

    const userLoginByGoogle = useCallback((values) => {
        dispatch(actions.userLoginByGoogle())
    }, [dispatch])

    const userLoginSucceeded = useCallback((values) => {
        dispatch(actions.userLoginSucceeded(values))
    }, [dispatch])
    const userLogout = useCallback(() => {
        dispatch(actions.userLogout())
    }, [dispatch])

    const setUserLanguage = useCallback((language) => {
        dispatch(actions.setUserLanguage(language))
    }, [dispatch])
    
    const setUserMessage = useCallback((message) => {
        dispatch(actions.setUserMessage(message))
    }, [dispatch])
    
    const getUserPermissions = useCallback(() => {
        dispatch(getCurrentUserPermissions())
    }, [dispatch])
    
    const getUserDetails = useCallback((storeId) => {
        dispatch(actions.getUserDetails())
    }, [dispatch])
    
    const registerUser = useCallback((values) => {
        dispatch(actions.registerUser({
            firstname: values.firstname,
            lastname: values.lastname,
            username: values.username,
            datebirth: dayjs(values.datebirth).format('YYYY-MM-DD'),
            mobile: values.mobile,
            email: values.email,
            password: values.password
        }))
    }, [dispatch])
    
    const resetUserPassword = useCallback((values) => {
        dispatch(actions.resetUserPassword({
            email: values.email,
        }))
    }, [dispatch])
    
    const forgotUserPassword = useCallback((values) => {
        dispatch(actions.forgotUserPassword({
            email: values.email,
            pin: values.pin,
            password: values.password
        }))
    }, [dispatch])
    
    const submitPost = useCallback((values) => {
        dispatch(actions.submitPost({
            description: values.description,
            public: values.public
        }))
    }, [dispatch])
    
    const getPersonalData = useCallback(() => {
        dispatch(actions.getPersonalData())
    }, [dispatch])
    
    const postPersonalData = useCallback((values) => {
        dispatch(actions.postPersonalData({
            country: values.country,
            city: values.city,
            family_state: values.family_state,
            work_state: values.work_state,
            address: values.address,
            phone1: values.phone1,
            phone2: values.phone2,
            facebook: values.facebook,
            twitter: values.twitter,
            linkedin: values.linkedin,
            url: values.url,
        }))
    }, [dispatch])
    const postUserDetails = useCallback((values) => {
        dispatch(actions.postUserDetails({
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            mobile: values.mobile,
            date_birth: dayjs(values.date_birth).format('YYYY-MM-DD'),
        }))
    }, [dispatch])
    
    const postUserRecommendation = useCallback((values) => {
        dispatch(actions.postUserRecommendation({
            title: values.title,
            description: values.description,
            id_user_put:values.id_user_put
        }))
    }, [dispatch])
    
    const getUserRecommendationsData = useCallback(() => {
        dispatch(actions.getUserReccomendations())
    }, [dispatch])
    
    // const recommendationWithId = useCallback((id) => {
    //     dispatch(actions.recommendationWithId({
    //         idd: id,
    //     }))
    // }, [dispatch])
    
    const postRecommendationEdit = useCallback((values) => {
        dispatch(actions.postRecommendationEdit({
            idd: values.id,
            title: values.title,
            description: values.description,
        }))
    }, [dispatch])
    
    const getUserProfileImg = useCallback(() => {
        dispatch(actions.getUserProfileImg())
    }, [dispatch])
    
    const getUserCoverImg = useCallback(() => {
        dispatch(actions.getUserCoverImg())
    }, [dispatch])
    
    const getAllPosts = useCallback(() => {
        dispatch(actions.getAllPosts())
    }, [dispatch])

    const getFullPost = useCallback((id) => {
        dispatch(actions.getFullPost({
            id_post:id
        }))
    }, [dispatch])

    const getPostComments = useCallback((values) => {
        dispatch(actions.getPostComments({
            id_post:values.postId
        }))
    }, [dispatch])

    const addPostComment = useCallback((values) => {
        dispatch(actions.addPostComment({
            id_post:values.postId,
            comment:values.comment
        }))
    }, [dispatch])

    const deletePostComment = useCallback((values) => {
        dispatch(actions.deletePostComment({
            id_comment:values.commentId,
            id_post:values.postId
        }))
    }, [dispatch])

    const deletePost = useCallback((values) => {
        dispatch(actions.deletePost({
            id_post:values.id_post
        }))
    }, [dispatch])
    const likePost = useCallback((values) => {
        dispatch(actions.likePost({
            id_post:values.postId
        }))
    }, [dispatch])

    const setCredentials = useCallback((values) => {
        dispatch(actions.setCredentials(
            values
        ))
    }, [dispatch])
    const clearCredentials = useCallback(() => {
        dispatch(actions.clearCredentials())
    }, [dispatch])

    const openModal = useCallback((values) => {
        dispatch(actions.openModal(values))
    }, [dispatch])
    const closeModal = useCallback(() => {
        dispatch(actions.closeModal())
    }, [dispatch])

    return {
        userLogin,
        userLogout,
        setUserLanguage,
        setUserMessage,
        getUserPermissions,
        getUserDetails,
        registerUser,
        resetUserPassword,
        forgotUserPassword,
        submitPost,
        getPersonalData,
        postPersonalData,
        postUserDetails,
        postUserRecommendation,
        getUserRecommendationsData,
        // recommendationWithId,
        postRecommendationEdit,
        getUserProfileImg,
        getUserCoverImg,
        getAllPosts,
        getFullPost,
        getPostComments,
        addPostComment,
        deletePostComment,
        deletePost,
        likePost,
        userLoginByGoogle,
        userLoginSucceeded,
        setCredentials,
        clearCredentials,
        openModal,
        closeModal,
    }
}

export default useUserAction