// @flow
import {useMediaQuery, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Images from "../../assets";
import './loginBanner.css'

const LoginBanner = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Box
			className={'login-banner'}
			sx={{
				padding: {
					sm: '28px 29px 28px 28px',
					md: '48px 49px 48px 48px'
				}
			}}
		>
			<img className={matches ? '' : 'login-logo'} alt="logo" src={Images.logo}/>
			{!matches && <img alt="banner" src={Images.loginBanner}/>}
		</Box>
	)
};

export default LoginBanner