// @flow
import React, { useState } from "react";
import Box from "@mui/material/Box";
import "./jobsData.css";
import { Button, CardContent, CardMedia, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PAGE_JOB } from "../../locales/pages/namespaces";
import Card from "@mui/material/Card";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_JOB_SHOW } from "../../routers/routes";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getCompanyFiles, getJobsBySearch } from "../../api/requests";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../../components/contentLoader/contentLoader";

type Props = {};

const JobsData = (props: Props) => {
  const { t } = useTranslation(PAGE_JOB);
  const { accessCode } = useUserData();
  const [typeSearch, setTypeSearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const history = useHistory();
  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getJobsBySearch", accessCode, typeSearch, locationSearch],
    queryFn: getJobsBySearch,
    enabled: false,
  });

  const handleResults = () => {
    refetch();
  };
  const handleJobInfo = (resultId) => {
    history.push(getRouteUrl(ROUTE_PAGE_JOB_SHOW, { jobId: resultId }));

  };
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box className="jobContainer">
      {matches ?
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
            <TextField className="searchField"
                       id={"typeSearch"}
                       name={"typeSearch"}
                       value={typeSearch}
                       onChange={(e) => setTypeSearch(e.target.value)}
                       placeholder={t("Marketing Executive")}
                       size={"small"}
                       inputProps={{ maxLength: 250 }}
                       variant="outlined"
                       style={{
                         backgroundColor: "white",
                         borderRadius: "30px",
                         border: "2px solid #27293D",

                       }}
              // required
                       sx={{
                         "& .MuiOutlinedInput-root": {
                           ":focus-within": { border: "0.0px solid #FFF !important" },
                           "border": "none",
                           "& fieldset": { border: "none" },
                           "width": "95%",
                         },
                       }}
            >
            </TextField>
            <TextField className="searchField"
                       id={"locationSearch"}
                       name={"locationSearch"}
                       value={locationSearch}
                       onChange={(e) => setLocationSearch(e.target.value)}
                       placeholder={t("location")}
                       size={"small"}
                       inputProps={{ maxLength: 250 }}
                       variant="outlined"
                       sx={{
                         "& .MuiOutlinedInput-root": {
                           ":focus-within": { border: "0.0px solid #FFF !important" },
                           "border": "none",
                           "& fieldset": { border: "none" },
                           "width": "95%",
                         },
                       }}
                       style={{
                         backgroundColor: "white",
                         borderRadius: "30px",
                         border: "2px solid #27293D",
                       }}
            >
            </TextField>
          </Box>
          <Button
            className="searchField"
            onClick={() => handleResults()}
            sx={{
              color: "white",
              backgroundColor: "black",
              borderRadius: "5px",
              "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
              height: "28px",
              paddingLeft: "5px",
              fontSize: "revert",
            }}
            style={{
              margin: "10px auto",
              width: "80%",
              height: "40px",
            }}
          >
            {t("search")}
          </Button>
        </Box>
        :
        <Box className="searchBarContainer">
          <TextField className="searchField"
                     id={"typeSearch"}
                     name={"typeSearch"}
                     value={typeSearch}
                     onChange={(e) => setTypeSearch(e.target.value)}
                     placeholder={t("Marketing Executive")}
                     size={"small"}
                     inputProps={{ maxLength: 250 }}
                     variant="outlined"
            // required
                     sx={{
                       "& .MuiOutlinedInput-root": {
                         ":focus-within": { border: "0.0px solid #FFF !important" },
                         "border": "none",
                         "& fieldset": { border: "none" },
                         "width": "95%",
                       },
                     }}
          >
          </TextField>
          <Typography
            sx={{
              width: "2px",
              height: "48px",
              flexShrink: "0",
              borderLeft: "2px solid #27293D",
            }}
          />
          <TextField className="searchField"
                     id={"locationSearch"}
                     value={locationSearch}
                     onChange={(e) => setLocationSearch(e.target.value)}
                     name={"locationSearch"}
                     placeholder={t("location")}
                     size={"small"}
                     inputProps={{ maxLength: 250 }}
                     variant="outlined"
                     sx={{
                       "& .MuiOutlinedInput-root": {
                         ":focus-within": { border: "0.0px solid #FFF !important" },
                         "border": "none",
                         "& fieldset": { border: "none" },
                         "width": "95%",
                       },
                     }}
          >
          </TextField>
          <Typography
            sx={{
              width: "2px",
              height: "48px",
              flexShrink: "0",
              borderLeft: "2px solid #27293D",
            }}
          />
          <Button
            className="searchBtn"
            onClick={() => handleResults()}
            sx={{
              color: "white",
              backgroundColor: "black",
              borderRadius: "5px",
              "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
              height: "28px",
              paddingLeft: "5px",
              fontSize: "revert",

            }}
          >
            {t("search")}
          </Button>
        </Box>
      }


      {isLoading ?
        <ContentLoader loading={isLoading}></ContentLoader>
        : jobs && <Box className="resultsNum">
          <Typography
            sx={{
              color: "#595959",
              fontFamily: "Open Sans-Regular",
              fontSize: "15px",
              marginTop: "2%",
            }}
          >
            {t("found")} {jobs?.length} {t("results")}
          </Typography>
        </Box>}
      {isLoading ?
        <ContentLoader loading={isLoading}></ContentLoader>
        : <Box className="resultsContainer">
          <Box className="result">
            {jobs && jobs?.map(job => (
                <Card
                  key={job.id}
                  elevation={0}
                  sx={{
                    minWidth: 275,
                    // maxWidth: '370px',
                    border: "2px solid",
                    borderRadius: "20px",
                    // maxWidth: 370,
                    width: {
                      xs: "100%",
                      sm: "98%",
                      md: "98%",
                      lg: "98%",
                    },
                    marginBottom: "2%",
                  }}

                >
                  <CardContent
                    // onClick={() => handleJobInfo(job.id)}
                  >
                    <Box className={"cardTitle"}>
                      <Typography gutterBottom variant="h6" component="div">
                        {job.title}
                      </Typography>
                      <Button
                        onClick={() => handleJobInfo(job.id)}
                        sx={{
                          color: "white",
                          backgroundColor: "black",
                          borderRadius: "10px",
                          "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                          justifyContent: "center",
                          padding: "15px",
                          height: "42px",
                          fontSize: "revert",

                        }}>
                        {t("show position")}
                      </Button>
                    </Box>

                    <Typography variant="body2" color="text.secondary" sx={{ fontWeight: "bold" }}>
                      {job?.company?.name}
                    </Typography>
                    <Typography sx={{ color: "#595959", fontSize: "small", marginBottom: "5%" }}>
                      {job.city}
                    </Typography>
                    <Box className="tags">
                      <Typography
                        sx={{
                          backgroundColor: "#F0F0F0",
                          borderRadius: "10px",
                          display: "flex",
                          width: "fit-content",
                          height: "32px",
                          color: "#616161",
                          padding: "5px",
                          fontSize: "small",

                        }}
                      >
                        {job.worktype}
                      </Typography>
                      {(job.salary) ?
                        <Typography
                          sx={{
                            backgroundColor: "#F0F0F0",
                            borderRadius: "10px",
                            display: "flex",
                            width: "fit-content",
                            height: "32px",
                            color: "#616161",
                            padding: "5px",
                            fontSize: "small",
                            marginLeft: "5px",
                          }}
                        >
                          {job.salary + "€"}
                        </Typography>
                        :
                        ""
                      }
                    </Box>
                  </CardContent>
                </Card>
              ),
            )
            }
          </Box>
        </Box>}
    </Box>
  );
};

export default JobsData;