// @flow
import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Button, ListItem, ListItemAvatar, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Avatar from "@mui/material/Avatar";
import {grey} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import './memberTab.css'
import Images from "../../../../assets";
import {PAGE_GROUP} from "../../../../locales/pages/namespaces";
import {useQuery} from "react-query";
import {getGroupMembers, getGroups} from "../../../../api/requests";
import useUserData from "../../../../hooks/user/useUserData";
import {useParams} from "react-router-dom";
import ContentLoader from "../../../contentLoader/contentLoader";
import ListItemPerson from "../../../loginBanner/listItemPerson";


type Props = {};

const MemberTab = (props: Props) => {
    const {t} = useTranslation(PAGE_GROUP)
    const {accessCode, personalData} = useUserData();
    const {groupCode} = useParams()
    const {data: membersData, isLoading} = useQuery(["getGroupMembers", accessCode, groupCode], getGroupMembers);
    return (
        <>
            {!isLoading ?
                <Box className="centerLeftTab">
                    <Box className="centerLeftTab">
                        <Typography sx={{padding: '5px'}}>
                            {t('members')}
                        </Typography>
                        <Typography sx={{border: '1px #F3F3F3 solid',}}/>
                    </Box>
                    <Box className="centerLeftTab" sx={{display: 'flow'}}>
                        {membersData.map(membersData => (
                                <ListItem key={membersData.id} className="friendsData">
                                    <ListItemPerson
                                        id={membersData.id}
                                        image_profile={membersData.image_profile}
                                        firstname={membersData.firstname}
                                        lastname={membersData.lastname}
                                        username={membersData.username}
                                    />
                                </ListItem>
                            )
                        )
                        }

                    </Box>
                </Box>
                :
                <ContentLoader loading={isLoading}></ContentLoader>}
        </>
    );
};

export default MemberTab