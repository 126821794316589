// @flow
import React from "react";
import Box from "@mui/material/Box";
import { Button, CardContent, CardMedia, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_GROUP, ROUTE_PAGE_GROUPS_CREATE } from "../../routers/routes";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { useTranslation } from "react-i18next";
import { PAGE_GROUP } from "../../locales/pages/namespaces";
import { useHistory } from "react-router-dom";
import Images from "../../assets";
import { Done } from "@mui/icons-material";
import useUserData from "../../hooks/user/useUserData";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { becomeMember, getGroups } from "../../api/requests";
import PostComponent from "../../components/postComponent/postComponent";

type Props = {};

const GroupsData = (props: Props) => {
  const { t } = useTranslation(PAGE_GROUP);
  const history = useHistory();
  const { accessCode } = useUserData();
  const queryClient = useQueryClient();

  const { data: groupsData } = useQuery(["getGroups", accessCode], getGroups);
  const { mutate: becomeMemberAction } = useMutation({
    mutationFn: becomeMember,
    onSuccess: (data, variables, context) => {
      let groups = groupsData;
      let group = groups.find(x => x.id === variables.id);
      group.isMember = data.action === "insert" ? "true" : "false";
      queryClient.setQueryData(["getGroups", accessCode], groups);
    },
  });
  const handleClick = (group) => {
    becomeMemberAction({ id: group.id, accessCode });
  };
  console.log(groupsData);
  return (
    <Box className="centerLeftTab" sx={{ marginTop: "15px" }}>
      <Box className="centerLeftTab">
        <Box className="tabTitle">
          <Typography sx={{ padding: "5px", width: "80%" }}>
            {t("groups")}
          </Typography>
          <Typography sx={{ marginTop: "1%" }}>
            <Link href={getRouteUrl(ROUTE_PAGE_GROUPS_CREATE)} color={"#616161"} underline="hover"
                  style={{ textDecoration: "none" }}
                  sx={{ fontSize: "smaller", fontFamily: "Open Sans-Regular" }}>{t("Create New Group")}</Link>
          </Typography>
        </Box>
        <Typography sx={{ border: "1px #F3F3F3 solid" }} />
      </Box>
      <Box className={"groupsContainer"}>
        {groupsData?.map(group => (
            <Card
              key={group.id}
              elevation={0}
              sx={{
                border: "none",
                maxWidth: 300,
                width: {
                  xs: "100%",
                  sm: "49%",
                  md: "49%",
                  lg: "49%",
                },
              }}
              style={{ cursor: "pointer" }}
            >
              <CardMedia
                component="img"
                sx={{ height: 100 }}
                style={{ objectFit: "contain" }}
                image={(group["img"]) ? group.img : Images.noCover}
                title={group.title}
                onClick={() => history.push(getRouteUrl(ROUTE_PAGE_GROUP, { groupCode: group.id }))}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div"
                            onClick={() => history.push(getRouteUrl(ROUTE_PAGE_GROUP, { groupCode: group.id }))}>
                  {group.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {group.subTitle}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  sx={{
                    display: "flex",
                    width: "318px",
                    padding: "7.522px 68.082px 7.483px 63.842px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "2.501px",
                    border: "0.625px solid rgba(38, 130, 213, 0.50)",
                  }}
                  onClick={group.isOwner === "false" ? () => handleClick(group) : () => null}
                  size="small">
                  {/*{t(group.member ? 'You are member' : 'Become a member') }*/}
                  {group.isOwner === "false" ? group.isMember === "true" ? <> <Done
                    color="success" /> {t("You are member")}</> : t("Become a member") : null}
                  {group.isOwner === "true" && <> <Done color="success" /> {t("You are the owner")}</>}
                </Button>
              </CardActions>
            </Card>
          ),
        )
        }
      </Box>
    </Box>
  );
};

export default GroupsData;