// @flow
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGE_GROUP } from "../../locales/pages/namespaces";
import Images from "../../assets";
import Box from "@mui/material/Box";
import { IconButton, Typography, Chip, Button, Modal } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import "./groupProfileHeader.css";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserData from "../../hooks/user/useUserData";
import ContentLoader from "../contentLoader/contentLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_GROUPS } from "../../routers/routes";
import RemoveIcon from "@mui/icons-material/Remove";
import { deleteGroup, becomeMember, getGroup, changeGroupPhoto } from "../../api/requests";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import { AccountCircle, Check, Close, PhotoCamera } from "@mui/icons-material";
import UploadImage from "../uploadImage/uploadImage";
import OstriaModal from "../ostriaModal/ostriaModal";
import { GENERIC } from "../../locales/components/namespaces";
import useUserAction from "../../hooks/user/useUserAction";

type Props = {};

const GroupProfileHeader = (props: Props) => {
  const { t } = useTranslation(PAGE_GROUP);
  const { t: g } = useTranslation(GENERIC);
  const { accessCode, isModalOpen: isDeleteModalOpen } = useUserData();
  const { openModal, closeModal } = useUserAction();
  const { groupCode } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [coverAnchorEl, setCoverAnchorEl] = React.useState(null);
  const coverOpen = Boolean(coverAnchorEl);
  const [showFullCoverScreen, setShowFullCoverScreen] = useState(false);

  //for upload image modal
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [imageType, setImageType] = useState(null);
  const uploadModal = () => {
    setOpenUploadModal(true);
  };
  const { mutate: changeGroupPhotoAction } = useMutation({
    mutationFn: changeGroupPhoto,
    onSuccess: (data, variables, context) => {
      if (data.saved === "true") {
        let newGroup = groupData;
        newGroup.img = variables.imageUrl;
        queryClient.setQueryData(["getGroup", accessCode, groupCode], newGroup);
      }
    },
  });

  const { mutate: deleteGroupAction } = useMutation({
    mutationFn: deleteGroup,
    onSuccess: (data, variables, context) => {
      history.push(getRouteUrl(ROUTE_PAGE_GROUPS));
    },
  });

  const { mutate: becomeMemberAction } = useMutation({
    mutationFn: becomeMember,
    onSuccess: (data, variables, context) => {
      let group = groupData;
      group.isMember = data.action === "insert" ? "true" : "false";
      group.members = data.action === "insert" ? (Number(groupData.members) + 1) : (Number(groupData.members) - 1);
      queryClient.setQueryData(["getGroup", accessCode, groupCode], group);
    },
  });

  const { data: groupData, isLoading } = useQuery(["getGroup", accessCode, groupCode], getGroup);

  const handleCoverClick = (event) => {
    setCoverAnchorEl(event.currentTarget);
  };
  const handleCoverClose = () => {
    setCoverAnchorEl(null);
  };
  const handleShowCoverClick = () => {
    setShowFullCoverScreen(true);
  };

  const handleCloseCoverClick = () => {
    setShowFullCoverScreen(false);
  };
  const deleteGroupModal = () => {
    deleteGroupAction(isDeleteModalOpen);
    closeModal();
  };
  return (
    <Box className="profileImages">
      <Popover
        id={"simple-popover-cover-profile"}
        open={coverAnchorEl}
        anchorEl={coverAnchorEl}
        onClose={handleCoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ display: "grid" }}>
          <Tooltip title={t("show cover profile")}>
            <Button sx={{ justifyContent: "start" }} onClick={() => {
              console.log("show image profile");
              setCoverAnchorEl(null); //gia na kleinei to popover, otan anoigei to quickview
              handleShowCoverClick();
            }}>
              <AccountCircle />
              {coverAnchorEl !== null ? t("show cover group") : null}
            </Button>
          </Tooltip>
          <Tooltip title={t("change image profile")}>
            <Button sx={{ justifyContent: "start" }} onClick={() => {
              console.log("change cover profile");
              setCoverAnchorEl(null); //gia na kleinei to popover, otan anoigei to quickview
              uploadModal();
              setImageType("group");
            }}>
              <PhotoCamera />
              {coverAnchorEl !== null ? t("change image group") : null}
            </Button>
          </Tooltip>
        </Box>
      </Popover>
      <OstriaModal
        open={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
      >
        <UploadImage  moreInfos={{aspect:3/2,groupCode}}
                      mutateFn={changeGroupPhotoAction} imageType={imageType}
                     onClose={() => setOpenUploadModal(false)} />

      </OstriaModal>
      {!!showFullCoverScreen && (
        <div
          className="fullscreen-overlay active"
          onClick={handleCloseCoverClick}
        >
          <div className="fullscreen-image">
            <img
              className="centered-image"
              // src={Images.userCoverPage}
              src={groupData && groupData.img ? groupData["img"] : Images.noCover}
              alt="Full Screen"

            />
          </div>
        </div>
      )}
      {!isLoading ?
        <>
          {(groupData["img"]) ?
            <img className="groupCover_png" style={{ objectFit: "contain" }} height={200} alt="groupCover_png"
                 onClick={groupData?.isOwner === "true" ? handleCoverClick : () => null}
                 src={groupData["img"]} /> :
            <img className="groupCover_png" alt="groupCover_png" src={Images.noCover}
                 onClick={groupData?.isOwner === "true" ? handleCoverClick : () => null} />}
          <Box>
            <Box className="groupDetails">
              <Box className="groupDetailsLabels">
                <Box sx={{ display: "flex" }}>
                  <Box display={"flex"} width={"100%"}>
                    <label className="groupDetailsLabel">{groupData["title"]} </label>
                    <img className="clickIcon" src={Images.clickIcon} />
                  </Box>
                  {groupData["isOwner"] === "false" && <IconButton
                    onClick={() => becomeMemberAction({ id: groupData.id, accessCode })}
                    className="becomeMember"
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                      paddingLeft: "5px",
                      width: "200px",
                      height: "30px",
                      marginLeft: "5px",

                    }}
                  >
                    {groupData["isMember"] === "true" ?
                      <RemoveIcon />
                      : <Avatar sx={{ width: "15px", height: "15px" }} src={Images.plusIcon} />}

                    <Box sx={{
                      marginLeft: "3%",
                      fontSize: "small",
                      fontFamily: "Open Sans-Regular",
                    }}>
                      {groupData["isMember"] === "true" ? t("Remove member") : t("Become a member")}
                    </Box>
                  </IconButton>}
                </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                  <Typography sx={{ fontSize: "large", fontFamily: "Roboto" }}>
                    {/*{groupData['description']} {' '}*/}
                    {/*{groupData['members']}*/}
                  </Typography>
                  {groupData["isOwner"] === "true" &&
                    <IconButton
                      onClick={() => openModal({ id: groupData.id, accessCode })}
                      className="becomeMember"
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "5px",
                        "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" },
                        paddingLeft: "5px",
                        width: "120px",
                        height: "30px",
                        marginLeft: "5px",

                      }}
                    >
                      <DeleteIcon width={"15px"} height={"15px"} fontSize="small" />
                      <Box sx={{
                        marginLeft: "3%",
                        fontSize: "small",
                        fontFamily: "Open Sans-Regular",
                      }}>
                        {t("Delete")}
                      </Box>
                    </IconButton>}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
        :
        <ContentLoader loading={isLoading}></ContentLoader>
      }

      <OstriaModal
       open={ Boolean(isDeleteModalOpen) && isDeleteModalOpen.id }
        onClose={closeModal}
      >
        <Box
          sx={{
            height: "auto",
            padding: "16px",
            margin: "auto",
          }}
        >
          <Typography
            sx={{
              color: "#050505",
              fontFamily: "\"Segoe UI-Bold\", Helvetica",
              fontSize: { xs: "15px", md: "21px" },
              fontWeight: "700",
              marginBottom: { xs: "5px", md: "10px" },
              textAlign: "center",
            }}
          >
            {g("Are you sure?")}
          </Typography>
          <Box className="reportButtons">
            <IconButton onClick={deleteGroupModal}>
              <Check color="success" />
              {g("yes")}
            </IconButton>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: "red" }} />
              {g("no")}
            </IconButton>
          </Box>
        </Box>
      </OstriaModal>
    </Box>
  );
};

export default GroupProfileHeader;