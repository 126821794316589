// @flow
import React from 'react';
import {Typography} from "@mui/material";

type Props = {

};

const Symvasi = (props: Props) => {
	return (
		<div>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					color:' #616161',
					fontFamily: '"Open Sans-Regular", Helvetica',
					fontSize: '13px',
					fontWeight: 400,
					paddingRight:'25px'
					
				}}
			>
				<b>1. Εισαγωγή <br></br>
					1.1 Σύμβαση</b> <br></br>
				Όταν χρησιμοποιείτε τις Υπηρεσίες μας, συμφωνείτε με όλους αυτούς τους όρους. Η
				χρήση των Υπηρεσιών μας από εσάς υπόκειται επίσης στην Πολιτική Cookies και την
				Πολιτική Απορρήτου μας, η οποία καλύπτει τον τρόπο συλλογής, χρήσης, κοινοποίησης
				και αποθήκευσης των προσωπικών σας δεδομένων και πληροφοριών.
				Συμφωνείτε ότι κάνοντας εγγραφή, πρόσβαση ή χρήση των υπηρεσιών μας
				(περιγράφεται παρακάτω), συμφωνείτε να συνάψετε μια νομικά δεσμευτική σύμβαση με
				το Ostrialinked (ακόμη και αν χρησιμοποιείτε τις Υπηρεσίες μας για λογαριασμό μιας
				εταιρείας). Εάν δεν συμφωνείτε με αυτήν τη σύμβαση ("Σύμβαση" ή "Συμφωνία
				χρήστη"), μην κάνετε κλικ στο "Εγγραφείτε τώρα" (ή παρόμοιο) και μην έχετε πρόσβαση
				ή χρησιμοποιείτε με άλλο τρόπο οποιαδήποτε από τις Υπηρεσίες μας. Εάν επιθυμείτε να
				τερματίσετε αυτήν τη σύμβαση, μπορείτε ανά πάσα στιγμή να το κάνετε κλείνοντας τον
				λογαριασμό σας και χωρίς πλέον να έχετε πρόσβαση ή να χρησιμοποιείτε τις Υπηρεσίες
				μας.<br></br>
				<b>Υπηρεσίες</b>
				<br></br>
				Η παρούσα Σύμβαση ισχύει για Ostrialinked.gr και άλλους ιστότοπους, εφαρμογές,
				επικοινωνίες και άλλες υπηρεσίες που σχετίζονται με το Ostrialinked και δηλώνουν ότι
				προσφέρονται στο πλαίσιο της παρούσας Σύμβασης («Υπηρεσίες»),
				συμπεριλαμβανομένης της συλλογής δεδομένων εκτός ιστότοπου για αυτές τις
				Υπηρεσίες, όπως οι διαφημίσεις μας. Οι εγγεγραμμένοι χρήστες των Υπηρεσιών μας
				είναι «Μέλη» και οι μη εγγεγραμμένοι χρήστες είναι «Επισκέπτες».<br></br>
				Ostrialinked<br></br>
				Συνάπτετε την παρούσα Σύμβαση με το Ostrialinked (αναφέρεται επίσης ως «εμείς» και
				«εμάς») και η Β. Παπανδρέου  &ΣΙΑ ΕΠΕ θα είναι ο υπεύθυνος επεξεργασίας των
				προσωπικών σας δεδομένων που παρέχονται ή συλλέγονται από ή για, ή υποβάλλονται
				σε επεξεργασία σε σχέση με τις Υπηρεσίες μας.<br></br>
				Η παρούσα Σύμβαση ισχύει για τα Μέλη και τους Επισκέπτες.<br></br>
				Ως Επισκέπτης ή Μέλος των Υπηρεσιών μας, η συλλογή, χρήση και κοινή χρήση των
				προσωπικών σας δεδομένων υπόκειται στην παρούσα Πολιτική Απορρήτου (η οποία
				περιλαμβάνει την Πολιτική Cookies και άλλα έγγραφα που αναφέρονται στην
				παρούσα Πολιτική Απορρήτου) και ενημερώνεται.<br></br>
				<b>
					1.2 Μέλη και Επισκέπτες
				</b> <br></br>
				
				Όταν εγγράφεστε και συμμετέχετε στις Υπηρεσίες Ostrialinked, γίνεστε Μέλος. Εάν
				έχετε επιλέξει να μην εγγραφείτε στις Υπηρεσίες μας, μπορείτε να αποκτήσετε πρόσβαση
				σε ορισμένες λειτουργίες ως "Επισκέπτης". <br></br>
				<b>1.3 Αλλαγή</b> <br></br>
				Ενδέχεται να προβούμε σε αλλαγές στη Σύμβαση.
				Ενδέχεται να τροποποιούμε κατά καιρούς την παρούσα Σύμβαση, την Πολιτική
				Απορρήτου και την Πολιτική Cookies. Εάν προβούμε σε ουσιαστικές αλλαγές σε αυτό,
				θα σας ειδοποιήσουμε μέσω των Υπηρεσιών μας ή με άλλα μέσα, για να σας δώσουμε
				την ευκαιρία να ελέγξετε τις αλλαγές προτού τεθούν σε ισχύ. Συμφωνούμε ότι οι αλλαγές
				δεν μπορούν να έχουν αναδρομική ισχύ. Εάν αντιταχθείτε σε οποιεσδήποτε αλλαγές,
				μπορείτε να κλείσετε τον λογαριασμό σας. Η συνέχιση της χρήσης των Υπηρεσιών μας
				μετά τη δημοσίευση ή την αποστολή ειδοποίησης σχετικά με τις αλλαγές μας σε αυτούς
				Ισχύει από την 1η Ιανουαρίου 2023
				τους όρους σημαίνει ότι συναινείτε στους ενημερωμένους όρους από την ημερομηνία
				έναρξης ισχύος τους.<br></br>
				<b>2. Υποχρεώσεις</b> <br></br>
				<b>2.1 Επιλεξιμότητα υπηρεσίας</b><br></br>
				Ακολουθούν ορισμένες υποσχέσεις που μας δίνετε στην παρούσα Σύμβαση:
				Δικαιούστε να συνάψετε αυτήν τη Σύμβαση και είστε τουλάχιστον στην «Ελάχιστη
				ηλικία» μας.
				Οι Υπηρεσίες δεν προορίζονται για χρήση από άτομα κάτω των 18 ετών.
				Για να χρησιμοποιήσετε τις Υπηρεσίες, συμφωνείτε ότι: (1) πρέπει να είστε στην
				"Ελάχιστη ηλικία" (περιγράφεται παρακάτω) ή μεγαλύτερη. (2) θα έχετε μόνο έναν
				λογαριασμό Ostrialinked, ο οποίος πρέπει να είναι στο πραγματικό σας όνομα. και (3)
				δεν περιορίζεστε ήδη από το Ostrialinked από τη χρήση των Υπηρεσιών. Η δημιουργία
				λογαριασμού με ψευδείς πληροφορίες αποτελεί παραβίαση των όρων μας,
				συμπεριλαμβανομένων λογαριασμών που έχουν καταχωρηθεί για λογαριασμό άλλων ή
				ατόμων κάτω των 18 ετών.
				"Ελάχιστη ηλικία" σημαίνει 18 ετών.<br></br>
				<b>2.2 Ο λογαριασμός σας </b> <br></br>
				Θα κρατήσετε μυστικό τον κωδικό πρόσβασής σας
				Δεν θα μοιραστείτε τον λογαριασμό με κανέναν άλλο και θα ακολουθήσετε τους κανόνες
				μας και τη νομοθεσία.
				Τα μέλη είναι κάτοχοι λογαριασμών. Συμφωνείτε να: (1) χρησιμοποιήσετε έναν ισχυρό
				κωδικό πρόσβασης και να τον διατηρήσετε εμπιστευτικό. (2) να μην μεταφέρετε κανένα
				μέρος του λογαριασμού σας (π.χ. συνδέσεις) και (3) να ακολουθείτε τη νομοθεσία και τη
				λίστα με τις υποχρεώσεις, παρακάτω. Είστε υπεύθυνοι για οτιδήποτε συμβαίνει μέσω του
				λογαριασμού σας, εκτός εάν τον κλείσετε ή αναφέρετε κατάχρηση.
				Μεταξύ εσάς και άλλων (συμπεριλαμβανομένου του εργοδότη σας), ο λογαριασμός σας
				ανήκει σε εσάς. Ωστόσο, εάν οι Υπηρεσίες αγοράστηκαν από άλλο φυσικό ή νομικό
				πρόσωπο για να τις χρησιμοποιήσετε (π.χ. θέση υπεύθυνου προσλήψεων που
				αγοράστηκε από τον εργοδότη σας), το φυσικό ή νομικό πρόσωπο που πληρώνει για την
				εν λόγω Υπηρεσία έχει το δικαίωμα να ελέγχει την πρόσβαση και να λαμβάνει αναφορές
				σχετικά με τη χρήση της εν λόγω Υπηρεσίας επί πληρωμή. Ωστόσο, δεν έχει δικαιώματα
				στον προσωπικό σας λογαριασμό<br></br>
				<b>2.3 Ειδοποιήσεις και μηνύματα</b> <br></br>
				Συμφωνείτε με την παροχή ειδοποιήσεων και μηνυμάτων σε εσάς μέσω των ιστότοπων,
				των εφαρμογών και των στοιχείων επικοινωνίας μας. Εάν τα στοιχεία επικοινωνίας σας
				δεν είναι ενημερωμένα, ενδέχεται να χάσετε σημαντικές ειδοποιήσεις.
				Συμφωνείτε ότι θα σας παρέχουμε ειδοποιήσεις και μηνύματα με τους ακόλουθους
				τρόπους:
				(1) εντός της Υπηρεσίας ή (2) μέσω αποστολής στα στοιχεία επικοινωνίας που
				μας παρείχατε (π.χ. email, αριθμός κινητού τηλεφώνου, φυσική διεύθυνση).
				Συμφωνείτε να διατηρείτε τα στοιχεία επικοινωνίας σας ενημερωμένα.
				Ελέγξτε τις ρυθμίσεις σας για να ελέγξετε και να περιορίσετε τα μηνύματα που
				λαμβάνετε από εμάς.<br></br>
				<b>2.4 Κοινή χρήση</b><br></br>
				Όταν μοιράζεστε πληροφορίες στις Υπηρεσίες μας, άλλοι μπορούν να δουν, να
				αντιγράψουν και να χρησιμοποιήσουν αυτές τις πληροφορίες.
				Ισχύει από την 1η Ιανουαρίου 2023
				Οι Υπηρεσίες μας επιτρέπουν την ανταλλαγή μηνυμάτων και την ανταλλαγή
				πληροφοριών με πολλούς τρόπους, όπως το προφίλ σας, άρθρα, ομαδικές αναρτήσεις,
				συνδέσμους προς άρθρα ειδήσεων, αγγελίες εργασίας, μηνύματα και InMails. Οι
				πληροφορίες και το περιεχόμενο που μοιράζεστε ή δημοσιεύετε ενδέχεται να είναι ορατά
				από άλλα Μέλη, Επισκέπτες ή άλλους (συμπεριλαμβανομένων των εκτός των
				Υπηρεσιών). Όπου έχουμε καταστήσει διαθέσιμες τις ρυθμίσεις, θα σεβαστούμε τις
				επιλογές που κάνετε σχετικά με το ποιος μπορεί να δει περιεχόμενο ή πληροφορίες (π.χ.
				περιεχόμενο μηνυμάτων στους παραλήπτες σας, κοινή χρήση περιεχομένου μόνο σε
				συνδέσεις Ostrialinked, περιορισμός της ορατότητας του προφίλ σας από τις μηχανές
				αναζήτησης ή επιλογή να μην ειδοποιείτε άλλους για την ενημέρωση του προφίλ σας στο
				Ostrialinked). Για δραστηριότητες αναζήτησης εργασίας, από προεπιλογή δεν
				ειδοποιούμε το δίκτυο συνδέσεών σας ή το κοινό. Έτσι, εάν υποβάλετε αίτηση για μια
				θέση εργασίας μέσω της Υπηρεσίας μας ή επιλέξετε να επισημάνετε ότι ενδιαφέρεστε
				για μια θέση εργασίας, η προεπιλογή μας είναι να την μοιραστούμε μόνο με την αγγελία
				εργασίας.
				Δεν είμαστε υποχρεωμένοι να δημοσιεύσουμε οποιαδήποτε πληροφορία ή περιεχόμενο
				στην Υπηρεσία μας και μπορούμε να το αφαιρέσουμε με ή χωρίς προειδοποίηση.
				<br></br>
				<b>
					3. Δικαιώματα και όρια
				</b>
				<br></br>
				<b>
					3.1. Η άδειά σας στο Ostrialinked
				</b>
				<br></br>
				
				Σας ανήκει όλο το περιεχόμενο, τα σχόλια και οι προσωπικές πληροφορίες που μας
				παρέχετε, αλλά μας παραχωρείτε επίσης μια μη αποκλειστική άδεια χρήσης.
				Θα σεβαστούμε τις επιλογές που κάνετε σχετικά με το ποιος μπορεί να δει τις
				πληροφορίες και το περιεχόμενό σας, συμπεριλαμβανομένου του τρόπου με τον οποίο
				μπορούν να χρησιμοποιηθούν για διαφημίσεις.
				Μεταξύ εσάς και του Ostrialinked, σας ανήκει το περιεχόμενο και οι πληροφορίες που
				υποβάλλετε ή δημοσιεύετε στις Υπηρεσίες και παραχωρείτε στο Ostrialinked μόνο την
				ακόλουθη μη αποκλειστική άδεια:
				Ένα μεταβιβάσιμο και υποαδειοδοτούμενο δικαίωμα χρήσης, αντιγραφής, τροποποίησης,
				διανομής, δημοσίευσης και επεξεργασίας πληροφοριών και περιεχομένου που παρέχετε
				μέσω των Υπηρεσιών μας και των υπηρεσιών άλλων, χωρίς περαιτέρω συγκατάθεση,
				ειδοποίηση ή/και αποζημίωση προς εσάς ή άλλους. Αυτά τα δικαιώματα περιορίζονται
				με τους ακόλουθους τρόπους: <br></br>
				1. Μπορείτε να τερματίσετε αυτήν την άδεια χρήσης για συγκεκριμένο περιεχόμενο
				διαγράφοντας το εν λόγω περιεχόμενο από τις Υπηρεσίες ή γενικά κλείνοντας τον
				λογαριασμό σας, εκτός (α) στο βαθμό που το μοιραστήκατε με άλλους ως μέρος
				της Υπηρεσίας και το αντέγραψαν, το μοιράστηκαν ξανά ή το αποθήκευσαν και
				(β) για το εύλογο χρονικό διάστημα που απαιτείται για την κατάργηση από
				εφεδρικά και άλλα συστήματα.<br></br>
				2. Δεν θα συμπεριλάβουμε το περιεχόμενό σας σε διαφημίσεις για προϊόντα και
				υπηρεσίες τρίτων σε άλλους χωρίς την ξεχωριστή συγκατάθεσή σας
				(συμπεριλαμβανομένου του χορηγούμενου περιεχομένου). Ωστόσο, έχουμε το
				δικαίωμα, χωρίς πληρωμή σε εσάς ή σε άλλους, να προβάλλουμε διαφημίσεις
				κοντά στο περιεχόμενο και τις πληροφορίες σας και οι ενέργειές σας ενδέχεται να
				είναι ορατές και να περιλαμβάνονται στις διαφημίσεις, όπως σημειώνεται στην
				Πολιτική Απορρήτου. Εάν χρησιμοποιείτε μια λειτουργία Υπηρεσίας, ενδέχεται
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
				να την αναφέρουμε με το όνομα ή τη φωτογραφία σας για να προωθήσουμε
				αυτήν τη δυνατότητα στις Υπηρεσίες μας, ανάλογα με τις ρυθμίσεις σας.<br></br>
				3. Θα λάβουμε τη συγκατάθεσή σας εάν θέλουμε να δώσουμε σε άλλους το
				δικαίωμα να δημοσιεύουν το περιεχόμενό σας πέρα από τις Υπηρεσίες. Ωστόσο,
				εάν επιλέξετε να κοινοποιήσετε την ανάρτησή σας ως "δημόσια, για όλους ή ως
				συναφή", θα ενεργοποιήσουμε μια λειτουργία που επιτρέπει σε άλλα μέλη να
				ενσωματώσουν αυτήν τη δημόσια ανάρτηση σε υπηρεσίες τρίτων και θα
				επιτρέψουμε στις μηχανές αναζήτησης να κάνουν αυτό το δημόσιο περιεχόμενο
				ανιχνεύσιμο μέσω των υπηρεσιών τους.<br></br>
				4. Παρόλο που ενδέχεται να επεξεργαστούμε και να κάνουμε αλλαγές μορφής στο
				περιεχόμενό σας (όπως μετάφραση ή μεταγραφή του, τροποποίηση του μεγέθους,
				της διάταξης ή του τύπου αρχείου ή κατάργηση μεταδεδομένων), δεν θα
				τροποποιήσουμε το νόημα της έκφρασής σας.
				Εσείς και το Ostrialinked συμφωνείτε ότι εάν το περιεχόμενο περιλαμβάνει προσωπικά
				δεδομένα, υπόκειται στην Πολιτική Απορρήτου μας.
				Εσείς και το Ostrialinked συμφωνείτε ότι μπορούμε να έχουμε πρόσβαση, να
				αποθηκεύουμε, να επεξεργαζόμαστε και να χρησιμοποιούμε οποιεσδήποτε πληροφορίες
				και προσωπικά δεδομένα παρέχετε σύμφωνα με τους όρους της Πολιτικής
				Απορρήτου και τις επιλογές σας (συμπεριλαμβανομένων των ρυθμίσεων).
				Υποβάλλοντας προτάσεις ή άλλα σχόλια σχετικά με τις Υπηρεσίες μας στο
				Ostrialinked, συμφωνείτε ότι το Ostrialinked μπορεί να χρησιμοποιήσει και να
				μοιραστεί (αλλά όχι υποχρεωτικά) αυτά τα σχόλια για οποιονδήποτε σκοπό χωρίς
				αποζημίωση προς εσάς.<br></br>
				Υπόσχεστε να παρέχετε μόνο πληροφορίες και περιεχόμενο που έχετε το δικαίωμα να
				μοιραστείτε και ότι το προφίλ σας στο Ostrialinked θα είναι αληθές.
				Συμφωνείτε να παρέχετε μόνο περιεχόμενο ή πληροφορίες που δεν παραβιάζουν το νόμο
				ούτε τα δικαιώματα κανενός (συμπεριλαμβανομένων των δικαιωμάτων πνευματικής
				ιδιοκτησίας). Συμφωνείτε επίσης ότι οι πληροφορίες του προφίλ σας θα είναι αληθείς.<br></br>
				<b>3.2 Διαθεσιμότητα υπηρεσίας</b>
				<br></br>
				Μπορούμε να αλλάξουμε ή να τερματίσουμε οποιαδήποτε Υπηρεσία ή να
				τροποποιήσουμε τις τιμές μας μελλοντικά.<br></br>
				Ενδέχεται να αλλάξουμε, να αναστείλουμε ή να διακόψουμε οποιαδήποτε από τις
				Υπηρεσίες μας. Μπορούμε επίσης να τροποποιήσουμε τις τιμές μας με ισχύ μελλοντικά
				κατόπιν εύλογης ειδοποίησης στον βαθμό που επιτρέπεται από το νόμο.
				Δεν υποσχόμαστε να αποθηκεύσουμε ή να συνεχίσουμε να εμφανίζουμε πληροφορίες
				και περιεχόμενο που έχετε δημοσιεύσει. Το Ostrialinked δεν είναι υπηρεσία
				αποθήκευσης. Συμφωνείτε ότι δεν έχουμε καμία υποχρέωση να αποθηκεύσουμε, να
				διατηρήσουμε ή να σας παράσχουμε αντίγραφο οποιουδήποτε περιεχομένου ή
				πληροφοριών που παρέχετε εσείς ή άλλοι, εκτός από το βαθμό που απαιτείται από την
				ισχύουσα νομοθεσία και όπως σημειώνεται στην Πολιτική Απορρήτου μας.<br></br>
				<b>3.3 Άλλο περιεχόμενο, ιστότοποι και εφαρμογές</b>
				<br></br>
				Η χρήση του περιεχομένου και των πληροφοριών άλλων που δημοσιεύονται στις
				Υπηρεσίες μας, γίνεται με δική σας ευθύνη.<br></br>
				Άλλοι ενδέχεται να προσφέρουν τα δικά τους προϊόντα και υπηρεσίες μέσω των
				Υπηρεσιών μας και δεν είμαστε υπεύθυνοι για αυτές τις δραστηριότητες τρίτων.<br></br>
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
				Χρησιμοποιώντας τις Υπηρεσίες, ενδέχεται να συναντήσετε περιεχόμενο ή πληροφορίες
				που ενδέχεται να είναι ανακριβείς, ελλιπείς, καθυστερημένες, παραπλανητικές,
				παράνομες, προσβλητικές ή άλλως επιβλαβείς. Το Ostrialinked γενικά δεν ελέγχει το
				περιεχόμενο που παρέχεται από τα Μέλη μας ή άλλους. Συμφωνείτε ότι δεν είμαστε
				υπεύθυνοι για το περιεχόμενο ή τις πληροφορίες άλλων (συμπεριλαμβανομένων άλλων
				μελών). Δεν μπορούμε πάντα να αποτρέψουμε αυτήν την κατάχρηση των Υπηρεσιών
				μας και συμφωνείτε ότι δεν είμαστε υπεύθυνοι για οποιαδήποτε τέτοια κατάχρηση.
				Αναγνωρίζετε επίσης τον κίνδυνο εσείς ή ο οργανισμός σας να συσχετιστείτε κατά λάθος
				με περιεχόμενο σχετικά με άλλους, όταν ενημερώνουμε τις συνδέσεις και τους
				ακόλουθούς σας ότι εσείς ή ο οργανισμός σας αναφερθήκατε στις ειδήσεις. Τα μέλη
				έχουν επιλογές σχετικά με αυτήν τη δυνατότητα.<br></br>
				Το Ostrialinked μπορεί να βοηθήσει στη σύνδεση των Μελών που προσφέρουν τις
				υπηρεσίες τους (επαγγελματική συμβουλευτική και προσανατολισμό, προσωπική
				επαγγελματική ανάπτυξη, κ.λπ.) με τα Μέλη που αναζητούν υπηρεσίες. Το Ostrialinked
				δεν απασχολεί άτομα για την εκτέλεση αυτών των υπηρεσιών. Πρέπει να είστε
				τουλάχιστον 18 ετών για να προσφέρετε, να εκτελέσετε ή να προμηθευτείτε αυτές τις
				υπηρεσίες. Αναγνωρίζετε ότι το Ostrialinked δεν εποπτεύει, κατευθύνει, ελέγχει ή
				παρακολουθεί τα Μέλη κατά την εκτέλεση αυτών των υπηρεσιών και συμφωνείτε ότι<br></br> (1)
				το Ostrialinked δεν είναι υπεύθυνο για την προσφορά, την απόδοση ή την προμήθεια
				αυτών των υπηρεσιών, <br></br>(2) το Ostrialinked δεν υποστηρίζει τις προσφερόμενες
				υπηρεσίες οποιουδήποτε συγκεκριμένου Μέλους και <br></br>(3) τίποτα δεν δημιουργεί σχέση ,
				συνεργασίας, απασχόλησης, εκπροσώπησης ή κοινοπραξίας μεταξύ του Ostrialinked
				και οποιουδήποτε Μέλους που προσφέρει υπηρεσίες. Εάν είστε Μέλος που προσφέρει
				υπηρεσίες, δηλώνετε και εγγυάστε ότι διαθέτετε όλες τις απαιτούμενες άδειες και θα
				παρέχετε υπηρεσίες σύμφωνα με τη νομοθεσία.<br></br>
				Ομοίως, το Ostrialinked μπορεί να σας βοηθήσει να εγγραφείτε ή/και να
				παρακολουθήσετε εκδηλώσεις που διοργανώνονται από Μέλη και να συνδεθείτε με άλλα
				Μέλη που συμμετέχουν σε τέτοιες εκδηλώσεις. Συμφωνείτε ότι<br></br> (1) το Ostrialinked δεν
				είναι υπεύθυνο για τη συμπεριφορά οποιουδήποτε από τα Μέλη ή άλλους συμμετέχοντες
				σε τέτοιες εκδηλώσεις,<br></br> (2) το Ostrialinked δεν υποστηρίζει κανένα συγκεκριμένο
				γεγονός που αναφέρεται στις Υπηρεσίες μας,<br></br> (3) το Ostrialinked δεν εξετάζει ή/και
				ελέγχει κανένα από αυτά τα γεγονότα και <br></br>(4) ότι θα τηρείτε αυτούς τους όρους και
				προϋποθέσεις που ισχύουν για τέτοιες εκδηλώσεις.<br></br>
				<b>3.4 Όρια</b>
				<br></br>
				Έχουμε το δικαίωμα να περιορίσουμε τον τρόπο με τον οποίο συνδέεστε και
				αλληλεπιδράτε στις Υπηρεσίες μας.<br></br>
				Το Ostrialinked διατηρεί το δικαίωμα να περιορίσει τη χρήση των Υπηρεσιών από εσάς,
				συμπεριλαμβανομένου του αριθμού των συνδέσεών σας και της δυνατότητάς σας να
				επικοινωνείτε με άλλα Μέλη. Το Ostrialinked διατηρεί το δικαίωμα να περιορίσει, να
				αναστείλει ή να τερματίσει τον λογαριασμό σας εάν παραβιάσετε την παρούσα Σύμβαση
				ή το νόμο ή κάνετε κατάχρηση των Υπηρεσιών (π.χ. παραβιάζοντας οποιαδήποτε από τις
				Υποχρεώσεις που αναφέρονται παρακάτω).<br></br>
				<b>3.5 Δικαιώματα πνευματικής ιδιοκτησίας</b><br></br>
				Σας παρέχουμε ειδοποίηση σχετικά με τα δικαιώματα πνευματικής ιδιοκτησίας μας.
				Το Ostrialinked διατηρεί όλα τα δικαιώματα πνευματικής ιδιοκτησίας του στις
				Υπηρεσίες. Τα εμπορικά σήματα και τα λογότυπα που χρησιμοποιούνται σε σχέση με τις<br></br>
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
				Υπηρεσίες είναι εμπορικά σήματα των αντίστοιχων κατόχων τους. Το Ostrialinked και
				τα λογότυπα του που χρησιμοποιούνται για τις Υπηρεσίες μας είναι εμπορικά σήματα ή
				σήματα κατατεθέντα του Ostrialinked.<br></br>
				<b>3.6 Αυτοματοποιημένη επεξεργασία</b><br></br>
				Χρησιμοποιούμε δεδομένα και πληροφορίες σας για να κάνουμε σχετικές προτάσεις σε
				εσάς και σε άλλους.<br></br>
				Χρησιμοποιούμε τις πληροφορίες και τα δεδομένα που παρέχετε και έχουμε σχετικά με
				τα Μέλη για να κάνουμε προτάσεις για συνδέσεις, περιεχόμενο και λειτουργίες που
				μπορεί να σας φανούν χρήσιμες. Για παράδειγμα, χρησιμοποιούμε δεδομένα και
				πληροφορίες σας για να προτείνουμε θέσεις εργασίας σε εσάς και εσάς σε υπεύθυνους
				προσλήψεων. Διατηρώντας το προφίλ σας ακριβές και ενημερωμένο, μπορούμε να
				κάνουμε αυτές τις προτάσεις πιο ακριβείς και σχετικές.<br></br>
				<b>4. Αποποίηση και όριο ευθυνών</b><br></br>
				<b>4.1 Καμία εγγύηση</b><br></br>
				Αυτή είναι η αποποίηση νομικών ευθυνών μας για την ποιότητα, την ασφάλεια ή την
				αξιοπιστία των Υπηρεσιών μας.<br></br>
				Το Ostrialinked δεν παρέχει καμιά δήλωση ή εγγύηση σχετικά με τις υπηρεσίες,
				συμπεριλαμβανόμενης οποιασδήποτε δήλωσης ότι οι υπηρεσίες θα είναι αδιάλειπτες ή
				χωρίς σφάλματα και παρέχει τις υπηρεσίες (συμπεριλαμβανόμενου του περιεχόμενου και
				των πληροφοριών) «ως έχουν» και «ως διατίθενται». Στον μέγιστο βαθμό που
				επιτρέπεται από την ισχύουσα νομοθεσία, το Ostrialinked αποποιείται οποιαδήποτε
				σιωπηρή ή νομική εγγύηση, συμπεριλαμβανόμενης οποιασδήποτε σιωπηρής εγγύησης
				τίτλου, ακρίβειας δεδομένων, μη παραβίασης, εμπορευσιμότητας ή καταλληλότητας για
				συγκεκριμένο σκοπό.<br></br>
				<b>4.2 Αποκλεισμός ευθύνης</b><br></br>
				Αυτά είναι τα όρια της νομικής ευθύνης που μπορεί να έχουμε απέναντί σας.<br></br>
				Στο μέγιστο βαθμό που επιτρέπεται από το νόμο (και εκτός εάν το Ostrialinked έχει
				συνάψει ξεχωριστή γραπτή συμφωνία που υπερισχύει της παρούσας σύμβασης), το
				Ostrialinked, δεν θα ευθύνεται σε σχέση με την παρούσα σύμβαση για απώλεια κερδών ή
				χαμένων επιχειρηματικών ευκαιριών, φήμης (π.χ. προσβλητικές ή δυσφημιστικές
				δηλώσεις), απώλειας δεδομένων (π.χ. χρόνος διακοπής λειτουργίας ή απώλεια, χρήση ή
				αλλαγές στις πληροφορίες ή το περιεχόμενό σας) ή οποιεσδήποτε έμμεσες, τυχαίες,
				επακόλουθες, ειδικές ή ποινικές ζημίες.<br></br>
				Το Ostrialinked δε θα φέρει καμία ευθύνη απέναντί σας σε σχέση με την παρούσα
				σύμβαση για οποιοδήποτε ποσό υπερβαίνει (α) τα συνολικά τέλη που καταβλήθηκαν ή
				καταβάλλονται από εσάς στο Ostrialinked για τις υπηρεσίες κατά τη διάρκεια ισχύος της
				παρούσας σύμβασης, εάν υπάρχουν, ή (β) 500 euro.<br></br>
				<b>4.3 Βάση της συμφωνίας. Εξαιρέσεις</b><br></br>
				Οι περιορισμοί ευθύνης στην παρούσα Ενότητα 4 αποτελούν μέρος της βάσης της
				συμφωνίας ανάμεσα σε εσάς και τo OSTRIALINKED και θα ισχύουν για όλες τις
				αξιώσεις ευθύνης (π.χ. εγγύηση, αδικοπραξία, αμέλεια, σύμβαση και νόμος), ακόμη και
				αν OSTRIALINKED έχει ενημερωθεί για την πιθανότητα οποιασδήποτε τέτοιας ζημίας
				και ακόμη και αν αυτά τα ένδικα μέσα αποτύχουν στον ουσιαστικό σκοπό τους.
				Αυτοί οι περιορισμοί ευθύνης δεν ισχύουν για ευθύνη για θάνατο ή σωματική βλάβη ή
				για απάτη, βαριά αμέλεια ή εκ προθέσεως παράπτωμα ή σε περιπτώσεις αμέλειας όπου
				έχει παραβιαστεί μια υλική υποχρέωση, μια υλική υποχρέωση είναι τέτοια που αποτελεί.<br></br>
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
				προϋπόθεση για την παροχή των υπηρεσιών μας και στην οποία μπορείτε εύλογα να
				βασιστείτε, αλλά μόνο στο βαθμό που οι ζημίες προκλήθηκαν άμεσα από την παραβίαση
				και ήταν προβλέψιμες μετά την ολοκλήρωση της την παρούσα Σύμβαση και στο βαθμό
				που είναι τυπικές στο πλαίσιο της παρούσας Σύμβασης.<br></br>
				<b>5. Τερματισμός</b><br></br>
				Ο καθένας μας μπορεί να τερματίσει αυτή τη Σύμβαση, αλλά ορισμένα δικαιώματα και
				υποχρεώσεις επιβιώνουν.<br></br>
				Τόσο εσείς όσο και το Ostrialinked μπορείτε να καταγγείλετε την παρούσα Σύμβαση
				ανά πάσα στιγμή με ειδοποίηση προς τον άλλο. Με τον τερματισμό, χάνετε το δικαίωμα
				πρόσβασης ή χρήσης των Υπηρεσιών. Μετά τον τερματισμό, εξακολουθούν να ισχύουν:
				Τα δικαιώματά μας να χρησιμοποιούμε και να γνωστοποιούμε τα σχόλιά σας.
				Δικαιώματα των Μελών ή/και των Επισκεπτών για περαιτέρω εκ νέου
				κοινοποίηση περιεχομένου και πληροφοριών που μοιραστήκατε μέσω των
				Υπηρεσιών.<br></br>
				Ενότητες 4, 6, 7 και 8.2 της παρούσας Σύμβασης.<br></br>
				Τυχόν ποσά που οφείλονται από οποιοδήποτε μέρος πριν από την καταγγελία
				εξακολουθούν να οφείλονται μετά τον τερματισμό.<br></br>
				Μπορείτε μέσω της πλατφόρμας μας, να κλείσετε τον λογαριασμό σας.<br></br>
				<b>6. Εφαρμοστέο δίκαιο και επίλυση διαφορών</b><br></br>
				Στην απίθανη περίπτωση που καταλήξουμε σε νομική διαφορά, ανάλογα με τον τόπο
				διαμονής σας, εσείς και το Ostrialinked συμφωνείτε να την επιλύσετε στα δικαστήρια
				των Πατρών.<br></br>
				<b>7. Γενικοί Όροι</b><br></br>
				Ακολουθούν ορισμένες σημαντικές λεπτομέρειες σχετικά με τη Σύμβαση.
				Εάν ένα δικαστήριο με εξουσία επί της παρούσας Σύμβασης κρίνει οποιοδήποτε μέρος
				της ανεφάρμοστο, εσείς και εμείς συμφωνούμε ότι το δικαστήριο θα πρέπει να
				τροποποιήσει τους όρους για να καταστήσει αυτό το μέρος εκτελεστό, επιτυγχάνοντας
				παράλληλα την πρόθεσή του. Εάν το δικαστήριο δεν μπορεί να το κάνει αυτό, εσείς και
				εμείς συμφωνούμε να ζητήσουμε από το δικαστήριο να αφαιρέσει αυτό το μη
				εφαρμόσιμο μέρος και να συνεχίσει να επιβάλλει το υπόλοιπο της παρούσας Σύμβασης.
				Η παρούσα Σύμβαση (συμπεριλαμβανομένων των πρόσθετων όρων που ενδέχεται να
				παρέχονται από εμάς όταν ασχολείστε με μια λειτουργία των Υπηρεσιών) είναι η μόνη
				συμφωνία μεταξύ μας σχετικά με τις Υπηρεσίες και αντικαθιστά όλες τις προηγούμενες
				συμφωνίες για τις Υπηρεσίες.<br></br>
				Εάν δεν ενεργήσουμε για την επιβολή παραβίασης της παρούσας Σύμβασης, αυτό δεν
				σημαίνει ότι το Ostrialinked έχει παραιτηθεί από το δικαίωμά του να επιβάλει την
				παρούσα Σύμβαση. Δεν μπορείτε να εκχωρήσετε ή να μεταβιβάσετε την παρούσα
				Σύμβαση (ή τη συμμετοχή σας ή τη χρήση των Υπηρεσιών) σε οποιονδήποτε χωρίς τη
				συγκατάθεσή μας. Ωστόσο, συμφωνείτε ότι το Ostrialinked μπορεί να εκχωρήσει την
				παρούσα Σύμβαση σε ένα μέρος που το αγοράζει χωρίς τη συγκατάθεσή σας. Δεν
				υπάρχουν τρίτοι δικαιούχοι στην παρούσα Σύμβαση.<br></br>
				Συμφωνείτε ότι ο μόνος τρόπος για να μας παράσχετε νομική ειδοποίηση είναι στις
				διευθύνσεις που παρέχονται στην Ενότητα 10.<br></br>
				<b>8. Ostrialinked "Υποχρεώσεις"</b><br></br>
				Το Ostrialinked είναι μια κοινότητα επαγγελματιών. Αυτή η λίστα Υποχρεώσεων
				περιορίζουν τι μπορείτε και τι δεν μπορείτε να κάνετε στις Υπηρεσίες μας.
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
				<b>8.1.
					Συμφωνείτε να:</b><br></br>
				1. Συμμορφώνεστε με όλους τους ισχύοντες νόμους, συμπεριλαμβανομένων, ενδεικτικά, των
				νόμων περί απορρήτου, των νόμων περί πνευματικής ιδιοκτησίας, των νόμων κατά της
				ανεπιθύμητης αλληλογραφίας, των νόμων περί ελέγχου εξαγωγών, των φορολογικών νόμων
				και των κανονιστικών απαιτήσεων.<br></br>
				2. Να μας παρέχετε ακριβείς πληροφορίες και να τις διατηρείτε ενημερωμένες.<br></br>
				3. Χρησιμοποιήσετε το πραγματικό σας όνομα στο προφίλ σας. και<br></br>
				4. Χρησιμοποιήσετε τις υπηρεσίες με επαγγελματική δεοντολογία.<br></br>
				<b>8.2.
					Συμφωνείτε ότι δε θα:</b><br></br>
				1. Δημιουργήσετε μια ψευδή ταυτότητα στο Ostrialinked, παραποιήσετε την ταυτότητά σας,
				δημιουργήστε ένα προφίλ μέλους για οποιονδήποτε άλλο εκτός από εσάς (πραγματικό
				πρόσωπο) ή χρησιμοποιήσετε ή επιχειρήσετε να χρησιμοποιήσετε τον λογαριασμό άλλου.<br></br>
				2. Κάνετε ανάπτυξη, υποστήριξη ή χρήση λογισμικού, συσκευών, δεσμών ενεργειών, ρομπότ ή
				οποιωνδήποτε άλλων μέσων ή διαδικασιών (συμπεριλαμβανομένων προγραμμάτων
				ανίχνευσης, προσθηκών και πρόσθετων προγράμματος περιήγησης ή οποιασδήποτε άλλης
				τεχνολογίας) για την αντιγραφή των Υπηρεσιών ή την αντιγραφή προφίλ και άλλων
				δεδομένων από τις Υπηρεσίες.<br></br>
				3. Κάνετε παράκαμψη οποιουδήποτε χαρακτηριστικού ασφαλείας ή παράκαμψη ή παράκαμψη
				οποιωνδήποτε ελέγχων πρόσβασης ή ορίων χρήσης της Υπηρεσίας (όπως ανώτατα όρια στις
				αναζητήσεις λέξεων-κλειδιών ή στις προβολές προφίλ),<br></br>
				4. Κάνετε αντιγραφή, χρήση, αποκάλυψη ή διανομή οποιωνδήποτε πληροφοριών που
				λαμβάνονται από τις Υπηρεσίες, είτε απευθείας είτε μέσω τρίτων (όπως μηχανές
				αναζήτησης), χωρίς τη συγκατάθεση του Ostrialinked.<br></br>
				5. Κάνετε αποκάλυψη πληροφοριών που δεν έχετε τη συγκατάθεση να αποκαλύψετε (όπως
				εμπιστευτικές πληροφορίες άλλων (συμπεριλαμβανομένου του εργοδότη σας)).<br></br>
				6. Παραβιάζετε τα δικαιώματα πνευματικής ιδιοκτησίας άλλων, συμπεριλαμβανομένων
				πνευματικών δικαιωμάτων, διπλωμάτων ευρεσιτεχνίας, εμπορικών σημάτων, εμπορικών
				μυστικών ή άλλων ιδιοκτησιακών δικαιωμάτων. Για παράδειγμα, μην αντιγράφετε ή
				διανέμετε (εκτός από τη διαθέσιμη λειτουργία κοινής χρήσης) τις αναρτήσεις ή άλλο
				περιεχόμενο άλλων χωρίς την άδειά τους.<br></br>
				7. Παραβιάζετε την πνευματική ιδιοκτησία ή άλλα δικαιώματα του Ostrialinked,
				συμπεριλαμβανομένων, ενδεικτικά, (i) της αντιγραφής ή διανομής των εκπαιδευτικών μας
				βίντεο ή άλλου υλικού ή (ii) της αντιγραφής ή διανομής της τεχνολογίας μας, εκτός εάν
				κυκλοφορεί με άδειες ανοιχτού κώδικα. (iii) χρησιμοποιώντας τη λέξη "Ostrialinked" ή τα
				λογότυπά μας σε οποιοδήποτε όνομα επιχείρησης, email ή διεύθυνση URL.<br></br>
				8. Δημοσιεύσετε οτιδήποτε περιέχει ιούς λογισμικού, ή οποιονδήποτε άλλο επιβλαβή κώδικα.<br></br>
				9. Κάνετε αποκωδικοποίηση, αποκρυπτογράφηση ή άλλη προσπάθεια εξαγωγής του πηγαίου
				κώδικα για τις Υπηρεσίες ή οποιαδήποτε σχετική τεχνολογία που δεν είναι ανοιχτού κώδικα.<br></br>
				10. Υπονοείτε ή δηλώνετε ότι συνδέεστε ή υποστηρίζεστε από το Ostrialinked χωρίς τη ρητή
				συγκατάθεσή μας (π.χ. εκπροσωπώντας τον εαυτό σας ως διαπιστευμένο εκπαιδευτή
				Ostrialinked).<br></br>
				11. Κάνετε ενοικίαση, μίσθωση, δανεισμό, εμπόριο, πώληση/μεταπώληση ή με άλλο τρόπο
				δημιουργία εσόδων από τις Υπηρεσίες ή τα σχετικά δεδομένα ή πρόσβαση σε αυτά, χωρίς τη
				συγκατάθεση του Ostrialinked.<br></br>
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
				12. Κάνετε σύνδεση με τις Υπηρεσίες μας για οποιονδήποτε άλλο σκοπό εκτός από την
				προώθηση του προφίλ σας ή μιας Ομάδας στις Υπηρεσίες μας, χωρίς τη συγκατάθεση του
				Ostrialinked.<br></br>
				13. Χρησιμοποιείτε bots ή άλλες αυτοματοποιημένες μεθόδους για πρόσβαση στις Υπηρεσίες,
				προσθήκη ή λήψη επαφών, αποστολή ή ανακατεύθυνση μηνυμάτων.<br></br>
				14. Κάνετε παρακολούθηση της διαθεσιμότητας, της απόδοσης ή της λειτουργικότητας των
				Υπηρεσιών για οποιονδήποτε ανταγωνιστικό σκοπό.<br></br>
				15. Συμμετέχετε σε προσομοίωση της εμφάνισης ή της λειτουργίας των Υπηρεσιών.<br></br>
				16. Κάνετε επικάλυψη ή με άλλο τρόπο τροποποίηση των Υπηρεσιών ή της εμφάνισής τους
				(όπως με την εισαγωγή στοιχείων στις Υπηρεσίες ή την αφαίρεση, κάλυψη ή απόκρυψη μιας
				διαφήμισης που περιλαμβάνεται στις Υπηρεσίες).<br></br>
				17. Παρεμβαίνετε στη λειτουργία των Υπηρεσιών ή τοποθετείτε αδικαιολόγητο φορτίο σε αυτές
				(π.χ. ανεπιθύμητη αλληλογραφία, ιοί, αλγόριθμοι τυχερών παιχνιδιών),<br></br>
				18. Παραβιάσετε τη νομοθεσία και την επαγγελματική δεοντολογία.<br></br>
				9. Παράπονα σχετικά με το περιεχόμενο<br></br>
				Στοιχεία επικοινωνίας για παράπονα σχετικά με περιεχόμενο που παρέχεται από τα μέλη
				μας.<br></br>
				Σεβόμαστε τα δικαιώματα πνευματικής ιδιοκτησίας των άλλων. Απαιτούμε οι
				πληροφορίες που δημοσιεύονται από τα Μέλη να είναι ακριβείς και να μην παραβιάζουν
				τα δικαιώματα πνευματικής ιδιοκτησίας ή άλλα δικαιώματα τρίτων. Παρέχουμε μια
				διαδικασία για παράπονα σχετικά με περιεχόμενο που δημοσιεύεται από τα μέλη μας.
				10. Πώς να επικοινωνήσετε μαζί μας<br></br>
				Τα στοιχεία επικοινωνίας μας. Το Κέντρο βοήθειας παρέχει επίσης πληροφορίες σχετικά
				με τις Υπηρεσίες μας.<br></br>
				Για γενικές ερωτήσεις, μπορείτε να επικοινωνήσετε μαζί μας online. Για νομικές
				ειδοποιήσεις ή επίδοση ή κοινοποίηση της διαδικασίας, μπορείτε να μας γράψετε σε αυτή
				τη διεύθυνση : Πάτρα, Χίου & Σαντορίνης 4, ΤΚ 26334.<br></br>
				Ισχύει από την 1η Ιανουαρίου 2023<br></br>
			</Typography>
		</div>
	);
};

export default Symvasi