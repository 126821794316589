// @flow 
import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import './companyMap.css';

type Props = {
	
};
const libraries = ['places'];
const mapContainerStyle = {
	width: '50vw',
	height: '50vh',
};
const center = {
	lat: 38.010972, // default latitude
	lng: 23.688744, // default longitude
};
const CompanyMap = (props: Props) => {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: 'AIzaSyCCPlYWbNsXlgDnLaL7QWw1b17-ieePfx8',
		libraries,
	});
	
	if (loadError) {
		return <div>Error loading maps</div>;
	}
	
	if (!isLoaded) {
		return <div>Loading maps</div>;
	}
	
	return (
		<div className="companyMap">
			{/*<GoogleMap*/}
			{/*	mapContainerStyle={mapContainerStyle}*/}
			{/*	zoom={10}*/}
			{/*	center={center}*/}
			{/*>*/}
			{/*	<Marker position={center} />*/}
			{/*</GoogleMap>*/}
		</div>
	);
};

export default CompanyMap