// @flow
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import {List, ListItem, Paper, Typography} from "@mui/material";
import './importAnnouncements.css'
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../routers/routes";
import useUserData from "../../hooks/user/useUserData";
import dayjs from "dayjs";

type Props = {};
//
// const announcements = [
// 	{
// 		id: 1,
// 		title: 'Νέο εκπαιδευτικό πρόγραμμα - Project Management',
// 		description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
// 	},
// 	{
// 		id: 2,
// 		title: 'Νέο εκπαιδευτικό πρόγραμμα - Project Management',
// 		description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
// 	}
// ]

const ImportantAnnouncements = (props: Props) => {
	const {accessCode} = useUserData();
	const [announcements,setAnnouncements] = useState([])
	useEffect(() => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=15a&scode=" + accessCode,
			method: 'get',
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log(response)
			setAnnouncements(response.data)
		}).catch((error) => {
		
		})
	}, [accessCode]);
	
	const {t} = useTranslation(PAGE_HOME)
	return (
		<Paper
			elevation={0}
			className={'announcements'}
			sx={{
				backgroundColor: '#27293d',
				borderRadius: '8px'
			}}
		>
			<Typography className={'announcements-title'}>
				{t('important announcements')}
			</Typography>
			<List>
				{announcements.map(announcement => (
					<ListItem key={announcement.id} className="announcement">
						<ListItemText
							className={'announcement-label'}
							primary={
								<>
									<Typography
										component="span"
										variant="body2"
										className={'announcement-title'}
										sx={{
											fontWeight: 'bold',
											marginBottom: 2
										}}

									>
									<div dangerouslySetInnerHTML={{__html:	announcement.title}}></div>
									</Typography>
								</>
							}
							secondary={
								<>
									<Typography
										component="span"
										variant="body2"
										className={"announcement-label"}
									>
										<div dangerouslySetInnerHTML={{ __html: announcement.description }}></div>
									</Typography>
									<Typography
										sx={{ fontSize: "12px", color: "white"}}>
										{/*{dayjs(announcement.date).format('YYYY-MM-DD')}*/}
									</Typography>
								</>
							}
						/>
					</ListItem>
				))}
			</List>
		</Paper>
	);
};

export default ImportantAnnouncements