// @flow
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import InputEmoji from "react-input-emoji";

const ChatMessagesContainerSendMsgBar = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    //Clear internal state
    setMessage("");

    //Callback
    onSendMessage(message);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <InputEmoji
        value={message}
        onChange={setMessage}
        cleanOnEnter
        onEnter={handleSend}
        placeholder="Πληκτρολογήστε ένα μήνυμα"
      />
      <IconButton color="primary" onClick={handleSend}>
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default ChatMessagesContainerSendMsgBar;
