import React from "react";
import {Box, Button, Fab, Typography} from "@mui/material";
import "./feedRightColumn.css";
import OpenPositions from "../openPositions/openPositions";
import Ads from "../ads/ads";
import Footer from "../footer/footer";
import Images from "../../assets";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import { ROUTE_PAGE_CHAT } from "../../routers/routes";
import { useHistory } from "react-router";
import Rss from "../rss/rss";

const FeedRightColumn = () => {
	const history = useHistory();
	return (
		<Box>
			<OpenPositions/>
			<Ads/>
			<Rss/>
			<Footer color={'#aeacac'}/>
			<Fab
				className={'fab_envelop'}
				onClick={()=> history.push(getRouteUrl(ROUTE_PAGE_CHAT))}
				color="primary"
				variant="extended"
				size="medium"
				sx={{
					position: "fixed",
					bottom: 46,
					right: 46,
					backgroundColor: "#ffffff",
					color: "#27293d",
					borderRadius: 10,
					border: "1px solid",
					borderColor: "#f3f3f3",
				}}
			>
					<img
						className="envelop_png"
						alt="envelop_png"
						src={Images.envelop}
					/>
			</Fab>
		</Box>
	
	);
}
export default FeedRightColumn
