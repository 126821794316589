// @flow
import React, {useState} from 'react';
import useUserData from "../../../hooks/user/useUserData";
import {FormikProvider, useFormik} from "formik";
import Box from "@mui/material/Box";
import {Button, IconButton, ListItem, TextField, Typography} from "@mui/material";
import {CloseRounded, Delete, EditOutlined, SaveOutlined} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import * as yup from "yup";
import axios from "axios";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

type Props = {

};

const Experience = (props: Props) => {
	const {accessCode} = useUserData();
	const experienceData = props.experienceData;
	
	const [showExperienceEdit,setShowExperienceEdit] = useState(false)
	
	
	
	const validationSchema = yup.object({
		date_from: yup
		.date()
		.required('Απαιτείται'),
		date_to: yup
		.date()
		.required('Απαιτείται'),
		etairia: yup
		.string()
		.required('Απαιτείται'),
		thesi: yup
		.string()
	})
	const onSubmit = (values) => {
		const newValues = {
			'date_from' : dayjs(values.date_from).format('YYYY-MM-DD'),
			'date_to' : dayjs(values.date_to).format('YYYY-MM-DD'),
			'etairia' : values.etairia,
			'thesi' : values.thesi,
			'ide' : values.ide,
		}
		
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=17c&scode=" + accessCode,
			method: 'post',
			data:newValues,
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log('update',response)
			setShowExperienceEdit(false)
			props.refreshData(true)


		}).catch((error) => {

		})
	}
	
	const formik = useFormik({
		initialValues: {
			date_from: dayjs(experienceData.date_from),
			date_to: dayjs(experienceData.date_to),
			etairia: experienceData.etairia,
			thesi: experienceData.thesi,
			ide: experienceData.id
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})
	
	const handleDelete = (id) => {
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=17d&scode=" + accessCode,
			method: 'post',
			data:{'ide':id},
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			// props.refreshData(true)
			window.location.reload();
			
			
		}).catch((error) => {
		
		})
	}
	return (
		<div>
			{showExperienceEdit ?
				<>
					<FormikProvider value={formik} onSubmit={formik.handleSubmit}
									noValidate>
						<Box component="form"
							 onSubmit={formik.handleSubmit}
							 noValidate>
							<Box sx={{display:'flex',justifyContent:'space-between'}}>
								<Box>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={formik.values.date_from}   //dayValue
											format="YYYY-MM-DD"
											label={'Ημ/νία από'}
											size={'small'}
											onChange={(newValue) => {
												formik.setFieldValue('date_from',newValue)
											}}
											sx={{marginBottom: '10px'}}
											renderInput={(params) =>
												<TextField
													{...params}
													variant={'outlined'}
												/>}
											slotProps={{
												textField: {variant: 'outlined',size: 'small',}
											}}
										/>
									</LocalizationProvider>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={formik.values.date_to}   //dayValue
											format="YYYY-MM-DD"
											size={'small'}
											onChange={(newValue) => {
												formik.setFieldValue('date_to',newValue)
											}}
											sx={{marginBottom: '10px'}}
											renderInput={(params) =>
												<TextField
													{...params}
													variant={'outlined'}
												/>}
											slotProps={{
												textField: {variant: 'outlined',size: 'small', }
											}}
										/>
									</LocalizationProvider>
									<TextField size={'small'}
											   id={'etairia'}
											   name={'etairia'}
											   onChange={formik.handleChange}
											   onBlur={formik.handleBlur}
											   sx={{
												   padding: '2%',
												   marginBottom: '1%',
												   '& .MuiOutlinedInput-root': {
													   // ':focus-within': { border: '0.0px solid #ffffff !important' }
													   fontSize: 'smaller',
												   },
											   }}
											   variant="outlined"
											   value={formik.values.etairia}
									/>
									<TextField size={'small'}
											   id={'thesi'}
											   name={'thesi'}
											   onChange={formik.handleChange}
											   onBlur={formik.handleBlur}
											   sx={{
												   padding: '2%',
												   marginBottom: '1%',
												   '& .MuiOutlinedInput-root': {
													   // ':focus-within': { border: '0.0px solid #ffffff !important' }
													   fontSize: 'smaller',
												   },
											   }}
											   variant="outlined"
											   value={formik.values.thesi}
									/>
								</Box>
								<Box sx={{display:'inline-flex'}}>
									<Box >
										<Button
											type={'submit'}
											sx={{
												fontSize: 'smaller'
											}}
										>
											<SaveOutlined />
										</Button>
									
									</Box>
									<Box>
										<Button>
											<CloseRounded onClick={()=>setShowExperienceEdit(false)}/>
										</Button>
									</Box>
								
								</Box>
							
							</Box>
						
						
						</Box>
					</FormikProvider>
				</>
				:
				<ListItem key={experienceData.id} className="announcement"
						  secondaryAction={
							  <>
								  <IconButton aria-label="edit" onClick={() => setShowExperienceEdit(true)}>
									  <EditOutlined/>
								  </IconButton>
								  <IconButton aria-label="edit" onClick={() => handleDelete(experienceData.id)}>
									  <Delete/>
								  </IconButton>
							  </>
						  }>
					<ListItemText>
						<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{dayjs(experienceData.date_from).format('YYYY-MM-DD')}</Typography>
						<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{dayjs(experienceData.date_to).format('YYYY-MM-DD')}</Typography>
						<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{experienceData.etairia}</Typography>
						<Typography sx={{fontSize:'smaller',color:'#616161', fontFamily: 'Open Sans-Regular'}}>{experienceData.thesi}</Typography>
					</ListItemText>
				</ListItem>
			}
		</div>
	);
};

export default Experience