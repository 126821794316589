//@flow
import FeedLayout from "../../../layouts/feedLayout/feedLayout";
import Box from "@mui/material/Box";
import {Button, Grid, Icon, ListItemAvatar, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import React, {useMemo, useState} from "react";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Images from "../../../../assets";
import ListItemIcon from "@mui/material/ListItemIcon";
import {useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_GROUPS_CREATE, ROUTE_PAGE_NOTIFICATIONS_SETTINGS} from "../../../../routers/routes";
import './notificationsList.css'
import {useTranslation} from "react-i18next";
import {PAGE_NOTIFICATIONS, PAGE_PERSONAL} from "../../../../locales/pages/namespaces";
import Link from "@mui/material/Link";



const NotificationsList = () => {
    const {t} = useTranslation(PAGE_NOTIFICATIONS)
    const history = useHistory()
    const [data,setData] = useState([
        {
            id: 1,
            primary: 'Paul Kellow',
            subOption: '22 ημ.',
            image1: Images.notificationAvatar,
            alt1: 'notificationAvatar_jpeg',
            image2: Images.threeDots,
            alt2: 'threeDots_svg'
        },
        {
            id: 2,
            primary: 'Paul Kellow',
            subOption: '22 ημ.',
            image1: Images.notificationAvatar,
            alt1: 'notificationAvatar_jpeg',
            image2: Images.threeDots,
            alt2: 'threeDots_svg'
        }
    ])
    return (
        <FeedLayout>
         
            
            <Box sx={{marginTop:'20px'}} className={'notification'}>
                <Box className="listHeader">
                    <Box className={"title"}>
                        <Typography sx={{fontSize:'large', fontWeight:'400',fontFamily: 'Open Sans-Regular'}}> {t('notifications')}</Typography>
                    </Box>
                    <Box className="notificationsBtns">
                            <Link
                                underline="hover"
                                onClick={()=>setData('')}
                                sx={{
                                    marginRight:'3%'
                                }}
                            >
                                <Typography
                                    sx={{ fontSize: 'smaller',fontFamily: 'Open Sans-Regular' }}
                                >
                                    {t('Mark all as read')}
                                </Typography>
                            </Link>
                            <Link
                                underline="hover"
                                href={getRouteUrl(ROUTE_PAGE_NOTIFICATIONS_SETTINGS)}
                            >
                                <Typography  sx={{ fontSize: 'smaller',fontFamily: 'Open Sans-Regular',color:'#050505' }}>{t('settings')}</Typography>
                            </Link>
                    </Box>
                </Box>
                <Paper elevation={0} sx={{borderRadius: 2}}>
                    <Box>
                        {(data)? data.map((x) => (
                            <MenuList key={x.id}>
                                <MenuItem>
                                    <ListItemAvatar>
                                        <Avatar alt={x.alt1} src={x.image1}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                        <Typography
                                            variant="body1"
                                            sx={{
                                            color: '#616161',
                                            fontSize: {xs: "x-small", sm: "smaller"},
                                        }}>
                                            Ο χρήστης <strong style={{color: '#050505'}}>{x.primary}</strong> ενημέρωσε
                                            την εικόνα προφίλ του.
                                    </Typography>
                                    }
                                        secondary={
                                        <Typography
                                            variant="body2"
                                            sx={{
                                            color: '#616161',
                                            fontSize: {xs: "x-small", sm: "smaller"},
                                        }}
                                        >
                                            {x.subOption}
                                        </Typography>}
                                    />
                                    <ListItemIcon>
                                        <img alt={x.alt2} src={x.image2}/>
                                    </ListItemIcon>
                                </MenuItem>
                            </MenuList>
                        )) : t('There are no new notifications')}
                    </Box>
                </Paper>
            </Box>
        </FeedLayout>
    )
}
export default NotificationsList