// @flow
import React from 'react';
import * as yup from "yup";
import axios from "axios";
import {FormikProvider, useFormik} from "formik";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Button, TextField} from "@mui/material";
import {CloseRounded, SaveOutlined} from "@mui/icons-material";
import useUserData from "../../../hooks/user/useUserData";

type Props = {

};

const CreateExperience = (props: Props) => {
	const {accessCode} = useUserData();
	const validationSchema = yup.object({
		date_from: yup
		.date()
		.required('Απαιτείται'),
		date_to: yup
		.date()
		.required('Απαιτείται'),
		etairia: yup
		.string()
		.required('Απαιτείται'),
		thesi: yup
		.string()
	})
	const onSubmit = (values) => {
		const newValues = {
			'date_from' : dayjs(values.date_from).format('YYYY-MM-DD'),
			'date_to' : dayjs(values.date_to).format('YYYY-MM-DD'),
			'etairia' : values.etairia,
			'thesi' : values.thesi,
		}
		axios({
			url: "https://dev.ostrialinked.gr/api/api.php?q=17a&scode=" + accessCode,
			method: 'post',
			data:newValues,
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then((response) => {
			console.log('store',response)
			window.location.reload();
			// props.setAddEducation(false)
			
		}).catch((error) => {
		
		})
	}
	
	const formik = useFormik({
		initialValues: {
			date_from: '',
			date_to: '',
			etairia: '',
			thesi: '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		
	})
	
	return (
		<div>
			<FormikProvider value={formik} onSubmit={formik.handleSubmit}
							noValidate>
				<Box component="form"
					 onSubmit={formik.handleSubmit}
					 noValidate>
					<Box sx={{display:'flex',justifyContent:'space-between'}}>
						<Box>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={formik.values.date_from}   //dayValue
									
									format="YYYY-MM-DD"
									size={'small'}
									onChange={(newValue) => {
										formik.setFieldValue('date_from',newValue)
									}}
									sx={{marginBottom: '10px'}}
									renderInput={(params) =>
										<TextField
											{...params}
											variant={'outlined'}
										/>}
									slotProps={{
										textField: {variant: 'outlined',size: 'small',}
									}}
								/>
							</LocalizationProvider>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={formik.values.date_to}   //dayValue
									label={'Ημ/νία έως'}
									format="YYYY-MM-DD"
									size={'small'}
									onChange={(newValue) => {
										formik.setFieldValue('date_to',newValue)
									}}
									sx={{marginBottom: '10px'}}
									renderInput={(params) =>
										<TextField
											{...params}
											variant={'outlined'}
										/>}
									slotProps={{
										textField: {variant: 'outlined',size: 'small', }
									}}
								/>
							</LocalizationProvider>
							<TextField size={'small'}
									   id={'etairia'}
									   name={'etairia'}
									   label={'Εταιρεία'}
									   onChange={formik.handleChange}
									   onBlur={formik.handleBlur}
									   sx={{
										   padding: '2%',
										   marginBottom: '1%',
										   '& .MuiOutlinedInput-root': {
											   // ':focus-within': { border: '0.0px solid #ffffff !important' }
											   fontSize: 'smaller',
										   },
									   }}
									   variant="outlined"
									   value={formik.values.etairia}
							/>
							<TextField size={'small'}
									   id={'thesi'}
									   name={'thesi'}
									   label={'Θέση'}
									   onChange={formik.handleChange}
									   onBlur={formik.handleBlur}
									   sx={{
										   padding: '2%',
										   marginBottom: '1%',
										   '& .MuiOutlinedInput-root': {
											   // ':focus-within': { border: '0.0px solid #ffffff !important' }
											   fontSize: 'smaller',
										   },
									   }}
									   variant="outlined"
									   value={formik.values.thesi}
							/>
						</Box>
						<Box sx={{display:'inline-flex'}}>
							<Box >
								<Button
									type={'submit'}
									sx={{
										fontSize: 'smaller'
									}}
								>
									<SaveOutlined />
								</Button>
							
							</Box>
							<Box>
								<Button>
									<CloseRounded onClick={()=>props.setAddExperience(false)}/>
								</Button>
							</Box>
						
						</Box>
					
					</Box>
				
				
				</Box>
			</FormikProvider>
		</div>
	);
};

export default CreateExperience